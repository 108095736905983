import React from "react";
import {useParams} from "react-router-dom";
import moment from "moment";

import {NoticeItemDetail} from "types/notice";

import header_back from "img/m/header-back.svg";

function MyNoticeDetail() {
    const params = useParams();
    const notice: NoticeItemDetail = {
        index: Number(params.bid),
        title: "메이크업 인스타그램 피드광고",
        contents: "시스템 점검에 관련된 내용입니다. 시스템 점검에 관련된 내용 입니다. 시스템 점검에 관련된 내용입니다. 시스템 점검에 관련된 내용 입니다.",
        date: new Date(2020, 9, 8)
    };

    const date = moment(notice.date).format('YYYY.MM.DD');

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">공지사항</span>
                    </div>
                </header>
                <div className="section">
                    <div className="board notice-list detail">
                        <div className="title_area">
                            <p className="date">{date}</p>
                            <p className="list-title">{notice.title}</p>
                        </div>
                        <div className="cont_area">
                            {notice.contents}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyNoticeDetail;