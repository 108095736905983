import React, {useEffect, useState} from "react";
import {Accordion, Card, Nav, Tab} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import MobileDetailComment from "components/m-detail-comment";
import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import {CampaignComment, CampaignComments} from "types/campaign";
import {
    GetProServicePriceOptionType,
    ProServiceItem,
    ProServiceItemDetail,
    ProServicePriceOption
} from "types/pro-service";

import icon_detail_back from "img/m/header-back-white.png";
import radio_checked_purple from "img/m/radio-checked-purple.png";
import icon_price_select_list from "img/m/icon-price_select-list.png";
import icon_minus from "img/m/icon-minus.png";
import icon_plus from "img/m/icon-plus.png";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import axios from "axios";
import {UserSimpleProfile} from "../../types/profile";
import {getLocalSession} from "../../modules/localSession";
import {UserType} from "../../types/user-type";
import default_profile from "../../img/m/request-human.png";

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function DivPrice(props: { minPrice?: number, maxPrice?: number }) {
    let price = "";

    if (props.minPrice != null && props.maxPrice != null) {
        price = `${props.minPrice.toLocaleString()}원 ~ ${props.maxPrice.toLocaleString()}원`;
    } else if (props.minPrice != null) {
        price = `${props.minPrice.toLocaleString()}원 ~`;
    } else if (props.maxPrice != null) {
        price = `~ ${props.maxPrice.toLocaleString()}원`;
    }

    return (<>{price}</>);
}

function DivAccordionCard(props: { header: JSX.Element, body: JSX.Element }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>("0");

    return (
        <Accordion className="type-detail"
                   defaultActiveKey="0"
                   onSelect={setActiveKey}>
            <Accordion.Item eventKey={"0"} bsPrefix={"#"}>
                <Accordion.Button as={"div"} className="head" bsPrefix={"#"}>
                    {props.header}
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    {props.body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

type CountSetState = React.Dispatch<React.SetStateAction<number>>;

function DivPriceOption(props: {
    checked: boolean, eventKey: string, option: ProServicePriceOption,
    priceOptionCount: number, setPriceOptionCount: CountSetState
}) {
    const minusCount = () => {
        if (props.priceOptionCount > 1) {
            props.setPriceOptionCount(props.priceOptionCount - 1);
        }
    };

    const plusCount = () => {
        props.setPriceOptionCount(props.priceOptionCount + 1);
    };

    let items: string[] = [];

    if (props.option.workingDay != 0)
        items = items.concat(`작업일 ${props.option.workingDay}일`);
    if (props.option.modifyCount != 0)
        items = items.concat(`수정 ${props.option.modifyCount}회`);
    if (props.option.draftCount != 0)
        items = items.concat(`시안 ${props.option.draftCount}개`);
    items = items.concat(props.option.customOptions);

    return (
        <Accordion.Item eventKey={props.eventKey} className={props.checked ? "item checked" : "item"} bsPrefix="#">
            <Accordion.Button as={"div"} className="head" bsPrefix="#">
                <div className="price_area">
                    <div className="price">{props.option.price.toLocaleString()}원</div>
                    <div className="grade">{GetProServicePriceOptionType(props.option.type)}</div>
                </div>
                <div className="checked_area">
                    <img src={radio_checked_purple} alt=""/>
                </div>
            </Accordion.Button>
            <Accordion.Body>
                <React.Fragment>
                    <Card.Body className="body">
                        <ul>
                            {items.map((option, key) => (
                                <li key={key}>
                                    <span className="dot">
                                        <img src={icon_price_select_list} alt=""/>
                                    </span>
                                    <span className="name">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </Card.Body>
                    {/*<Card.Footer className="foot" bsPrefix="#">
                        <div className="price_area">{(props.priceOptionCount * props.option.price).toLocaleString()} 원
                        </div>
                        <div className="quantity">
                            <a onClick={() => minusCount()}><img src={icon_minus} alt=""/></a>
                            <span className="num">{props.priceOptionCount.toLocaleString()}</span>
                            <a onClick={() => plusCount()}><img src={icon_plus} alt=""/></a>
                        </div>
                    </Card.Footer>*/}
                </React.Fragment>
            </Accordion.Body>
        </Accordion.Item>
    );
}

const enum CommentSortFilter {
    Order,
    HighRating,
    LowRating
}

type commentCountSetState = React.Dispatch<React.SetStateAction<number>>;

function Comment(props: { bid: any, setCommentCount: commentCountSetState }) {
    const [sortFilter, setSortFilter] = useState(CommentSortFilter.Order);
    const [comments, setComments] = useState<CampaignComment[]>([]);
    const [commentCount, setCommentCount] = useState<number>(0);

    useEffect(() => {
        axios.get(`api/ProService/pro/${props.bid}/comment/${sortFilter}`)
            .then(response => {
                const data: CampaignComments = response.data;
                setComments(data.comments);

                props.setCommentCount(data.totalCount);
                setCommentCount(data.totalCount);
            });
    }, [sortFilter, props.bid]);

    return (
        <>
            {/* 총 314개 최신순 / 높은 평점순 낮은 평점순 */}
            <div className="star_list">
                <div className="total">총 {commentCount.toLocaleString()}개</div>
                <div className="grade">
                    <a className={sortFilter == CommentSortFilter.Order ? "on blue" : ""}
                       onClick={() => setSortFilter(CommentSortFilter.Order)}>최신순</a>
                    <a className={sortFilter == CommentSortFilter.HighRating ? "on blue" : ""}
                       onClick={() => setSortFilter(CommentSortFilter.HighRating)}>높은 평점순</a>
                    <a className={sortFilter == CommentSortFilter.LowRating ? "on blue" : ""}
                       onClick={() => setSortFilter(CommentSortFilter.LowRating)}>낮은 평점순</a>
                </div>
            </div>

            {/* 리플들 */}
            <div className="c-review_list">
                {comments.map((comment, key) => (
                    <MobileDetailComment key={key} campaignComment={comment}/>
                ))}
            </div>
        </>
    );
}

function Variation(props: { bid: any }) {
    const session = getLocalSession();
    const [proVariation, setProVariation] = useState<any[]>([])

    useEffect(() => {
        axios.get(`api/ProService/pro/${props.bid}/variation`)
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.proItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setProVariation(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setProVariation(newItems);
                }
            });
    }, [props.bid]);

    return (
        <div className="similar_area">
            <div className="title">유사 서비스</div>
            <div className="contents">
                <Swiper className="thumbnail slide swiper-container"
                        speed={400}
                        spaceBetween={10}
                        width={190}>
                    {proVariation.map((service, key) => (
                        <SwiperSlide key={key}>
                            <MobileProServiceThumbnail height campaign={service}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

function ProDetail() {
    const params = useParams();
    const [zzim, setZzim] = useState(false);
    const [commentCount, setCommentCount] = useState<number>(0);
    const [profile, setProfile] = useState<UserSimpleProfile>();
    const [campaign, setCampaign] = useState<ProServiceItemDetail>();
    const proCategory = useSelector((state: RootState) => state.proCategory);
    const [activePriceOption, setActivePriceOption] = useState<AccordionEventKey>("0");
    const [priceOption, setPriceOption] = useState<AccordionEventKey>("0");
    const [priceOptionCount, setPriceOptionCount] = useState(1);
    const [isPayment, setIsPayment] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const session = getLocalSession();
    const navigate = useNavigate();

    const onPriceOptionSelect = (eventKey: AccordionEventKey) => {
        const activeKey = eventKey || "0";
        setActivePriceOption(activeKey);
        setPriceOption(activeKey);
        setPriceOptionCount(1);
    };

    useEffect(() => {
        axios.get(`api/ProService/pro/${params.bid}`)
            .then(response => {
                setCampaign(response.data);
            });
        axios.get(`api/ProService/pro/${params.bid}/profile`)
            .then(response => {
                setProfile(response.data);
            });

        let session = getLocalSession();

        if(!session) {
            setIsPayment(false);
        } else {
            axios.get(`api/Zzim/item/${UserType.Pro}/${params.bid}`)
                .then(response => {
                    let data = response.data;
                    setZzim(data.isZzim);
                });
            
            if(session.userType !== UserType.Advertiser && session.userType !== UserType.Influencer){
                setIsPayment(false);
            } else {
                let control = session.userType === UserType.Advertiser ? "MyAdvertiser" : "MyInfluencer";

                axios.get(`api/${control}/receipts/pro/${params.bid}/item`)
                    .then(response => {
                        let data2 = response.data;

                        if(data2.receiptId) {
                            setIsPaid(true);
                        }
                    });
            }
        }
    }, [params]);

    if (campaign == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    const totalRating: number = campaign.rating;
    const totalLinkCount: number = campaign.reviewCount;
    const serviceDesc: string = campaign.detail;
    const headerImages: string[] = campaign.images.length != 0 ? campaign.images : ["/img/dummy-detail01.png"];
    const imageAreas: string[] = campaign.images.length != 0 ? campaign.images : ["/img/dummy-detail01.png"];
    const hashTags: string[] = campaign.keywords;
    const proPriceOption: ProServicePriceOption[] = campaign.priceOptions;

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: params.bid, ZzimType: UserType.Pro, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    };

    const deleteItem = () => {
        if(confirm("삭제하시겠습니까?")) {
            axios.delete(`api/ProService/pro/${params.bid}`).then(response => {
                alert("삭제되었습니다.");
                navigate(`/home/pro-list/${campaign.categoryId}`);
            });
        }
    }

    return (
        <div>
            {/* 이미지 */}
            <div className="detail_product">
                <Link to={`/home/pro-list/${campaign.categoryId}`} className="detail_back">
                    <img src={icon_detail_back} alt=""/>
                </Link>
                <Swiper className="swiper-container detail_product-img"
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    {headerImages.map((imageSrc, key) => (
                        <SwiperSlide key={key}>
                            <a className="item">
                                <img src={imageSrc} style={{width: '100%', height: '300px', objectFit: 'cover'}} alt=""/>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* 상세보기  */}
            <div className="page-detail foot-on">
                {/* 가격정보 */}
                <div className="info_area">
                    <div className="cate">
                        <div className="cate_title">{proCategory.repo.getMainCategoryName(campaign.categoryId)}</div>
                        <div className="cate_heart">
                            {session && session.userType !== UserType.None ?
                            <div onClick={() => changeZzim()} className={`button heart purple${zzim ? " on" : ""}`}></div>
                            : null }
                        </div>
                    </div>
                    <div className="title">{campaign.title}</div>
                    <div className="price"><DivPrice minPrice={campaign.minPrice} maxPrice={campaign.maxPrice}/></div>
                </div>

                {/* 가격 선택 */}
                <div className="price_select">
                    <Accordion activeKey={activePriceOption || "0"}
                               defaultActiveKey={priceOption || "0"}
                               onSelect={onPriceOptionSelect}>
                        {proPriceOption.map((opt, key) => (
                            <DivPriceOption
                                key={key}
                                checked={priceOption == key.toLocaleString()}
                                eventKey={key.toLocaleString()}
                                option={opt}
                                priceOptionCount={priceOptionCount}
                                setPriceOptionCount={setPriceOptionCount}
                            />
                        ))}
                    </Accordion>
                </div>

                {/* 서비스정보, 평점 탭*/}
                <Tab.Container defaultActiveKey="info">
                    <div className="detail_tab">
                        <Nav className="navigation tab purple" as={"ul"}>
                            <Nav.Link eventKey="info" as={"li"}>
                                <a>서비스 정보</a>
                            </Nav.Link>
                            <Nav.Link eventKey="comment" as={"li"}>
                                <a>평점({commentCount}개)</a>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="info">
                            {/* 서비스 정보 */}
                            <div className="detail_contents show">
                                {/* 프로필 영역 */}
                                <div className="detail_profile">
                                    <div className="info">
                                        <div className="profile_img">
                                            <img src={profile.profileImg || default_profile} alt=""/>
                                        </div>
                                        <div className="profile_text">
                                            <div className="name">{profile.name}</div>
                                            <div className="contact">{profile.comment}</div>
                                        </div>
                                    </div>
                                    <div className="button_area">
                                        <Link to={`/profile/pro-profile/${profile.userId}`}
                                              className="button detail-profile">프로필</Link>
                                    </div>
                                </div>
                                {/* 서비스 설명 */}
                                <div className="service_desc">
                                    <div className="title">서비스 설명</div>
                                    <div className="desc" dangerouslySetInnerHTML={{
                                        __html: serviceDesc
                                    }}>
                                    </div>
                                </div>
                                {/* 이미지 영역 */}
                                {/*<div className="img_area">
                                    {imageAreas.map((item, key) => (
                                        <div className="item" key={key}>
                                            <img src={item} alt=""/>
                                        </div>
                                    ))}
                                </div>*/}

                                {/*/S 규정*/}
                                <div className="as_area">
                                    <DivAccordionCard
                                        header={<span className="name">A/S규정</span>}
                                        body={
                                            <>
                                                서채, 컬러, 배치 등 <br/>
                                                선택된 1개의 시안 내에서의 수정<br/>
                                                시안 내 수정 가능한 범위 내<br/>
                                            </>
                                        }
                                    />
                                </div>

                                {/* 취소 및 환불규정 */}
                                <div className="cancel_area">
                                    <DivAccordionCard
                                        header={<span className="name">취소 및 환불규정</span>}
                                        body={
                                            <>
                                                1. 전문가와 의뢰인 간의 상호 협의 후 청약철회가 가능합니다. <br/>
                                                2. 전문가의 귀책 사유로 디자인 작업을 시작하지...
                                            </>
                                        }
                                    />
                                </div>

                                {/* 해시태그 */}
                                <div className="hashtag_area">
                                    {hashTags.map((tag, key) => (
                                        <a className="button round-deep purple" key={key}>#{tag}</a>
                                    ))}
                                </div>

                                {/* 유사 서비스*/}
                                <Variation bid={params.bid}/>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="comment">
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

                {/* 평점 */}
                <div className="detail_contents02">
                    {/* 결과 별점 */}
                    <div className="total_warp">
                        <div className="total_count">{totalRating}</div>
                        <div className="total_star">
                            <DivCommentStar num={1} star={totalRating}/>
                            <DivCommentStar num={2} star={totalRating}/>
                            <DivCommentStar num={3} star={totalRating}/>
                            <DivCommentStar num={4} star={totalRating}/>
                            <DivCommentStar num={5} star={totalRating}/>
                        </div>
                        <div className="total_link">{totalLinkCount.toLocaleString()}개</div>
                    </div>

                    <Comment bid={params.bid} setCommentCount={setCommentCount}/>
                </div>
            </div>

            {/* 채팅하기 / 결제하기 */}
            {isPayment ?
            <div className="foot-btn">
                <div className="detail-foot_button">
                    {/*<div><a className="button full purple">채팅하기</a></div>*/}
                    <div className="w-100">
                        {isPaid ?
                        <a className="button full gray">결제완료</a>
                        : profile.memberStatus ? 
                            <Link to={`/home/pro-option/${params.bid}`} className="button full black">결제하기</Link>
                            : <a className="button full gray">탈퇴한 회원의 서비스입니다.</a>
                        }
                    </div>
                </div>
            </div>
            : session && session.id == profile.userId ?
            <div className="foot-btn">
                <div className="detail-foot_button">
                    <div className="w-100"><a onClick={() => deleteItem()} className="button full black">삭제</a></div>
                    {/*<div><a className="button full black">수정</a></div>*/}
                </div>
            </div>
            : <></>
            }
        </div>
    );
}

export default ProDetail;