import React, {useRef, useState} from "react";
import {Form} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import setUser from "utils/setUser";

import header_back from "img/m/header-back.svg";
import axios from "axios";

function Join() {
    const [page, setPage] = useState(0);
    const [allChecked, setAllChecked] = useState(false);
    const [agree1, setAgree1] = useState(false);
    const [agree2, setAgree2] = useState(false);

    const [nickName, setNickName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password1, setPassword1] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [phoneAuthCode, setPhoneAuthCode] = useState<string>("");
    const navigate = useNavigate();

    const emailRef = useRef<HTMLInputElement>(null);
    const nickRef = useRef<HTMLInputElement>(null);
    const passwordRef1 = useRef<HTMLInputElement>(null);
    const passwordRef2 = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const authRef = useRef<HTMLInputElement>(null);
    
    const regPass = /^.*(?=^.{8,20}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/;
    const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const regPhone = /^\d{3}\d{3,4}\d{4}$/;

    const handleAllCheckChange = (e: any) => {
        setAllChecked(!allChecked);
        setAgree1(!allChecked);
        setAgree2(!allChecked);
    }

    const handleAgreeCheck1Change = (e: any) => {
        setAllChecked(!agree1 && agree2);
        setAgree1(!agree1);
    }

    const handleAgreeCheck2Change = (e: any) => {
        setAllChecked(agree1 && !agree2);
        setAgree2(!agree2);
    }

    const onBack = () => {
        if (page == 0) {
            history.back();
        }

        setPage(page - 1);
    }
    
    const onNext = () => {
        if('' == nickName) {
            alert("닉네임을 입력하세요.");
            if(nickRef.current !== null) nickRef.current.focus();
            return;
        }
        
        if('' == email) {
            alert("이메일을 입력하세요.");
            if(emailRef.current !== null) emailRef.current.focus();
            return;
        }

        if(!regEmail.test(email)) {
            alert("이메일 형식이 맞지 않습니다.");
            if(emailRef.current !== null) emailRef.current.focus();
            return;
        }

        if('' == password1) {
            alert("비밀번호를 입력하세요.");
            if(passwordRef1.current !== null) passwordRef1.current.focus();
            return;
        }

        if(!regPass.test(password1)) {
            alert("영문 대소문자, 숫자, 특수문자 조합으로 8~20자 이내 입력해주세요.");
            if(passwordRef1.current !== null) passwordRef1.current.focus();
            return;
        }

        if('' == password2) {
            alert("비밀번호를 입력하세요.");
            if(passwordRef2.current !== null) passwordRef2.current.focus();
            return;
        }

        if(password2 !== password1) {
            alert("비밀번호가 같지 않습니다.");
            if(passwordRef2.current !== null) passwordRef2.current.focus();
            return;
        }

        setPage(1);
    };
    
    const sendAuthCode = () => {
        if(regPhone.test(phoneNumber)) {
            let authNum: string = "";

            for(let i=0; i<6; i++) {
                authNum += Math.floor(Math.random() * 10);
            }

            {/* TODO: 인증문자 전송 API 구현 필요 */}

            {/* 임시 */}
            setPhoneAuthCode(authNum);
        }
    };

    const handleSignUpSubmit = () => {
        if('' == phoneNumber) {
            alert("휴대폰 번호를 입력하세요.");
            if(phoneRef.current !== null) phoneRef.current.focus();
            return;
        }

        if(!regPhone.test(phoneNumber)) {
            alert("휴대폰 번호 형식이 맞지 않습니다.");
            if(phoneRef.current !== null) phoneRef.current.focus();
            return;
        }

        if('' == phoneAuthCode) {
            alert("인증번호를 입력하세요.");
            if(authRef.current !== null) authRef.current.focus();
            return;
        }
        
        {/* TODO: 인증문자 비교 로직 및 API 구현 필요 */}
        
        if(!agree1) {
            alert("이용약관 및 개인정보 취급방침에 동의해야 합니다.");
            return;
        }
        
        axios.post('/api/Account/signup', {
            nickName: nickName,
            email: email,
            password: password1,
            phoneNumber: phoneNumber,
            phoneAuthCode: phoneAuthCode,
            isSubscribeEvent: agree2
        }).then(response => {
            setUser(response.data);

            navigate('/');
            navigate('/member/welcome');
        }).catch(error => {
            if (500 == error.response.status) {
                alert(error.response.data?.detail);
            } else {
                console.log(error);
            }

            return;
        });
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a className="back" onClick={() => onBack()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">회원가입</span>
                    </div>
                    <div className="header_right">
                    </div>
                </header>

                {page == 0 ?
                    <>
                        <div className="member join">
                            <form action="">
                                <div className="layout line">
                                    <Form.Control type="text" className="input basic" bsPrefix="#" placeholder="닉네임"
                                                  value={nickName} onChange={e => setNickName(e.target.value)}
                                                  ref={nickRef}/>
                                </div>
                                <div className="layout line">
                                    <Form.Control type="email" className="input basic" bsPrefix="#" placeholder="이메일"
                                                  value={email} onChange={e => setEmail(e.target.value)}
                                                  ref={emailRef}/>
                                </div>
                                <div className="layout line">
                                    <Form.Control type="password" className="input basic" bsPrefix="#"
                                                  placeholder="비밀번호(영문대소문자,숫자,특수문자조합 8~20자이내)"
                                                  value={password1} onChange={e => setPassword1(e.target.value)}
                                                  ref={passwordRef1}/>
                                </div>
                                <div className="layout line">
                                    <Form.Control type="password" className="input basic" bsPrefix="#"
                                                  placeholder="비밀번호 재입력"
                                                  value={password2} onChange={e => setPassword2(e.target.value)}
                                                  ref={passwordRef2}/>
                                </div>
                            </form>
                        </div>

                        <div className="bottom">
                            <div className="button full black radius" onClick={() => onNext()}>다음</div>
                        </div>
                    </>
                    : <>
                        <div className="member join">
                            <form action="">
                                <div className="layout line">
                                    <Form.Control type="number" className="input basic" bsPrefix="#"
                                                  placeholder="휴대폰 번호(-없이 입력)"
                                                  value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                                                  ref={phoneRef}/>
                                </div>
                                <div className="layout line">
                                    <div className={regPhone.test(phoneNumber) ? 
                                        "button full black radius" : "button full gray_bt radius"}
                                        onClick={() => sendAuthCode()}>인증문자 받기</div>
                                </div>
                                <div className="layout line">
                                    <Form.Control type="number" className="input basic" bsPrefix="#"
                                                  placeholder="인증번호 입력"
                                                  value={phoneAuthCode}
                                                  onChange={e => setPhoneAuthCode(e.target.value)}
                                                  ref={authRef}/>
                                </div>
                            </form>
                        </div>

                        <div className="bottom">
                            <div className="layout">
                                <div className="agree_title">
                                    <div className="checkbox">
                                        <Form.Check.Label>
                                            <Form.Check.Input type="checkbox" id="allChecked"
                                                              className="check_input"
                                                              checked={allChecked}
                                                              onChange={handleAllCheckChange}
                                            ></Form.Check.Input>
                                            <Form.Check.Label htmlFor="allChecked">
                                                <span className={`circle ${allChecked ? "checked" : ""}`}></span>
                                                <span style={{marginLeft: "4.4rem"}}>전체 약관동의</span>
                                            </Form.Check.Label>
                                        </Form.Check.Label>
                                    </div>
                                </div>
                            </div>
                            <div className="layout line type-profile">
                                <div className="checkbox" style={{marginBottom: "3rem"}}>
                                    <Form.Check.Label>
                                        <Form.Check.Input type="checkbox" id="agree1"
                                                          className="check_input"
                                                          checked={agree1}
                                                          onChange={handleAgreeCheck1Change}
                                        ></Form.Check.Input>
                                        <Form.Check.Label htmlFor="agree1">
                                            <span className={`circle ${agree1 ? "checked" : ""}`}></span>
                                            <span className="name agree_text type04">이용약관 및 개인정보 취급방침 동의 (필수)</span>
                                        </Form.Check.Label>
                                    </Form.Check.Label>
                                </div>
                                <div className="checkbox" style={{marginBottom: "3rem"}}>
                                    <Form.Check.Label>
                                        <Form.Check.Input type="checkbox" id="agree2"
                                                          className="check_input"
                                                          checked={agree2}
                                                          onChange={handleAgreeCheck2Change}
                                        ></Form.Check.Input>
                                        <Form.Check.Label htmlFor="agree2">
                                            <span className={`circle ${agree2 ? "checked" : ""}`}></span>
                                            <span className="name agree_text type04">이벤트 알림 및 이메일 수신 (선택)</span>
                                        </Form.Check.Label>
                                    </Form.Check.Label>
                                </div>
                            </div>

                            {/*TODO: 가입 완료 버튼 구현 필요*/}
                            <div className="button full black radius" onClick={handleSignUpSubmit}>
                                가입완료
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    );
}

export default Join;