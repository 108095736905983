import React, {useEffect, useRef, useState} from "react";
import {Nav, Tab} from "react-bootstrap";

import header_back from "img/m/header-back.svg";
import file_photo from "img/m/file-photo.svg";
import file_speaker from "img/m/file-speaker.svg";
import axios from "axios";
import {BankCode} from "../../utils/bank-code";
import {getPlatformName, PlatformType} from "../../types/platform";
import {UpdateCampaignMemberRequest} from "../../types/advertiser-request";
import WishLocation from "../member/wish-location";
import WishAdvertisement from "../member/wish-ad";
import {getLocalSession, removeLocalSession} from "../../modules/localSession";
import {useNavigate} from "react-router-dom";

let memData: any = {};

type setStrArrState =  React.Dispatch<React.SetStateAction<string[]>>;

function DivAreaBadge(props: { areaName: string, setAreaName: setStrArrState }) {
    const deleteArea = () => {
        props.setAreaName(prevState => prevState.filter(item => item !== props.areaName));
    };
    
    return (
        <a onClick={() => deleteArea()} className="button close_type blue">
            <span className="wrap">
                <span className="name">{props.areaName}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function DivInterestBadge(props: { interest: string, setInterest: setStrArrState }) {
    const deleteInterest = () => {
        props.setInterest(prevState => prevState.filter(item => item !== props.interest));
    };
    
    return (
        <a onClick={() => deleteInterest()} className="button close_type blue">
            <span className="wrap">
                <span className="name">{props.interest}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function AdProfileModify() {
    const session = getLocalSession();
    const [pwd, setPwd] = useState<string>("");
    const [repeatPwd, setRepeatPwd] = useState<string>("");
    const [profileData, setProfileData] = useState<UpdateCampaignMemberRequest>();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [platformSel, setPlatformSel] = useState<string[]>([]);
    const [wishLoc, setWishLoc] = useState(false);
    const [wishAd, setWishAd] = useState(false);
    const [wishLocSel, setWishLocSel] = useState<string[]>([]);
    const [wishAdSel, setWishAdSel] = useState<string[]>([]);
    const [tab, setTab] = useState<string>("defaultInfo");
    const navigate = useNavigate();

    const CampaignMemberRef = {
        name: useRef<HTMLInputElement>(null),
        identificationNumber: useRef<HTMLInputElement>(null),
        businessOwner: useRef<HTMLInputElement>(null),
        address1: useRef<HTMLInputElement>(null),
        address2: useRef<HTMLInputElement>(null),
        contactNumber: useRef<HTMLInputElement>(null),
        bankNumber: useRef<HTMLInputElement>(null),
        bankCode: useRef<HTMLSelectElement>(null),
        bankOwner: useRef<HTMLInputElement>(null),
        homepage: useRef<HTMLInputElement>(null),
        comment: useRef<HTMLInputElement>(null),
        detailComment: useRef<HTMLTextAreaElement>(null),
        pwd: useRef<HTMLInputElement>(null),
        repeatPwd: useRef<HTMLInputElement>(null)
    }

    useEffect(() => {
        axios.get(`api/MyAdvertiser/detail`)
            .then(response => {
                let data = response.data;
                setProfileData(data);
                setProfileImage(data.profileImg);
                setWishLocSel(data.regionOfInterest);
                setWishAdSel(data.areaOfInterest);
                setPlatformSel(data.platforms);

                memData = {
                    IsBusiness: data.isBusiness,
                    Name: data.name,
                    ProfileImg: data.profileImg,
                    IdentificationNumber: data.identificationNumber,
                    BusinessOwner: data.businessOwner,
                    Address1: data.address1,
                    Address2: data.address2,
                    ContactNumber: data.contactNumber,
                    BankNumber: data.bankNumber,
                    BankCode: data.bankCode,
                    BankOwner: data.bankOwner,
                    RegionOfInterest: data.regionOfInterest,
                    AreaOfInterest: data.areaOfInterest,
                    Platforms: data.platforms,
                    Homepage: data.homepage,
                    Comment: data.comment,
                    DetailComment: data.detailComment
                };
            });
    }, []);

    if (profileData == null) {
        return (<></>);
    }
    
    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        let imgRead = profileImageReader.result;
        setProfileImage(imgRead);
        memData.ProfileImg = imgRead || "";
    }

    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    }

    const handleDataChange = (e: any) => {
        let targetName = e.target.name.charAt(0).toLowerCase() + e.target.name.substring(1);
        
        setProfileData({
            ...profileData,
            [targetName]: e.target.value
        });

        memData[e.target.name] = e.target.value;
    };

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];

    const selectPlatform = (platformType: number) => {
        let platformName = getPlatformName(platformType);
        if (platformSel.includes(platformName)) {
            setPlatformSel(platformSel.filter(item => item !== platformName));
        } else {
            setPlatformSel([...platformSel, platformName]);
        }
    };
    
    const logout = () => {
        axios.post("api/Account/logout")
            .then(response => {
                removeLocalSession();
                location.href = "/";
            });
    }
    
    const updateProfile = () => {
        let regNum = /^[0-9]+$/;

        if(!("" == pwd && "" == repeatPwd)) {
            if (pwd !== repeatPwd) {
                alert("비밀번호가 일치하지 않습니다.");
                setTab("defaultInfo");
                if (CampaignMemberRef.pwd.current !== null) CampaignMemberRef.pwd.current.focus();
                return;
            }
        }

        if (!memData.IsBusiness) {
            if ('' == memData.Name) {
                alert("이름을 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.name.current !== null) CampaignMemberRef.name.current.focus();
                return;
            }

            /*if ('' == memData.IdentificationNumber) {
                alert("주민번호를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("주민번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }*/

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankCode.current !== null) CampaignMemberRef.bankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankOwner.current !== null) CampaignMemberRef.bankOwner.current.focus();
                return;
            }
        } else {
            if ('' == memData.Name) {
                alert("기업명을 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.name.current !== null) CampaignMemberRef.name.current.focus();
                return;
            }

            if ('' == memData.IdentificationNumber) {
                alert("사업자번호를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if ('' == memData.BusinessOwner) {
                alert("대표자명을 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.businessOwner.current !== null) CampaignMemberRef.businessOwner.current.focus();
                return;
            }

            if ('' == memData.Address1) {
                alert("주소를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.address1.current !== null) CampaignMemberRef.address1.current.focus();
                return;
            }

            if ('' == memData.Address2) {
                alert("상세주소를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.address2.current !== null) CampaignMemberRef.address2.current.focus();
                return;
            }

            if ('' == memData.ContactNumber) {
                alert("담당자 연락처를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.contactNumber.current !== null) CampaignMemberRef.contactNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.ContactNumber)) {
                alert("담당자 연락처를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.contactNumber.current !== null) CampaignMemberRef.contactNumber.current.focus();
                return;
            }

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankCode.current !== null) CampaignMemberRef.bankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                setTab("profileInfo");
                if (CampaignMemberRef.bankOwner.current !== null) CampaignMemberRef.bankOwner.current.focus();
                return;
            }
        }

        if (wishLocSel.length < 1) {
            alert("관심 지역을 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if (wishAdSel.length < 1) {
            alert("관심 분야를 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if (platformSel.length < 1) {
            alert("광고 채널을 1개 이상 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if ('' == memData.Homepage) {
            alert("자사 홈페이지를 입력해주세요.");
            setTab("extraInfo");
            if (CampaignMemberRef.homepage.current !== null) CampaignMemberRef.homepage.current.focus();
            return;
        }

        if ('' == memData.Comment) {
            alert("한줄 자기 소개를 입력해주세요.");
            setTab("extraInfo");
            if (CampaignMemberRef.comment.current !== null) CampaignMemberRef.comment.current.focus();
            return;
        }

        if ('' == memData.DetailComment) {
            alert("상세 소개를 입력해주세요.");
            setTab("extraInfo");
            if (CampaignMemberRef.detailComment.current !== null) CampaignMemberRef.detailComment.current.focus();
            return;
        }

        memData.Platforms = platformSel;
        memData.RegionOfInterest = wishLocSel;
        memData.AreaOfInterest = wishAdSel;
        console.log(memData);

        axios.patch('/api/MyAdvertiser', memData).then(response => {
            if("" !== pwd) {
                axios.patch('/api/Account/password', {Password: pwd}).then(response => {
                    alert("프로필 수정이 완료되었습니다.");
                    navigate('/my/my-ad-main');
                }).catch(error => {
                    console.log(error);
                    alert("시스템 오류가 발생했습니다.");
                    return;
                });
            } else {
                alert("프로필 수정이 완료되었습니다.");
                navigate('/my/my-ad-main');
            }
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");
            return;
        });
    };

    if(wishLoc) {
        return (<WishLocation setWishLoc={setWishLoc} setSelect={setWishLocSel}/>);
    } else if(wishAd) {
        return (<WishAdvertisement setWishAd={setWishAd} setSelect={setWishAdSel}/>);
    } else {
        return (
            <div>
                <div className="contents mobile profile-modify-bg">
                    <header className="header-member">
                        <div className="header_left">
                            <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">개인정보 수정</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => logout()} className="logout">로그아웃</a>
                        </div>
                    </header>
                    {/* 프로필 사진 영역 */}
                    <div className="profile-modify">
                        <div className="profile-area">
                            <div className="my-main_profile">
                                <div className="file_upload center">
                                    <div className="circle">
                                        {!profileImage ? (<img className="speaker" src={file_speaker} alt=""/>)
                                            : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                                    </div>
                                    <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                                    <img className="photo_button" src={file_photo} alt=""/>
                                </div>
                            </div>
                        </div>

                        <Tab.Container defaultActiveKey="defaultInfo">
                            {/* 탭 영역 */}
                            <Nav className="navigation tab blue tab-area" as={"ul"}>
                                <Nav.Link eventKey="defaultInfo" as={"li"} active={tab === "defaultInfo"}>
                                    <a onClick={() => setTab("defaultInfo")}>기본 정보</a>
                                </Nav.Link>
                                <Nav.Link eventKey="profileInfo" as={"li"} active={tab === "profileInfo"}>
                                    <a onClick={() => setTab("profileInfo")}>
                                        {profileData.isBusiness ? "기업정보" : "프로필정보"}
                                    </a>
                                </Nav.Link>
                                <Nav.Link eventKey="extraInfo" as={"li"} active={tab === "extraInfo"}>
                                    <a onClick={() => setTab("extraInfo")}>추가정보</a>
                                </Nav.Link>
                            </Nav>

                            {/* 콘텐츠 아레아 */}
                            <Tab.Content>
                                <Tab.Pane eventKey="defaultInfo" className="requirements-list-body"
                                          active={tab === "defaultInfo"}>
                                    <div className="detail_area">
                                        <div className="item">
                                            <span className="title">닉네임</span>
                                            <span className="info">{session?.displayName}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">이메일</span>
                                            <span className="info">{profileData.email}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={pwd}
                                                   onChange={(e) => setPwd(e.target.value)}
                                                   ref={CampaignMemberRef.pwd}/>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호 재입력</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={repeatPwd}
                                                   onChange={(e) => setRepeatPwd(e.target.value)}
                                                   ref={CampaignMemberRef.repeatPwd}/>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="profileInfo" className="requirements-list-body"
                                          active={tab === "profileInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02 border-0">
                                            <div className="item_title">
                                                <div className="title">신상정보</div>
                                            </div>
                                        </div>
                                    {profileData.isBusiness ?
                                        <>
                                        <div className="item type02 pt-0">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">기업명</div>
                                                    <input type="text" className="input basic" name="Name"
                                                           value={profileData.name} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.name} placeholder="기업명을 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">사업자 등록번호</div>
                                                    <input type="text" className="input basic"
                                                           name="IdentificationNumber"
                                                           value={profileData.identificationNumber}
                                                           onChange={handleDataChange}
                                                           ref={CampaignMemberRef.identificationNumber}
                                                           placeholder="- 제외, 숫자만 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">대표자명</div>
                                                    <input type="text" className="input basic"
                                                           name="BusinessOwner"
                                                           value={profileData.businessOwner}
                                                           onChange={handleDataChange}
                                                           ref={CampaignMemberRef.businessOwner}
                                                           placeholder="대표자명을 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">주소</div>
                                                    <input type="text" className="input basic" name="Address1"
                                                           value={profileData.address1} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.address1}
                                                           placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Address2"
                                                           value={profileData.address2} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.address2}
                                                           placeholder="상세주소를 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">담당자 연락처</div>
                                                    <input type="text" className="input basic" name="ContactNumber"
                                                           value={profileData.contactNumber}
                                                           onChange={handleDataChange}
                                                           ref={CampaignMemberRef.contactNumber}
                                                           placeholder="- 제외, 숫자만 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">출금계좌번호</div>
                                                    <input type="text" className="input basic" name="BankNumber"
                                                           value={profileData.bankNumber} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.bankNumber}
                                                           placeholder="'-' 없이 계좌번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">은행명</div>
                                                    <div className="con">
                                                        <div className="write-select">
                                                            <select name="BankCode" value={profileData.bankCode}
                                                                    onChange={handleDataChange}
                                                                    ref={CampaignMemberRef.bankCode}>
                                                                {BankCode.map((bank, key) => (
                                                                    <option key={key} value={bank.code}>{bank.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">예금주</div>
                                                    <input type="text" className="input basic" name="BankOwner"
                                                           value={profileData.bankOwner} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.bankOwner}
                                                           placeholder="예금주를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <>
                                        <div className="item type02 pt-0">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">이름</div>
                                                    <input type="text" className="input basic" name="Name"
                                                           value={profileData.name} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.name} placeholder="실명을 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">주민번호</div>
                                                    <input type="text" className="input basic"
                                                           name="IdentificationNumber"
                                                           value={profileData.identificationNumber}
                                                           onChange={handleDataChange}
                                                           ref={CampaignMemberRef.identificationNumber}
                                                           placeholder="'-' 없이 주민번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">계좌번호</div>
                                                    <input type="text" className="input basic" name="BankNumber"
                                                           value={profileData.bankNumber} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.bankNumber}
                                                           placeholder="'-' 없이 계좌번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">은행명</div>
                                                    <div className="con">
                                                        <div className="write-select">
                                                            <select name="BankCode" value={profileData.bankCode}
                                                                    onChange={handleDataChange}
                                                                    ref={CampaignMemberRef.bankCode}>
                                                                {BankCode.map((bank, key) => (
                                                                    <option key={key}
                                                                            value={bank.code}>{bank.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">예금주</div>
                                                    <input type="text" className="input basic" name="BankOwner"
                                                           value={profileData.bankOwner} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.bankOwner}
                                                           placeholder="예금주를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="extraInfo" className="requirements-list-body"
                                          active={tab === "extraInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">거주 및 활동 지역</div>
                                                <a onClick={() => setWishLoc(true)}>선택하기</a>
                                            </div>
                                            <div className="item_body">
                                            {wishLocSel.map((loc, key) => (
                                                <DivAreaBadge areaName={loc} key={key} setAreaName={setWishLocSel}/>
                                            ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">나의 관심 분야</div>
                                                <a onClick={() => setWishAd(true)}>선택하기</a>
                                            </div>
                                            <div className="item_body">
                                            {wishAdSel.map((ad, key) => (
                                                <DivInterestBadge interest={ad} key={key} setInterest={setWishAdSel}/>
                                            ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">광고 채널</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="grid grid-3">
                                                {platforms.map((p, idx) => (
                                                    <div key={idx}>
                                                        <a className={`button ad_channel _onOff
                                                        ${platformSel.includes(getPlatformName(p.type)) ? ' on' : ''}
                                                        `}
                                                           onClick={() => selectPlatform(p.type)}>{p.name}</a>
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">자사 홈페이지</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" placeholder="http://"
                                                           name="Homepage" value={profileData.homepage}
                                                           onChange={handleDataChange}
                                                           ref={CampaignMemberRef.homepage}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">한줄 자기소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Comment"
                                                           value={profileData.comment} onChange={handleDataChange}
                                                           ref={CampaignMemberRef.comment} placeholder="자기소개를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">상세 소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                <textarea className="textarea basic" name="DetailComment"
                                                          value={profileData.detailComment} onChange={handleDataChange}
                                                          ref={CampaignMemberRef.detailComment}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                        <div className="navigation height">
                            <div className="foot-btn">
                                <div className="detail-foot_one_button">
                                    <div>
                                        <a onClick={() => updateProfile()} className="button full blue">수정</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AdProfileModify;