import React from "react";
import moment from "moment";
import default_profile from "img/m/request-human.png";
import {CampaignComment} from "types/campaign";
import {getLocalSession} from "../modules/localSession";
import axios from "axios";

function MobileDetailCampaignComment(props: { campaignComment: CampaignComment, adUserId: string, bid: number }) {
    const date = moment(props.campaignComment.date).format('YYYY.MM.DD');
    const session = getLocalSession();

    const giveReward = () => {
        axios.post(`/api/Advertiser/feed_reward/${props.bid}/${props.campaignComment.userId}`)
            .then(response => {
                alert("리워드가 지급되었습니다.");
                window.location.reload();
            }).catch(error => {
                console.log(error);
                alert("리워드 지급에 실패했습니다.");
                return;
        });
    };
    
    const getCommentDesc = () => {
        const desc = props.campaignComment.desc;
        
        if(desc && desc.length > 0) {
            let content = desc;
            const descs: string[] = [];

            desc.split(' ').forEach(d => {
                d.split('\n').forEach(d2 => {
                    const txt = d2.trim();
                    descs.push(txt);
                });
            });

            descs.forEach((d, i) => {
                if(d.startsWith('http://')) {
                    content = content.replace(d, `<a href="${d}" target="_blank">${d}</a>`);
                } else if(d.startsWith('https://')) {
                    content = content.replace(d, `<a href="${d}" target="_blank">${d}</a>`);
                } else if(d.startsWith('www.')) {
                    content = content.replace(d, `<a href="https://${d}" target="_blank">${d}</a>`);
                }
            });

            return content;
        }

        return desc;
    }

    return (
        <div className="item">
            <div className="profile">
                <div className="review_thumbnail">
                    <div>
                        <img className="comment-profile_img" src={props.campaignComment.profileImg || default_profile} alt=""/>
                    </div>
                </div>
                <div className="info">
                    <div className="name">
                        {props.campaignComment.name}&nbsp;
                        {session && props.adUserId === session.id && !props.campaignComment.rewardComplete ?
                            <a onClick={() => giveReward()} className="button round-deep purple">리워드지급</a>
                            : null}
                    </div>
                </div>
            </div>
            <div className="desc" dangerouslySetInnerHTML={{__html: getCommentDesc().replace(/(?:\r\n|\r|\n)/g, '<br />')}}>
                {/*/!* html에서의 말줄임은 1줄 또는 2줄 과 같이 단일 속성으로만 지정할 수 있습니다. 한문장의 끝에 1번이 최대입니다. *!/*/}
                {/*https://search.naver.com/search.naver?where... /!* ... 이후 다시 글 나오는거 불가능 *!/*/}
                {/*<br/>*/}
                {/*블로그에 글올렸습니다.*/}
                {/*<br/>*/}
                {/*/!* 이미지 *!/*/}
                {/*<img src={dummy_detail01} alt=""/>*/}
            </div>
            <div className="date">{date}</div>
        </div>
    );
}

export default MobileDetailCampaignComment;