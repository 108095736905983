import React, {useEffect, useRef, useState} from "react";

import header_refresh from "img/m/header-refresh.svg";
import header_close from "img/m/header-close.svg";
import write_right from "img/m/write-right.png";
import write_pro_plus from "img/m/write-pro_plus.png";
import write_pro_minus from "img/m/icon-minus.png";
import {MainCategory, SubCategory} from "../../types/category";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import ReactQuill from "react-quill";
import {
    CreateProServiceCustomOptionRequest,
    CreateProServicePriceOptionRequest,
    CreateProServiceRequest
} from "../../types/pro-service-request";
import {GetProServicePriceOptionType, ProServicePriceOptionType} from "../../types/pro-service";

const proData: any = {
    CategoryId: 0,
    SubCategoryId: 0,
    Title: '',
    Detail: '',
    Keywords: '',
    PriceOptionRequests: [],
    CustomOptionRequests: [],
    text: ''
};

let Step1Ref: any = {};

function DivRichEditor(props: { value: string, setValue: (e: ReactQuill.UnprivilegedEditor) => void,
    setRef: (ref: ReactQuill | null) => void })
{
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}],
            [{'size': ['small', false, 'large', 'huge']}],
            ['image'],
            [{'list': 'bullet'}, {'list': 'ordered'}],
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'color',
        'size',
        'image',
        'bullet', 'list',
    ];

    return (
        <ReactQuill theme="snow" value={props.value} modules={modules} formats={formats} ref={ref => props.setRef(ref)}
                    onChange={(c, d, s, editor) => props.setValue(editor)}/>
    );
}

function Step1(props: { onNext: Function }) {
    const [categories, setCategories] = useState<MainCategory[]>([]);
    const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
    const [selSubCate, setSelSubCate] = useState(false);
    const [subCateName, setSubCateName] = useState('상세분야 선택');
    const [selCate, setSelCate] = useState(false);
    const [cateName, setCateName] = useState('카테고리 선택');
    const [step1Data, setStep1Data] = useState<CreateProServiceRequest>({
        CategoryId: 0,
        SubCategoryId: 0,
        Title: '',
        Detail: '',
        Keywords: '',
        PriceOptionRequests: [],
        CustomOptionRequests: []
    });

    const proCategory = useSelector((state: RootState) => state.proCategory);

    Step1Ref = {
        Title: useRef<HTMLInputElement>(null),
        Keywords: useRef<HTMLInputElement>(null),
        Detail: null
    };

    const selectCate = (cateId: number, cateName: string) => {
        setSelCate(false);
        setCateName(cateName);
        setSelSubCate(true);
        setSubCategories(proCategory.repo.getSubCategories(cateId));
        proData.CategoryId = cateId;
    };

    const selectSubCate = (cateId: number, cateName: string) => {
        setSubCateName(cateName);
        setSelSubCate(false);
        proData.SubCategoryId = cateId;
    };

    const handleStep1Change = (e: any) => {
        setStep1Data({
            ...step1Data,
            [e.target.name]: e.target.value
        });
        
        proData[e.target.name] = e.target.value;
    };

    const changeContents = (editor: ReactQuill.UnprivilegedEditor) => {
        setStep1Data({
            ...step1Data,
            Detail: editor.getHTML()
        });

        proData.Detail = editor.getHTML();
        proData.text = editor.getText();
    }

    useEffect(() => {
        axios.get("api/ProService/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
    }, []);
    
    return (
        <>
            <div className="write pro">
                <div className="section-write">
                    <div className="title">서비스 분야</div>
                    <a className="write-select_button" onClick={() => setSelCate(!selCate)}>
                        <span>{cateName}</span>
                        <span className="arrow">
                            <img src={write_right} alt=""/>
                        </span>
                    </a>
                {selCate ?
                    <div className="border" style={{borderRadius: '2rem'}}>
                        <ul style={{fontSize: '5.4rem'}}>
                            {categories.map((cate, idx) => (
                                <li key={idx} onClick={() => selectCate(cate.categoryId, cate.name)}
                                    className="p-3">{cate.name}</li>
                            ))}
                        </ul>
                    </div>
                : null}
                    <a className="write-select_button" onClick={() => setSelSubCate(!selSubCate)}>
                        <span>{subCateName}</span>
                        <span className="arrow">
                            <img src={write_right} alt=""/>
                        </span>
                    </a>
                {selSubCate ?
                    <div className="border" style={{borderRadius: '2rem'}}>
                        <ul style={{fontSize: '5.4rem'}}>
                            {subCategories.filter(sub => sub.subCategoryId != 0).map((cate, idx) => (
                                <li key={idx} onClick={() => selectSubCate(cate.subCategoryId, cate.name)}
                                    className="p-3">{cate.name}</li>
                            ))}
                        </ul>
                    </div>
                : null}
                </div>
                <div className="section-write">
                    <div className="title">서비스명</div>
                    <input type="text" className="write-input" placeholder="서비스명을 입력해주세요." name="Title"
                           value={step1Data.Title} onChange={handleStep1Change}
                           ref={Step1Ref.Title}/>
                </div>
                <div className="section-write">
                    <div className="title">키워드</div>
                    <input type="text" className="write-input" placeholder="쉼표(,)로 구분하여 입력해주세요." name="Keywords"
                           value={step1Data.Keywords} onChange={handleStep1Change}
                           ref={Step1Ref.Keywords}/>
                </div>
                <div className="section-write">
                    <div className="title">내용</div>
                    <div className="write-textarea">
                        <DivRichEditor value={step1Data.Detail} setValue={(e) => changeContents(e)}
                                       setRef={(ref: ReactQuill | null) => Step1Ref.Detail = ref}/>
                    </div>
                </div>
                <div className="section-write_notice">
                    <div className="write-notice">
                        *입력한 정보가 서비스 정보 페이지로 설정됩니다. ※사진파일의 사이즈는 2M 이하를 업로드하여 주시기 바랍니다.
                    </div>
                </div>
            </div>

            <div className="bottom chap">
                <a className="button full purple" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step2() {
    const [priceAccodian, setPriceAccodian] = useState<boolean[]>([true, false, false]);
    const [isAgree, setIsAgree] = useState<boolean>(true);
    const [prices, setPrices] = useState<CreateProServicePriceOptionRequest[]>([
        { PriceOptionType: ProServicePriceOptionType.Standard, Price: 0, WorkingDay: 0, ModifyCount: 0, DraftCount: 0, CustomOptions: '' },
        { PriceOptionType: ProServicePriceOptionType.Advanced, Price: 0, WorkingDay: 0, ModifyCount: 0, DraftCount: 0, CustomOptions: '' },
        { PriceOptionType: ProServicePriceOptionType.Premium, Price: 0, WorkingDay: 0, ModifyCount: 0, DraftCount: 0, CustomOptions: '' }
    ]);
    const [options, setOptions] = useState<CreateProServiceCustomOptionRequest[]>([]);
    
    const handlePriceAccodian = (idx: number, flag: boolean) => {
        setPriceAccodian(priceAccodian.map((p, i) => (
            i === idx ? flag : p
        )));
    };

    const handlePriceValue = (idx: number, e: any) => {
        setPrices(prices.map((p, i) => (
            i === idx ? {
                ...p,
                [e.target.name]: e.target.name === "CustomOptions" ? e.target.value : Number(e.target.value)
            } : p
        )));
    };
    
    const addCustomOption = () => {
        setOptions(options.concat({Title: '', Price: 1000}));
    }

    const handleOptionValue = (idx: number, e: any) => {
        setOptions(options.map((o, i) => (
            i === idx ? {
                ...o,
                [e.target.name]: e.target.name === "Title" ? e.target.value : Number(e.target.value)
            } : o
        )));
    };
    
    const registerProService = () => {
        let flag = true;

        prices.some((price, idx) => {
            if(100 > price.Price) {
                alert("서비스 가격을 100원 이상 입력해주세요.");
                flag = false;
                return true;
            }
        });

        if(!flag) return;
        
        if (!isAgree) {
            alert("약관에 동의해야 합니다.");
            return;
        }
        
        console.log(proData);

        axios.post('/api/ProService/pro', proData).then(response => {
            alert("서비스 등록이 완료되었습니다.");
            location.href = '/my/my-pro-main';
        }).catch(error => {
            console.log(error);
            alert("서비스 등록에 실패했습니다. 관리자에 문의해주세요.");

            return;
        });
    };

    useEffect(() => {
        proData.PriceOptionRequests = prices;
        proData.CustomOptionRequests = options;
    }, [prices, options]);
    
    return (
        <>
            <div className="write pro">
                {/* 가격설정 */}
                <div className="section-write">
                    <div className="title">가격 설정</div>
                {prices.map((price, idx) => (
                    <div key={idx} className={`write-pro_product _writePriceSelect${priceAccodian[idx] ? " on" : ""}`}>
                        <div className="head" onClick={() => handlePriceAccodian(idx, !priceAccodian[idx])}>
                            {GetProServicePriceOptionType(price.PriceOptionType)}
                        </div>
                        <div className="body">
                            <div className="line write-input_won">
                                <div className="title">서비스 가격</div>
                                <div className="desc">
                                    <input type="number" placeholder="0" value={price.Price} name="Price"
                                           onChange={(e) => handlePriceValue(idx, e)}/>
                                    <span>원</span>
                                </div>
                            </div>
                            <div className="line write-input_won">
                                <div className="title">작업 기간</div>
                                <div className="desc">
                                    <input type="number" placeholder="0" value={price.WorkingDay} name="WorkingDay"
                                           onChange={(e) => handlePriceValue(idx, e)}/>
                                    <span>일</span>
                                </div>
                            </div>
                            <div className="line write-input_won">
                                <div className="title">수정 횟수</div>
                                <div className="desc">
                                    <input type="number" placeholder="0" value={price.ModifyCount} name="ModifyCount"
                                           onChange={(e) => handlePriceValue(idx, e)}/>
                                    <span>회</span>
                                </div>
                            </div>
                            <div className="line write-input_won">
                                <div className="title">시안 개수</div>
                                <div className="desc">
                                    <input type="number" placeholder="0" value={price.DraftCount} name="DraftCount"
                                           onChange={(e) => handlePriceValue(idx, e)}/>
                                    <span>개</span>
                                </div>
                            </div>
                            <div className="line">
                                <div className="title">맞춤옵션(선택)</div>
                                <div className="desc">
                                    <input type="text" className="write-input" placeholder="옵션을 입력해주세요."
                                           value={price.CustomOptions} name="CustomOptions"
                                           onChange={(e) => handlePriceValue(idx, e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
                
                {/* 옵션설정 */}
                <div className="section-write type02">
                    <div className="title">
                        <span>옵션 설정</span>
                        <a onClick={() => addCustomOption()}>
                            <span>추가하기</span>
                            <img src={write_pro_plus} alt=""/>
                        </a>
                    </div>
                {options.map((option, idx) => (
                    <div key={idx} className="write-option_input">
                        <div className="item">
                            <span className="name">옵션명</span>
                            <input type="text" placeholder="예)추가 작업 1회당" name="Title" value={option.Title}
                                   onChange={(e) => handleOptionValue(idx, e)}/>
                        </div>
                        <div className="item">
                            <span className="name">추가 금액</span>
                            <div className="duce_input">
                                <input type="number" placeholder="최소 1,000" name="Price" value={option.Price}
                                       onChange={(e) => handleOptionValue(idx, e)}/>
                                <span>원</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-2">
                            <a onClick={() => setOptions(options.filter((_, i) => i !== idx))}>삭제하기</a>
                            <img src={write_pro_minus} style={{width: '10px'}} alt=""/>
                        </div>
                    </div>
                ))}
                </div>
                {/* 유의사항 필수공지 약관동의 */}
                <div className="section-write type03">
                    {/* 유의사항 */}
                    <div className="item">
                        <div className="accordion type-detail type-write">
                            { // @ts-ignore
                                <div className="head" data-toggle="collapse" href="#collapseExample"
                                     aria-expanded="false"
                                     aria-controls="collapseExample">
                                    <span className="name">유의사항</span><span className="arrow"></span>
                                </div>
                            }
                            <div className="body collapse" id="collapseExample">
                                <div className="desc">
                                    <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                    <div className="cont">
                                        제 1조 [목적]<br/>
                                        본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                        계약에
                                        따른
                                        권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 필수공지 */}
                    <div className="item">
                        <div className="accordion type-detail type-write">
                            { // @ts-ignore
                                <div className="head" data-toggle="collapse" href="#collapseExample2"
                                     aria-expanded="false"
                                     aria-controls="collapseExample2">
                                    <span className="name">필수공지</span><span className="arrow"></span>
                                </div>
                            }
                            <div className="body collapse" id="collapseExample2">
                                <div className="desc">
                                    <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                    <div className="cont">
                                        제 1조 [목적]<br/>
                                        본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                        계약에
                                        따른
                                        권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 약관동의 */}
                    <div className="item">
                        <div className="accordion type-detail type-write">
                            { // @ts-ignore
                                <div className="head" data-toggle="collapse" href="#collapseExample3"
                                     aria-expanded="false"
                                     aria-controls="collapseExample3">
                                    <span className="name">약관동의</span><span className="arrow down"></span>
                                </div>
                            }
                            <div className="body collapse show" id="collapseExample3">
                                <div className="desc">
                                    <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                    <div className="cont">
                                        제 1조 [목적]<br/>
                                        본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                        계약에
                                        따른
                                        권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="agree_check">
                            <div className="checkbox">
                                <input type="checkbox" className="check_input" id="vehicle2" checked={isAgree}
                                       onChange={() => setIsAgree(!isAgree)}/>
                                <label htmlFor="vehicle2">
                                    <span className={`circle${isAgree ? " checked" : ""}`}></span>
                                    <span className="name agree_text type03">
                                        본인은 위 약관의 내용을 모두 확인하였으며, 약관에 따라 성실히 활동할 것에 동의합니다.
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bottom chap">
                <a onClick={() => registerProService()} className="button full purple">등록하기</a>
            </div>
        </>
    );
}

function DivStep(props: { step: number, onNext: Function }) {
    if (props.step == 0) {
        return (<Step1 onNext={props.onNext}/>);
    } else if (props.step == 1) {
        return (<Step2/>);
    }

    throw new Error(`[DivStep]: ${props.step} not implemented.`)
}

function DivComplete(props: { index: number, step: number }) {
    if (props.index <= props.step) {
        return (<li className="complete"></li>);
    }

    return (<li></li>);
}

function ProWrite() {
    const [step, setStep] = useState(0);
    const stepArr = [0, 1];

    const handleNext = () => {
        if(1 > proData.CategoryId) {
            alert("카테고리를 선택하세요.");
            return;
        }

        if(1 > proData.SubCategoryId) {
            alert("상세분야를 선택하세요.");
            return;
        }
        
        if ('' == proData.Title) {
            alert("서비스명을 입력해주세요.");
            if (Step1Ref.Title.current !== null) Step1Ref.Title.current.focus();
            return;
        }

        if ('' == proData.Keywords) {
            alert("키워드를 입력해주세요.");
            if (Step1Ref.Keywords.current !== null) Step1Ref.Keywords.current.focus();
            return;
        }
        
        if('' == proData.text) {
            alert("내용을 입력하세요.");
            if(Step1Ref.Detail !== null) Step1Ref.Detail.focus();
            return;
        }
        console.log(proData);
        setStep(step + 1);
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">전문가 서비스 등록</span>
                    </div>
                    <div className="header_right">
                        <a><img className="refresh" src={header_refresh} alt=""/></a>
                        <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <ul className="navigation progress purple">
                    {stepArr.map((_, index) => (
                        <DivComplete key={index} index={index} step={step}/>
                    ))}
                </ul>

                <DivStep step={step} onNext={handleNext}/>
            </div>
        </div>
    );
}

export default ProWrite;