import React from "react";

import logo_design from "img/m/logo-design.svg";
import logo_design_bk from "img/m/logo_design-bk.svg";

function Logo() {
    return (
        <div className="contents mobile logo">
            <div className="logo-design">
                <img src={logo_design} alt="pick-pong"/>
            </div>
            <div className="logo-exp">
                <div className="title">LOGO DESIGN</div>
                <div className="txt">
                    임시 네이밍인 '광고 주고 받고'의 의미를 그대로 담아내면서 사용자에게 친근하게 다가갈 수 있도록 리네이밍하였습니다.<br/>
                    '주고 받고'라는 단어를 쉽고 흥미를 갖을 수 있도록 'ping pong' 이라는 재미있는 어감의 단어와 광고주, 광고자, 전문가 각 분야에 맞게 서로가 서로를 선택하여 이루어지는
                    시스템을 방식에서 'pick' 이라는 단어를<br/>
                    도출하여 결합하였습니다.
                </div>
                <div className="shape">
                    <div className="circle">
                        <strong>Ping Pong</strong>
                        <p>주고받다</p>
                    </div>
                    <div className="sum">+</div>
                    <div className="circle">
                        <strong>Pick</strong>
                        <p>선택하다</p>
                    </div>
                    <div className="equal">=</div>
                    <div className="total">Pick Pong</div>
                </div>
            </div>
            <div className="logo-color">
                <div className="color-box">
                    <div className="name">#FFFFFF</div>
                    <div className="chip white"></div>
                </div>
                <div className="color-box">
                    <div className="name">#DE0013</div>
                    <div className="chip red"></div>
                </div>
                <div className="color-box">
                    <div className="name">#090909</div>
                    <div className="chip black"></div>
                </div>
            </div>
            <div className="logo-ver">
                <div className="white"><img src={logo_design} alt="pick-pong"/></div>
                <div className="black"><img src={logo_design_bk} alt="pick-pong"/></div>
            </div>
        </div>
    );
}

export default Logo;