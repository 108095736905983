import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import MobileInfluThumbnail from "components/m-influ-thumbnail";
import {DivRank, RankChangeEnum} from "types/rank";

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {getLocalSession} from "../../modules/localSession";
import axios from "axios";
import LoadingSpinner from "../../components/m-loading-spinner";
import GivePointInfluPopup from "../popup/give-point-influ";
import FilteringEmpty from "../../components/m-filtering-empty";

function DivRankCheckBox() {
    return (
        <div className="rank">
            <input type="checkbox" name="check" id=""/>
        </div>
    );
}

function setZzim(items: any[], setItems: React.Dispatch<React.SetStateAction<any[] | undefined>>) {
    const session = getLocalSession();
    let newItems: any[] = [];

    if(session) {
        axios.get(`api/Zzim/item`)
            .then(response2 => {
                let data2 = response2.data.adItems;

                items.forEach((c: any) => {
                    let zzim = data2.find((z: any) => z.boardId == c.bid);

                    if(zzim) {
                        newItems.push({...c, isZzim: zzim.isZzim});
                    } else {
                        newItems.push({...c, isZzim: false});
                    }
                });

                setItems(newItems);
            });
    } else {
        newItems = items.map((c: any) => ({...c, isZzim: false}));
        setItems(newItems);
    }
}

function InfluFilteringResult() {
    const location = useLocation();
    const [orderPrice, setOrderPrice] = useState(true);
    const [orderFriend, setOrderFriend] = useState(true);
    const [orderRating, setOrderRating] = useState(true);
    const [orderPriceData, setOrderPriceData] = useState<any[]>();
    const [orderFriendData, setOrderFriendData] = useState<any[]>();
    const [orderRatingData, setOrderRatingData] = useState<any[]>();
    const [filteringData, setFilteringData] = useState<any[]>();
    const [analyzedData, setAnalyzedData] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const [result, setResult] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        let param = location.state;

        axios.post(`api/Filtering/InfluencerFilteringByPrice`, param).then(response => {
            let data = response.data;
            setZzim(data, setOrderPriceData);
        });
        axios.post(`api/Filtering/InfluencerFilteringBySubscribe`, param).then(response => {
            let data = response.data;
            setZzim(data, setOrderFriendData);
        });
        axios.post(`api/Filtering/InfluencerFilteringByRating`, param).then(response => {
            let data = response.data;
            setZzim(data, setOrderRatingData);
        });
        axios.post(`api/Filtering/InfluencerFilteringByCustom`, {
            FilterRequest: param
        }).then(response => {
            let data = response.data;
            setZzim(data, setFilteringData);

            setLoading(false);
        });
    }, [JSON.stringify(location.state)]);

    if(orderPriceData == null) return (<></>);
    if(orderFriendData == null) return (<></>);
    if(orderRatingData == null) return (<></>);
    if(filteringData == null) return (<></>);
    
    return (
        <div className="contents mobile filtering result influ">
            <header className="header-sub shadow-none">
                <div className="header_left">
                    <span className="location">인플루언서 필터링 결과</span>
                </div>
                <div className="header_right">
                    <a onClick={() => window.location.reload()} className="refresh">
                        <img src={header_refresh} alt="새로고침"/>
                    </a>
                    <a onClick={() => navigate('/filtering/influ-filtering-form', {state: location.state})}
                       className="close">
                        <img src={icon_close} alt="닫기"/>
                    </a>
                </div>
            </header>
            <div className="form">
                <div className="grid grid-3">
                    <div className="ipt-button green">
                        <input type="checkbox" id="ad-ch1" title="가격" checked={orderPrice}
                               onChange={() => setOrderPrice(!orderPrice)}/>
                        <label htmlFor="ad-ch1">가격</label>
                    </div>
                    <div className="ipt-button green">
                        <input type="checkbox" id="ad-ch2" title="친구수" checked={orderFriend}
                               onChange={() => setOrderFriend(!orderFriend)}/>
                        <label htmlFor="ad-ch2">친구수</label>
                    </div>
                    <div className="ipt-button green">
                        <input type="checkbox" id="ad-ch3" title="평점" checked={orderRating}
                               onChange={() => setOrderRating(!orderRating)}/>
                        <label htmlFor="ad-ch3">평점</label>
                    </div>
                </div>
            </div>
            <div className="section" style={{paddingTop: "0px"}}>
                <Swiper className="swiper-container"
                        slidesPerView={"auto"}
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    {orderPrice ?
                    <SwiperSlide className="swiper-slide result-box">
                        <div className="title">가격순</div>
                        {orderPriceData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {orderPriceData.map((data, idx) => (
                                <MobileInfluThumbnail key={idx} influ={data}/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    {orderFriend ?
                    <SwiperSlide className="swiper-slide result-box">
                        <div className="title">친구수 순</div>
                        {orderFriendData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {orderFriendData.map((data, idx) => (
                                <MobileInfluThumbnail key={idx} influ={data}/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    {orderRating ?
                    <SwiperSlide className="swiper-slide result-box">
                        <div className="title">평점순</div>
                        {orderRatingData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {orderRatingData.map((data, idx) => (
                                <MobileInfluThumbnail key={idx} influ={data}/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    <SwiperSlide className="result-box suit">
                        <div className="title">
                            맞춤 필터링 결과
                            <a onClick={() => setShow(true)} className="button round bg-white">가점주기</a>
                        </div>
                        {filteringData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {filteringData.map((data, idx) => (
                                <MobileInfluThumbnail key={idx} influ={data} header={
                                    <DivRank rank={{rank: idx+1, change: RankChangeEnum.Equal}}/>
                                }/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    {result ?
                    <SwiperSlide className="result-box suit">
                        <div className="title">
                            최적 분석결과 순위
                            <a onClick={() => setShow(true)} className="button round bg-white">가점 다시 주기</a>
                        </div>
                        {analyzedData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {analyzedData.map((data, idx) => (
                                <MobileInfluThumbnail key={idx} influ={data} header={
                                    <DivRank rank={{rank: idx+1, change: RankChangeEnum.Equal}}/>
                                }/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    {/*<SwiperSlide className="result-box suit">
                        <div className="title">
                            최적 분석결과 순위
                            <a href="#none" className="button round bg-white">가점 다시 주기</a>
                        </div>
                        <div className="cont-list">
                            <div className="all-check">
                                <label htmlFor="all-check"
                                       className="button round bg-white border-black">전체선택</label>
                                <input type="checkbox" name="all-check" id="all-check"/>
                            </div>
                            <MobileInfluThumbnail influ={{
                                bid: 21, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 22, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 23, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 24, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 25, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerFeed: 10,
                                        costPerClick: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                        </div>
                    </SwiperSlide>*/}
                </Swiper>
            </div>
            {/*<div className="foot-btn">
                <div className="detail-foot_one_button">
                    <div><a className="button full black">선택 광고 보내기</a></div>
                </div>
            </div>*/}
            <GivePointInfluPopup show={show} setShow={setShow} setData={setAnalyzedData} setResult={setResult}
                            param={location.state}/>
            {loading ? <LoadingSpinner/> : null}
        </div>
    );
}

export default InfluFilteringResult;