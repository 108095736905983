import React, {useEffect, useState} from "react";

import header_close from "img/m/header-close.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {getLocalSession} from "../../modules/localSession";
import {AdvertisingType} from "../../types/advertising-type";
import moment from "moment/moment";
import {getPlatformName} from "../../types/platform";
import {EstimatePaymentType} from "../../types/estimate";

function ProRequire() {
    const params = useParams();
    const session = getLocalSession();
    const [received, setReceived] = useState<any>();
    const [estimates, setEstimates] = useState<any>();
    const [tab, setTab] = useState<string>("req");
    const [rcv, setRcv] = useState<any>();
    const [price, setPrice] = useState<number>(0);
    const [comment, setComment] = useState<string>("");

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                setEstimates(data);
            });
        axios.get(`api/MyAdvertiser/estimate/${params.bid}/joined`)
            .then(response => {
                let data = response.data;

                if(session) {
                    let rcvd = data.find((d: any) => d.userId == session.id);

                    if(rcvd) {
                        setRcv(rcvd);
                        setPrice(rcvd.price);
                        setComment(rcvd.comment);
                    }
                }
            });
        axios.get(`api/MyProService/estimate/received/${params.bid}`)
            .then(response => {
                let data = response.data;
                setReceived(data);
            });
    }, [params]);

    if (estimates == null) {
        return (<></>);
    }

    const adType = estimates.advertisingType === AdvertisingType.FeedUpload ? "피드업로드" :
        estimates.advertisingType === AdvertisingType.Review ? "후기작성" :
            estimates.advertisingType === AdvertisingType.ExperienceGroup ? "체험단" :
                estimates.advertisingType === AdvertisingType.RequestKakaoFriend ? "카카오톡 친구추천" : "";
    const price2 = estimates.minBudget === 0 && estimates.maxBudget === 0 ? "협의" :
        estimates.minBudget.toLocaleString() + "원 ~ " + estimates.maxBudget.toLocaleString() + "원";

    const chgPrice = (value: string) => {
        setPrice(Number(value.replace(/[^0-9]/g, "")));
    };

    const updateEstimate = () => {
        if(price < 100) {
            alert("견적 금액을 100원 이상 입력해주세요.");
            return;
        }
        
        axios.patch(`/api/ProService/estimate/join`, {
            BoardId: params.bid,
            Price: price,
            Comment: comment
        }).then(response => {
            alert("견적서가 수정되었습니다.");
        }).catch(error => {
            console.log(error);
            alert("견적서 수정에 실패했습니다.");
            return;
        });
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">보낸 견적서</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>

                <div className={`requirements${estimates.enabled ? " detail" : ""}`}>
                    <div className="requir-head">
                        {received ?
                            received.paymentProcessType == EstimatePaymentType.CompletePayment ?
                                <span className="requir-badge gray">결제완료</span>
                                :
                                <span className="requir-badge black">결제대기</span>
                            :
                        <span className="requir-badge purple">전문가</span>
                        }
                        <div className="title">{estimates.title}</div>
                        <div className="date">{moment(estimates.createAt).format("YYYY.MM.DD")}</div>
                    </div>
                    {estimates.enabled ?
                        <div className="requir-tab">
                            <ul className="navigation tab">
                                <li id="tab-01" className={tab === "req" ? "on" : ""}>
                                    <a onClick={() => setTab("req")}>광고정보</a>
                                </li>
                                <li id="tab-02" className={tab === "rcv" ? "on" : ""}>
                                    <a onClick={() => setTab("rcv")}>보낸 견적 내용</a>
                                </li>
                            </ul>
                        </div>
                        : null}
                    {/* 견적요청정보 */}
                    <div className={`requir-body${tab === "req" ? " show" : ""}`} id="contents-01">
                        <div className="requir-block">
                            <div className="title">견적서 요청할 사람</div>
                            <div className="desc">인플루언서</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 분야</div>
                            <div className="desc">
                                {estimates.categories.map((category: string, idx: number) => (
                                    <a key={idx} className="button round purple big"
                                       style={{marginRight: "3px"}}>{category}</a>
                                ))}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고채널</div>
                            <div className="desc">{getPlatformName(estimates.platformType)}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고방식</div>
                            <div className="desc">{adType}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고기간</div>
                            <div className="desc">
                                {moment(estimates.startAt).format("YYYY.MM.DD")} ~ {moment(estimates.endAt).format("YYYY.MM.DD")}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 예상 금액</div>
                            <div className="desc">{price2}</div>
                        </div>
                    </div>

                    {/* 참여 견적서 3건 */}
                    <div className={`requir-body${tab === "rcv" ? " show" : ""}`} id="contents-02">
                        {rcv ?
                            <>
                                <div className="send">
                                    <div className="requir_sub_title">견적 금액</div>
                                    <div className="requir_input_price common">
                                        <input type="text" value={price.toLocaleString()} onChange={e => chgPrice(e.target.value)}/>
                                        &nbsp;<span>원</span>
                                    </div>
                                    <div className="requir_textarea">
                                <textarea cols={30} rows={10} placeholder="전달할 메시지가 있으면 입력해주세요."
                                          value={comment} onChange={e => setComment(e.target.value)}></textarea>
                                    </div>
                                </div>

                                <div className="foot-btn">
                                    <div className="detail-foot_one_button type_half all_black">
                                    {received ? null :
                                        <div><a onClick={() => updateEstimate()} className="button full black">수정</a></div>
                                    }
                                    </div>
                                </div>
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProRequire;