import React, {useEffect, useState} from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import MobileEstimateRequest from "../../components/m-estimate-request";

function CommonRequirementsList() {
    const params = useParams();
    const [estimates, setEstimates] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        const control = params.type === "influ" ? "Influencer" : "ProService";
        axios.get(`api/${control}/estimates`)
            .then(response => {
                setEstimates(response.data);
            })
    }, [params]);
    
    if(estimates == null) {
        return (<></>);
    }
    
    const back = () => {
        navigate(`/my/my-${params.type}-main`);
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">견적서 의뢰</span>
                    </div>
                    <div className="header_right">
                        {/*<a className="search"><img src={header_search_black} alt="검색"/></a>*/}
                    </div>
                </header>

                <div className="requirements-list">
                    {/*  견적 요청중 탭 */}
                    <div className="requirements-list-body show" id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <div className="list-sort">
                                <div className="total_count">총 {estimates.count}건</div>
                                {/*<div href="#" className="sort_gorup">
                                    <div className="sort_button">
                                        <div className="channel">
                                            <select className="selectbox sort_button" name="job">
                                                <option value="">채널별</option>
                                                <option value="채널01">채널01</option>
                                                <option value="채널01">채널01</option>
                                                <option value="채널01">채널01</option>
                                            </select>
                                        </div>
                                        <div className="ad">
                                            <select className="selectbox sort_button" name="job">
                                                <option value="">광고별</option>
                                                <option value="광고01">광고01</option>
                                                <option value="광고02">광고01</option>
                                                <option value="광고03">광고01</option>
                                            </select>
                                        </div>
                                    </div>
                                    <a href="#" className="list_sort">
                                        <img src={icon_list_left} alt=""/>
                                    </a>
                                </div>*/}
                            </div>
                        </div>
                        {/*  list 영역 */}
                        <div className="requirements-list-area">
                            {estimates.items.map((est: any, idx: number) => (
                                <MobileEstimateRequest key={idx} estimate={est}/>
                            ))}
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={params.type === "influ" ? PageType.Influ : PageType.Pro}
                                        curMenu={"my"}/>
            </div>
        </div>
    );
}

export default CommonRequirementsList;