import React from "react";
import {Nav, Tab} from "react-bootstrap";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileCouponItem from "components/m-coupon-item";
import {Coupon} from "types/coupon";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";

function MyCouponInflu() {
    const coupons: Coupon[] = [
        {
            isUsed: false,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            startDate: new Date(2022, 0, 1)
        },
        {
            isUsed: false,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            endDate: new Date(2023, 0, 1)
        },
        {
            isUsed: false,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            startDate: new Date(2022, 0, 1),
            endDate: new Date(2023, 0, 1)
        },
    ];

    const useCoupons: Coupon[] = [
        {
            isUsed: true,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            startDate: new Date(2022, 0, 1)
        },
        {
            isUsed: true,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            endDate: new Date(2023, 0, 1)
        },
        {
            isUsed: true,
            cate: "회원가입 축하",
            title: "<span>10%</span> 할인",
            startDate: new Date(2022, 0, 1),
            endDate: new Date(2023, 0, 1)
        },
    ];

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">쿠폰내역</span>
                    </div>
                    <div className="header_right">
                        {/*TODO: 기능 구현 필요*/}
                        <a className="del"><img src={icon_del} alt="삭제"/></a>
                    </div>
                </header>

                <Tab.Container defaultActiveKey={"tab1"}>
                    {/* 탭 영역 */}
                    <div className="requir-list-tab">
                        {/* 쿠폰내역 tab color 변경시 green -> blue 변경  */}
                        <Nav className="navigation tab green" as={"ul"}>
                            <Nav.Link eventKey="tab1" as={"li"}>
                                <a>사용 가능</a>
                            </Nav.Link>
                            <Nav.Link eventKey="tab2" as={"li"}>
                                <a>사용 완료</a>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <Tab.Content className="my-coupon">
                        <Tab.Pane eventKey="tab1" className="requirements-list-body">
                            <div className="coupon-list">
                                {coupons.map((coupon, key) => (
                                    <MobileCouponItem coupon={coupon} key={key}/>
                                ))}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab2" className="requirements-list-body">
                            <div className="coupon-list">
                                {useCoupons.map((coupon, key) => (
                                    <MobileCouponItem coupon={coupon} key={key}/>
                                ))}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyCouponInflu;