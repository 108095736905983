import React from "react";
import {combineReducers} from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import adCategorySlice from "./slice/adCategorySlice";
import influCategorySlice from "./slice/influCategorySlice";
import proCategorySlice from "./slice/proCategorySlice";

export const rootReducer = {
    user: userSlice,
    adCategory: adCategorySlice,
    influCategory: influCategorySlice,
    proCategory: proCategorySlice,
};

const rootCombineReducer = combineReducers(rootReducer);

export default rootCombineReducer;
export type RootState = ReturnType<typeof rootCombineReducer>;