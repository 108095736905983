import React, {useState} from "react";
import {Modal} from "react-bootstrap";

import MobileCampaignThumbnail from "components/m-campaign-thumbnail";
import {CampaignFlag} from "types/campaign";

import modal_close from "img/m/modal-close.png";

function ProcessAdPopup() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    return (
        <Modal className="type-center" centered show={show} onHide={handleClose}>
            <div className="modal-close"><img src={modal_close} alt="" onClick={handleClose}/></div>
            <Modal.Body>
                <div className="trade_detail">
                    <div className="title">나의 진행 광고</div>
                    <div className="my_process_list">
                        <div className="item">
                            <div className="check"><input type="checkbox"/></div>
                            <MobileCampaignThumbnail campaign={{
                                bid: 1,
                                categoryId: 1,
                                subCategoryId: 1,
                                images: [],
                                point: 10,
                                flag: CampaignFlag.Click,
                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                            }}/>
                        </div>
                        <div className="item">
                            <div className="check"><input type="checkbox"/></div>
                            <MobileCampaignThumbnail campaign={{
                                bid: 2,
                                categoryId: 1,
                                subCategoryId: 1,
                                images: [],
                                point: 10,
                                flag: CampaignFlag.Click,
                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                            }}/>
                        </div>
                        <div className="item">
                            <div className="check"><input type="checkbox"/></div>
                            <MobileCampaignThumbnail campaign={{
                                bid: 3,
                                categoryId: 1,
                                subCategoryId: 1,
                                images: [],
                                point: 10,
                                flag: CampaignFlag.Click,
                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                            }}/>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="type-button" onClick={handleClose}>
                광고보내기
            </Modal.Footer>
        </Modal>
    );
}

export default ProcessAdPopup;