import React, {useEffect, useState} from "react";

import header_close from "img/m/header-close.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {UserType} from "../../types/user-type";

function CommonRequireSend() {
    const params = useParams();
    const [price, setPrice] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [userType, setUserType] = useState<UserType>(UserType.None);

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                setPrice(data.maxBudget);
                setUserType(data.requestTargetUser);
            });
    }, [params]);
    
    const chgPrice = (value: string) => {
        setPrice(Number(value.replace(/[^0-9]/g, "")));
    };
    
    const sendRequire = () => {
        if(price < 100) {
            alert("견적 금액을 100원 이상 입력해주세요.");
            return;
        }
        
        let control = userType === UserType.Influencer ? "Influencer" : "ProService";
        
        axios.post(`/api/${control}/estimate/join`, {
            BoardId: params.bid,
            Price: price,
            Comment: comment
        }).then(response => {
            alert("견적서가 전송되었습니다.");
            let type = userType === UserType.Influencer ? "influ" : "pro";
            location.href = `/requirements-list/common-requirements-list/${type}`;
        }).catch(error => {
            console.log(error);
            alert("견적 보내기에 실패했습니다.");
            return;
        });
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_close} alt="뒤로가기"/></a>
                        <span className="location">견적 보내기</span>
                    </div>
                    <div className="header_right">
                    </div>
                </header>
                <div className="c-line"></div>

                <div className="requirements send">
                    <div className="requir_sub_title">견적 금액</div>
                    <div className="requir_input_price common">
                        <input type="text" value={price.toLocaleString()} onChange={e => chgPrice(e.target.value)}/>
                        &nbsp;<span>원</span>
                    </div>
                    <div className="requir_textarea">
                        <textarea cols={30} rows={10} placeholder="전달할 메시지가 있으면 입력해주세요."
                                  value={comment} onChange={e => setComment(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="bottom chap">
                    <a onClick={() => sendRequire()} className="button full black">견적 보내기</a>
                </div>
            </div>
        </div>
    );
}

export default CommonRequireSend;