import React, {useState} from "react";
import {Form} from "react-bootstrap";

import header_back from "img/m/header-back.svg";

type checkedSetState = React.Dispatch<React.SetStateAction<boolean>>;

function DivCheckBox(props: { id: string, checked: boolean, onChange: checkedSetState }) {
    return (
        <Form.Check type="checkbox" className={`button setting ${props.checked ? "on" : ""}`} id={props.id}>
            <Form.Check.Input type="checkbox" className="d-none"
                              checked={props.checked} onChange={() => props.onChange(!props.checked)}
            />
            <Form.Check.Label>
                <div className="slot">
                    <span className="circle"></span>
                </div>
            </Form.Check.Label>
        </Form.Check>
    );
}

function MySetting() {
    const [messageAlert, setMessageAlert] = useState(true);
    const [noticeAlert, setNoticeAlert] = useState(true);
    const [alarmCateAlert, setAlarmCateAlert] = useState(true);

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">설정</span>
                    </div>
                </header>
                <div className="section">
                    <div className="title">알림</div>
                    <ul className="board">
                        <li>
                            <p className="subject">메시지 알림</p>
                            <DivCheckBox id="messageAlert" checked={messageAlert} onChange={setMessageAlert}/>
                        </li>
                        <li>
                            <p className="subject">소식알림</p>
                            <DivCheckBox id="noticeAlert" checked={noticeAlert} onChange={setNoticeAlert}/>
                        </li>
                        <li>
                            <p className="subject">알림 받을 분야</p>
                            <DivCheckBox id="alarmCateAlert" checked={alarmCateAlert} onChange={setAlarmCateAlert}/>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MySetting;