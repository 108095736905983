import React from "react";
import {Link, useNavigate} from "react-router-dom";

import header_logo from "img/m/header-logo.svg";
import header_select from "img/m/header-select.svg";
import kakao_friend from "img/pc/kakao-friend.png";
import MobileHeaderLeft from "../../components/m-header-left";
import {getLocalSession, removeLocalSession} from "../../modules/localSession";
import axios from "axios";

function KakaoFriend() {
    const session = getLocalSession();
    const navigate = useNavigate();

    const handleLogout = () => {
        axios.post("/api/Account/logout").then();
        removeLocalSession();
        navigate("/");
    }
    
    return (
        <div className="contents mobile sns-benefit">
            <header className="black">
                <MobileHeaderLeft/>
                <div className="header_right">
                    {
                        !session ?
                            (
                                <Link to={"/member/login"}>
                                    <span className="button round bg-gray small">로그인/회원가입</span>
                                </Link>
                            ) : (
                                <span className="button round bg-gray small" onClick={handleLogout}>로그아웃</span>
                            )
                    }
                </div>
            </header>
            <div className="sns-body">
                <img src={kakao_friend} alt=""/>
            </div>
            <div className="foot-btn">
                <a href="https://pf.kakao.com/_xaTcaxj" target="_blank" className="button full black">친구하러 가기</a>
            </div>
        </div>
    );
}

export default KakaoFriend;