import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";
import dummy_01 from "img/m/dummy-01.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";

function AnalysisResult() {
    return (
        <div className="contents mobile filtering result ad">
            <header className="header-sub shadow-none">
                <div className="header_left">
                    <span className="location">분석 결과</span>
                </div>
                <div className="header_right">
                    <a href="#" className="refresh"><img src={header_refresh} alt="새로고침"/></a>
                    <a href="#" className="close"><img src={icon_close} alt="닫기"/></a>
                </div>
            </header>
            <div className="section">
                <div className="filter-tag">
                    <ul>
                        <li className="button round blue">전문가</li>
                        <li className="button round blue">서울 전체</li>
                        <li className="button round blue">디자인&gt;로고</li>
                        <li className="button round blue">디자인&gt;명함</li>
                        <li className="button round blue">성별전체</li>
                        <li className="button round blue">경력 5~50년</li>
                        <li className="button round blue">평점 4~5점</li>
                    </ul>
                </div>
                <Swiper className="swiper-container"
                        slidesPerView={"auto"}
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    <SwiperSlide className="swiper-slide result-box analysis">
                        <div className="title default">
                            최적 분석결과 순위
                            <span className="total">총 24건</span>
                        </div>
                        <div className="cont-list">
                            <div className="all-check">
                                <label htmlFor="all-check"
                                       className="button round bg-white border-black">전체선택</label>
                                <input type="checkbox" name="all-check" id="all-check"/>
                            </div>
                            <div className="thumbnail width x-small best">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>1</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>2</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>3</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>4</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>5</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide result-box analysis">
                        <div className="title default">
                            평점 순위
                            <span className="total">총 24건</span>
                        </div>
                        <div className="cont-list">
                            <div className="all-check">
                                <label htmlFor="all-check"
                                       className="button round bg-white border-black">전체선택</label>
                                <input type="checkbox" name="all-check" id="all-check"/>
                            </div>

                            <div className="thumbnail width x-small best">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>1</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>2</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>3</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>4</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="thumbnail width x-small">
                                <div className="rank">
                                    <input type="checkbox" name="check" id=""/>
                                </div>
                                <div className="rank">
                                    <p>5</p>
                                    <p className="down"></p>
                                </div>
                                <div className="image_area">
                                    <div className="button heart purple _onOff"></div>
                                    <img src={dummy_01}/>
                                </div>
                                <div className="text_area space-between">
                                    <div className="text_top">
                                        <div className="name">새로미</div>
                                        <div className="gender">여</div>
                                    </div>
                                    <div className="spec">
                                        <div className="ad">평점 <span>1위</span></div>
                                        <div className="follow">광고수 <span>1위</span></div>
                                    </div>
                                    <div className="text_bottom">
                                        <div className="sns_count">
                                            <div className="instagram">
                                                <img src={icon_instagram} alt=""/>
                                            </div>
                                            <div className="facebook">
                                                <img src={icon_facebook} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                {/*    $(function(){*/}
                {/*    $('.all-check').on('click', function () {*/}
                {/*        if ($('.all-check>input[type="checkbox"]').is(":checked")) {*/}
                {/*            $(this).parent('.cont-list').find('input[type="checkbox"]').prop('checked', true);*/}
                {/*        } else {*/}
                {/*            $(this).parents('.cont-list').find('input[type="checkbox"]').prop('checked', false);*/}
                {/*        }*/}
                {/*    });*/}
                {/*});*/}

                {/*</script>*/}
            </div>
            <div className="foot-btn">
                <div className="detail-foot_one_button">
                    <div><a href="#" className="button full black">선택 광고 보내기</a></div>
                </div>
            </div>
        </div>
    );
}

export default AnalysisResult;