import React from "react";
import moment from "moment";

export function DivDateRange(props: { startDate?: Date, endDate?: Date }) {
    const startDate = moment(props.startDate).format('YYYY.MM.DD');
    const endDate = moment(props.endDate).format('YYYY.MM.DD');

    let date = "";

    if (props.startDate != null && props.endDate != null) {
        date = `${startDate} ~ ${endDate}`
    } else if (props.startDate != null) {
        date = `${startDate} ~ `
    } else if (props.endDate != null) {
        date = ` ~ ${endDate}`
    }

    return (<>{date}</>);
}