import React from "react";

import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import icon_twitter from "img/m/icon-twitter.svg";
import icon_naver from "img/m/icon-naver.svg";
import icon_naverblog from "img/m/icon-naverblog.png";
import icon_kakaofriend from "img/m/icon-kakao.png.png";
import icon_default from "../img/m/file-basic.svg";

export const enum PlatformType {
    Instagram,
    Facebook,
    Twitter,
    NaverCafe,
    NaverBlog,
    KakaoFriend,
    Youtube,
    Etc = 99
}

export type PlatformRecord = {
    type: PlatformType,
    costPerClick: number,
    costPerFeed: number,
    subscribeCount: number
}

export function DivPlatformIcon(props: { type: PlatformType }) {
    if (props.type == PlatformType.Instagram) {
        return (<img className="mr-1 mb-1" src={icon_instagram} alt=""/>);
    } else if (props.type == PlatformType.Facebook) {
        return (<img className="mr-1 mb-1" src={icon_facebook} alt=""/>);
    } else if (props.type == PlatformType.Twitter) {
        return (<img className="mr-1 mb-1" src={icon_twitter} alt=""/>);
    } else if (props.type == PlatformType.NaverCafe) {
        return (<img className="mr-1 mb-1" src={icon_naver} alt=""/>);
    } else if (props.type == PlatformType.NaverBlog) {
        return (<img className="mr-1 mb-1" src={icon_naverblog} alt=""/>);
    } else if (props.type == PlatformType.KakaoFriend) {
        return (<img className="mr-1 mb-1" src={icon_kakaofriend} alt=""/>);
    }

    throw new Error(`[DivPlatformIcon]: ${props.type} not implemented.`)
}

export const getPlatformName = (type: PlatformType) => {
    let platformName = "";

    if (type == PlatformType.Instagram) {
        platformName = "Instagram";
    } else if (type == PlatformType.Facebook) {
        platformName = "Facebook";
    } else if (type == PlatformType.Twitter) {
        platformName = "Twitter";
    } else if (type == PlatformType.NaverCafe) {
        platformName = "Naver Cafe";
    } else if (type == PlatformType.NaverBlog) {
        platformName = "Naver Blog";
    } else if (type == PlatformType.KakaoFriend) {
        platformName = "Kakao Friend";
    } else if (type == PlatformType.Youtube) {
        platformName = "Youtube";
    } else if (type == PlatformType.Etc) {
        platformName = "기타";
    }

    return platformName;
};

export const getPlatformIcon = (type: PlatformType) => {
    let platformIcon = "";

    if (type == PlatformType.Instagram) {
        platformIcon = icon_instagram;
    } else if (type == PlatformType.Facebook) {
        platformIcon = icon_facebook;
    } else if (type == PlatformType.Twitter) {
        platformIcon = icon_twitter;
    } else if (type == PlatformType.NaverCafe) {
        platformIcon = icon_naver;
    } else if (type == PlatformType.NaverBlog) {
        platformIcon = icon_naverblog;
    } else if (type == PlatformType.KakaoFriend) {
        platformIcon = icon_kakaofriend;
    } else {
        platformIcon = icon_default;
    }
    
    return platformIcon;
};