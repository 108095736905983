import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";

import MobileNavigationFooter from "components/m-navigation-footer";
import {PageType} from "types/page-type";
import {AdDetailWriteModal} from "../write/ad-detail-write";

import icon_my_transfer_arrow_down from "img/m/icon-my-transfer-arrow-down.png";
import icon_header_setting from "img/m/icon-header-setting.png";
import icon_my_modify from "img/m/icon-my-modify.png";
import file_speaker from "img/m/file-speaker.svg";
import axios from "axios";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import {Accordion, Button, OverlayTrigger, Popover} from "react-bootstrap";
import {UserType} from "../../types/user-type";
import setUser from "../../utils/setUser";

function DivProfileImg(props: { img?: string }) {
    if (props.img) {
        return (<img className="photo2" src={props.img} alt=""/>);
    }

    return (<img className="speaker" src={file_speaker} alt=""/>);
}

function DivAccordionCard(props: { header: JSX.Element, body: JSX.Element }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>("0");

    return (
        <Accordion defaultActiveKey="0" className="type-detail type-my-profile"
                   onSelect={setActiveKey}>
            <Accordion.Item eventKey={"0"} bsPrefix="#">
                <Accordion.Button as={"div"} className="head" bsPrefix="#">
                    {props.header}
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    {props.body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function MyInfluMain() {
    const [profile, setProfile] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/MyInfluencer`)
            .then(response => {
                setProfile(response.data);
            });
    }, []);

    if (profile == null) {
        return (<></>);
    }

    const changeAd = () => {
        if(confirm("광고주로 전환하시겠습니까?")) {
            axios.post("api/MyInfluencer/change", {Type: UserType.Advertiser}).then(response => {
                setUser(response.data);
                navigate("/my/my-ad-main");
            }).catch(error => {
                console.log(error);
                alert("전환에 실패했습니다.");
                return;
            });
        }
    };

    const changePro= () => {
        if(confirm("전문가로 전환하시겠습니까?")) {
            axios.post("api/MyInfluencer/change", {Type: UserType.Pro}).then(response => {
                setUser(response.data);
                navigate("/my/my-pro-main");
            }).catch(error => {
                console.log(error);
                alert("전환에 실패했습니다.");
                return;
            });
        }
    };

    return (
        <div>
            <div className="contents mobile">

                {/* 마이페이지 헤더 */}
                <header className="header-my my-influ-main">
                    <div className="header_left">
                        <span className="location">마이페이지</span>
                        <div className="my-transfer_type">
                            <span>광고주로 전환</span>
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={
                                    <Popover id={`popover-positioned-bottom`} className="top-menu-popover">
                                        <Popover.Body>
                                            <div className="popover-nav" style={{padding: "4rem"}}>
                                                <div onClick={() => changeAd()}>
                                                    <div className="text" style={{marginBottom: "3rem"}}>
                                                        <div className="title">광고주로 전환</div>
                                                    </div>
                                                </div>
                                                <div onClick={() => changePro()}>
                                                    <div className="text">
                                                        <div className="title">전문가로 전환</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <Button variant="link">
                                    <img src={icon_my_transfer_arrow_down} alt=""/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="header_right">
                        <Link to={"/my/my-setting-home"} className="setting">
                            <img src={icon_header_setting} alt="설정"/>
                        </Link>
                    </div>
                </header>

                {/* 마이페이지 메인영역*/}
                <div className="my-main green">
                    <div className="my-main_profile">
                        <div className="file_upload center">
                            <div className="circle">
                                <DivProfileImg img={profile.profileImg}/>
                            </div>
                            <Link to={"/my/influ-profile-modify"}>
                                <img className="photo_button" src={icon_my_modify} alt=""/>
                            </Link>
                        </div>
                        <div className="name">{profile.name} 님</div>
                        <div className="email">{profile.mail}</div>
                    </div>

                    {/* MY 포인트*/}
                    <div className="my-main_point-wrap">
                        <div className="my-main_point">
                            <span className="title">MY 포인트</span>
                            <span className="desc">
                                <span className="money">{profile.point.toLocaleString()}</span>
                                <span className="won">원</span>
                            </span>
                        </div>
                    </div>

                    {/* 마이 서비스 */}
                    <div className="my-main_service-wrap">
                        <div className="my-main_service">
                            <span className="title">MY 서비스</span>
                            <span className="desc">
                                <Link to={"/my/my-influ-ing"} className="money" style={{color: "#fff"}}>
                                    {profile.myServiceCount}
                                </Link>
                                <span className="won">건</span>
                            </span>
                        </div>
                    </div>


                    {/* MY 광고 현황 */}
                    <div className="my-main_ad_dash-wrap">
                        <div className="my-main_ad_dash">
                            <div className="title">MY 광고 현황</div>
                            <div className="item_wrap">
                                <div className="item">
                                    <Link to={"/my/my-ad-live/join"} className="num">
                                        {profile.joinCount.toLocaleString()}
                                    </Link>
                                    <div className="sub">지원한 광고</div>
                                </div>
                                <div className="item">
                                    <Link to={"/my/my-ad-live/ing"} className="num">
                                        {profile.ingCount.toLocaleString()}
                                    </Link>
                                    <div className="sub">진행광고</div>
                                </div>
                                <div className="item">
                                    <Link to={"/my/my-ad-live/end"} className="num">
                                        {profile.completeCount.toLocaleString()}
                                    </Link>
                                    <div className="sub">완료광고</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 광고등록 & 카카오플러스 친구 광고 신청*/}
                <div className="my-main-ad-write">
                    <AdDetailWriteModal/>
                </div>

                {/* 목록 */}
                <div className="my-main-list">
                    {/*<div className="item">
                        <div className="accordion type-detail type-my-profile">
                            <div className="head">
                                TODO: 무슨 데이터가 들어가는 것인가?
                                <span className="name">나의 실적 통계</span>
                            </div>
                        </div>
                    </div>*/}
                    <div className="item">
                        <DivAccordionCard
                            header={<span className="name">내 정보</span>}
                            body={
                                <>
                                    <Link to={"/my/my-zzim"} className="my-main-list-link">찜내역</Link>
                                    <Link to={"/my/my-influ-trade"} className="my-main-list-link">거래내역</Link>
                                    <Link to={"/requirements-list/common-requirements-list/influ"}
                                          className="my-main-list-link">견적서</Link>
                                    {/*<a className="my-main-list-link">쿠폰내역</a>*/}
                                </>
                            }/>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyInfluMain;