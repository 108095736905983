import React from "react";
import {Link} from "react-router-dom";

import header_back from "img/m/header-back.svg";
import member_01 from "img/m/member-01.svg";
import member_02 from "img/m/member-02.svg";
import member_03 from "img/m/member-03.svg";

function Welcome() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location"></span>
                    </div>
                    <div className="header_right">
                    </div>
                </header>

                <div className="member welcome">
                    <div className="section01">
                        <div className="text01">가입을 환영합니다.</div>
                        <div className="text02">원하는 <span className="strong">서비스</span>를 선택해보세요.</div>
                    </div>
                    <div className="section02">
                        <div className="line">
                            <Link to={"/member/campaign-profile-add"} className="d-flex align-items-center">
                                <img src={member_01} alt=""/>
                                <div className="text_box">
                                    <div className="title">캠페인</div>
                                    <div className="desc">홍보가 필요하세요?</div>
                                </div>
                            </Link>
                        </div>
                        <div className="line">
                            <Link to={"/member/influ-profile-add"} className="d-flex align-items-center">
                                <img src={member_02} alt=""/>
                                <div className="text_box">
                                    <div className="title">인플루언서</div>
                                    <div className="desc">홍보가 필요하세요?</div>
                                </div>
                            </Link>
                        </div>
                        <div className="line">
                            <Link to={"/member/pro-profile-add"} className="d-flex align-items-center">
                                <img src={member_03} alt=""/>
                                <div className="text_box">
                                    <div className="title">전문가</div>
                                    <div className="desc">의뢰인을 만나보세요.</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="bottom">
                    <div className="layout line right">
                        <Link to={"/"} className="link type01">홈으로</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Welcome;