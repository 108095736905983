import React, {useState} from "react";
import {Form, Nav, Tab} from "react-bootstrap";
import moment from "moment";

import {InquiryItem, InquiryType} from "types/inquiry";

import header_back from "img/m/header-back.svg";

function DivInquiryItem(props: { item: InquiryItem }) {
    const date = moment(props.item.date).format('YYYY.MM.DD');

    return (
        <li>
            <a>
                <p className="date">{date}</p>
                <p className="list-title">{props.item.title}</p>
                <span className={props.item.inquiryType == InquiryType.Wait ? "answer wait" : "answer comp"}>
                    {props.item.inquiryType == InquiryType.Wait ? "답변대기" : "답변완료"}
                </span>
            </a>
        </li>
    );
}

function DivPicture(props: { picture?: string }) {
    if (props.picture) {
        return (<img className="photo" src={props.picture} alt=""/>);
    }

    return (<></>);
}

function MyMtm() {
    const [category, setCategory] = useState<string | undefined>(undefined);
    const [title, setTitle] = useState<string>();
    const [picture1, setPicture1] = useState<string | undefined>("https://placeimg.com/700/700/any");
    const [picture2, setPicture2] = useState<string | undefined>(undefined);
    const [picture3, setPicture3] = useState<string | undefined>(undefined);

    const inquires: InquiryItem[] = [
        {
            title: "메이크업 인스타그램 피드광고",
            date: new Date(2020, 9, 8),
            inquiryType: InquiryType.Wait
        },
        {
            title: "메이크업 인스타그램 피드광고",
            date: new Date(2020, 9, 8),
            inquiryType: InquiryType.Complete
        },
    ];

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">1:1문의</span>
                    </div>
                </header>

                <Tab.Container defaultActiveKey="tab1">
                    <Nav className="navigation tab black">
                        <Nav.Link eventKey="tab1" as={"li"}>
                            <a>문의하기</a>
                        </Nav.Link>
                        <Nav.Link eventKey="tab2" as={"li"}>
                            <a>문의내역</a>
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="tab1" className="section">
                            <div className="form select">
                                <Form.Control as="select" bsPrefix={"#"}
                                              value={category}
                                              onChange={e => setCategory(e.target.value)}>
                                    <option hidden>카테고리를 선택하세요</option>
                                    <option value="카">카</option>
                                    <option value="테">테</option>
                                    <option value="고">고</option>
                                    <option value="리">리</option>
                                </Form.Control>
                            </div>

                            <div className="form input">
                                <span className="form-title">제목</span>
                                <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                            </div>

                            <div className="form textarea">
                                <span className="form-title">내용</span>
                                <Form.Control as="textarea" rows={10}/>
                            </div>

                            <div className="form attached">
                                <span className="form-title">사진첨부</span>
                                <div className="attached-wrap">
                                    <div className="attached-box">
                                        <label htmlFor="u_file" className="img01"></label>
                                        <input type="file" id="u_file" name="u_file" accept="image/*"/>
                                        <DivPicture picture={picture1}/>
                                    </div>

                                    <div className="attached-box">
                                        <label htmlFor="u_file" className="img02"></label>
                                        <input type="file" id="u_file" name="u_file" accept="image/*"/>
                                        <DivPicture picture={picture2}/>
                                    </div>

                                    <div className="attached-box">
                                        <label htmlFor="u_file" className="img03"></label>
                                        <input type="file" id="u_file" name="u_file" accept="image/*"/>
                                        <DivPicture picture={picture3}/>
                                    </div>
                                </div>
                            </div>

                            <div className="foot-btn">
                                <div className="detail-foot_button type_half">
                                    <div><a className="button full gray" onClick={() => history.back()}>취소</a></div>
                                    {/*TODO : 기능 구현 필요*/}
                                    <div><a className="button full black">등록</a></div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="tab2" className="section">
                            <ul className="board notice-list history">
                                {inquires.map((item, key) => (
                                    <DivInquiryItem item={item} key={key}/>
                                ))}
                            </ul>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default MyMtm;