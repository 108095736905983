import React from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import ico_trade_etc from "img/m/ico-trade_etc.png";
import icon_list_left from "img/m/icon-list-left.png";

function MyAdIng() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a href="#" className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">MY 서비스 현황</span>
                    </div>
                    <div className="header_right">
                        <a className="etc _etc-header-popover" data-container="body" data-toggle="popover"
                           data-placement="bottom"
                           data-content="Vivamus."><img src={ico_trade_etc}/></a>
                        {/*<script>*/}
                        {/*    $('._etc-header-popover').popover({*/}
                        {/*    html: true,*/}
                        {/*    template: '<div class="etc-popover" role="tooltip"><div class="etc-popover-list"><a>내용없음</a><a>내용없음</a><a>내용없음</a><a>내용없음</a></div></div>',*/}
                        {/*})*/}
                        {/*</script>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab blue">
                        <li id="tab-01" className="on">
                            <a href="#none">진행서비스</a>
                        </li>
                        <li id="tab-02">
                            <a href="#none">완료서비스</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">

                    {/*  진행서비스 */}
                    <div className="requirements-list-body show" id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 54건</span>
                            <span className="sort"><img src={icon_list_left}
                                                        alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body">
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 1, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                            <a href="#" className="review_btn">심사중</a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 2, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                            <a href="#" className="review_btn">심사중</a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 3, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                            <a href="#" className="review_btn">심사중</a>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 4, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                            <a href="#" className="review_btn">심사중</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.04</span>
                                </div>
                                <div className="list_body">
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 5, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 6, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 7, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  완료서비스 */}
                    <div className="requirements-list-body" id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 54건</span>
                            <span className="sort"><img src={icon_list_left}
                                                        alt=""/><span>인기순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body">
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 8, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 9, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 10, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 11, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.04</span>
                                </div>
                                <div className="list_body">
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 12, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 13, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="trade_wrap">
                                            <MobileProServiceThumbnail usePopup campaign={{
                                                bid: 14, categoryId: 1, subCategoryId: 1, images: [],
                                                rating: 4.2,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                                minPrice: 120000
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*  완료서비스 */}
                <div className="requirements-list-body" id="contents-02">
                    {/* total 영역 */}
                    <div className="total_area">
                        <span className="all">총 54건</span>
                        <span className="sort"><img src={icon_list_left}
                                                    alt=""/><span>인기순</span></span>
                    </div>
                    {/*  list 영역 */}
                    <div className="pro-trade-list-area influ">
                        <div className="trade-list-day">
                            <div className="list_head">
                                <span>2020.05.05</span>
                            </div>
                            <div className="list_body">
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 15, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                        <a href="#" className="review_btn">인증하기</a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 16, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 17, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 18, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="trade-list-day">
                            <div className="list_head">
                                <span>2020.05.04</span>
                            </div>
                            <div className="list_body">
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 19, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 20, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="trade_wrap">
                                        <MobileProServiceThumbnail usePopup campaign={{
                                            bid: 21, categoryId: 1, subCategoryId: 1, images: [],
                                            rating: 4.2,
                                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                                            minPrice: 120000
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Ad} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyAdIng;