import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "modules";

import {SubCategory} from "types/category";

import header_close from "img/m/header-close.svg";
import layer_check from "img/m/layer-check.svg";

function CategoryListPopup() {
    const adCategory = useSelector((state: RootState) => state.adCategory);

    const [mainCategoryId, setMainCategoryId] = useState<number>(0);
    const [subCategoryId, setSubCategoryId] = useState<number>(0);

    const mainCategories = adCategory.repo.getMainCategories();
    const [subCategories, setSubCategories] = useState<SubCategory[]>([]);

    useEffect(() => {
        if (mainCategories.length != 0) {
            handleOnMainCategoryClick(mainCategories[0].categoryId);
        }
    }, [adCategory])

    const handleOnMainCategoryClick = (mainCategoryId: number) => {
        setMainCategoryId(mainCategoryId);
        setSubCategoryId(0);

        setSubCategories(adCategory.repo.getSubCategories(mainCategoryId));
    }

    return (
        <div className="contents mobile">
            <header className="header-layout under_line">
                <div className="header_left">
                    <span className="location">카테고리 선택</span>
                </div>
                <div className="header_right">
                    <a><img className="close" src={header_close} alt=""/></a>
                </div>
            </header>

            <div className="layer type01">
                <div className="scroll_wrap">
                    <ul className="scroll type01">
                        {mainCategories.map((mainCategory, key) => (
                            <li key={key} className={mainCategoryId == mainCategory.categoryId ? "on" : ""}
                                onClick={_ => handleOnMainCategoryClick(mainCategory.categoryId)}>
                                <a>{mainCategory.name}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="depth3_area">
                    <ul className="depth02">
                        {subCategories.filter(r => r.subCategoryId != 0).map((subCategory, key) => (
                            <li className={subCategoryId == subCategory.subCategoryId ? "checked" : ""}
                                onClick={_ => setSubCategoryId(subCategory.subCategoryId)}>
                                <a>
                                    <span className="name">{subCategory.name}</span>
                                    <span className="check_img">
                                        <img src={layer_check} alt=""/>
                                    </span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="bottom chap">
                {subCategoryId != 0 ?
                    <a className="button full black">선택완료</a> :
                    <a className="button full gray">선택완료</a>}
            </div>
        </div>
    );
}

export default CategoryListPopup;