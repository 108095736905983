import React from "react";

export const enum TradeState {
    Trading,
    Complete,
    Fail,
    Cancel
}

export type TradeItem = {
    category: string,
    title: string,
    completedAt: Date,
    bannerImg: string,
    money: number,
    receiptId: string,
    status: TradeState
}