import React from "react";
import {Button, Nav, OverlayTrigger, Popover} from "react-bootstrap";

import header_logo from "img/m/header-logo.svg";
import icon_intro_service01 from "img/m/intro-ad.png";
import icon_intro_service02 from "img/m/intro-influ.png";
import icon_intro_service03 from "img/m/intro-pro.png";
import header_select from "img/m/header-select.svg";

function MobileHeaderLeft() {
    return (
        <div className="header_left">
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                    // TODO : Warning 1건 발생
                    <Popover id={`popover-positioned-bottom`} className="top-menu-popover">
                        <Popover.Body>
                            <Nav className="popover-nav flex-column">
                                <Nav.Link href="/home/ad-main">
                                    <div className="icon"><img src={icon_intro_service01} alt=""/></div>
                                    <div className="text">
                                        <div className="title">캠페인</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/home/influ-main">
                                    <div className="icon"><img src={icon_intro_service02} alt=""/></div>
                                    <div className="text">
                                        <div className="title">인플루언서</div>
                                    </div>
                                </Nav.Link>
                                <Nav.Link href="/home/pro-main">
                                    <div className="icon"><img src={icon_intro_service03} alt=""/></div>
                                    <div className="text">
                                        <div className="title">전문가</div>
                                    </div>
                                </Nav.Link>
                            </Nav>
                        </Popover.Body>
                    </Popover>
                }>
                <a className="d-flex align-items-center">
                    <h1><img src={header_logo} alt="pickpong"/></h1>
                    <Button className="select" variant="link">
                        <img src={header_select} alt=""/>
                    </Button>
                </a>
            </OverlayTrigger>
        </div>
    );
}

export default MobileHeaderLeft;