import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import axios from "axios";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileCampaignThumbnail from "components/m-campaign-thumbnail";
import {CampaignItem} from "types/campaign";
import {PageType} from "types/page-type";
import {SearchResultModal} from "../search/search-result";

import header_back from "img/m/header-back.svg";
import icon_list_left from "img/m/icon-list-left.png";
import {useSelector} from "react-redux";
import {RootState} from "modules";
import {getLocalSession} from "../../modules/localSession";

function AdList() {
    const {mid = "1", sid = "0"} = useParams();
    const [fetching, setFetching] = useState(false);
    const session = getLocalSession();

    const mainCategoryId: number = Number.parseInt(mid);
    const subCategoryId: number = Number.parseInt(sid);

    const adCategory = useSelector((state: RootState) => state.adCategory);

    const mainCategoryName = adCategory.repo.getMainCategoryName(mainCategoryId);
    const categories = adCategory.repo.getSubCategories(mainCategoryId);

    const [campaignCount, setCampaignCount] = useState<number>(0);
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [lastBid, setLastBid] = useState<number>(0);

    useEffect(() => {
        axios.get(`api/Advertiser/campaigns/${mid}/${sid}`)
            .then(response => {
                const count: number = response.data.count;
                const items: any[] = response.data.items;
                let newItems: any[] = [];

                setCampaignCount(count);

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.adItems;

                            items.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setCampaigns(newItems);

                            if (newItems.length != 0) {
                                setLastBid(newItems[newItems.length - 1].bid);
                            }
                        });
                } else {
                    newItems = items.map((c: any) => ({...c, isZzim: false}));
                    setCampaigns(newItems);

                    if (newItems.length != 0) {
                        setLastBid(newItems[newItems.length - 1].bid);
                    }
                }
            });
    }, [mid, sid])

    const fetchMoreFeeds = async () => {
        setFetching(true);

        let queryParam = lastBid !== 0 ? `?last=${lastBid}` : "";
        
        const response = await axios.get(`api/Advertiser/campaigns/${mid}/${sid}${queryParam}`);
        const count: number = response.data.count;
        const items: any[] = response.data.items;
        let newItems: any[] = [];

        setCampaignCount(count);

        if(session) {
            axios.get(`api/Zzim/item`)
                .then(response2 => {
                    let data2 = response2.data.adItems;

                    items.forEach((c: any) => {
                        let zzim = data2.find((z: any) => z.boardId == c.bid);

                        if(zzim) {
                            newItems.push({...c, isZzim: zzim.isZzim});
                        } else {
                            newItems.push({...c, isZzim: false});
                        }
                    });

                    setCampaigns(campaigns.concat(newItems));

                    if (newItems.length != 0) {
                        setLastBid(newItems[newItems.length - 1].bid);
                    }

                    setFetching(false);
                });
        } else {
            newItems = items.map((c: any) => ({...c, isZzim: false}));
            setCampaigns(campaigns.concat(newItems));

            if (newItems.length != 0) {
                setLastBid(newItems[newItems.length - 1].bid);
            }

            setFetching(false);
        }
    };

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight && !fetching) {
            fetchMoreFeeds().then();
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    })

    return (
        <div>
            {/* 리스트 상단 */}
            <header className="header-sub">
                <div className="header_left">
                    <Link to={"/home/ad-main"} className="back"><img src={header_back} alt="뒤로가기"/></Link>
                    <span className="location">{mainCategoryName}</span>
                </div>
                <div className="header_right">
                    {/*<SearchResultModal/>*/}
                </div>
            </header>
            {/* 리스트 메뉴 */}
            <div className="scroll_wrap">
                <ul className="scroll type02 blue d-inline-flex scroll-width-unset">
                    {categories.map((item, key) => (
                        <li key={key}
                            className={item.subCategoryId == subCategoryId ? "on" : ""}>
                            <Link to={`/home/ad-list/${mid}/${item.subCategoryId}`} replace={true}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {/* 리스트 전체 */}
            <div className="page-list">
                {/* 리스트 총 ${count}, sort */}
                <div className="list-sort">
                    <div className="total_count">
                        총 {campaignCount}건
                    </div>
                    {/*<div href="#" className="sort_gorup">*/}
                    <div className="sort_gorup">
                        <div className="sort_button">
                            {/*<div className="channel">
                                <select className="selectbox sort_button" name="job">
                                    <option value="">채널별</option>
                                    <option value="채널01">채널01</option>
                                    <option value="채널01">채널01</option>
                                    <option value="채널01">채널01</option>
                                </select>
                            </div>
                            <div className="ad">
                                <select className="selectbox sort_button" name="job">
                                    <option value="">광고별</option>
                                    <option value="광고01">광고01</option>
                                    <option value="광고02">광고01</option>
                                    <option value="광고03">광고01</option>
                                </select>
                            </div>*/}
                        </div>
                        <a className="list_sort">
                            <img src={icon_list_left} alt=""/>
                        </a>
                    </div>
                </div>
                {/* 리스트 세로 2열 목록 */}
                <div className="list-list">
                    <div className="thumbnail wrap">
                        <div className="line">
                            {campaigns.map((campaign, key) => (
                                <MobileCampaignThumbnail key={key} height campaign={campaign}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* 리스트 네비게이션 */}
            <MobileNavigationFooter hasHeight pageType={PageType.Ad} curMenu={""}/>
        </div>
    );
}

export default AdList;