import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";

import {getLocalSession, removeLocalSession} from "modules/localSession";

import "swiper/css";
import "swiper/css/pagination";

import MobileNavigationFooter from "components/m-navigation-footer";
import {PageType} from "types/page-type";

import icon_intro_service01 from "img/m/intro-ad.png";
import icon_intro_service02 from "img/m/intro-influ.png";
import icon_intro_service03 from "img/m/intro-pro.png";
import MobileHeaderLeftIntro from "../../components/m-header-left-intro";
import header_logo from "../../img/m/logo-2.png";
import imgBack from "../../img/m/intro-back.png";
import img1 from "../../img/m/intro-1.png";
import img2 from "../../img/m/intro-2.png";
import img3 from "../../img/m/intro-3.png";
import img4 from "../../img/m/intro-4.png";
import {UserType} from "../../types/user-type";
import setUser from "../../utils/setUser";
import {AddKakaoModal2} from "../write/add-kakao";

function DivSlide(props: { content: any }) {
    return (
        <a href={props.content.link} target="_blank">
            <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={props.content.image} alt=""/>
        </a>
    );
}

function Intro() {
    const session = getLocalSession();
    const [contents, seContents] = useState<any[]>();

    useEffect(() => {
        axios.get(`api/Home/banner`)
            .then(response => {
                seContents(response.data);
            });

        if(session) {
            axios.get(`api/Account/user`).then(response => {
                let data = response.data;
                
                if(!data) {
                    alert("토큰이 만료되었습니다.\n다시 로그인해 주세요.");
                    axios.post("/api/Account/logout").then();
                    removeLocalSession();
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error);
                alert("토큰이 만료되었습니다.\n다시 로그인해 주세요.");
                axios.post("/api/Account/logout").then();
                removeLocalSession();
                window.location.reload();
            });
        }
    }, []);

    if(contents == null) {
        return (<></>);
    }

    const handleLogout = () => {
        axios.post("/api/Account/logout").then();

        removeLocalSession();

        window.location.reload();
    };
    
    const goAddCampaignPage = () => {
        if(!session) {
            alert("로그인을 해주세요.");
            location.href = "/member/login";
        } else {
            const type: number = session.userType;

            if(type == UserType.None) {
                if(confirm(`등록된 서비스가 없습니다.\n프로필 등록을 하시겠습니까?`)) {
                    location.href = `/member/welcome`;
                }
            } else if(type != UserType.Advertiser) {
                if(confirm(`고객님은 광고주가 아닙니다.\n광고주로 전환을 하시겠습니까?`)) {
                    const controller = type == UserType.Influencer ? 'MyInfluencer' : "MyProService";
                    axios.post(`api/${controller}/change`, {Type: UserType.Advertiser}).then(response => {
                        setUser(response.data);
                        location.href = "/my/my-ad-main";
                    }).catch(error => {
                        console.log(error);
                        alert("전환에 실패했습니다.");
                        return;
                    });
                }
            } else {
                location.href = "/my/my-ad-main";
            }
        }
    }

    return (
        <div className="contents mobile intro">
            {session ?
            <header style={{background: '#fff'}}>
                <div className="header_left"><h1><img src={header_logo} alt="pickpong"/></h1></div>
                <div className="header_right">
                    <span className="button round bg-red small" onClick={handleLogout}>로그아웃</span>
                </div>
            </header>
            :
            <header className="intro" style={{backgroundImage: `url(${imgBack})`}}>
                <div className="header_left"><h1><img src={header_logo} alt="pickpong"/></h1></div>
                <div className="wrap">
                    <div className="exp">우주 최강 광고앱</div>
                    <div className="title">픽퐁이 처음이신가요?</div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Link to={"/member/join"} className="button round bg-red big2"
                          style={{color: '#fff', width: '45%'}}>회원가입</Link>
                    <Link to={"/member/login"} className="button round bg-white big2"
                          style={{color: '#de0013', marginLeft: '6px', width: '45%'}}>로그인</Link>
                </div>
            </header>
            }
            {/*<Swiper className="swiper-container main-swiper"
                    modules={[Pagination]}
                    pagination={{clickable: true}}>
                {contents.map((value, key) => (
                    <SwiperSlide key={key}><DivSlide content={value}/></SwiperSlide>
                ))}
            </Swiper>*/}

            <div className="box-link-wrap" style={{background: '#efefef'}}>
                <h2>고객님, 필요한 서비스를 선택하세요.</h2>
                <div className="d-flex align-items-center justify-content-between">
                    <Link to={"/kakaofriend"} style={{width: '48%'}}>
                        <div className="box-link service">
                            <div className="text" style={{width: '100%'}}>
                                <div className="title">카카오톡<br/>친구하기</div>
                                <div className="icon" style={{textAlign: 'right', width: '100%', marginTop: '-7px'}}>
                                    <img src={img1} style={{height: '27px'}} alt="kakao friend"/>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <AddKakaoModal2/>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <a onClick={() => goAddCampaignPage()} style={{width: '48%'}}>
                        <div className="box-link service">
                            <div className="text" style={{width: '100%'}}>
                                <div className="title">무료로 광고<br/>등록하기</div>
                                <div className="icon" style={{textAlign: 'right', width: '100%', marginTop: '-7px'}}>
                                    <img src={img3} style={{height: '32px'}} alt="add advert"/>
                                </div>
                            </div>
                        </div>
                    </a>
                    <Link to={"/home/ad-main"} style={{width: '48%'}}>
                        <div className="box-link service" style={{paddingRight: '5rem'}}>
                            <div className="text" style={{width: '100%'}}>
                                <div className="title">광고보고<br/>포인트받기</div>
                                <div className="icon" style={{textAlign: 'right', width: '100%', marginTop: '-5px'}}>
                                    <img src={img4} style={{height: '29px'}} alt="point"/>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <Link to={"/home/ad-main"}>
                    <div className="box-link ad">
                        <div className="text">
                            <div className="title" style={{color: '#1963ac'}}>광고주 이신가요?</div>
                            <div className="exp" style={{width: "80%"}}>
                                홍보하고 싶은 광고를 등록하고, 많은 사람들에게 전달해보세요.
                            </div>
                        </div>
                        <div className="icon"><img src={icon_intro_service01} alt=""/></div>
                    </div>
                </Link>
                <Link to={"/home/influ-main"}>
                    <div className="box-link influ">
                        <div className="text">
                            <div className="title" style={{color: '#219578'}}>인플루언서 이신가요?</div>
                            <div className="exp" style={{width: "80%"}}>
                                다양한 광고에 참여해서 광고도하고 리워드도 받아보세요.
                            </div>
                        </div>
                        <div className="icon"><img src={icon_intro_service02} alt=""/></div>
                    </div>
                </Link>
                <Link to={"/home/pro-main"}>
                    <div className="box-link pro">
                        <div className="text">
                            <div className="title" style={{color: '#553eab'}}>전문가 이신가요?</div>
                            <div className="exp" style={{width: "80%"}}>
                                광고에 필요한 명함, 배너, 전단지 등 전문가에게 맡겨보세요.
                            </div>
                        </div>
                        <div className="icon"><img src={icon_intro_service03} alt=""/></div>
                    </div>
                </Link>
            </div>
            <MobileNavigationFooter pageType={PageType.Common} curMenu={"home"}/>
        </div>
    );
}

export default Intro;