import React, {useEffect, useState} from "react";

import header_close from "img/m/header-close.svg";
import icon_service_strong03 from "img/m/icon-service_strong03.svg";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import {UserType} from "../../types/user-type";
import {getLocalSession} from "../../modules/localSession";

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function CommonRequirementsListDetail() {
    const params = useParams();
    const location = useLocation();
    const [profile, setProfile] = useState<any>();
    const [price, setPrice] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [isReceived, setIsReceived] = useState(false);

    useEffect(() => {
        let state: any = location.state;
        setPrice(state.price);
        setComment(state.comment);
        
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                let control = data.requestTargetUser == UserType.Influencer ? "Influencer" : "ProService";

                axios.get(`api/${control}/profile/${state.userId}`)
                    .then(response => {
                        setProfile(response.data);
                    });
            });
        axios.get(`api/MyAdvertiser/estimate/${params.bid}/received`)
            .then(response => {
                let data = response.data;
                let rcv = data.find((j: any) => j.userId == state.userId);

                if(rcv) {
                    setIsReceived(true);
                }
            });
    }, [params]);

    if (profile == null) {
        return (<></>);
    }

    const sendEstimate = () => {
        axios.post(`/api/Advertiser/estimate/request`, {
            BoardId: params.bid,
            UserId: profile.userId
        }).then(response => {
            alert("견적의뢰가 처리되었습니다.");
            window.location.href = `/requirements/ad-require/${params.bid}`;
        }).catch(error => {
            console.log(error);
            alert("견적의뢰에 실패했습니다.");
            return;
        });
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">보낸 견적서</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>

                <div className="requirements detail">
                    {/* 참여 견적서 3건 */}
                    <div className="requir-body show">
                        <div className="profile_area page-profile">
                            <div className="user">
                                <div className="img"><img src={profile.profileImg || icon_service_strong03} alt=""/>
                                </div>
                                <div className="name">{profile.name}</div>
                                <div className="comm">{profile.comment}</div>
                            </div>
                            <div className="review">
                                <div className="count">
                                    <div className="num"><span>{profile.workCount}</span>건</div>
                                    <div className="num"><span>{profile.yearOfService}</span>년</div>
                                    <div className="title">총 작업수/경력</div>
                                </div>
                                <div className="score">
                                    <div className="num">{profile.rating}</div>
                                    <div className="star">
                                        <DivCommentStar num={1} star={profile.rating}/>
                                        <DivCommentStar num={2} star={profile.rating}/>
                                        <DivCommentStar num={3} star={profile.rating}/>
                                        <DivCommentStar num={4} star={profile.rating}/>
                                        <DivCommentStar num={5} star={profile.rating}/>
                                    </div>
                                    <div className="title">평균평점</div>
                                </div>
                                <div className="count">
                                    <div className="num">{`${profile.businessHoursStart}~${profile.businessHoursEnd}`}</div>
                                    <div>{profile.exceptForWeekEnds === true ? "(주말제외)" : ""}</div>
                                    <div className="title">연락 가능 시간</div>
                                </div>
                            </div>
                        </div>

                        <div className="send">
                            <div className="requir_sub_title">받은 견적 금액</div>
                            <div className="requir_input_price common">
                                <input type="text" value={price.toLocaleString()} readOnly={true}/>
                                &nbsp;<span>원</span>
                            </div>
                            <div className="requir_textarea">
                                <textarea cols={30} rows={10} value={comment} readOnly={true}></textarea>
                            </div>
                        </div>

                        <div className="foot-btn">
                            <div className="detail-foot_one_button type_half all_black">
                                <div>
                                    {isReceived ? null :
                                    <a onClick={() => sendEstimate()} className="button full black">의뢰하기</a>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommonRequirementsListDetail;