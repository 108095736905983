import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import axios from "axios";

import "swiper/css";
import "swiper/css/pagination";

import MobileHeaderLeft from "components/m-header-left";
import MobileNavigationFooter from "components/m-navigation-footer";
import MobileEstimateRequest from "components/m-estimate-request";
import MobileInfluThumbnail from "components/m-influ-thumbnail";
import {Estimate} from "types/estimate";
import {MainCategory} from "types/category";
import {Influ} from "types/influ";
import {PageType} from "types/page-type";
import {AdSearchResultModal} from "../search/ad-search-result";
import {getLocalSession} from "modules/localSession";

import header_search from "img/m/header-search.svg";
import main_banner from "img/m/main-banner.png";
import arrow_enter from "img/m/arrow-enter.svg";
import {UserType} from "../../types/user-type";

function CategoryMenu() {
    const [categories, setCategories] = useState<MainCategory[]>([]);

    useEffect(() => {
        axios.get("api/Influencer/mainCategory")
            .then(response => {
                setCategories(response.data);
            })
    }, []);

    return (
        <Swiper className="swiper-container main-menu"
                modules={[Pagination]}
                slidesPerGroup={5}
                slidesPerView={5}
                pagination={{clickable: true}}>
            {categories.map((category, key) => (
                <SwiperSlide key={key}>
                    <div className="value_list">
                        <Link to={`/home/influ-list/${category.categoryId}`}>
                            <div className="item">
                                <div className="img_box"><img src={category.icon} alt=""/></div>
                                <span>{category.name}</span>
                            </div>
                        </Link>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

function UserRecommends() {
    const session = getLocalSession();

    if (!session) {
        return (<></>);
    }

    const [recommendedInfluencers, setRecommendedInfluencers] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/Influencer/recommendInflu")
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.influItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setRecommendedInfluencers(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setRecommendedInfluencers(newItems);
                }
            });
    }, []);

    return (
        <div className="list">
            <Link to={"/home/influ-list"} className="title">
                <span>{session.displayName}님을 위한 추천 인플루언서</span>
                <span className="arrow enter"><img src={arrow_enter} alt=""/></span>
            </Link>
            <div className="item_wrap">
                <div className="thumbnail wrap">
                    <div className="line">
                        {recommendedInfluencers.map((influ, key) => (
                            <MobileInfluThumbnail key={key} height influ={influ}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function PopularityInfluencer() {
    const session = getLocalSession();
    const [popularityInfluencers, setPopularityInfluencers] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/Influencer/popularityInflu")
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.influItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setPopularityInfluencers(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setPopularityInfluencers(newItems);
                }
            })
    }, []);

    return (
        <div className="list">
            <Link to={"/home/influ-list"} className="title">
                <span>인기 인플루언서</span>
                <span className="arrow enter"><img src={arrow_enter} alt=""/></span>
            </Link>
            <div className="item_wrap">
                {popularityInfluencers.map((influ, key) => (
                    <MobileInfluThumbnail key={key} influ={influ}/>
                ))}
            </div>
        </div>
    )
}

function Estimates() {
    const [estimates, setEstimates] = useState<Estimate[]>([]);

    useEffect(() => {
        axios.get("api/Influencer/estimates/top")
            .then(response => {
                setEstimates(response.data);
            })
    }, []);

    return (
        <div className="contact">
            <div className="list">
                <Link to={"/requirements-list/common-requirements-list/influ"} className="title">
                    <span>견적서 의뢰</span>
                    <span className="arrow enter"><img src={arrow_enter} alt=""/></span>
                </Link>
                <div className="item_wrap">
                    {estimates.map((estimate, key) => (
                        <MobileEstimateRequest key={key} estimate={estimate}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

function InfluMain() {
    const session = getLocalSession();
    const [mainSlides, setMainSlides] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/Influencer/banner")
            .then(response => {
                setMainSlides(response.data);
            });
    }, []);

    return (
        <div>
            {/* 광고주 헤더 */}
            <header className="green">
                <MobileHeaderLeft/>
                <div className="header_right">
                    {/*<AdSearchResultModal iconBlack={false}/>*/}
                    <Link to={"/filtering/influ-filtering-form"} className="filter">
                        <img src={header_search} alt="필터"/>
                    </Link>
                </div>
            </header>
            {/* slide */}
            <Swiper className="swiper-container main-slide"
                    modules={[Pagination]}
                    pagination={{clickable: true}}>
            {mainSlides.map((img, key) => (
                <SwiperSlide key={key}>
                    <a href={img.link} target="_blank"><img src={img.image} alt=""/></a>
                </SwiperSlide>
            ))}
            </Swiper>

            <div className="page-main">
                {/* 메인 메뉴 */}
                <CategoryMenu/>

                {/* 홍길동님을 위한 추천 ${type} */}
                <UserRecommends/>
            </div>
            {/* //main*/}
            {/* 광고배너 */}
            <Link to={"/kakaofriend"} className="ad_banner">
                <img src={main_banner} alt=""/>
            </Link>
            <div className="page-main">
                <PopularityInfluencer/>
            </div>

            <div className="page-main">
                {/* 견적서 의뢰 */}
            {session && session.userType === UserType.Influencer ?
                <Estimates/>
            : null}
            </div>

            <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={""}/>
        </div>);
}

export default InfluMain;