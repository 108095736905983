import React from "react";

import MobileCampaignThumbnail from "components/m-campaign-thumbnail";
import {CampaignFlag} from "types/campaign";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";
import icon_list_left from "img/m/icon-list-left.png";
import icon_my3_ing_human from "img/m/icon-my3-ing-human.png";

function MyIngModify() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a href="#" className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">MY 광고 현황</span>
                    </div>
                    <div className="header_right">
                        <a href="#" className="del"><img src={icon_del} alt="삭제"/></a>
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab blue">
                        <li id="tab-01" className="on">
                            <a href="#none">진행광고</a>
                        </li>
                        <li id="tab-02">
                            <a href="#none">완료광고</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">

                    {/*  진행광고 */}
                    <div className="requirements-list-body show" id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all"> <a href="" className="button round small gray">전체선택</a></span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body modify">
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 1,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 2,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 3,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 4,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trade-list-day">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body modify">
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 5,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 6,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 7,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 8,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*  완료광고 */}
                    <div className="requirements-list-body" id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 54건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>인기순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <div className="trade-list-day sucess">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body modify">
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 9,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 10,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 11,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 12,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trade-list-day sucess">
                                <div className="list_head">
                                    <span>2020.05.05</span>
                                </div>
                                <div className="list_body modify">
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 13,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 14,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 15,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                        <div className="trade_wrap">
                                            <MobileCampaignThumbnail usePopup campaign={{
                                                bid: 16,
                                                categoryId: 1,
                                                subCategoryId: 1,
                                                images: [],
                                                point: 10,
                                                flag: CampaignFlag.Feed,
                                                title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다."
                                            }}/>
                                            <div className="my3-ing_btngroup">
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">참여자</span><span className="color">54명</span></a>
                                                <a href=""><img src={icon_my3_ing_human} alt=""/><span
                                                    className="name">완료자</span><span className="color">54명</span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/* 하단영역 */}
                <div className="navigation height"></div>
                {/* 하단영역 */}
                <div className="foot-btn">
                    <div className="detail-foot_one_button type_half all_black">
                        <div><a href="#" className="button full black">삭제하기</a></div>
                    </div>
                </div>


            </div>
            {/* //container*/}
        </div>
    );
}

export default MyIngModify;