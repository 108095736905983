import React from "react";
import {createSlice} from "@reduxjs/toolkit";
import {SubCategory} from "types/category";

class ProCategoryRepository {
    private mainCategories: Map<number, string>;
    private subCategories: Map<number, Map<number, string>>;

    constructor(mainCategories: Map<number, string>, subCategories: Map<number, Map<number, string>>) {
        this.mainCategories = mainCategories;
        this.subCategories = subCategories;
    }

    public getMainCategoryName(id: number) {
        return this.mainCategories.get(id);
    }

    public getMainCategories() {
        return Array.from(this.mainCategories, ([categoryId, name]) => ({categoryId, name}));
    }

    public getSubCategoryName(id: number, subId: number) {
        return this.subCategories.get(id)?.get(subId);
    }

    public getSubCategories(id: number): SubCategory[] {
        const subCategories = this.subCategories.get(id);
        if (subCategories == undefined) {
            return [];
        }

        return Array.from(subCategories, ([subCategoryId, name]) => ({subCategoryId, name}));
    }
}

export const proCategorySlice = createSlice({
    name: "proCategory",
    initialState: {
        repo: new ProCategoryRepository(new Map<number, string>(), new Map<number, Map<number, string>>()),
        mainCategories: new Map<number, string>(),
        subCategories: new Map<number, Map<number, string>>(),
        isLoading: false,
    },
    reducers: {
        setCategories: (state, action) => {
            const categories = action.payload.categories;

            const mainCategories = categories.reduce((map: Map<number, string>, obj: any) => {
                map.set(obj.mainCategory.categoryId, obj.mainCategory.name);

                return map;
            }, new Map);

            const subCategories = categories.reduce((map: Map<number, Map<number, string>>, obj: any) => {
                map.set(obj.mainCategory.categoryId,
                    obj.subCategory.reduce((map: Map<number, string>, obj: any) => {
                        map.set(obj.subCategoryId, obj.name);

                        return map;
                    }, new Map));

                return map;
            }, new Map);

            state.repo = new ProCategoryRepository(mainCategories, subCategories);

            return state;
        }
    }
});

export const {setCategories} = proCategorySlice.actions;
export default proCategorySlice.reducer;