import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {Influ} from "types/influ";
import {PlatformRecord, PlatformType} from "types/platform";

import dummy_01 from "img/m/dummy-01.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import icon_twitter from "img/m/icon-twitter.png";
import icon_navercafe from "img/m/icon-naver.svg";
import icon_kakaofriend from "img/m/icon-kakao.svg";
import icon_naverblog from "img/m/icon-naverblog.png";
import ico_trade_etc from "img/m/ico-trade_etc.png";
import {useSelector} from "react-redux";
import {RootState} from "../modules";
import icon_default from "../img/m/file-basic.svg";
import {getLocalSession} from "../modules/localSession";
import axios from "axios";
import {UserType} from "../types/user-type";

function DivFeed(props: { name: string, price: number }) {
    if (props.price == 0)
        return (<></>)

    return (<a className="button round gray small">{props.name} {props.price.toLocaleString()}원</a>);
}

function DivPlatform(props: { platformRecord: PlatformRecord }) {
    if (props.platformRecord.type == PlatformType.Instagram) {
        return (
            <div className="instagram">
                <img src={icon_instagram} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else if (props.platformRecord.type == PlatformType.Facebook) {
        return (
            <div className="facebook">
                <img src={icon_facebook} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else if (props.platformRecord.type == PlatformType.Twitter) {
        return (
            <div className="twitter">
                <img src={icon_twitter} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else if (props.platformRecord.type == PlatformType.NaverCafe) {
        return (
            <div className="navercafe">
                <img src={icon_navercafe} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else if (props.platformRecord.type == PlatformType.KakaoFriend) {
        return (
            <div className="kakaofriend">
                <img src={icon_kakaofriend} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else if (props.platformRecord.type == PlatformType.NaverBlog) {
        return (
            <div className="naverblog">
                <img src={icon_naverblog} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    } else {
        return (
            <div>
                <img src={icon_default} alt=""/>
                <span>{props.platformRecord.subscribeCount.toPlatformCountString()}</span>
            </div>
        );
    }
}

function MobileInfluThumbnailWidth(props: { influ: any, usePopup?: boolean, header?: JSX.Element }) {
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);
    const influCategory = useSelector((state: RootState) => state.influCategory);
    let minCostPerClick = 0;
    let minCostPerFeed = 0;
    
    useEffect(() => {
        if(session) {
            setZzim(props.influ.isZzim);
        }
    });
    
    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.influ.bid, ZzimType: UserType.Influencer, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }
    
    if(0 < props.influ.platforms.length) {
        const costPerClicks = Array.from(props.influ.platforms, (item: any) => item.costPerClick);
        const costPerFeeds = Array.from(props.influ.platforms, (item: any) => item.costPerFeed);

        minCostPerClick = Math.min(...costPerClicks);
        minCostPerFeed = Math.min(...costPerFeeds);
    }

    return (
        <div className="thumbnail width">
            {props.header}
            <div className="image_area">
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart green${zzim ? " on" : ""}`}></div>
                : null }
                <Link to={`/home/influ-detail/${props.influ.bid}`}>
                    <img src={props.influ.images.length > 0 ? props.influ.images[0] : dummy_01} style={{objectFit: 'cover'}} alt=""/>
                </Link>
            </div>
            <div className="text_area space-between">
                <div className="text_top">
                    <Link to={`/home/influ-list/${props.influ.categoryId}/${props.influ.subCategoryId}`}
                          className="cate type-star">
                        <span>{influCategory.repo.getSubCategoryName(props.influ.categoryId, props.influ.subCategoryId)}</span>
                        <div className="trade_etc">
                            <span className="grade">
                                <span className="star">★</span>{props.influ.rating}
                            </span>
                            {props.usePopup ? (
                                <a className="etc _etc-popover"
                                   data-container="body"
                                   data-toggle="popover" data-placement="bottom"
                                   data-content="Vivamus.">
                                    <img src={ico_trade_etc} alt=""/>
                                </a>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Link>
                    <Link to={`/home/influ-detail/${props.influ.bid}`} className="title">
                        {props.influ.title}
                    </Link>
                    <div className="feed_info">
                        <DivFeed name={"클릭"} price={minCostPerClick}/>
                        <DivFeed name={"피드"} price={minCostPerFeed}/>
                    </div>
                </div>
                <div className="text_bottom">
                    <div className="sns_count">
                        {props.influ.platforms.map((platformRecord: PlatformRecord, key: number) => (
                            <DivPlatform key={key} platformRecord={platformRecord}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function MobileInfluThumbnailHeight(props: { influ: any }) {
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);
    const influCategory = useSelector((state: RootState) => state.influCategory);
    let minCostPerClick = 0;
    let minCostPerFeed = 0;

    useEffect(() => {
        if(session) {
            setZzim(props.influ.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.influ.bid, ZzimType: UserType.Influencer, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }

    if(0 < props.influ.platforms.length) {
        const costPerClicks = Array.from(props.influ.platforms, (item: any) => item.costPerClick);
        const costPerFeeds = Array.from(props.influ.platforms, (item: any) => item.costPerFeed);

        minCostPerClick = Math.min(...costPerClicks);
        minCostPerFeed = Math.min(...costPerFeeds);
    }

    return (
        <div className="thumbnail height">
            <div className="image_area">
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart green${zzim ? " on" : ""}`}></div>
                : null }
                <Link to={`/home/influ-detail/${props.influ.bid}`}>
                    <img src={props.influ.images.length > 0 ? props.influ.images[0] : dummy_01} style={{objectFit: 'cover'}} alt=""/>
                </Link>
            </div>
            <div className="text_area">
                <Link to={"/home/influ-list"} className="cate type-star">
                    <span>{influCategory.repo.getSubCategoryName(props.influ.categoryId, props.influ.subCategoryId)}</span>
                    <div className="trade_etc">
                        <span className="grade">
                            <span className="star">★</span>{props.influ.rating}
                        </span>
                    </div>
                </Link>
                <Link to={`/home/influ-detail/${props.influ.bid}`} className="title">
                    {props.influ.title}
                </Link>
                <div className="feed_info">
                    <DivFeed name={"클릭"} price={minCostPerClick}/>
                    <DivFeed name={"피드"} price={minCostPerFeed}/>
                </div>
                <div className="sns_count">
                    {props.influ.platforms.map((platformRecord: PlatformRecord, key: number) => (
                        <DivPlatform key={key} platformRecord={platformRecord}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

function MobileInfluThumbnail(props: { height?: boolean, influ: any, usePopup?: boolean, header?: JSX.Element }) {
    const isHeight = props.height ?? false;

    return isHeight ?
        <MobileInfluThumbnailHeight influ={props.influ}/> :
        <MobileInfluThumbnailWidth influ={props.influ} usePopup={props.usePopup} header={props.header}/>
}

export default MobileInfluThumbnail;