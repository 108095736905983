import React, {useRef, useState} from "react";
import {Form} from "react-bootstrap";

import form_alert from "img/m/form-alert.svg";
import header_back from "img/m/header-back.svg";
import header_close from "img/m/header-close.svg";
import file_speaker from "img/m/file-speaker.svg";
import file_photo from "img/m/file-photo.svg";
import {initData, RegisterCampaignMemberRequest} from "types/advertiser-request";
import {BankCode} from "utils/bank-code";
import WishLocation from "./wish-location";
import WishAdvertisement from "./wish-ad";
import {PlatformType} from "types/platform";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import setUser from "utils/setUser";

let memData: any = {
    IsBusiness: false,
    Name: '',
    ProfileImg: '',
    IdentificationNumber: '',
    BusinessOwner: '',
    Address1: '',
    Address2: '',
    ContactNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RegionOfInterest: [],
    AreaOfInterest: [],
    Platforms: [],
    Homepage: '',
    Comment: '',
    DetailComment: ''
};

let CampaignMemberRef: any = {};
let isAgree = true;
let isCompany = false;

function DivComplete(props: { index: number, step: number }) {
    if (props.index <= props.step) {
        return (<li className="complete"></li>);
    }

    return (<li></li>);
}

function CampaignProfileAdd() {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [wishLoc, setWishLoc] = useState(false);
    const [wishAd, setWishAd] = useState(false);
    const [wishLocSel, setWishLocSel] = useState<string[]>([]);
    const [wishAdSel, setWishAdSel] = useState<string[]>([]);
    const [platformSel, setPlatformSel] = useState<string[]>([]);
    const stepArr = [0, 1];
    const [userType, setUserType] = useState<string>("Individual");
    const [profileImage, setProfileImage] = useState<any>(null);

    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        setProfileImage(profileImageReader.result);
    }

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];


    CampaignMemberRef = {
        name: useRef<HTMLInputElement>(null),
        identificationNumber: useRef<HTMLInputElement>(null),
        businessOwner: useRef<HTMLInputElement>(null),
        address1: useRef<HTMLInputElement>(null),
        address2: useRef<HTMLInputElement>(null),
        contactNumber: useRef<HTMLInputElement>(null),
        bankNumber: useRef<HTMLInputElement>(null),
        bankCode: useRef<HTMLSelectElement>(null),
        bankOwner: useRef<HTMLInputElement>(null),
        homepage: useRef<HTMLInputElement>(null),
        comment: useRef<HTMLInputElement>(null),
        detailComment: useRef<HTMLTextAreaElement>(null),
    }
    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    }

    /* TODO: 필수 항목 재정의 필요 (개인 선택한 경우 주소 필수 항목 불필요 등) */
    const handleNext = () => {
        let regNum = /^[0-9]+$/;

        if (userType === "Individual") {
            memData.IsBusiness = false;

            if ('' == memData.Name) {
                alert("이름을 입력해주세요.");
                if (CampaignMemberRef.name.current !== null) CampaignMemberRef.name.current.focus();
                return;
            }

            /*if ('' == memData.IdentificationNumber) {
                alert("주민번호를 입력해주세요.");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }*/

            /*if (!regNum.test(memData.IdentificationNumber)) {
                alert("주민번호를 숫자로만 입력해주세요.");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }*/

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                if (CampaignMemberRef.bankCode.current !== null) CampaignMemberRef.bankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                if (CampaignMemberRef.bankOwner.current !== null) CampaignMemberRef.bankOwner.current.focus();
                return;
            }
        } else {
            memData.isBusiness = true;

            if ('' == memData.Name) {
                alert("기업명을 입력해주세요.");
                if (CampaignMemberRef.name.current !== null) CampaignMemberRef.name.current.focus();
                return;
            }

            if ('' == memData.IdentificationNumber) {
                alert("사업자번호를 입력해주세요.");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("숫자로만 입력해주세요.");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if (!isCompany) {
                alert("사업자번호를 인증해주세요.");
                if (CampaignMemberRef.identificationNumber.current !== null)
                    CampaignMemberRef.identificationNumber.current.focus();
                return;
            }

            if ('' == memData.BusinessOwner) {
                alert("대표자명을 입력해주세요.");
                if (CampaignMemberRef.businessOwner.current !== null) CampaignMemberRef.businessOwner.current.focus();
                return;
            }

            if ('' == memData.Address1) {
                alert("주소를 입력해주세요.");
                if (CampaignMemberRef.address1.current !== null) CampaignMemberRef.address1.current.focus();
                return;
            }

            if ('' == memData.Address2) {
                alert("상세주소를 입력해주세요.");
                if (CampaignMemberRef.address2.current !== null) CampaignMemberRef.address2.current.focus();
                return;
            }

            if ('' == memData.ContactNumber) {
                alert("담당자 연락처를 입력해주세요.");
                if (CampaignMemberRef.contactNumber.current !== null) CampaignMemberRef.contactNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.ContactNumber)) {
                alert("담당자 연락처를 숫자로만 입력해주세요.");
                if (CampaignMemberRef.contactNumber.current !== null) CampaignMemberRef.contactNumber.current.focus();
                return;
            }

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                if (CampaignMemberRef.bankNumber.current !== null) CampaignMemberRef.bankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                if (CampaignMemberRef.bankCode.current !== null) CampaignMemberRef.bankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                if (CampaignMemberRef.bankOwner.current !== null) CampaignMemberRef.bankOwner.current.focus();
                return;
            }
        }

        if (!isAgree) {
            alert("약관에 동의해야 합니다.");
            return;
        }

        console.log(memData);
        setStep(step + 1);
    };

    const registCampaignMember = () => {
        if (wishLocSel.length < 1) {
            alert("관심 지역을 선택해주세요.");
            return;
        }

        if (wishAdSel.length < 1) {
            alert("관심 분야를 선택해주세요.");
            return;
        }

        if (platformSel.length < 1) {
            alert("광고 채널을 1개 이상 선택해주세요.");
            return;
        }

        if ('' == memData.Homepage) {
            alert("자사 홈페이지를 입력해주세요.");
            if (CampaignMemberRef.homepage.current !== null) CampaignMemberRef.homepage.current.focus();
            return;
        }

        if ('' == memData.Comment) {
            alert("한줄 자기 소개를 입력해주세요.");
            if (CampaignMemberRef.comment.current !== null) CampaignMemberRef.comment.current.focus();
            return;
        }

        if ('' == memData.DetailComment) {
            alert("상세 소개를 입력해주세요.");
            if (CampaignMemberRef.detailComment.current !== null) CampaignMemberRef.detailComment.current.focus();
            return;
        }

        memData.Platforms = [];
        platformSel.forEach(platform => {
            if(Number(platform) == PlatformType.Instagram) {
                memData.Platforms.push("Instagram");
            } else if(Number(platform) == PlatformType.Facebook) {
                memData.Platforms.push("Facebook");
            } else if(Number(platform) == PlatformType.Twitter) {
                memData.Platforms.push("Twitter");
            } else if(Number(platform) == PlatformType.NaverBlog) {
                memData.Platforms.push("NaverBlog");
            } else if(Number(platform) == PlatformType.NaverCafe) {
                memData.Platforms.push("NaverCafe");
            } else if(Number(platform) == PlatformType.KakaoFriend) {
                memData.Platforms.push("KakaoStory");
            } else if(Number(platform) == 6) {
                memData.Platforms.push("Youtube");
            } else if(Number(platform) == 99) {
                memData.Platforms.push("Etc");
            }
        });

        memData.RegionOfInterest = wishLocSel;
        memData.AreaOfInterest = wishAdSel;
        memData.ProfileImg = profileImage || "";

        axios.post('/api/Advertiser/member', memData).then(response => {
            alert("프로필 등록이 완료되었습니다.");
            setUser(response.data);

            navigate('/');
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");

            return;
        });
    };

    const onBack = () => {
        if (step == 0) {
            history.back();
        }

        setStep(step - 1);

        memData = {
            IsBusiness: memData.isBusiness,
            Name: '',
            ProfileImg: '',
            IdentificationNumber: '',
            BusinessOwner: '',
            Address1: '',
            Address2: '',
            ContactNumber: '',
            BankNumber: '',
            BankCode: '',
            BankOwner: '',
            RegionOfInterest: [],
            AreaOfInterest: [],
            Platforms: [],
            Homepage: '',
            Comment: '',
            DetailComment: ''
        };
    };

    const selectPlatform = (platformType: number) => {
        if (platformSel.includes(platformType.toLocaleString())) {
            setPlatformSel(platformSel.filter(item => item !== platformType.toLocaleString()));
        } else {
            setPlatformSel([...platformSel, platformType.toLocaleString()])
        }
    };

    const Individual = () => {
        const [individualData, setIndividualData] = useState<RegisterCampaignMemberRequest>(initData);

        const handleIndividualChange = (e: any) => {
            setIndividualData({
                ...individualData,
                [e.target.name]: e.target.value
            });

            memData[e.target.name] = e.target.value;
        };

        return (
            <>
                {/* 이름 */}
                <div className="layout line type-profile">
                    <div className="profile_name">이름</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="실명을 입력해주세요" name="Name"
                               value={individualData.Name} onChange={handleIndividualChange}
                               ref={CampaignMemberRef.name}/>
                    </div>
                </div>
                {/* 주민번호 */}
                {/*<div className="layout line type-profile">
                    <div className="profile_name">주민번호</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="'-' 없이 주민번호를 입력해주세요"
                               name="IdentificationNumber" value={individualData.IdentificationNumber}
                               onChange={handleIndividualChange} ref={CampaignMemberRef.identificationNumber}/>
                    </div>
                </div>*/}
                {/* 출금계좌번호 */}
                <div className="layout line type-profile">
                    <div className="profile_name">계좌번호</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="'-' 없이 계좌번호를 입력해주세요"
                               name="BankNumber" value={individualData.BankNumber} onChange={handleIndividualChange}
                               ref={CampaignMemberRef.bankNumber}/>
                    </div>
                </div>
                {/* 은행명 */}
                <div className="layout line type-profile">
                    <div className="profile_name">은행명</div>
                    <div className="input_section">
                        <select className="selectbox" name="BankCode" value={individualData.BankCode}
                                onChange={handleIndividualChange} ref={CampaignMemberRef.bankCode}>
                            <option value="">은행 선택</option>
                            {BankCode.map((bank, key) => (
                                <option key={key} value={bank.code}>{bank.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* 예금주 */}
                <div className="layout line type-profile">
                    <div className="profile_name">예금주</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="예금주를 입력해주세요" name="BankOwner"
                               value={individualData.BankOwner} onChange={handleIndividualChange}
                               ref={CampaignMemberRef.bankOwner}/>
                    </div>
                </div>
                <div className="layout line type-profile">
                    <div className="notice_area">
                        <div className="title">
                            <img src={form_alert} alt=""/>
                            <span>세금 관련 유의사항</span>
                        </div>
                        <div className="desc">
                            -픽퐁을 통해 서비스 판매하는 판매자는 별도의 사업자 등록이 없어도 서비스 등록과 판매가 가능합니다.<br/>
                            -지속적인 매출이 발생하는 경우 국세청에서는 사업자 등록을 권고하고 있으니 해당 내용을 참고하시어 사업자 등록 여부를 확인하시길 바랍니다.
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const Organizations = () => {
        const [organizationData, setOrganizationData] = useState<RegisterCampaignMemberRequest>(initData);

        const handleOrganizationChange = (e: any) => {
            setOrganizationData({
                ...organizationData,
                [e.target.name]: e.target.value
            });

            memData[e.target.name] = e.target.value;

            if("IdentificationNumber" == e.target.name) {
                isCompany = false;
            }
        };

        const validateCompany = () => {
            if("" !== organizationData.IdentificationNumber) {
                axios.get(`api/Advertiser/validate_company/${organizationData.IdentificationNumber}`)
                    .then(response => {
                        isCompany = response.data;

                        if (!response.data) {
                            alert("인증에 실패했습니다.");
                        } else {
                            alert("인증에 성공했습니다.");
                        }
                    });
            }
        };

        return (
            <>
                {/* 기업명 */}
                <div className="layout line type-profile">
                    <div className="profile_name">기업명</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="기업명을 입력해주세요" name="Name"
                               value={organizationData.Name} onChange={handleOrganizationChange}
                               ref={CampaignMemberRef.name}/>
                    </div>
                </div>
                {/* 사업자 등록번호 */}
                <div className="layout line type-profile">
                    <div className="profile_name">사업자 등록번호</div>
                    <div className="input_section">
                        <div className="input button-right">
                            <input className="input basic" type="text" placeholder="- 제외, 숫자만 입력하세요"
                                   name="IdentificationNumber" value={organizationData.IdentificationNumber}
                                   onChange={handleOrganizationChange} ref={CampaignMemberRef.identificationNumber}/>
                            <a onClick={() => validateCompany()}
                               className="input button d-flex align-items-center justify-content-center">인증</a>
                        </div>
                    </div>
                </div>
                {/* 대표자명 */}
                <div className="layout line type-profile">
                    <div className="profile_name">대표자명</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="대표자명을 입력하세요"
                               name="BusinessOwner" value={organizationData.BusinessOwner}
                               onChange={handleOrganizationChange} ref={CampaignMemberRef.businessOwner}/>
                    </div>
                </div>
                {/* 주소 */}
                <div className="layout line type-profile">
                    <div className="profile_name">주소</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"
                               name="Address1" value={organizationData.Address1} ref={CampaignMemberRef.address1}
                               onChange={handleOrganizationChange}/>
                    </div>
                    <div className="input_section adress">
                        <input className="input basic" type="text" placeholder="상세주소를 입력하세요"
                               name="Address2" value={organizationData.Address2} ref={CampaignMemberRef.address2}
                               onChange={handleOrganizationChange}/>
                    </div>
                </div>
                {/* 담당자 연락처 */}
                <div className="layout line type-profile">
                    <div className="profile_name">담당자 연락처</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="- 제외, 숫자만 입력하세요"
                               name="ContactNumber" value={organizationData.ContactNumber}
                               onChange={handleOrganizationChange} ref={CampaignMemberRef.contactNumber}/>
                    </div>
                </div>
                {/* 출금계좌번호 */}
                <div className="layout line type-profile">
                    <div className="profile_name">출금계좌번호</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="'-' 없이 계좌번호를 입력해주세요"
                               name="BankNumber" value={organizationData.BankNumber} ref={CampaignMemberRef.bankNumber}
                               onChange={handleOrganizationChange}/>
                    </div>
                </div>
                {/* 은행명 */}
                <div className="layout line type-profile">
                    <div className="profile_name">은행명</div>
                    <div className="input_section">
                        <select className="selectbox" name="BankCode" value={organizationData.BankCode}
                                onChange={handleOrganizationChange} ref={CampaignMemberRef.bankCode}>
                            <option value="">은행 선택</option>
                            {BankCode.map((bank, key) => (
                                <option key={key} value={bank.code}>{bank.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* 예금주 */}
                <div className="layout line type-profile">
                    <div className="profile_name">예금주</div>
                    <div className="input_section">
                        <input className="input basic" type="text" placeholder="예금주를 입력해주세요"
                               name="BankOwner" value={organizationData.BankOwner} ref={CampaignMemberRef.bankOwner}
                               onChange={handleOrganizationChange}/>
                    </div>
                </div>
                <div className="layout line type-profile">
                    <div className="notice_area">
                        <div className="title">
                            <img src={form_alert} alt=""/>
                            <span>세금 관련 유의사항</span>
                        </div>
                        <div className="desc">
                            -픽퐁을 통해 서비스 판매하는 판매자는 별도의 사업자 등록이 없어도 서비스 등록과 판매가 가능합니다.<br/>
                            -지속적인 매출이 발생하는 경우 국세청에서는 사업자 등록을 권고하고 있으니 해당 내용을 참고하시어 사업자 등록 여부를 확인하시길 바랍니다.
                        </div>
                    </div>
                </div>
            </>
        );
    }

    const Stipulate = () => {
        const [chk, setChk] = useState(true);

        const handleAgreeCheckChange = (e: any) => {
            isAgree = !isAgree;
            setChk(isAgree);
        }
        
        return (
            <>
                {/* 약관동의 */}
                <div className="layout line type-profile">
                    <div className="profile_name">약관동의</div>
                    <div className="notice_area">
                        <div className="desc scroll_limit">
                            <div className="limit_seciton">
                                <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                <div className="info">
                                    제 1조 [목적]<br/>
                                    본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                    계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                </div>
                            </div>
                            <div className="limit_seciton">
                                <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                <div className="info">
                                    제 1조 [목적]<br/>
                                    본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                    계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="layout line type-profile">
                    <div className="checkbox">
                        <input type="checkbox" className="check_input" id="vehicle1"
                               checked={chk} onChange={handleAgreeCheckChange}/>
                        <label htmlFor="vehicle1">
                            <span className={`circle${isAgree ? " checked" : ""}`}></span>
                            <span className="name agree_text type03">
                                본인은 위 약관의 내용을 모두 확인하였으며, 약관에 따라 성실히 활동할 것에 동의합니다.
                            </span>
                        </label>
                    </div>
                </div>
                {/* 서명 */}
                {/*TODO : 보류*/}
                {/*<div className="layout line type-profile">*/}
                {/*    <div className="profile_name del">*/}
                {/*        <span>서명</span>*/}
                {/*        <a href="#" className="del_btn">지우기</a>*/}
                {/*    </div>*/}
                {/*    <div className="signature"></div>*/}
                {/*</div>*/}
            </>
        );
    }

    const DivStep = () => {
        const handleUserTypeChange = (e: any) => setUserType(e.target.value);

        if (step == 0) {
            return (
                <>
                    {/* TODO: 이미지 업로드 API 구현 필요 */}
                    <div className="member profile">
                        <div className="file_upload center">
                            <div className="circle">
                                {profileImage === null ? (<img className="speaker" src={file_speaker} alt=""/>)
                                    : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                            </div>
                            <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                            <img className="photo_button" src={file_photo} alt=""/>
                        </div>
                        {/* 본인인증 타이틀 */}
                        <div className="layout">
                            <div className="profile_title">본인인증</div>
                        </div>
                        <form action="">
                            {/* 판매자 구분 */}
                            <div className="layout line type-profile">
                                <div className="profile_name">판매자 구분</div>
                                <div className="input_section">
                                    <Form.Check inline className="radio inline">
                                        <Form.Check.Label>
                                            <input type="radio" id="individual" style={{accentColor: "#0a61d4"}}
                                                              className="circle"
                                                              value="Individual"
                                                              name="user_type"
                                                              checked={userType === "Individual"}
                                                              onChange={handleUserTypeChange}
                                            />
                                            <Form.Check.Label className="name basic"
                                                              htmlFor="individual">개인</Form.Check.Label>
                                        </Form.Check.Label>
                                    </Form.Check>
                                    <Form.Check inline className="radio">
                                        <Form.Check.Label>
                                            <input type="radio" id="organizations" style={{accentColor: "#0a61d4"}}
                                                              className="circle"
                                                              name="user_type"
                                                              value="Organizations"
                                                              checked={userType === "Organizations"}
                                                              onChange={handleUserTypeChange}
                                            />
                                            <Form.Check.Label className="name basic"
                                                              htmlFor="organizations">기업</Form.Check.Label>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </div>
                            </div>
                            {
                                userType === "Individual" ?
                                    <><Individual/><Stipulate/></>
                                    :
                                    <><Organizations/><Stipulate/></>
                            }
                        </form>
                    </div>
                    <div className="bottom chap">
                        <a className="button full blue" onClick={() => handleNext()}>다음단계</a>
                    </div>
                </>
            );
        } else {
            const [addData, setAddData] = useState<RegisterCampaignMemberRequest>(initData);

            const handleAddChange = (e: any) => {
                setAddData({
                    ...addData,
                    [e.target.name]: e.target.value
                });

                memData[e.target.name] = e.target.value;
            };

            return (
                <>
                    <div className="member profile">
                        {/* 타이틀 - 추가정보 */}
                        <div className="profile-title">
                            <div className="profile_title">추가정보</div>
                        </div>
                        <form action="">
                            {/* 관심지역 */}
                            <div className="layout line type-profile">
                                <div className="wish_local">
                                    <div className="title">관심 지역</div>
                                    <a className="choice _wish_local" onClick={() => setWishLoc(true)}>선택하기</a>
                                </div>
                                <div className="input_section wish_local-info">
                                    {wishLocSel.length > 0 ? wishLocSel.join(', ') : '지역을 선택하세요.'}
                                </div>
                            </div>
                            {/* 관심분야 */}
                            <div className="layout line type-profile">
                                <div className="wish_local">
                                    <div className="title">관심 분야</div>
                                    <a className="choice _wish_field" onClick={() => setWishAd(true)}>선택하기</a>
                                </div>
                                <div className="input_section wish_local-info">
                                    {wishAdSel.length > 0 ? wishAdSel.join(', ') : '분야를 선택하세요.'}
                                </div>
                            </div>
                            {/* 광고 채널 */}
                            <div className="layout line type-profile">
                                <div className="profile_name">광고 채널</div>
                                <div className="input_section">
                                    <div className="grid grid-3">
                                        {platforms.map((platform, idx) => (
                                            <div key={idx}>
                                                <a className={`button ad_channel _onOff${platformSel.includes(platform.type.toLocaleString()) ? ' on' : ''}`}
                                                   onClick={() => selectPlatform(platform.type)}>{platform.name}</a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* 자사 홈페이지 */}
                            <div className="layout line type-profile">
                                <div className="profile_name">자사 홈페이지</div>
                                <div className="input_section">
                                    <input className="input basic" type="text" placeholder="https://"
                                           name="Homepage" value={addData.Homepage}
                                           ref={CampaignMemberRef.homepage}
                                           onChange={handleAddChange}/>
                                </div>
                            </div>
                            {/* 한줄 자기 소개 */}
                            <div className="profile-title">
                                <div className="profile_title">한줄 자기 소개</div>
                            </div>
                            <div className="layout line type-profile">
                                <div className="input_section">
                                    <input className="input basic" placeholder="한줄 자기 소개를 입력하세요."
                                              name="Comment" value={addData.Comment}
                                              ref={CampaignMemberRef.comment}
                                              onChange={handleAddChange}/>
                                </div>
                            </div>
                            {/* 한줄 자기 소개 */}
                            <div className="profile-title">
                                <div className="profile_title">상세 소개</div>
                            </div>
                            <div className="layout line type-profile">
                                <div className="input_section">
                                    { // @ts-ignore
                                        <textarea className="textarea basic" placeholder=""
                                                  name="DetailComment" value={addData.DetailComment}
                                                  ref={CampaignMemberRef.detailComment}
                                                  onChange={handleAddChange}></textarea>
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="bottom chap">
                        <a onClick={() => registCampaignMember()} className="button full blue">등록완료</a>
                    </div>
                </>
            );
        }
    };

    if(wishLoc) {
        return (<WishLocation setWishLoc={setWishLoc} setSelect={setWishLocSel}/>);
    } else if(wishAd) {
        return (<WishAdvertisement setWishAd={setWishAd} setSelect={setWishAdSel}/>);
    } else {
        return (
            <>
                <div>
                    <div className="contents mobile">
                        <header className="header-member">
                            <div className="header_left">
                                <a onClick={() => onBack()} className="back">
                                    <img src={header_back} alt="뒤로가기"/>
                                </a>
                                <span className="location">캠페인 프로필 등록</span>
                            </div>
                            <div className="header_right">
                                <a onClick={() => history.back()}>
                                    <img className="close" src={header_close} alt=""/>
                                </a>
                            </div>
                        </header>
                        <ul className="navigation progress blue">
                            {stepArr.map((_, index) => (
                                <DivComplete key={index} index={index} step={step}/>
                            ))}
                        </ul>

                        <DivStep/>
                    </div>
                </div>
            </>
        );
    }
}

export default CampaignProfileAdd;