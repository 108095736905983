import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {Link, useNavigate, useParams} from "react-router-dom";
import {Nav, Tab} from "react-bootstrap";
import axios from "axios";
import default_profile from "img/m/request-human.png";
import "swiper/css";
import "swiper/css/pagination";

import MobileCampaignThumbnail from "components/m-campaign-thumbnail";
import MobileDetailCampaignComment from "components/m-detail-campaign-comment";
import {
    CampaignComment,
    CampaignComments,
    CampaignFlag,
    CampaignItem,
    CampaignItemDetail,
    DivCampaignFlag
} from "types/campaign";

import {DivDateRange} from "types/datetime-extensions";
import {DivPlatformIcon} from "types/platform";

import icon_detail_back from "img/m/header-back-white.png";
import {UserSimpleProfile} from "types/profile";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import {getLocalSession} from "../../modules/localSession";
import {AdvertisingJoinType} from "../../types/advertising-type";
import RewardPopup from "../popup/reward";
import {UserType} from "../../types/user-type";

const enum CommentSortFilter {
    Order,
    HighRating,
    LowRating
}

type commentCountSetState = React.Dispatch<React.SetStateAction<number>>;

function Comment(props: { bid: any, setCommentCount: commentCountSetState, adUserId: string }) {
    const [sortFilter, setSortFilter] = useState(CommentSortFilter.Order);
    const [comments, setComments] = useState<CampaignComment[]>([]);
    const [commentCount, setCommentCount] = useState<number>(0);

    useEffect(() => {
        axios.get(`api/Advertiser/campaign/${props.bid}/comment/${sortFilter}`)
            .then(response => {
                const data: CampaignComments = response.data;
                setComments(data.comments);

                props.setCommentCount(data.totalCount);
                setCommentCount(data.totalCount);
            });
    }, [sortFilter, props.bid]);

    return (
        <>
            {/* 총 314개 최신순 / 높은 평점순 낮은 평점순 */}
            <div className="star_list">
                <div className="total">총 {commentCount.toLocaleString()}개</div>
                <div className="grade">
                    <a className={sortFilter == CommentSortFilter.Order ? "on blue" : ""}
                       onClick={() => setSortFilter(CommentSortFilter.Order)}>최신순</a>
                    {/*<a className={sortFilter == CommentSortFilter.HighRating ? "on blue" : ""}*/}
                    {/*   onClick={() => setSortFilter(CommentSortFilter.HighRating)}>높은 평점순</a>*/}
                    {/*<a className={sortFilter == CommentSortFilter.LowRating ? "on blue" : ""}*/}
                    {/*   onClick={() => setSortFilter(CommentSortFilter.LowRating)}>낮은 평점순</a>*/}
                </div>
            </div>

            {/* 리플들 */}
            <div className="c-review_list">
                {comments.map((comment, key) => (
                    <MobileDetailCampaignComment key={key} campaignComment={comment} adUserId={props.adUserId}
                                                 bid={props.bid}/>
                ))}
            </div>
        </>
    );
}

function Variation(props: { bid: any }) {
    const session = getLocalSession();
    const [adVariation, setAdVariation] = useState<any[]>([]);

    useEffect(() => {
        axios.get(`api/Advertiser/campaign/${props.bid}/variation`)
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.adItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setAdVariation(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setAdVariation(newItems);
                }
            });
    }, [props.bid]);

    return (
        <div className="similar_area">
            <div className="title">유사 광고</div>
            <div className="contents">
                <Swiper className="thumbnail slide swiper-container"
                        speed={400}
                        spaceBetween={10}
                        width={190}>
                    {adVariation.map((campaign, key) => (
                        <SwiperSlide key={key}>
                            <MobileCampaignThumbnail height campaign={campaign}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
}

function Profile(props: { bid: any, profile: UserSimpleProfile }) {
    return (
        <div className="detail_profile">
            <div className="info">
                <div>
                    <img className="comment-profile_img" src={props.profile.profileImg || default_profile} alt=""/>
                </div>
                <div className="profile_text">
                    <div className="name">{props.profile.name}</div>
                    <div className="contact">{props.profile.comment}</div>
                </div>
            </div>
            <div className="button_area">
                <Link to={`/profile/ad-profile/${props.profile.userId}`}
                      className="button detail-profile blue">프로필</Link>
            </div>
        </div>
    );
}

const enum ButtonType {
    Join,
    Auth,
    Reward,
    Complete
}

function AdDetail() {
    const params = useParams();
    const [commentCount, setCommentCount] = useState<number>(0);
    const [rewardPoint, setRewardPoint] = useState<number>(0);
    const [popupShow, setPopupShow] = useState<boolean>(false);
    const [campaign, setCampaign] = useState<CampaignItemDetail>();
    const [btnType, setBtnType] = useState<ButtonType>(ButtonType.Reward);
    const [btnName, setBtnName] = useState("리워드 받기");
    const [profile, setProfile] = useState<UserSimpleProfile>();
    const [zzim, setZzim] = useState(false);
    const adCategory = useSelector((state: RootState) => state.adCategory);
    const session = getLocalSession();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/Advertiser/campaign/${params.bid}`)
            .then(response => {
                let data = response.data;
                let jType = AdvertisingJoinType.None;
                let cam = data.campaign;
                
                setCampaign(cam);
                
                if (session) {
                    let rewards = data.rewards;

                    if (0 < rewards.length) {
                        let reward = data.rewards.find((r: any) => r.userId == session.id);

                        if (reward) {
                            jType = reward.joinType;
                        }
                    }
                }
                
                if(cam.flag == CampaignFlag.Feed) {
                    if(jType == AdvertisingJoinType.FeedJoin) {
                        setBtnType(ButtonType.Auth);
                        setBtnName("광고 인증하기");
                    } else if(jType == AdvertisingJoinType.None) {
                        setBtnType(ButtonType.Join);
                        setBtnName("광고 참여 신청");
                    } else {
                        setBtnType(ButtonType.Complete);
                        setBtnName("참여 완료");
                    }
                } else if(jType == AdvertisingJoinType.Click) {
                    setBtnType(ButtonType.Complete);
                    setBtnName("리워드 받기 완료");
                }
            });
        axios.get(`api/Advertiser/campaign/${params.bid}/profile`)
            .then(response => {
                setProfile(response.data);
            });
        
        if(session) {
            axios.get(`api/Zzim/item/${UserType.Advertiser}/${params.bid}`)
                .then(response => {
                    let data = response.data;
                    setZzim(data.isZzim);
                });
        }
    }, [params]);

    if (campaign == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    const headerImages = campaign.images.length != 0 ? campaign.images : ["/img/dummy-detail01.png"];

    const joinReward = () => {
        if (btnType === ButtonType.Join) {
            axios.post(`/api/Advertiser/feed_join/${params.bid}`).then(response => {
                alert("광고 참여 신청이 되었습니다.");
                window.location.reload();
            }).catch(error => {
                console.log(error);
                alert("광고 참여 신청에 실패했습니다.");
                return;
            });
        } else if (btnType === ButtonType.Auth) {
            navigate("/write/ad-write-auth", {
                state: {
                    bid: params.bid
                }
            });
        } else if (btnType === ButtonType.Reward) {
            axios.post(`/api/Advertiser/click_reward/${params.bid}`).then(response => {
                setRewardPoint(response.data.point);
                setPopupShow(true);
            }).catch(error => {
                console.log(error);
                alert("리워드 획득에 실패했습니다.");
                return;
            });
        }
    };

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: params.bid, ZzimType: UserType.Advertiser, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    };
    
    const deleteItem = () => {
        if(confirm("삭제하시겠습니까?")) {
            axios.delete(`api/Advertiser/campaign/${params.bid}`).then(response => {
                alert("삭제되었습니다.");
                navigate(`/home/ad-list/${campaign.categoryId}`);
            });
        }
    };
    
    const endItem = () => {
        if(confirm("마감하시겠습니까?")) {
            axios.patch(`api/Advertiser/campaign/${params.bid}/end`).then(response => {
                alert("마감되었습니다.");
                window.location.reload();
            });
        }
    };

    return (
        <div>
            {/* 이미지 */}
            <div className="detail_product">
                <a className="detail_back" onClick={() => history.back()}>
                    <img src={icon_detail_back} alt="left arrow"/>
                </a>
                <Swiper className="swiper-container detail_product-img"
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    {headerImages.map((imageSrc, key) => (
                        <SwiperSlide key={key}>
                            <a className="item">
                                <img src={imageSrc} style={{width: '100%', height: '300px', objectFit: 'cover'}} alt=""/>
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            {/* 상세보기  */}
            <div className="page-detail foot-on">
                {/* 가격정보 */}
                <div className="info_area">
                    <div className="cate">
                        <div className="cate_title">{adCategory.repo.getMainCategoryName(campaign.categoryId)}</div>
                        <div className="cate_heart">
                            {session && session.userType !== UserType.None ?
                            <div onClick={() => changeZzim()} className={`button heart blue${zzim ? " on" : ""}`}></div>
                            : null }
                        </div>
                    </div>
                    <div className="title">{campaign.title}</div>
                    <div className="price point">
                        <DivCampaignFlag flag={campaign.flag}/>
                        <div className="name">{campaign.point.toLocaleString()}P</div>
                    </div>
                </div>

                {/* tab에서 지정한 id 값에 따라 [서비스정보] 또는 [인증(314개)]가 노출됩니다. */}
                {/* 서비스정보, 평점 탭*/}
                <Tab.Container defaultActiveKey="info">
                    <div className="detail_tab">
                        <Nav className="navigation tab blue" as={"ul"}>
                            <Nav.Link eventKey="info" as={"li"}>
                                <a>광고 정보</a>
                            </Nav.Link>
                            <Nav.Link eventKey="comment" as={"li"}>
                                <a>인증({commentCount}개)</a>
                            </Nav.Link>
                        </Nav>
                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="info">
                            {/* 서비스 정보 */}
                            <div className="detail_contents">
                                {/* 프로필 영역 */}
                                {profile ?
                                <Profile bid={params.bid} profile={profile}/>
                                : null}

                                {/* 프로필 영역 == 광고신청기간 , 광고진행채널 */}
                                <div className="detail_ad">
                                    <div className="ad_wrap">
                                        <div className="date">
                                            <div className="title">광고 신청기간</div>
                                            <div className="dateondate">
                                                <DivDateRange startDate={campaign.startDate}
                                                              endDate={campaign.endDate}/>
                                            </div>
                                        </div>
                                        <div className="channel">
                                            <div className="title">광고 진행 채널</div>
                                            <div className="sns_group">
                                                {campaign.campaignChannel.map((platformType, key) => (
                                                    <React.Fragment key={key}>
                                                        <DivPlatformIcon type={platformType}/>
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 서비스 설명 + 광고 설명 */}
                                <div className="service_desc">
                                    <div className="title">광고 설명</div>
                                    <div className="desc" dangerouslySetInnerHTML={{__html: campaign.detail}}>
                                    </div>
                                </div>
                                {/*/!* 이미지 영역 *!/*/}
                                {/*<div className="img_area">*/}
                                {/*    {imageAreas.map((item, key) => (*/}
                                {/*        <div className="item" key={key}>*/}
                                {/*            <img src={item} alt=""/>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}

                                {/* 해시태그 */}
                                <div className="hashtag_area">
                                    {campaign.keywords.map((tag, key) => (
                                        <a className="button round-deep blue" key={key}>#{tag}</a>
                                    ))}
                                </div>

                                {/* 유사 서비스 + 유사 광고 */}
                                <Variation bid={params.bid}/>

                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="comment">
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

                {/* 인증(314개)*/}
                <div className="detail_contents">
                    {/* 결과 별점 */}
                    {/*<div className="total_warp">*/}
                    {/*    <div className="total_count">{campaign.rating}</div>*/}
                    {/*    <div className="total_star">*/}
                    {/*        <DivCommentStar num={1} star={campaign.rating}/>*/}
                    {/*        <DivCommentStar num={2} star={campaign.rating}/>*/}
                    {/*        <DivCommentStar num={3} star={campaign.rating}/>*/}
                    {/*        <DivCommentStar num={4} star={campaign.rating}/>*/}
                    {/*        <DivCommentStar num={5} star={campaign.rating}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="total_link">{campaign.ratingCount.toLocaleString()}개</div>*/}
                    {/*</div>*/}

                    <Comment bid={params.bid} setCommentCount={setCommentCount} adUserId={profile.userId}/>

                </div>
            </div>

            {/* 채팅하기 / 결제하기 */}
            <div className="foot-btn">
                <div className="detail-foot_one_button">
                {session ? 
                    session.id == profile.userId ?
                    <>
                    <div><a onClick={() => deleteItem()} className="button full black">삭제</a></div>
                    {/*<div><a className="button full black">수정</a></div>*/}
                    {campaign.isComplete ? null : 
                        <div><a onClick={() => endItem()} className="button full black">광고마감</a></div>}
                    </>
                    : 
                    profile.memberStatus ?
                    <div>{btnType === ButtonType.Complete ?
                        <a className="button full gray">{btnName}</a>
                        : <a onClick={() => joinReward()} className="button full black">{btnName}</a>
                    }</div>
                    : <div><a className="button full gray">탈퇴한 회원의 광고입니다.</a></div>
                : null
                }
                </div>
            </div>

            <RewardPopup show={popupShow} setShow={setPopupShow} point={rewardPoint}/>
        </div>
    );
}

export default AdDetail;