import {useNavigate} from "react-router-dom";
import React from "react";
import {DivEstimateOrderType, DivEstimatePaymentType} from "../types/estimate";

function MobileInfluEstimate(props: { estimate: any, userType: string }) {
    const navigate = useNavigate();

    const detailRequirement = () => {
        navigate(`/requirements/${props.userType}-require/${props.estimate.bid}`);
    };

    return (
        <div className="thumbnail height" onClick={() => detailRequirement()}>
            <div className="text_area">
                {props.estimate.paymentType ?
                <div className="cate">
                    <DivEstimatePaymentType type={props.estimate.paymentType}/>
                </div>
                : null}
                <div style={{marginTop: "3.5rem", fontWeight: "500", fontSize: "5.8rem"}}>{props.estimate.title}</div>
                <div className="d-flex align-items-center" style={{marginTop: "3rem", fontSize: "5rem"}}>
                    <span style={{marginRight: "3.4rem"}}>참여 견적수</span>
                    <span>{props.estimate.joinCount}건</span>
                </div>
                <div className="d-flex align-items-center justify-content-between" style={{marginTop: "4rem"}}>
                    <span className="hash_group">
                        {props.estimate.categories.map((tag: string, key: number) => (
                            <React.Fragment key={key}>
                                <a className="button round gray small">{tag}</a>
                                {'\n'}
                            </React.Fragment>
                        ))}
                    </span>
                </div>
            </div>
        </div>
    );
}

export default MobileInfluEstimate;