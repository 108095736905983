import React from "react";

import icon_close from "img/m/icon-close.svg";
import img_service from "img/m/img-service.png";
import icon_service_strong01 from "img/m/icon-service_strong01.svg";
import icon_service_strong02 from "img/m/icon-service_strong02.svg";
import icon_service_strong03 from "img/m/icon-service_strong03.svg";
import icon_service_strong04 from "img/m/icon-service_strong04.svg";
import icon_service_strong05 from "img/m/icon-service_strong05.svg";
import icon_service_strong06 from "img/m/icon-service_strong06.svg";

function Service() {
    return (
        <div className="contents mobile service">
            <div className="close"><a href="#"><img src={icon_close} alt="닫기"/></a></div>
            <section className="intro-box">
                <div className="cate">서비스 소개</div>
                <h2>Pick Pong 서비스는</h2>
                <div className="exp">캠페인(광고주), 인플루언서, 전문가가 삼각구도를<br/>
                    이루어 서로의 서비스를 한 곳에서 거래할 수 있도록 만든<br/>
                    All In One 광고 플랫폼입니다.
                </div>
                <div className="img">
                    <img src={img_service} alt=""/>
                </div>
            </section>
            <section className="intro-box gray-bg">
                <div className="cate">서비스 강점</div>
                <h2>Pick Pong을 써야하는 이유!</h2>
                <div className="exp">캠페인(광고주), 인플루언서, 전문가가 삼각구도를<br/>
                    이루어 서로의 서비스를 한 곳에서 거래할 수 있도록 만든
                </div>
                <div className="img">
                    <ul className="icon-list">
                        <li><img src={icon_service_strong01} alt=""/><span>맞춤 필터링</span></li>
                        <li><img src={icon_service_strong02} alt=""/><span>특정 타겟팅</span></li>
                        <li><img src={icon_service_strong03} alt=""/><span>높은 광고 효과</span></li>
                        <li><img src={icon_service_strong04} alt=""/><span>낮은 광고비용</span></li>
                        <li><img src={icon_service_strong05} alt=""/><span>리워드 수익창출</span></li>
                        <li><img src={icon_service_strong06} alt=""/><span>선한 광고</span></li>
                    </ul>
                </div>
            </section>
        </div>
    );
}

export default Service;