import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";

import MobileInfluThumbnail from "components/m-influ-thumbnail";
import {PlatformType} from "types/platform";
import {Influ} from "types/influ";

import header_search from "img/m/header-search.svg";
import header_search_black from "img/m/header-search_black.svg";
import icon_close from "img/m/icon-close.svg";
import icon_gray_delete from "img/m/icon-gray-delete.png";

function AdSearchResult() {
    const handleOnClose = () => {
    };

    return (<AdSearchResultImpl onClose={() => handleOnClose()}/>);
}

function AdSearchResultImpl(props: { onClose: () => void }) {
    const [searchKeyword, setSearchKeyword] = useState<string>("");
    const [searchHistories, setSearchHistories] = useState<string[]>([
        "런닝화1",
        "런닝화2",
        "런닝화3",
    ]);

    const popularKeywords = [
        "인플루언서",
        "브런치카페",
        "에어팟",
        "기초화장품",
        "인플루언서",
        "브런치카페",
        "에어팟",
        "기초화장품",
    ];

    const suggestedKeywords = [
        "인플루언서",
        "브런치카페",
        "에어팟",
        "기초화장품",
        "인플루언서",
        "브런치카페",
        "에어팟",
        "기초화장품",
    ];

    const recentlyViewedInflu: Influ[] = [
        {
            bid: 1, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
        {
            bid: 2, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
        {
            bid: 3, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
        {
            bid: 4, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
        {
            bid: 5, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
        {
            bid: 6, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
            platforms: [{type: PlatformType.Instagram, subscribeCount: 210000, costPerClick: 10, costPerFeed: 10},
                {type: PlatformType.Facebook, subscribeCount: 213000, costPerClick: 10, costPerFeed: 10}]
        },
    ]

    const searchHistoryRemove = (keyword: string) => {
        setSearchHistories(searchHistories.filter(item => item !== keyword));
    }

    const searchHistoryRemoveAll = () => {
        setSearchHistories([]);
    }

    return (
        <div className="contents mobile search">
            <header className="header-sub shadow-none border-none">
                <div className="header_right">
                    <a className="search"></a>
                    {/*검색 클릭시 검색 박스 노출*/}
                    <div className="search-box on">
                        <Form.Control type="text" className="header-search" value={searchKeyword}
                                      onChange={e => setSearchKeyword(e.target.value)}
                                      bsPrefix={"#"}
                        ></Form.Control>
                        <input type="button" title="검색"/>
                        <a className="close" onClick={() => props.onClose()}><img src={icon_close} alt="닫기"/></a>
                    </div>
                </div>
            </header>
            <div className="section">
                <div className="title-sub">
                    최근검색어
                    <a className="all-del" onClick={() => searchHistoryRemoveAll()}>전체 삭제</a>
                </div>
                <ul className="recently">
                    {searchHistories.map((keyword, key) => (
                        <li key={key}>
                            {keyword}
                            <a className="del" onClick={() => searchHistoryRemove(keyword)}><img src={icon_gray_delete}
                                                                                                 alt="삭제"/></a>
                        </li>
                    ))}
                </ul>

                <div className="title-sub">인기검색어</div>
                <div className="pop-word green">
                    <ul>
                        {popularKeywords.map((keyword, key) => (
                            <li key={key}>{keyword}</li>
                        ))}
                    </ul>
                </div>

                <div className="title-sub">추천 검색어</div>
                <div className="pop-word green">
                    <ul>
                        {suggestedKeywords.map((keyword, key) => (
                            <li key={key}>{keyword}</li>
                        ))}
                    </ul>
                </div>

                <div className="title-sub">최근 본 인플루언서</div>
                <div className="thumbnail-list">
                    {recentlyViewedInflu.map((influ, key) => (
                        <MobileInfluThumbnail key={key} influ={influ}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export function AdSearchResultModal(props: { iconBlack?: boolean }) {
    const [show, setShow] = useState(false);

    return (
        <>
            <a className="search" onClick={() => setShow(true)}>
                <img src={props.iconBlack === false ? header_search : header_search_black} alt="검색"/>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AdSearchResultImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdSearchResult;