import React, {useEffect, useState} from "react";
import moment from "moment";

import MobileNavigationFooter from "components/m-navigation-footer";
import {TradeItem} from "types/trade";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";
import icon_list_left from "img/m/icon-list-left.png";
import axios from "axios";
import DetailProPopup from "../popup/detail-list-pro";
import MobileTradeProServiceItem from "../../components/m-trade-pro-service-item";

type setBoolState = React.Dispatch<React.SetStateAction<boolean>>;
type setStrState = React.Dispatch<React.SetStateAction<string>>;

function DivTradeListDay(props: {
    date: string, items: TradeItem[], popupShow: boolean, setPopupShow: setBoolState, setTradeId: setStrState,
    service: string, setService: setStrState
}) {
    const detailTrade = (id: string) => {
        props.setPopupShow(true);
        props.setTradeId(id);
        props.setService(props.service);
    };

    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.date}</span>
            </div>
            <div className="list_body">
            {props.items.map((item, key) => (
                <MobileTradeProServiceItem key={key} item={item} onClick={() => detailTrade(item.receiptId)}/>
            ))}
            </div>
        </div>
    );
}

function MyProTrade() {
    const [tab, setTab] = useState<string>("ad");
    const [campaign, setCampaign] = useState<any>();
    const [influencer, setInfluencer] = useState<any>();
    const [popupShow, setPopupShow] = useState<boolean>(false);
    const [receiptId, setReceiptId] = useState<string>('');
    const [service, setService] = useState<string>('');

    useEffect(() => {
        axios.get(`api/MyProService/receipts/campaign`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setCampaign({ ...data, items: list });
            });
        axios.get(`api/MyProService/receipts/influencer`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setInfluencer({ ...data, items: list });
            });
    }, []);

    if (campaign == null) {
        return (<></>);
    }

    if (influencer == null) {
        return (<></>);
    }

    const camDts = campaign.items.map((item: any) => item.createDt);
    const dateListCampaign = camDts.filter((dt: any, i: number) => camDts.indexOf(dt) === i);

    const inDts = influencer.items.map((item: any) => item.createDt);
    const dateListInflu = inDts.filter((dt: any, i: number) => inDts.indexOf(dt) === i);
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">거래내역</span>
                    </div>
                    <div className="header_right">
                        {/*<a className="del"><img src={icon_del} alt="삭제"/></a>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab purple">
                        <li id="tab-01" className={tab === "ad" ? "on" : ""}>
                            <a onClick={() => setTab("ad")}>캠페인</a>
                        </li>
                        <li id="tab-02" className={tab === "influ" ? "on" : ""}>
                            <a onClick={() => setTab("influ")}>인플루언서</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">
                    {/*  견적 요청중 탭 */}
                    <div className={`requirements-list-body${tab === "ad" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {campaign.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area">
                        {dateListCampaign.map((date: string, idx: number) => (
                            <DivTradeListDay key={idx} date={date} popupShow={popupShow} setPopupShow={setPopupShow}
                                             items={campaign.items.filter((c: any) => c.createDt === date)}
                                             setTradeId={setReceiptId} service={"campaign"} setService={setService}/>
                        ))}
                        </div>
                    </div>

                    {/*  견적마감 */}
                    <div className={`requirements-list-body${tab === "influ" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {influencer.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area">
                        {dateListInflu.map((date: string, idx: number) => (
                            <DivTradeListDay key={idx} date={date} popupShow={popupShow} setPopupShow={setPopupShow}
                                             items={campaign.items.filter((i: any) => i.createDt === date)}
                                             setTradeId={setReceiptId} service={"influencer"} setService={setService}/>
                        ))}
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Pro} curMenu={"my"}/>
            </div>

            <DetailProPopup show={popupShow} setShow={setPopupShow} receiptId={receiptId} service={service}
                            myService="MyProService"/>
        </div>
    );
}

export default MyProTrade;