import React from "react";
import {ProServiceProfileCareerModel} from "./profile";

export type ProServiceItem = {
    bid: number,
    categoryId: number,
    subCategoryId: number,
    images: string[],
    title: string,
    rating: number,
    minPrice?: number,
    maxPrice?: number
}

export interface ProServiceItemDetail extends ProServiceItem {
    keywords: string[],
    detail: string,
    rating: number,
    reviewCount: number,
    priceOptions: ProServicePriceOption[],
    customOptions: ProServiceCustomOption[]
}

export const enum ProServicePriceOptionType {
    Standard,
    Advanced,
    Premium
}

export function GetProServicePriceOptionType(type: ProServicePriceOptionType) {
    if (type == ProServicePriceOptionType.Standard) return "STANDARD";
    if (type == ProServicePriceOptionType.Advanced) return "ADVANCED";
    if (type == ProServicePriceOptionType.Premium) return "PREMIUM";
    return "";
}

export interface ProServicePriceOption {
    type: ProServicePriceOptionType,
    price: number,
    workingDay: number,
    modifyCount: number,
    draftCount: number,
    customOptions: string[]
}

export interface ProServiceCustomOption {
    uniqueId: string,
    title: string,
    price: number
}

export interface ProServiceMember {
    IsBusiness: boolean,
    Name: string,
    Email: string,
    ProfileImg: string,
    IdentificationNumber: string,
    BusinessOwner: string,
    Address1: string,
    Address2: string,
    ContactNumber: string,
    BankNumber: string,
    BankCode: string,
    BankOwner: string,
    Comment: string,
    RepresentativeActivitiesField: string[],
    DetailedField: string[],
    YearOfService: number,
    BusinessHoursStart: string,
    BusinessHoursEnd: string,
    ExceptForWeekEnds: boolean,
    Skills: string[],
    DetailComment: string,
    Careers: ProServiceProfileCareerModel[]
}