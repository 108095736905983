import React from "react";
import {Link} from "react-router-dom";
import moment from "moment";

import {NoticeItem} from "types/notice";

import header_back from "img/m/header-back.svg";

function DivNoticeItem(props: { item: NoticeItem }) {
    const date = moment(props.item.date).format('YYYY.MM.DD');

    return (
        <li>
            <Link to={`/my/my-notice-detail/${props.item.index}`}>
                <p className="date">{date}</p>
                <p className="list-title">{props.item.title}</p>
            </Link>
        </li>
    );
}

function MyNoticeList() {
    const notices: NoticeItem[] = [
        {index: 1, title: "메이크업 인스타그램 피드광고", date: new Date(2020, 9, 8)},
        {index: 2, title: "메이크업 인스타그램 피드광고", date: new Date(2020, 9, 8)},
        {index: 3, title: "메이크업 인스타그램 피드광고", date: new Date(2020, 9, 8)},
        {index: 4, title: "메이크업 인스타그램 피드광고", date: new Date(2020, 9, 8)},
        {index: 5, title: "메이크업 인스타그램 피드광고", date: new Date(2020, 9, 8)},
    ];

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">공지사항</span>
                    </div>
                </header>
                <div className="section">
                    <ul className="board notice-list">
                        {notices.map((notice, key) => (
                            <DivNoticeItem item={notice} key={key}/>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MyNoticeList;