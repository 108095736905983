import React from "react";

import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";

import header_back from "img/m/header-back.svg";

function UserReview() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">리뷰쓰기</span>
                    </div>
                    <div className="header_right">

                    </div>
                </header>

                <div className="write pro">
                    <div className="review-product">
                        <MobileProServiceThumbnail campaign={{
                            bid: 1,
                            categoryId: 1, subCategoryId: 1, images: [],
                            rating: 4.2,
                            title: "미술, 디자인, 사진전공자들이 모여 감각적인 로고를 만들어줍니다.",
                            minPrice: 120000
                        }}/>
                        {/*<MobileInfluThumbnail influ={{
                            category: "디자인 · 로고", rating: 4.3, name: "뷰티뷰크",
                            feeds: ["피드 10.0원", "후기작성 5.0원"],
                            Platforms: [{type: PlatformType.Instagram, value: 210000}, {
                                type: PlatformType.Facebook,
                                value: 213000
                            }]
                        }}/>*/}
                    </div>
                    <div className="section-write">
                        <div className="title">평점</div>
                        <div className="chocie_star">
                            <div className="grade">
                                <span className="on">★</span>
                                <span>★</span>
                                <span>★</span>
                                <span>★</span>
                                <span>★</span>
                            </div>
                            <div className="ment">선택해주세요.</div>
                        </div>
                    </div>
                    <div className="section-write">
                        <div className="title">제목<span className="required">(필수)</span></div>
                        <input type="text" className="write-input" placeholder="광고명을 입력해주세요."/>
                    </div>
                    <div className="section-write">
                        <div className="title">내용<span className="required">(필수)</span></div>
                        {/* editor 영역입니다. text area로 대체해둠.*/}
                        <div className="write-textarea">
                            { // @ts-ignore
                                <textarea name="" id="" cols="30" rows="10" placeholder="소개 내용을 입력해주세요."></textarea>
                            }
                        </div>
                    </div>
                    <div className="section-write_notice">
                        <div className="write-notice">
                            *입력한 정보가 서비스 정보 페이지로 설정됩니다. ※사진파일의 사이즈는 2M 이하를 업로드하여 주시기 바랍니다.
                        </div>
                    </div>


                    <div className="section-write type03">
                        {/* 리뷰 작성시 혜택 안내 */}
                        <div className="item">
                            <div className="accordion type-detail type-review">
                                { // @ts-ignore
                                    <div className="head" data-toggle="collapse" href="#collapseExample"
                                         aria-expanded="false"
                                         aria-controls="collapseExample">
                                        <span className="name">리뷰 작성시 혜택 안내</span><span className="arrow down"></span>
                                    </div>
                                }
                                <div className="body collapse show" id="collapseExample">
                                    <div className="desc">
                                        <div className="cont">
                                            <span className="line"><span className="dot"></span><span>일반, 포토리뷰 작성시 최초 1회에 한해 적립금 10원 즉시 지급</span></span>
                                            <span className="line"><span className="dot"></span><span>리뷰 작성 적립금은 적립일 기준 14일 이내 사용 가능</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* 리뷰 작성시 주의사항 */}
                        <div className="item">
                            <div className="accordion type-detail type-review">
                                { // @ts-ignore
                                    <div className="head" data-toggle="collapse" href="#collapseExample2"
                                         aria-expanded="false"
                                         aria-controls="collapseExample2">
                                        <span className="name">리뷰 작성시 주의사항</span><span className="arrow down"></span>
                                    </div>
                                }
                                <div className="body collapse show" id="collapseExample2">
                                    <div className="desc">
                                        <div className="cont">
                                            <span className="line"><span className="dot"></span><span>일반, 포토리뷰 작성시 최초 1회에 한해 적립금 10원 즉시 지급</span></span>
                                            <span className="line"><span className="dot"></span><span>리뷰 작성 적립금은 적립일 기준 14일 이내 사용 가능</span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="foot-btn">
                    <div className="detail-foot_button type_half">
                        <div><a className="button full gray">취소</a></div>
                        <div><a className="button full black">등록</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserReview;