import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import MobileHeaderLeft from "components/m-header-left";
import MobileNavigationFooter from "components/m-navigation-footer";
import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import {MainCategory} from "types/category";
import {ProServiceItem} from "types/pro-service";
import {PageType} from "types/page-type";
import {SearchResultModal} from "../search/search-result";

import header_search from "img/m/header-search.svg";
import arrow_enter from "img/m/arrow-enter.svg";
import main_banner from "img/m/main-banner.png";
import {getLocalSession} from "../../modules/localSession";
import axios from "axios";
import {UserType} from "../../types/user-type";
import {Estimate} from "../../types/estimate";
import MobileEstimateRequest from "../../components/m-estimate-request";

function CategoryMenu() {
    const [categories, setCategories] = useState<MainCategory[]>([]);

    useEffect(() => {
        axios.get("api/ProService/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
    }, [])

    return (
        <Swiper className="swiper-container main-menu"
                modules={[Pagination]}
                slidesPerGroup={5}
                slidesPerView={5}
                pagination={{clickable: true}}>
            {categories.map((category, key) => (
                <SwiperSlide key={key}>
                    <div className="value_list">
                        <Link to={`/home/pro-list/${category.categoryId}`}>
                            <div className="item">
                                <div className="img_box"><img src={category.icon} alt=""/></div>
                                <span>{category.name}</span>
                            </div>
                        </Link>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

function UserRecommends() {
    const session = getLocalSession();

    if (!session) {
        return (<></>);
    }

    const [recommendPro, setRecommendPro] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/ProService/recommendProService")
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.proItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setRecommendPro(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setRecommendPro(newItems);
                }
            });
    }, [])

    return (
        <div className="list">
            <Link to={"/home/pro-list"} className="title">
                <span>{session.displayName}님을 위한 추천 전문가</span>
                <span className="arrow enter"><img src={arrow_enter} alt=""/></span>
            </Link>
            <div className="item_wrap">
                <div className="thumbnail wrap">
                    <div className="line">
                        {recommendPro.map((pro, key) => (
                            <MobileProServiceThumbnail key={key} height campaign={pro}/>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

function TodayProService() {
    const session = getLocalSession();
    const [todayProService, setTodayProService] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/ProService/todayProService")
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.proItems;

                            data.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setTodayProService(newItems);
                        });
                } else {
                    newItems = data.map((c: any) => ({...c, isZzim: false}));
                    setTodayProService(newItems);
                }
            });
    }, [])
    
    return (
        <div className="page-main">
            <div className="list">
                <Link to={"/home/pro-list"} className="title">
                    <span>Today 전문가</span><span className="arrow enter">
                        <img src={arrow_enter} alt=""/></span>
                </Link>
                <div className="item_wrap">
                    {todayProService.map((pro, key) => (
                        <MobileProServiceThumbnail key={key} campaign={pro}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

function Estimates() {
    const [estimates, setEstimates] = useState<Estimate[]>([]);

    useEffect(() => {
        axios.get("api/ProService/estimates/top")
            .then(response => {
                setEstimates(response.data);
            })
    }, []);

    return (
        <div className="contact">
            <div className="list">
                <Link to={"/requirements-list/common-requirements-list/pro"} className="title">
                    <span>견적서 의뢰</span>
                    <span className="arrow enter"><img src={arrow_enter} alt=""/></span>
                </Link>
                <div className="item_wrap">
                    {estimates.map((estimate, key) => (
                        <MobileEstimateRequest key={key} estimate={estimate}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

function ProMain() {
    const session = getLocalSession();
    const [mainSlides, setMainSlides] = useState<any[]>([]);

    useEffect(() => {
        axios.get("api/ProService/banner")
            .then(response => {
                setMainSlides(response.data);
            });
    }, []);

    return (
        <div>
            {/* 광고주 헤더 */}
            <header>
                <MobileHeaderLeft/>
                <div className="header_right">
                    {/*<SearchResultModal iconBlack={false}/>*/}
                    <Link to={"/filtering/pro-filtering-form"} className="filter">
                        <img src={header_search} alt="필터"/>
                    </Link>
                </div>
            </header>
            {/* slide */}
            <Swiper className="swiper-container main-slide"
                    modules={[Pagination]}
                    pagination={{clickable: true}}>
            {mainSlides.map((img, key) => (
                <SwiperSlide key={key}>
                    <a href={img.link} target="_blank"><img src={img.image} alt=""/></a>
                </SwiperSlide>
            ))}
            </Swiper>
            {/* slide 끝 */}

            <div className="page-main">

                {/* 메인 메뉴 */}
                <CategoryMenu/>

                {/* 홍길동님을 위한 추천 ${type} */}
                <UserRecommends/>
            </div>
            {/* //main */}
            
            {/* 광고배너 */}
            <Link to={"/kakaofriend"} className="ad_banner">
                <img src={main_banner} alt=""/>
            </Link>
            
            <TodayProService/>

            <div className="page-main">
                {/* 견적서 의뢰 */}
                {session && session.userType === UserType.Pro ?
                    <Estimates/>
                    : null}
            </div>
            
            <MobileNavigationFooter hasHeight pageType={PageType.Pro} curMenu={""}/>
        </div>
    );
}

export default ProMain;