import React, {useEffect, useState} from "react";
import dummy_detail01 from "img/m/dummy-detail01.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import {PlatformRecord, PlatformType} from "types/platform";
import {useSelector} from "react-redux";
import {RootState} from "../modules";
import icon_twitter from "../img/m/icon-twitter.svg";
import icon_naver from "../img/m/icon-naver.svg";
import icon_naverblog from "../img/m/icon-naverblog.png";
import icon_kakaofriend from "../img/m/icon-kakao.png.png";
import icon_default from "../img/m/file-basic.svg";
import {getLocalSession} from "../modules/localSession";
import axios from "axios";
import {UserType} from "../types/user-type";

function DivPlatform(props: { platformRecord: PlatformRecord }) {
    if (props.platformRecord.type == PlatformType.Instagram) {
        return (<img src={icon_instagram} alt=""/>);
    } else if (props.platformRecord.type == PlatformType.Facebook) {
        return (<img src={icon_facebook} alt=""/>);
    } else if (props.platformRecord.type == PlatformType.Twitter) {
        return (<img src={icon_twitter} alt=""/>);
    } else if (props.platformRecord.type == PlatformType.NaverCafe) {
        return (<img src={icon_naver} alt=""/>);
    } else if (props.platformRecord.type == PlatformType.NaverBlog) {
        return (<img src={icon_naverblog} alt=""/>);
    } else if (props.platformRecord.type == PlatformType.KakaoFriend) {
        return (<img src={icon_kakaofriend} alt=""/>);
    } else {
        return (<img src={icon_default} alt=""/>);
    }
}

function MobileInfluRecommendCampaign(props: { influ: any }) {
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);
    const influCategory = useSelector((state: RootState) => state.influCategory);

    useEffect(() => {
        if(session) {
            setZzim(props.influ.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.influ.bid, ZzimType: UserType.Influencer, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }

    return (
        <div className="thumbnail height">
            <div className="image_area">
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart green${zzim ? " on" : ""}`}></div>
                : null }
                <img src={props.influ.images ? props.influ.images[0] : dummy_detail01} style={{objectFit: 'cover'}} alt=""/>
            </div>
            <div className="text_area">
                <a className="cate">{influCategory.repo.getSubCategoryName(props.influ.categoryId, props.influ.subCategoryId)}</a>
                <a className="title">{props.influ.title}</a>
                <div className="price_area">
                    <a className="price">
                        <div className="price_icon">
                            {props.influ.platforms.map((platformRecord: PlatformRecord, key: number) => (
                                <DivPlatform key={key} platformRecord={platformRecord}/>
                            ))}
                        </div>
                    </a>
                    <a className="grade"><span className="star">★</span>{props.influ.rating}</a>
                </div>
            </div>
        </div>
    );
}

export default MobileInfluRecommendCampaign;