import {CampaignFlag} from "./campaign";
import {PlatformType} from "./platform";
import {UserType} from "./user-type";
import {AdvertisingType} from "./advertising-type";

export interface RegisterCampaignMemberRequest {
    IsBusiness: boolean,
    Name: string,
    ProfileImg: string,
    IdentificationNumber: string,
    BusinessOwner: string,
    Address1: string,
    Address2: string,
    ContactNumber: string,
    BankNumber: string,
    BankCode: string,
    BankOwner: string,
    RegionOfInterest: string[],
    AreaOfInterest: string[],
    Platforms: string[],
    Homepage: string,
    Comment: string,
    DetailComment: string,
}

export interface UpdateCampaignMemberRequest {
    isBusiness: boolean,
    name: string,
    profileImg: string,
    identificationNumber: string,
    businessOwner: string,
    address1: string,
    address2: string,
    contactNumber: string,
    bankNumber: string,
    bankCode: string,
    bankOwner: string,
    regionOfInterest: string[],
    areaOfInterest: string[],
    platforms: string[],
    homepage: string,
    comment: string,
    detailComment: string,
    email: string
}

export const initData: RegisterCampaignMemberRequest = {
    IsBusiness: false,
    Name: '',
    ProfileImg: '',
    IdentificationNumber: '',
    BusinessOwner: '',
    Address1: '',
    Address2: '',
    ContactNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RegionOfInterest: [],
    AreaOfInterest: [],
    Platforms: [],
    Homepage: '',
    Comment: '',
    DetailComment: '',
};

export interface CreateCampaignRequest {
    CategoryId: number,
    SubCategoryId: number,
    Title: string,
    CampaignFlag: CampaignFlag,
    Point: number,
    Detail: string,
    Keywords: string,
    Platforms: PlatformType[],
    StartDateTime: Date | null,
    EndDateTime: Date | null
}

export interface CreateCampaignEstimateRequest {
    RequestTargetUser: UserType,
    Title: string,
    Categories: string[],
    PlatformType: PlatformType,
    AdvertisingType: AdvertisingType,
    WorkingDay: number,
    MinBudget: number,
    MaxBudget: number,
    StartAt: Date,
    EndAt: Date,
}

export interface ModifyCampaignEstimateRequest {
    BoardId: number,
    RequestTargetUser: UserType,
    Title: string,
    Categories: string[],
    PlatformType: PlatformType,
    AdvertisingType: AdvertisingType,
    WorkingDay: number,
    MinBudget: number,
    MaxBudget: number,
    StartAt: Date,
    EndAt: Date,
}

export interface CreateCampaignJoinEstimateRequest {
    BoardId: number,
    Price: number,
    Comment: string,
}