import React, {useEffect, useRef, useState} from "react";
import {Accordion, Form, Modal} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

import locale from 'antd/es/date-picker/locale/ko_KR';
import moment from "moment";
import {DatePicker} from "antd";
import ReactQuill from 'react-quill';

import {CampaignFlag, CampaignFlagParse} from "types/campaign";

import header_close from "img/m/header-close.svg";
import write_right from "img/m/write-right.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import icon_twitter from "img/m/icon-twitter.png";
import icon_naverblog from "img/m/icon-naverblog.png";
import icon_naver from "img/m/icon-naver.svg";
import icon_kakao from "img/m/icon-kakao.svg";
import icon_main_write from "img/m/icon-main-wirte.png";
import axios from "axios";
import {MainCategory, SubCategory} from "../../types/category";
import {CreateCampaignRequest} from "../../types/advertiser-request";
import {PlatformType} from "../../types/platform";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";

function AdWrite() {
    const handleOnClose = () => {
    };

    return (<AdWriteImpl onClose={() => handleOnClose()}/>);
}

function DivAccordionCard(props: { header: JSX.Element, body: JSX.Element }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>();

    return (
        <Accordion className="type-detail type-write"
                   onSelect={setActiveKey}>
            <Accordion.Item eventKey="0" bsPrefix="#">
                <Accordion.Button as={"div"} className="head" bsPrefix="#">
                    {props.header}
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    {props.body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

type checkedSetState = React.Dispatch<React.SetStateAction<boolean>>;

function DivCheckBox(props: { id: string, icon: string, name: string, checked: boolean, onChange: checkedSetState }) {
    return (
        <Form.Check type="checkbox" className={`button ad_channel type_add ${props.checked ? "on" : ""}`} id={props.id}>
            <Form.Check.Input type="checkbox" className="d-none"
                              checked={props.checked} onChange={() => props.onChange(!props.checked)}
            />
            <Form.Check.Label>
                <img src={props.icon} alt=""/>
                <span>{props.name}</span>
            </Form.Check.Label>
        </Form.Check>
    );
}

type richEditorSetRef = React.Dispatch<React.SetStateAction<ReactQuill | null>>;

function DivRichEditor(props: { value: string, setValue: (e: ReactQuill.UnprivilegedEditor) => void, setRef: richEditorSetRef })
{
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}],
            [{'size': ['small', false, 'large', 'huge']}],
            ['image'],
            [{'list': 'bullet'}, {'list': 'ordered'}],
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'color',
        'size',
        'image',
        'bullet', 'list',
    ];

    return (
        <ReactQuill theme="snow" value={props.value} modules={modules} formats={formats} ref={ref => props.setRef(ref)}
                    onChange={(content, delta, source, editor) => props.setValue(editor)}/>
    );
}

function AdWriteImpl(props: { onClose: () => void }) {
    const [instagram, setInstagram] = useState(false);
    const [facebook, setFacebook] = useState(false);
    const [twitter, setTwitter] = useState(false);
    const [naverBlog, setNaverBlog] = useState(false);
    const [naverCafe, setNaverCafe] = useState(false);
    const [kakaoFriends, setKakaoFriends] = useState(false);
    const [title, setTitle] = useState<string>("");
    const [keywords, setKeywords] = useState<string>("");
    const [price, setPrice] = useState(0);
    const [tos, setTos] = useState(false);
    const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
    const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
    const [rewardType, setRewardType] = useState<CampaignFlag | undefined>();
    const [contents, setContents] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [categories, setCategories] = useState<MainCategory[]>([]);
    const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
    const [selSubCate, setSelSubCate] = useState(false);
    const [subCateName, setSubCateName] = useState('상세 카테고리 선택');
    const [selCate, setSelCate] = useState(false);
    const [cateName, setCateName] = useState('카테고리 선택');
    const [contentRef, setContentRef] = useState<ReactQuill | null>(null);
    
    const [adData, setAdData] = useState<CreateCampaignRequest>({
        CategoryId: 0,
        SubCategoryId: 0,
        Title: '',
        CampaignFlag: -1,
        Point: 0,
        Detail: '',
        Keywords: '',
        Platforms: [],
        StartDateTime: new Date(),
        EndDateTime: new Date()
    });
    
    const AdRef = {
        Title: useRef<HTMLInputElement>(null),
        CampaignFlag: useRef<HTMLSelectElement>(null),
        Keywords: useRef<HTMLInputElement>(null),
        Point: useRef<HTMLInputElement>(null),
        StartDateTime: useRef<any>(null),
        EndDateTime: useRef<any>(null)
    };

    const adCategory = useSelector((state: RootState) => state.adCategory);

    const rewardTypeCheck = (v: any) => {
        setRewardType(CampaignFlagParse(v));
    }

    const priceCheck = (v: any) => {
        let num = v || 0;
        if (!isFinite(num))
            return;
        num = num.toString();

        if (num !== '0' && !num.includes('.')) {
            num = num.replace(/^0+/, '');
        }

        setPrice(num);
    }
    
    const selectCate = (cateId: number, cateName: string) => {
        setSelCate(false);
        setCateName(cateName);
        setSelSubCate(true);
        setSubCategories(adCategory.repo.getSubCategories(cateId));
        setAdData({ ...adData, CategoryId: cateId });
    };

    const selectSubCate = (cateId: number, cateName: string) => {
        setSubCateName(cateName);
        setSelSubCate(false);
        setAdData({ ...adData, SubCategoryId: cateId });
    };
    
    const changeContents = (editor: ReactQuill.UnprivilegedEditor) => {
        setContents(editor.getHTML());
        setText(editor.getText());
    }

    useEffect(() => {
        axios.get("api/Advertiser/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
    }, []);

    const dateFormat = 'YYYY-MM-DD';
    
    const registerAdvert = () => {
        if(1 > adData.CategoryId) {
            alert("카테고리를 선택하세요.");
            return;
        }

        if(1 > adData.SubCategoryId) {
            alert("상세 카테고리를 선택하세요.");
            return;
        }
        
        if(!instagram && !facebook && !twitter && !naverBlog && !naverCafe && !kakaoFriends) {
            alert("광고 채널을 1개 이상 선택해주세요.");
            return;
        }
        
        if('' == title) {
            alert("광고명을 입력하세요.");
            if(AdRef.Title.current !== null) AdRef.Title.current.focus();
            return;
        }

        if('' == keywords) {
            alert("광고 키워드를 입력하세요.");
            if(AdRef.Keywords.current !== null) AdRef.Keywords.current.focus();
            return;
        }

        if(!startDate) {
            alert("광고 기간을 입력하세요.");
            if(AdRef.StartDateTime.current !== null) AdRef.StartDateTime.current.focus();
            return;
        }

        if(!endDate) {
            alert("광고 기간을 입력하세요.");
            if(AdRef.EndDateTime.current !== null) AdRef.EndDateTime.current.focus();
            return;
        }

        if(rewardType === undefined || 0 > rewardType) {
            alert("리워드 방식을 선택하세요.");
            if(AdRef.CampaignFlag.current !== null) AdRef.CampaignFlag.current.focus();
            return;
        }

        if('' == price.toLocaleString()) {
            alert("리워드 금액을 입력하세요.");
            if(AdRef.Point.current !== null) AdRef.Point.current.focus();
            return;
        }

        if(10 > price) {
            alert("리워드 금액을 10원 이상 입력하세요.");
            if(AdRef.Point.current !== null) AdRef.Point.current.focus();
            return;
        }
        
        if('' == text) {
            alert("내용을 입력하세요.");
            if(contentRef !== null) contentRef.focus();
            return;
        }

        if (!tos) {
            alert("약관에 동의해야 합니다.");
            return;
        }

        adData.Platforms = [];
        if(instagram) adData.Platforms.push(PlatformType.Instagram);
        if(facebook) adData.Platforms.push(PlatformType.Facebook);
        if(twitter) adData.Platforms.push(PlatformType.Twitter);
        if(naverCafe) adData.Platforms.push(PlatformType.NaverCafe);
        if(naverBlog) adData.Platforms.push(PlatformType.NaverBlog);
        if(kakaoFriends) adData.Platforms.push(PlatformType.KakaoFriend);
        
        adData.Title = title;
        adData.Keywords = keywords;
        adData.StartDateTime = new Date(startDate.format('YYYY-MM-DD'));
        adData.EndDateTime = new Date(endDate.format('YYYY-MM-DD'));
        adData.CampaignFlag = rewardType;
        adData.Point = price;
        adData.Detail = contents;
        
        console.log(adData);

        axios.post('/api/Advertiser/campaign', adData).then(response => {
            alert("광고 등록이 완료되었습니다.");
            location.href = '/my/my-ad-main';
        }).catch(error => {
            console.log(error);
            alert("광고 등록에 실패했습니다. 관리자에 문의해주세요.");

            return;
        });
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">광고등록</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => props.onClose()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="write pro">
                    <div className="section-write">
                        <div className="title">광고 분야</div>
                        <a className="write-select_button" onClick={() => setSelCate(!selCate)}>
                            <span>{cateName}</span>
                            <span className="arrow"><img src={write_right} alt=""/></span>
                        </a>
                    {selCate ?
                        <div className="border" style={{borderRadius: '2rem'}}>
                            <ul style={{fontSize: '5.4rem'}}>
                            {categories.map((cate, idx) => (
                                <li key={idx} onClick={() => selectCate(cate.categoryId, cate.name)}
                                    className="p-3">{cate.name}</li>
                            ))}
                            </ul>
                        </div>
                        : null}
                        <a className="write-select_button" onClick={() => setSelSubCate(!selSubCate)}>
                            <span>{subCateName}</span>
                            <span className="arrow"><img src={write_right} alt=""/></span>
                        </a>
                    {selSubCate ?
                        <div className="border" style={{borderRadius: '2rem'}}>
                            <ul style={{fontSize: '5.4rem'}}>
                            {subCategories.filter(sub => sub.subCategoryId != 0).map((cate, idx) => (
                                <li key={idx} onClick={() => selectSubCate(cate.subCategoryId, cate.name)}
                                    className="p-3">{cate.name}</li>
                            ))}
                            </ul>
                        </div>
                        : null}
                    </div>
                    <div className="section-write">
                        <div className="title">광고채널</div>
                        <div className="write-channel_group">
                            <div className="grid grid-3">
                                <div>
                                    <DivCheckBox id="check-instagram" name="인스타그램" icon={icon_instagram}
                                                 checked={instagram} onChange={setInstagram}/>
                                </div>
                                <div>
                                    <DivCheckBox id="check-facebook" name="페이스북" icon={icon_facebook}
                                                 checked={facebook} onChange={setFacebook}/>
                                </div>
                                <div>
                                    <DivCheckBox id="check-twitter" name="트위터" icon={icon_twitter}
                                                 checked={twitter} onChange={setTwitter}/>
                                </div>
                                <div>
                                    <DivCheckBox id="check-naverBlog" name="네이버 블로그" icon={icon_naverblog}
                                                 checked={naverBlog} onChange={setNaverBlog}/>
                                </div>
                                <div>
                                    <DivCheckBox id="check-naverCafe" name="네이버 카페" icon={icon_naver}
                                                 checked={naverCafe} onChange={setNaverCafe}/>
                                </div>
                                <div>
                                    <DivCheckBox id="check-kakaoFriends" name="카카오 친구" icon={icon_kakao}
                                                 checked={kakaoFriends} onChange={setKakaoFriends}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-write">
                        <div className="title">광고명</div>
                        <Form.Control type="text" className="write-input" placeholder="광고명을 입력해주세요."
                                      value={title} onChange={event => setTitle(event.target.value)} bsPrefix={"#"}
                                      ref={AdRef.Title}/>
                    </div>
                    <div className="section-write">
                        <div className="title">광고 키워드</div>
                        <Form.Control type="text" className="write-input" placeholder="쉼표(,)로 구분하여 입력해주세요."
                                      value={keywords} onChange={event => setKeywords(event.target.value)}
                                      bsPrefix={"#"} ref={AdRef.Keywords}/>
                    </div>
                    <div className="section-write">
                        <div className="title">광고 기간</div>
                        <div className="write-date">
                            <DatePicker className="datepicker" allowClear={false} locale={locale}
                                        value={startDate} onChange={(date, _) => setStartDate(date)}
                                        format={dateFormat} ref={AdRef.StartDateTime}/>
                            <span>~</span>
                            <DatePicker className="datepicker" allowClear={false} locale={locale}
                                        value={endDate} onChange={(date, _) => setEndDate(date)}
                                        defaultValue={moment()} format={dateFormat} ref={AdRef.EndDateTime}/>
                        </div>
                    </div>
                    <div className="section-write">
                        <div className="title">광고 리워드 방식 & 금액</div>
                        <div className="write-selectandprice">
                            <select ref={AdRef.CampaignFlag} className="col-5"
                                          value={rewardType} onChange={event => rewardTypeCheck(event.target.value)}>
                                <option>리워드 방식 선택</option>
                                <option value={CampaignFlag.Click}>클릭</option>
                                <option value={CampaignFlag.Feed}>피드</option>
                            </select>
                            <div className="write-won">
                                <input type="number" placeholder="금액" ref={AdRef.Point} className="col-10"
                                              value={price} onChange={event => priceCheck(event.target.value)}/>
                                <span>원</span>
                            </div>
                        </div>
                    </div>
                    <div className="section-write">
                        <div className="title">내용</div>
                        <div className="write-textarea">
                            <DivRichEditor value={contents} setValue={(e) => changeContents(e)}
                                           setRef={setContentRef}/>
                        </div>
                    </div>
                    <div className="section-write_notice">
                        <div className="write-notice">
                            *입력한 정보가 서비스 정보 페이지로 설정됩니다. ※사진파일의 사이즈는 2M 이하를 업로드하여 주시기 바랍니다.
                        </div>
                    </div>


                    <div className="section-write type03">
                        {/* 유의사항 */}
                        <div className="item">
                            <DivAccordionCard
                                header={<span className="name">유의사항</span>}
                                body={
                                    <div className="desc">
                                        <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                        <div className="cont">
                                            제 1조 [목적]<br/>
                                            본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자") 사이에 체결된
                                            입점 계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        {/* 필수공지 */}
                        <div className="item">
                            <DivAccordionCard
                                header={<span className="name">필수공지</span>}
                                body={
                                    <div className="desc">
                                        <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                        <div className="cont">
                                            제 1조 [목적]<br/>
                                            본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자") 사이에 체결된
                                            입점 계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        {/* 약관동의 */}
                        <div className="item">
                            <DivAccordionCard
                                header={<span className="name">약관동의</span>}
                                body={
                                    <div className="desc">
                                        <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                        <div className="cont">
                                            제 1조 [목적]<br/>
                                            본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된
                                            입점
                                            계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                        </div>
                                    </div>
                                }
                            />
                            <Form.Check type="checkbox" className="agree_check checkbox" id="check-tos">
                                <Form.Check.Input type="checkbox" className="d-none"
                                                  checked={tos} onChange={() => setTos(!tos)}
                                />
                                <Form.Check.Label>
                                    <>
                                        <span className={`circle ${tos ? "checked" : ""}`}></span>
                                        <span className="name agree_text type03">
                                            본인은 위 약관의 내용을 모두 확인하였으며, 약관에 따라 성실히 활동할 것에 동의합니다.
                                        </span>
                                    </>
                                </Form.Check.Label>
                            </Form.Check>
                        </div>
                    </div>
                </div>

                <div className="bottom chap">
                    <a className="button full black" onClick={() => registerAdvert()}>등록</a>
                </div>
            </div>
        </div>
    );
}

export function AdWriteModal() {
    const [show, setShow] = useState(false);

    return (
        <>
            <a className="ad_button" onClick={() => setShow(true)}>
                <img src={icon_main_write} alt=""/>
                <span>광고등록</span>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AdWriteImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdWrite;