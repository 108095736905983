import React, {useEffect, useState} from "react";
import moment from "moment";

import MobileNavigationFooter from "components/m-navigation-footer";
import {TradeItem} from "types/trade";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";
import icon_list_left from "img/m/icon-list-left.png";
import axios from "axios";
import DetailProPopup from "../popup/detail-list-pro";
import DetailInfluPopup from "../popup/detail-list-influ";
import MobileTradeCampaignItem from "../../components/m-trade-campaign-item";

type setBoolState = React.Dispatch<React.SetStateAction<boolean>>;
type setStrState = React.Dispatch<React.SetStateAction<string>>;

function DivTradeListDay(props: {
    date: string, items: TradeItem[], popupShow: boolean, setPopupShow: setBoolState, setTradeId: setStrState,
    service: string, setService: setStrState
}) {
    const detailTrade = (id: string) => {
        props.setPopupShow(true);
        props.setTradeId(id);
        props.setService(props.service);
    };

    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.date}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <MobileTradeCampaignItem key={key} item={item} onClick={() => detailTrade(item.receiptId)}/>
                ))}
            </div>
        </div>
    );
}

function MyAdTrade() {
    const [tab, setTab] = useState<string>("influ");
    const [proService, setProService] = useState<any>();
    const [influencer, setInfluencer] = useState<any>();
    const [influPopupShow, setInfluPopupShow] = useState<boolean>(false);
    const [proPopupShow, setProPopupShow] = useState<boolean>(false);
    const [receiptId, setReceiptId] = useState<string>('');
    const [service, setService] = useState<string>('');

    useEffect(() => {
        axios.get(`api/MyAdvertiser/receipts/influencer`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setInfluencer({ ...data, items: list });
            });
        axios.get(`api/MyAdvertiser/receipts/pro`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setProService({ ...data, items: list });
            });
    }, []);

    if (influencer == null) return (<></>);
    if (proService == null) return (<></>);

    const inDts = influencer.items.map((item: any) => item.createDt);
    const dateListInflu = inDts.filter((dt: any, i: number) => inDts.indexOf(dt) === i);

    const proDts = proService.items.map((item: any) => item.createDt);
    const dateListProService = proDts.filter((dt: any, i: number) => proDts.indexOf(dt) === i);

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">거래내역</span>
                    </div>
                    <div className="header_right">
                        {/*<a className="del"><img src={icon_del} alt="삭제"/></a>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab blue">
                        <li id="tab-01" className={tab === "influ" ? "on" : ""}>
                            <a onClick={() => setTab("influ")}>인플루언서</a>
                        </li>
                        <li id="tab-02" className={tab === "pro" ? "on" : ""}>
                            <a onClick={() => setTab("pro")}>전문가</a>
                        </li>
                    </ul>
                </div>
                <div className="requirements-list">
                    {/*  견적 요청중 탭 */}
                    <div className={`requirements-list-body${tab === "influ" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {influencer.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area">
                            {dateListInflu.map((date: string, idx: number) => (
                                <DivTradeListDay key={idx} date={date} popupShow={influPopupShow} setPopupShow={setInfluPopupShow}
                                                 items={influencer.items.filter((c: any) => c.createDt === date)}
                                                 setTradeId={setReceiptId} service={"influencer"} setService={setService}/>
                            ))}
                        </div>
                    </div>

                    {/*  견적마감 */}
                    <div className={`requirements-list-body${tab === "pro" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {proService.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area">
                            {dateListProService.map((date: string, idx: number) => (
                                <DivTradeListDay key={idx} date={date} popupShow={proPopupShow} setPopupShow={setProPopupShow}
                                                 items={proService.items.filter((p: any) => p.createDt === date)}
                                                 setTradeId={setReceiptId} service={"pro"} setService={setService}/>
                            ))}
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Ad} curMenu={"my"}/>
            </div>

            <DetailInfluPopup show={influPopupShow} setShow={setInfluPopupShow} receiptId={receiptId} service={service}
                              myService="MyAdvertiser"/>
            <DetailProPopup show={proPopupShow} setShow={setProPopupShow} receiptId={receiptId} service={service}
                            myService="MyAdvertiser"/>
        </div>
    );
}

export default MyAdTrade;