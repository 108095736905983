import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {store} from "./app/store";
import {Provider} from "react-redux";
import "./types/number-extensions";
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import {getLocalSession} from "./modules/localSession";

axios.defaults.baseURL = process.env.REACT_APP_SERVICE_TYPE;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    async (config) => {
        const session = getLocalSession();
        if (!session) {
            return config;
        }

        if (session.token) {
            config.headers = {
                ...config.headers,
                authorization: `Bearer ${session.token}`,
            };
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// TODO : Refresh Token은 차후 구현
// axios.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const config = error?.config;
//
//         if (error?.response?.status === 401 && !config?.sent) {
//             config.sent = true;
//
//             const result = await memoizedRefreshToken();
//
//             if (result?.accessToken) {
//                 config.headers = {
//                     ...config.headers,
//                     authorization: `Bearer ${result.accessToken}`,
//                 };
//             }
//
//             return axios(config);
//         }
//         return Promise.reject(error);
//     }
// );

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <App/>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
