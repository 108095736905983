import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";

import MobileAdThumbnailSmall from "components/m-ad-thumbnail-small";
import {DivRank, RankChangeEnum, RankProp} from "types/rank";

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";

function DivRankCheckBox(props: { rank: RankProp }) {
    return (
        <>
            <div className="rank">
                <input type="checkbox" name="check" title="선택"/>
            </div>
            <DivRank rank={props.rank}/>
        </>
    );
}

function AdFilteringResult() {
    return (
        <div className="contents mobile filtering result ad">
            <header className="header-sub shadow-none">
                <div className="header_left">
                    <span className="location">광고 필터링 결과</span>
                </div>
                <div className="header_right">
                    <a href="#" className="refresh"><img src={header_refresh} alt="새로고침"/></a>
                    <a href="#" className="close"><img src={icon_close} alt="닫기"/></a>
                </div>
            </header>
            <div className="section">
                <Swiper className="swiper-container"
                        slidesPerView={"auto"}
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title default">
                            상세지역별
                            <select name="city" id="city">
                                <option value="">인천</option>
                                <option value="">서울</option>
                                <option value="">경기</option>
                                <option value="">강원</option>
                            </select>
                        </div>
                        <div className="title-sub">
                            총 15,000명
                        </div>
                        <div className="cont-list">
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 1, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 2, change: RankChangeEnum.Up}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 3, change: RankChangeEnum.Down}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 4, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 5, change: RankChangeEnum.Equal}}/>}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title default">
                            성별
                            <select name="city" id="city">
                                <option value="">여자</option>
                                <option value="">남자</option>
                            </select>
                        </div>
                        <div className="title-sub">
                            총 8,500명
                        </div>
                        <div className="cont-list">
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 1, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 2, change: RankChangeEnum.Up}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 3, change: RankChangeEnum.Down}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 4, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 5, change: RankChangeEnum.Equal}}/>}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title default">
                            연령대별
                            <select name="city" id="city">
                                <option value="">10대</option>
                                <option value="">20대</option>
                                <option value="">30대</option>
                                <option value="">40대</option>
                                <option value="">50대</option>
                                <option value="">60대</option>
                            </select>
                        </div>
                        <div className="title-sub">
                            총 750명
                        </div>
                        <div className="cont-list">
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 1, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 2, change: RankChangeEnum.Up}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 3, change: RankChangeEnum.Down}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 4, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRank rank={{rank: 5, change: RankChangeEnum.Equal}}/>}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title">최적 분석결과 순위</div>
                        <div className="title-sub">
                        <span className="all-check">
                            <input type="checkbox" name="all-check" id="all-check"/>
                            <label htmlFor="all-check">전체 선택</label>
                        </span>
                            총 750명
                        </div>
                        <div className="cont-list">
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRankCheckBox rank={{rank: 1, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRankCheckBox rank={{rank: 2, change: RankChangeEnum.Up}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRankCheckBox rank={{rank: 3, change: RankChangeEnum.Down}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRankCheckBox rank={{rank: 4, change: RankChangeEnum.Equal}}/>}/>
                            <MobileAdThumbnailSmall
                                user={{name: "나료", rating: 4.8, adCount: 200, follow: 2491}}
                                header={<DivRankCheckBox rank={{rank: 5, change: RankChangeEnum.Equal}}/>}/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title">최적 분석결과</div>
                        <div className="title-sub">
                        <span className="all-check">
                            <input type="checkbox" name="all-check2" id="all-check2"/>
                            <label htmlFor="all-check2">전체 선택</label>
                        </span>
                        </div>
                        <div className="cont-list">
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category one">지역별</div>
                                <div className="text_area space-between">
                                    <div className="total">15,000명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category one">성별</div>
                                <div className="text_area space-between">
                                    <div className="total">8,000명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category one">연령대별</div>
                                <div className="text_area space-between">
                                    <div className="total">4,000명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category two">성별 + 연령대별</div>
                                <div className="text_area space-between">
                                    <div className="total">1,000명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category two">지역 + 성별</div>
                                <div className="text_area space-between">
                                    <div className="total">800명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category two">지역 + 연령대별</div>
                                <div className="text_area space-between">
                                    <div className="total">750명</div>
                                </div>
                            </div>
                            <div className="thumbnail width category">
                                <div className="rank">
                                    <input type="checkbox" name="check" title="선택"/>
                                </div>
                                <div className="category three">지역 + 성별 + 연령대별</div>
                                <div className="text_area space-between">
                                    <div className="total">500명</div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                {/*    $(function(){*/}
                {/*    $('.all-check').on('click', function () {*/}
                {/*        if ($('.all-check>input[type="checkbox"]').is(":checked")) {*/}
                {/*            $(this).parents('.title-sub').siblings('.cont-list').find('input[type="checkbox"]').prop('checked', true);*/}
                {/*        } else {*/}
                {/*            $(this).parents('.title-sub').siblings('.cont-list').find('input[type="checkbox"]').prop('checked', false);*/}
                {/*        }*/}
                {/*    });*/}
                {/*});*/}

                {/*</script>*/}
            </div>
            <div className="foot-btn">
                <div className="detail-foot_one_button">
                    <div><a href="#" className="button full black">선택 광고 보내기</a></div>
                </div>
            </div>
        </div>
    );
}

export default AdFilteringResult;