import React from "react";

declare global {
    interface Number {
        toPlatformCountString(this: Number): string;
    }
}

Number.prototype.toPlatformCountString = function toPlatformCountString(): string {
    if (this < 10000)
        return `${this}`;

    return `${this.valueOf() / 10000}만`;
}
