import React from "react";
import {PlatformRecord, PlatformType} from "./platform";
import {ProServicePriceOptionType} from "./pro-service";

export const enum PaymentType {
    None,
    CreditCard,
    CellPhone,
    Bank
}

export interface PaymentInfluencerOption {
    PlatformType: PlatformType,
    ClickCount: number,
    FeedCount: number
}

export interface PaymentInfluencerLedger {
    ReceiptId: string,
    Category: string,
    Title: string,
    BannerImg: string,
    Rating: number,
    Platforms: PlatformRecord[],
    Options: PaymentInfluencerOption[],
    BuyerName: string,
    PaymentType: PaymentType,
    CreatedAt: Date,
}

export interface PaymentProServiceCustomOption {
    Title: string,
    Price: number
}

export interface PaymentProServiceLedger {
    ReceiptId: string,
    Category: string,
    Title: string,
    BannerImg: string,
    Rating: number,
    PriceOptionType: ProServicePriceOptionType,
    ServicePrice: number,
    CustomOptions: PaymentProServiceCustomOption[],
    BuyerName: string,
    PaymentType: PaymentType,
    CreatedAt: Date,
}

export const PayMethod = [ "none", "card", "phone", "trans" ];

export const IMPCode = "imp73057336";

export const PG = "danal_tpay";
export const PG_PHONE = "danal";

//export const CPID = "9810030929"; // 테스트
export const CPID = "A010012791";
