import React, {useEffect, useState} from "react";
import Slider from "rc-slider";

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {PlatformType} from "../../types/platform";
import WishLocation from "../member/wish-location";
import {CampaignFlag} from "../../types/campaign";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";

function InfluFilteringForm() {
    const [price, setPrice] = useState([0, 10000000]);
    const [friend, setFriend] = useState([0, 10000000]);
    const [rating, setRating] = useState([0, 5]);
    const [platformSel, setPlatformSel] = useState<number[]>([]);
    const [wishLoc, setWishLoc] = useState(false);
    const [cateSel, setCateSel] = useState<number[]>([]);
    const [wishLocSel, setWishLocSel] = useState<string[]>([]);
    const [reward, setReward] = useState<CampaignFlag>(CampaignFlag.Click);
    const navigate = useNavigate();
    const influCategory = useSelector((state: RootState) => state.influCategory);
    const location = useLocation();

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];

    const categories = influCategory.repo.getMainCategories();

    useEffect(() => {
        if(location.state) {
            const state: any = location.state;

            setWishLocSel(state.Regions);
            setCateSel(state.Categories);
            setPlatformSel(state.PlatformTypes);
            setReward(state.RewardTypes);
            setPrice([state.MinPrice, state.MaxPrice]);
            setFriend([state.MinFriendCount, state.MaxFriendCount]);
            setRating([state.MinRate, state.MaxRate]);
        }
    }, []);

    const selectPlatform = (platformType: number) => {
        if (platformSel.includes(platformType)) {
            setPlatformSel(platformSel.filter(item => item !== platformType));
        } else {
            setPlatformSel([...platformSel, platformType]);
        }
    };

    const deleteArea = (loc: string) => {
        setWishLocSel(prevState => prevState.filter(item => item !== loc));
    };

    const selectCategory = (categoryId: number) => {
        if (cateSel.includes(categoryId)) {
            setCateSel(cateSel.filter(item => item !== categoryId));
        } else {
            setCateSel([...cateSel, categoryId]);
        }
    };

    const resultFiltering = () => {
        let filter = {
            Regions: wishLocSel,
            Categories: cateSel,
            PlatformTypes: platformSel,
            RewardTypes: reward,
            MinPrice: price[0],
            MaxPrice: price[1],
            MinFriendCount: friend[0],
            MaxFriendCount: friend[1],
            MinRate: rating[0],
            MaxRate: rating[1]
        };

        console.log(filter);

        navigate('/filtering/influ-filtering-result',{state: filter});
    };

    const reloadFiltering = () => {
        window.history.replaceState({}, '', '/filtering/influ-filtering-form');
        window.location.reload();
    };

    if(wishLoc) {
        return (<WishLocation setWishLoc={setWishLoc} setSelect={setWishLocSel}/>);
    } else {
        return (
            <div className="contents mobile filtering campaign">
                <header className="header-sub shadow-none border-none">
                    <div className="header_left">
                        <span className="location">인플루언서 필터링</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => reloadFiltering()} className="refresh">
                            <img src={header_refresh} alt="새로고침"/>
                        </a>
                        <a onClick={() => navigate('/home/influ-main')} className="close">
                            <img src={icon_close} alt="닫기"/>
                        </a>
                    </div>
                </header>
                <div className="section">
                    <div className="form-wrap">
                        {/*<div className="form">
                        <span className="form-title">성별</span>
                        <div className="grid grid-3">
                            <div className="ipt-button green">
                                <input type="radio" name="gender" id="all" title="전체" checked/>
                                <label htmlFor="all">전체</label>
                            </div>
                            <div className="ipt-button green">
                                <input type="radio" name="gender" id="male" title="남"/>
                                <label htmlFor="male">남</label>
                            </div>
                            <div className="ipt-button green">
                                <input type="radio" name="gender" id="female" title="여"/>
                                <label htmlFor="female">여</label>
                            </div>
                        </div>
                    </div>*/}
                        {/*<div className="form">
                            <span className="form-title">검색어</span>
                            <div className="request-input">
                                <input type="text"/>
                            </div>
                        </div>*/}
                        <div className="form">
                            <span className="form-title">지역
                                <a onClick={() => setWishLoc(true)} className="select">선택하기</a>
                            </span>
                            <div className="item_body">
                                {wishLocSel.length < 1 ?
                                    <span className="button close_type" style={{color: "#a0a0a0"}}>지역을 선택하세요.</span>
                                    :
                                    wishLocSel.map((loc, key) => (
                                        <a key={key} onClick={() => deleteArea(loc)} className="button close_type green">
                                        <span className="wrap">
                                            <span className="name">{loc}</span>
                                            <span className="exit"></span>
                                        </span>
                                        </a>
                                    ))}
                            </div>
                        </div>

                        <div className="form">
                            <span className="form-title">카테고리</span>
                            <div className="grid grid-3">
                                {categories.map((c, idx) => (
                                    <div key={idx} className="ipt-button green">
                                        <input type="checkbox" id={`ad-ctgry${c.categoryId}`}
                                               checked={cateSel.includes(c.categoryId)}
                                               onChange={() => selectCategory(c.categoryId)}/>
                                        <label htmlFor={`ad-ctgry${c.categoryId}`}>{c.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>


                        <div className="form">
                            <span className="form-title">광고채널</span>
                            <div className="grid grid-3">
                                {platforms.map((p, idx) => (
                                    <div key={idx} className="ipt-button green">
                                        <input type="checkbox" id={`ad-ch${p.type}`}
                                               checked={platformSel.includes(p.type)}
                                               onChange={() => selectPlatform(p.type)}/>
                                        <label htmlFor={`ad-ch${p.type}`}>{p.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="form">
                            <span className="form-title">리워드방식</span>
                            <div className="grid grid-3">
                                <div className="ipt-button green">
                                    <input type="radio" id="reward1" checked={reward === CampaignFlag.Click}
                                           onChange={() => setReward(CampaignFlag.Click)}/>
                                    <label htmlFor="reward1">클릭</label>
                                </div>
                                <div className="ipt-button green">
                                    <input type="radio" id="reward2" checked={reward === CampaignFlag.Feed}
                                           onChange={() => setReward(CampaignFlag.Feed)}/>
                                    <label htmlFor="reward2">피드</label>
                                </div>
                            </div>
                        </div>
                        <div className="form" style={{margin: "15rem 0"}}>
                            <span className="form-title">단가</span>
                            <form>
                                <Slider range={true} min={0} max={10000000} step={10000} value={price}
                                        onChange={(values: any) => setPrice(values)} railStyle={{height: '10px'}}
                                        trackStyle={{backgroundColor: "green", height: '10px'}}
                                        handleStyle={{width: '20px', height: '20px'}}
                                />
                                <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>
                                    {price[0].toLocaleString()}
                                </span>
                                    <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>
                                    {price[1].toLocaleString()}
                                </span>
                            </form>
                        </div>

                        <div className="form" style={{margin: "15rem 0"}}>
                            <span className="form-title">친구 수</span>
                            <form>
                                <Slider range={true} min={0} max={10000000} step={10000} value={friend}
                                        onChange={(values: any) => setFriend(values)} railStyle={{height: '10px'}}
                                        trackStyle={{backgroundColor: "green", height: '10px'}}
                                        handleStyle={{width: '20px', height: '20px'}}
                                />
                                <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>
                                    {friend[0].toLocaleString()}
                                </span>
                                    <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>
                                    {friend[1].toLocaleString()}
                                </span>
                            </form>
                        </div>

                        <div className="form" style={{margin: "15rem 0"}}>
                            <span className="form-title">평점</span>
                            <form>
                                <Slider range={true} min={0} max={5} value={rating}
                                        onChange={(values: any) => setRating(values)} railStyle={{height: '10px'}}
                                        trackStyle={{backgroundColor: "green", height: '10px'}}
                                        handleStyle={{width: '20px', height: '20px'}}
                                />
                                <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>{rating[0]}</span>
                                <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>{rating[1]}</span>
                            </form>
                        </div>
                    </div>
                    <div className="foot-btn">
                        <div className="detail-foot_one_button">
                            <div><a onClick={() => resultFiltering()} className="button full black">결과 보기</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfluFilteringForm;