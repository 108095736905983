import React, {useEffect, useState} from "react";
import axios from "axios";
import {Navigate} from "react-router-dom";
import setUser from "utils/setUser";

function ExternalLogin() {
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        axios.post('/api/Account/external_login').then(response => {
            setUser(response.data);

            setIsLogin(true);
        })
    }, [])


    return (isLogin ? <Navigate to={"/"}/> : <>Login...</>);
}

export default ExternalLogin;