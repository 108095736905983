import React, {useEffect, useState} from "react";
import axios from "axios";

function Terms() {
    const [content, setContent] = useState<string>("");
    
    useEffect(() => {
        axios.get(`api/Home/terms`)
            .then(response => {
                setContent(response.data.content);
            });
    }, []);
    
    return (
        <>
            <div className="p-2" style={{fontSize: "4.5rem"}}
                 dangerouslySetInnerHTML={
                    { __html: content.replace(/(?:\r\n|\r|\n)/g, '<br />') }
                }></div>
            <div onClick={() => window.scrollTo(0, 0)} className="position-fixed p-3"
                 style={{
                     fontSize: "6.5rem", bottom: "10rem", right: "10rem", backgroundColor: "#fff", borderRadius: "10rem",
                     cursor: "pointer"
                 }}>▲</div>
        </>
    );
}

export default Terms;