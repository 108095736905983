import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import {DivRank, RankChangeEnum} from "types/rank";

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import MobileCampaignThumbnail from "../../components/m-campaign-thumbnail";
import GivePointPopup from "../popup/give-point";
import {getLocalSession} from "../../modules/localSession";
import LoadingSpinner from "../../components/m-loading-spinner";
import {DivCampaignFlag} from "../../types/campaign";
import FilteringEmpty from "../../components/m-filtering-empty";

function DivRankCheckBox() {
    return (
        <div className="rank">
            <input type="checkbox" name="check" id=""/>
        </div>
    );
}

function CampaignFilteringResult() {
    const location = useLocation();
    const session = getLocalSession();
    const [orderPrice, setOrderPrice] = useState(true);
    const [orderPriceData, setOrderPriceData] = useState<any[]>();
    const [filteringData, setFilteringData] = useState<any[]>();
    const [analyzedData, setAnalyzedData] = useState<any[]>([]);
    const [show, setShow] = useState(false);
    const [result, setResult] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    useEffect(() => {
        let param = location.state;
        
        axios.post(`api/Filtering/CampaignFilteringByPrice`, param).then(response => {
            let data = response.data;
            let newItems: any[] = [];

            if(session) {
                axios.get(`api/Zzim/item`)
                    .then(response2 => {
                        let data2 = response2.data.adItems;

                        data.forEach((c: any) => {
                            let zzim = data2.find((z: any) => z.boardId == c.bid);

                            if(zzim) {
                                newItems.push({...c, isZzim: zzim.isZzim});
                            } else {
                                newItems.push({...c, isZzim: false});
                            }
                        });

                        setOrderPriceData(newItems);
                    });
            } else {
                newItems = data.map((c: any) => ({...c, isZzim: false}));
                setOrderPriceData(newItems);
            }
        });
        
        axios.post(`api/Filtering/CampaignFilteringByCustom`, {
            FilterRequest: param
        }).then(response => {
            let data = response.data;
            let newItems: any[] = [];

            if(session) {
                axios.get(`api/Zzim/item`)
                    .then(response2 => {
                        let data2 = response2.data.adItems;

                        data.forEach((c: any) => {
                            let zzim = data2.find((z: any) => z.boardId == c.bid);

                            if(zzim) {
                                newItems.push({...c, isZzim: zzim.isZzim});
                            } else {
                                newItems.push({...c, isZzim: false});
                            }
                        });

                        setFilteringData(newItems);
                    });
            } else {
                newItems = data.map((c: any) => ({...c, isZzim: false}));
                setFilteringData(newItems);
            }

            setLoading(false);
        });
    }, [JSON.stringify(location.state)]);
    
    if(orderPriceData == null) return (<></>);
    if(filteringData == null) return (<></>);
    
    return (
        <div className="contents mobile filtering result campaign">
            <header className="header-sub shadow-none">
                <div className="header_left">
                    <span className="location">캠페인 필터링 결과</span>
                </div>
                <div className="header_right">
                    <a onClick={() => window.location.reload()} className="refresh">
                        <img src={header_refresh} alt="새로고침"/>
                    </a>
                    <a onClick={() => navigate('/filtering/campaign-filtering-form', {state: location.state})}
                       className="close">
                        <img src={icon_close} alt="닫기"/>
                    </a>
                </div>
            </header>
            <div className="form">
                <div className="grid grid-3">
                    <div className="ipt-button blue">
                        <input type="checkbox" id="ad-ch1" title="가격" checked={orderPrice}
                               onChange={() => setOrderPrice(!orderPrice)}/>
                        <label htmlFor="ad-ch1">가격</label>
                    </div>
                </div>
            </div>
            <div className="section" style={{paddingTop: "0px"}}>
                <Swiper className="swiper-container"
                        slidesPerView={"auto"}
                        modules={[Pagination]}
                        pagination={{clickable: true}}>
                    {orderPrice ?
                    <SwiperSlide className="swiper-slide result-box">
                        <div className="title">가격순</div>
                        {orderPriceData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {orderPriceData.map((data, idx) => (
                                <MobileCampaignThumbnail key={idx} campaign={data}/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title">
                            맞춤 필터링 결과
                            <a onClick={() => setShow(true)} className="button round bg-white">가점주기</a>
                        </div>
                        {filteringData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {filteringData.map((data, idx) => (
                                <MobileCampaignThumbnail key={idx} campaign={data} header={
                                    <DivRank rank={{rank: idx+1, change: RankChangeEnum.Equal}}/>
                                }/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    {result ?
                    <SwiperSlide className="swiper-slide result-box suit">
                        <div className="title">
                            최적 분석결과 순위
                            <a onClick={() => setShow(true)} className="button round bg-white">가점 다시 주기</a>
                        </div>
                        {analyzedData.length < 1 ? <FilteringEmpty/> :
                        <div className="cont-list" style={{minHeight: "219.12rem"}}>
                            {analyzedData.map((data, idx) => (
                                <MobileCampaignThumbnail key={idx} campaign={data} header={
                                    <DivRank rank={{rank: idx+1, change: RankChangeEnum.Equal}}/>
                                }/>
                            ))}
                        </div>
                        }
                    </SwiperSlide>
                    : null}
                    {/*<SwiperSlide className="swiper-slide result-box suit">
                        <div className="title">
                            최적 분석결과 순위
                            <a href="#none" className="button round bg-white">가점 다시 주기</a>
                        </div>
                        <div className="cont-list">
                            <div className="all-check">
                                <label htmlFor="all-check"
                                       className="button round bg-white border-black">전체선택</label>
                                <input type="checkbox" name="all-check" id="all-check"/>
                            </div>
                            <MobileInfluThumbnail influ={{
                                bid: 21, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 22, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 23, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 24, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                            <MobileInfluThumbnail influ={{
                                bid: 25, categoryId: 1, subCategoryId: 1, rating: 4.3, title: "뷰티뷰크", images: [],
                                platforms: [
                                    {
                                        type: PlatformType.Instagram,
                                        subscribeCount: 210000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    },
                                    {
                                        type: PlatformType.Facebook,
                                        subscribeCount: 213000,
                                        costPerClick: 10,
                                        costPerFeed: 10
                                    }]
                            }} header={<DivRankCheckBox/>}/>
                        </div>
                    </SwiperSlide>*/}
                </Swiper>
            </div>
            
            <GivePointPopup show={show} setShow={setShow} setData={setAnalyzedData} setResult={setResult}
                            param={location.state}/>
            {loading ? <LoadingSpinner/> : null}
        </div>
    );
}

export default CampaignFilteringResult;