import React, {useRef, useState} from "react";

import file_photo from "img/m/file-photo.svg";
import form_alert from "img/m/form-alert.svg";
import header_back from "img/m/header-back.svg";
import header_close from "img/m/header-close.svg";
import {useNavigate} from "react-router-dom";
import {initData, RegisterInfluencerMemberRequest} from "types/influencer-request";
import {BankCode} from "utils/bank-code";
import WishLocation from "./wish-location";
import WishInfluencer from "./wish-influ";
import {PlatformType} from "types/platform";
import axios from "axios";
import file_expert from "img/m/file-expert.svg";
import {TimePicker} from "antd";
import dash from "img/m/dash.svg";
import setUser from "utils/setUser";

let memData: any = {
    Name: '',
    ProfileImg: '',
    Job: '',
    Address1: '',
    Address2: '',
    IdentificationNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RegionOfInterest: [],
    AreaOfInterest: [],
    YearOfService: 0,
    BusinessHoursStart: '',
    BusinessHoursEnd: '',
    ExceptForWeekEnds: false,
    InstagramUrl: '',
    FacebookUrl: '',
    TwitterUrl: '',
    NaverBlogUrl: '',
    NaverCafeUrl: '',
    YoutubeUrl: '',
    KakaoStoryUrl: '',
    EtcUrl: '',
    Comment: '',
    DetailComment: ''
};

let Step1Ref: any = {};
let Step2Ref: any = {};
let isAgree = true;
let isExceptWeekend = false;

function Step1(props: { onNext: Function }) {
    const [step1Data, setStep1Data] = useState<RegisterInfluencerMemberRequest>(initData);
    const [profileImage, setProfileImage] = useState<any>(null);

    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        setProfileImage(profileImageReader.result);
        memData.ProfileImg = profileImageReader.result || "";
    }

    Step1Ref = {
        Name: useRef<HTMLInputElement>(null),
        Job: useRef<HTMLSelectElement>(null),
        Address1: useRef<HTMLInputElement>(null),
        Address2: useRef<HTMLInputElement>(null),
        IdentificationNumber: useRef<HTMLInputElement>(null),
        BankNumber: useRef<HTMLInputElement>(null),
        BankCode: useRef<HTMLSelectElement>(null),
        BankOwner: useRef<HTMLInputElement>(null)
    };

    const handleStep1Change = (e: any) => {
        setStep1Data({
            ...step1Data,
            [e.target.name]: e.target.value
        });

        memData[e.target.name] = e.target.value;
    };

    const handleAgreeCheckChange = (e: any) => {
        isAgree = !isAgree;
    }

    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    }
    
    return (
        <>
            <div className="member profile">
                <div className="file_upload center">
                    <div className="circle">
                        {profileImage === null ? (<img className="speaker" src={file_expert} alt=""/>)
                            : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                    </div>
                    <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                    <img className="photo_button" src={file_photo} alt=""/>
                </div>
                {/* 타이틀 - 기본정보 */}
                <div className="layout">
                    <div className="profile_title">기본정보</div>
                </div>
                <form action="">
                    {/* 판매자 구분 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">직업</div>
                        <div className="input_section">
                            <select className="selectbox" name="Job" value={step1Data.Job}
                                    onChange={handleStep1Change} ref={Step1Ref.Job}>
                                <option value="">-- 선택 --</option>
                                <option value="학생">학생</option>
                                <option value="직장인">직장인</option>
                                <option value="사업가">사업가</option>
                            </select>
                        </div>
                    </div>
                    {/* 주소 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">주소</div>
                        <div className="input_section">
                            <input className="input basic" type="text" placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"
                                   name="Address1" value={step1Data.Address1} onChange={handleStep1Change}
                                   ref={Step1Ref.Address1}/>
                        </div>
                        <div className="input_section adress">
                            <input className="input basic" type="text" placeholder="상세주소를 입력하세요" name="Address2"
                                   value={step1Data.Address2} onChange={handleStep1Change}
                                   ref={Step1Ref.Address2}/>
                        </div>
                    </div>

                    {/* 타이틀 - 본인인증 */}
                    <div className="layout">
                        <div className="profile_title">본인인증</div>
                    </div>
                    {/* 이름 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">이름</div>
                        <div className="input_section">
                            <input className="input basic" type="text" placeholder="성명을 입력해주세요" name="Name"
                                   value={step1Data.Name} onChange={handleStep1Change}
                                   ref={Step1Ref.Name}/>
                        </div>
                    </div>
                    {/* 주민번호 */}
                    {/*<div className="layout line type-profile">
                        <div className="profile_name">주민번호</div>
                        <div className="input_section">
                            <input className="input basic" type="text" placeholder="'-' 없이 주민번호를 입력해주세요"
                                   name="IdentificationNumber" value={step1Data.IdentificationNumber}
                                   onChange={handleStep1Change} ref={Step1Ref.IdentificationNumber}/>
                        </div>
                    </div>*/}
                    {/* 출금계좌번호 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">계좌 번호</div>
                        <div className="input_section">
                            <input className="input basic" type="text" placeholder="'-' 없이 계좌번호를 입력해주세요"
                                   name="BankNumber" value={step1Data.BankNumber}
                                   onChange={handleStep1Change} ref={Step1Ref.BankNumber}/>
                        </div>
                    </div>
                    {/* 은행명 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">은행명</div>
                        <div className="input_section">
                            <select className="selectbox" name="BankCode" value={step1Data.BankCode}
                                    onChange={handleStep1Change} ref={Step1Ref.BankCode}>
                                <option value="">은행 선택</option>
                            {BankCode.map((bank, key) => (
                                <option key={key} value={bank.code}>{bank.name}</option>
                            ))}
                            </select>
                        </div>
                    </div>
                    {/* 예금주 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">예금주</div>
                        <div className="input_section">
                            <input className="input basic" type="text" placeholder="예금주를 입력해주세요"
                                   name="BankOwner" value={step1Data.BankOwner} onChange={handleStep1Change}
                                   ref={Step1Ref.BankOwner}/>
                        </div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="notice_area">
                            <div className="title">
                                <img src={form_alert} alt=""/>
                                <span>세금 관련 유의사항</span>
                            </div>
                            <div className="desc">
                                -픽퐁을 통해 서비스 판매하는 판매자는 별도의 사업자 등록이 없어도 서비스 등록과 판매가 가능합니다.<br/>
                                -지속적인 매출이 발생하는 경우 국세청에서는 사업자 등록을 권고하고 있으니 해당 내용을 참고하시어 사업자 등록 여부를 확인하시길 바랍니다.
                            </div>
                        </div>
                    </div>

                    {/* 약관동의 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">약관동의</div>
                        <div className="notice_area">
                            <div className="desc scroll_limit">
                                <div className="limit_seciton">
                                    <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                    <div className="info">
                                        제 1조 [목적]<br/>
                                        본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                        계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                    </div>
                                </div>
                                <div className="limit_seciton">
                                    <div className="title">픽퐁 판매/홍보 대행 약관</div>
                                    <div className="info">
                                        제 1조 [목적]<br/>
                                        본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                        계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="checkbox">
                            <input type="checkbox" className="check_input" id="vehicle2"
                                   checked={isAgree} onChange={handleAgreeCheckChange}/>
                            <label htmlFor="vehicle2">
                                <span className="circle checked"></span>
                                <span className="name agree_text type03">
                            본인은 위 약관의 내용을 모두 확인하였으며, 약관에 따라 성실히 활동할 것에 동의합니다.
                        </span>
                            </label>
                        </div>
                    </div>
                    {/* 서명 */}
                    {/*TODO : 차후 구현*/}
                    {/*<div className="layout line type-profile">*/}
                    {/*    <div className="profile_name del">*/}
                    {/*        <span>서명</span>*/}
                    {/*        <a href="#" className="del_btn">지우기</a>*/}
                    {/*    </div>*/}
                    {/*    <div className="signature"></div>*/}
                    {/*</div>*/}
                </form>
            </div>
            <div className="bottom chap">
                <a className="button full green" onClick={() => props.onNext()}>다음단계</a>
            </div>
        </>
    );
}

type setPopupState =  React.Dispatch<React.SetStateAction<boolean>>;

function Step2(props: {setLoc: setPopupState, setCate: setPopupState}) {
    const [step2Data, setStep2Data] = useState<RegisterInfluencerMemberRequest>(initData);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [platformSel, setPlatformSel] = useState<any[]>([]);
    const navigate = useNavigate();

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];

    Step2Ref = {
        BusinessHoursStart: useRef<HTMLInputElement>(null),
        BusinessHoursEnd: useRef<HTMLInputElement>(null),
        YearOfService: useRef<HTMLInputElement>(null),
        Url: useRef<HTMLInputElement[]>([]),
        Comment: useRef<HTMLInputElement>(null),
        DetailComment: useRef<HTMLTextAreaElement>(null),
    };

    const handleStep2Change = (e: any) => {
        setStep2Data({
            ...step2Data,
            [e.target.name]: e.target.value
        });

        memData[e.target.name] = e.target.value;
    };

    const handleChkChange = (e: any) => {
        isExceptWeekend = !isExceptWeekend;

        setStep2Data({
            ...step2Data,
            ExceptForWeekEnds: isExceptWeekend
        });

        memData.ExceptForWeekEnds = isExceptWeekend;
    };

    const handleUrlChange = (e: any, id: number) => {
        setPlatformSel(platformSel.map(platform => (
            platform.id === id ? {...platform, url: e.target.value} : platform
        )));
    };

    const selectPlatform = (platformType: number, platformName: string) => {
        if(platformSel.includes(platformSel.find(p => p.id === platformType))) {
            setPlatformSel(platformSel.filter(item => item.id !== platformType));
        } else {
            setPlatformSel([...platformSel, {id: platformType, name: platformName, url: ''}]);
        }
    };

    const registInfluMember = () => {
        if(memData.RegionOfInterest.length < 1) {
            alert("관심 지역을 선택해주세요.");
            return;
        }

        if(memData.AreaOfInterest.length < 1) {
            alert("관심 분야를 선택해주세요.");
            return;
        }

        if(!startTime) {
            alert("연락 가능 시간을 입력해주세요.");
            if(Step2Ref.BusinessHoursStart.current !== null) Step2Ref.BusinessHoursStart.current.focus();
            return;
        }

        if(!endTime) {
            alert("연락 가능 시간을 입력해주세요.");
            if(Step2Ref.BusinessHoursEnd.current !== null) Step2Ref.BusinessHoursEnd.current.focus();
            return;
        }

        if('' == memData.YearOfService.toLocaleString()) {
            alert("경력 년수를 입력해주세요.");
            if(Step2Ref.YearOfService.current !== null) Step2Ref.YearOfService.current.focus();
            return;
        }

        if(platformSel.length < 1) {
            alert("광고 채널을 1개 이상 선택해주세요.");
            return;
        }
        
        let flag = true;

        platformSel.some((platform, idx) => {
            if('' == platform.url) {
                alert("광고 채널 URL을 입력해주세요.");
                if(Step2Ref.Url.current[idx] !== null) Step2Ref.Url.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if(!flag) {
            return;
        }

        if('' == memData.Comment) {
            alert("자기 소개를 입력해주세요.");
            if(Step2Ref.Comment.current !== null) Step2Ref.Comment.current.focus();
            return;
        }

        if('' == memData.DetailComment) {
            alert("상세 소개를 입력해주세요.");
            if(Step2Ref.DetailComment.current !== null) Step2Ref.DetailComment.current.focus();
            return;
        }
        
        memData.BusinessHoursStart = startTime.format('HH:mm');
        memData.BusinessHoursEnd = endTime.format('HH:mm');
        
        platformSel.forEach(platform => {
            if(Number(platform.id) == PlatformType.Instagram) {
                memData.InstagramUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.Facebook) {
                memData.FacebookUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.Twitter) {
                memData.TwitterUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.NaverBlog) {
                memData.NaverBlogUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.NaverCafe) {
                memData.NaverCafeUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.KakaoFriend) {
                memData.KakaoStoryUrl = platform.url;
            } else if(Number(platform.id) == 6) {
                memData.YoutubeUrl = platform.url;
            } else if(Number(platform.id) == 99) {
                memData.EtcUrl = platform.url;
            }
        });
        
        console.log(memData);

        axios.post('/api/Influencer/member', memData).then(response => {
            alert("프로필 등록이 완료되었습니다.");
            setUser(response.data);

            navigate('/');
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");

            return;
        });
    };
    
    return (
        <>
            <div className="member profile">
                {/* 타이틀 - 추가정보 */}
                <div className="profile-title">
                    <div className="profile_title">추가정보</div>
                </div>
                <form action="">
                    {/* 관심지역 */}
                    <div className="layout line type-profile">
                        <div className="wish_local green">
                            <div className="title">관심 지역</div>
                            <a className="choice _wish_local" onClick={() => props.setLoc(true)}>선택하기</a>
                        </div>
                        <div className="input_section wish_local-info">
                            {memData.RegionOfInterest.length > 0 ? memData.RegionOfInterest.join(', ') : '지역을 선택하세요.'}
                        </div>
                    </div>
                    {/* 관심분야 */}
                    <div className="layout line type-profile">
                        <div className="wish_local green">
                            <div className="title">관심 분야</div>
                            <a className="choice _wish_field" onClick={() => props.setCate(true)}>선택하기</a>
                        </div>
                        <div className="input_section wish_local-info">
                            {memData.AreaOfInterest.length > 0 ? memData.AreaOfInterest.join(', ') : '분야를 선택하세요.'}
                        </div>
                    </div>
                    {/*연락 가능 시간*/}
                    <div className="layout line type-profile">
                        <div className="wish_local">
                            <div className="title">연락 가능시간</div>
                                <div className="choice checkbox">
                                    <span className="choice_name">주말 제외</span>
                                    <span className="choice_check">
                                    <input type="checkbox" checked={isExceptWeekend} onChange={handleChkChange}/>
                                </span>
                            </div>
                        </div>
                        <div className="input_section wish_local-info">
                            <div className="time_time">
                                <div className="time">
                                    <TimePicker className="input basic" format="HH:mm" placeholder="HH24:MI"
                                                value={startTime} ref={Step2Ref.BusinessHoursStart}
                                                onChange={(time) => setStartTime(time)}/>
                                </div>
                                <div className="dash"><img src={dash} alt=""/></div>
                                <div className="time">
                                    <TimePicker className="input basic" format="HH:mm" placeholder="HH24:MI"
                                                value={endTime} ref={Step2Ref.BusinessHoursEnd}
                                                onChange={(time) => setEndTime(time)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*경력*/}
                    <div className="layout line type-profile">
                        <div className="profile_name">경력</div>
                        <div className="input_section">
                            <div className="input button-right">
                                <input style={{marginRight: '5px'}} className="input basic" type="number"
                                       name="YearOfService" value={step2Data.YearOfService}
                                       onChange={handleStep2Change} ref={Step2Ref.YearOfService}/>
                                <div className="input basic" style={{backgroundColor: '#fff'}}>년</div>
                            </div>
                        </div>
                    </div>
                    {/* 광고 채널 */}
                    {/* TODO : 광고 채널 항목이 복수 선택 항목인지 확인 필요.. */}
                    <div className="layout line type-profile">
                        <div className="profile_name">광고 채널</div>
                        <div className="input_section">
                            <div className="grid grid-3">
                                {platforms.map((platform, idx) => (
                                    <div key={idx}>
                                        <a className={
                                            `button ad_channel green _onOff
                                            ${platformSel.includes(platformSel.find(p => p.id === platform.type)) ? ' on' : ''}`
                                        } onClick={() => selectPlatform(platform.type, platform.name)}>{platform.name}</a>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* 자사 홈페이지 */}
                    {platformSel.map((platform, idx) => (
                        <div key={idx} className="layout line type-profile">
                            <div className="profile_name">{platform.name}</div>
                            <div className="input_section">
                                <input className="input basic" type="text" placeholder="https://"
                                       onChange={(event) => handleUrlChange(event, platform.id)}
                                       ref={ref => Step2Ref.Url.current[idx] = ref} value={platform.url}/>
                            </div>
                        </div>
                    ))}
                    {/* 한줄 자기 소개 */}
                    <div className="profile-title">
                        <div className="profile_title">한줄 자기 소개</div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="input_section">
                            <input type="text" className="input basic" placeholder="" name="Comment"
                                   value={step2Data.Comment} onChange={handleStep2Change}
                                   ref={Step2Ref.Comment}/>
                        </div>
                    </div>
                    <div className="profile-title">
                        <div className="profile_title">상세 소개</div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="input_section">
                            { // @ts-ignore
                                <textarea className="textarea basic" placeholder="" name="DetailComment"
                                          value={step2Data.DetailComment} onChange={handleStep2Change}
                                          ref={Step2Ref.DetailComment}></textarea>
                            }
                        </div>
                    </div>

                </form>
            </div>
            <div className="bottom chap">
                <a onClick={() => registInfluMember()} className="button full green">등록완료</a>
            </div>
        </>
    );
}

function DivStep(props: { step: number, onNext: Function, setLoc: setPopupState, setCate: setPopupState }) {
    if (props.step == 0) {
        return (<Step1 onNext={props.onNext}/>);
    } else if (props.step == 1) {
        return (<Step2 setLoc={props.setLoc} setCate={props.setCate}/>);
    }

    throw new Error(`[DivStep]: ${props.step} not implemented.`)
}

function DivComplete(props: { index: number, step: number }) {
    if (props.index <= props.step) {
        return (<li className="complete"></li>);
    }

    return (<li></li>);
}

function InfluProfileAdd() {
    const [step, setStep] = useState(0);
    const [wishLoc, setWishLoc] = useState(false);
    const [wishInflu, setWishInflu] = useState(false);
    const stepArr = [0, 1];
    
    const selectLoc = (values: string[]) => {
        memData.RegionOfInterest = values;
    };

    const selectCate = (values: string[]) => {
        memData.AreaOfInterest = values;
    };

    const handleNext = () => {
        let regNum = /^[0-9]+$/;

        if('' == memData.Job) {
            alert("직업을 선택해주세요.");
            if(Step1Ref.Job.current !== null) Step1Ref.Job.current.focus();
            return;
        }

        if('' == memData.Address1) {
            alert("주소를 입력해주세요.");
            if(Step1Ref.Address1.current !== null) Step1Ref.Address1.current.focus();
            return;
        }

        if('' == memData.Address2) {
            alert("상세주소를 입력해주세요.");
            if(Step1Ref.Address2.current !== null) Step1Ref.Address2.current.focus();
            return;
        }

        if('' == memData.Name) {
            alert("이름을 입력해주세요.");
            if(Step1Ref.Name.current !== null) Step1Ref.Name.current.focus();
            return;
        }

        /*if('' == memData.IdentificationNumber) {
            alert("주민번호를 입력해주세요.");
            if(Step1Ref.IdentificationNumber.current !== null)
                Step1Ref.IdentificationNumber.current.focus();
            return;
        }

        if(!regNum.test(memData.IdentificationNumber)) {
            alert("주민번호를 숫자로만 입력해주세요.");
            if(Step1Ref.IdentificationNumber.current !== null)
                Step1Ref.IdentificationNumber.current.focus();
            return;
        }*/

        if('' == memData.BankNumber) {
            alert("계좌번호를 입력해주세요.");
            if(Step1Ref.BankNumber.current !== null) Step1Ref.BankNumber.current.focus();
            return;
        }

        if(!regNum.test(memData.BankNumber)) {
            alert("계좌번호를 숫자로만 입력해주세요.");
            if(Step1Ref.BankNumber.current !== null) Step1Ref.BankNumber.current.focus();
            return;
        }

        if('' == memData.BankCode) {
            alert("은행명을 선택해주세요.");
            if(Step1Ref.BankCode.current !== null) Step1Ref.BankCode.current.focus();
            return;
        }

        if('' == memData.BankOwner) {
            alert("예금주를 입력해주세요.");
            if(Step1Ref.BankOwner.current !== null) Step1Ref.BankOwner.current.focus();
            return;
        }

        if(!isAgree) {
            alert("약관에 동의해야 합니다.");
            return;
        }
        
        setStep(step + 1);
    }

    const onBack = () => {
        if (step == 0) {
            history.back();
        }

        setStep(step - 1);

        memData = {
            Name: '',
            ProfileImg: '',
            Job: '',
            Address1: '',
            Address2: '',
            IdentificationNumber: '',
            BankNumber: '',
            BankCode: '',
            BankOwner: '',
            RegionOfInterest: [],
            AreaOfInterest: [],
            YearOfService: 0,
            BusinessHoursStart: '',
            BusinessHoursEnd: '',
            ExceptForWeekEnds: false,
            InstagramUrl: '',
            FacebookUrl: '',
            TwitterUrl: '',
            NaverBlogUrl: '',
            NaverCafeUrl: '',
            YoutubeUrl: '',
            KakaoStoryUrl: '',
            EtcUrl: '',
            Comment: '',
            DetailComment: ''
        };
    };

    if(wishLoc) {
        return (<WishLocation setWishLoc={setWishLoc} setSelect={selectLoc}/>);
    } else if(wishInflu) {
        return (<WishInfluencer setWishInflu={setWishInflu} setSelect={selectCate}/>);
    } else {
        return (
            <div>
                <div className="contents mobile">
                    <header className="header-member">
                        <div className="header_left">
                            <a onClick={() => onBack()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">인플루언서 프로필 등록</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                        </div>
                    </header>
                    <ul className="navigation progress green">
                        {stepArr.map((_, index) => (
                            <DivComplete key={index} index={index} step={step}/>
                        ))}
                    </ul>

                    <DivStep step={step} onNext={handleNext} setLoc={setWishLoc} setCate={setWishInflu}/>
                </div>
            </div>
        );
    }
}

export default InfluProfileAdd;