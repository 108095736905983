import React from "react";
import {UserType} from "types/user-type";

export interface LocalSession {
    id: string,
    displayName: string,
    token: string,
    userName: string
    userType: UserType
}

export function getLocalSession(): LocalSession | null {
    const sessionStr = localStorage.getItem("session");

    if (sessionStr == null)
        return null;

    return JSON.parse(sessionStr);
}

export function setLocalSession(session: any) {
    localStorage.setItem("session", JSON.stringify(session));
}

export function removeLocalSession() {
    localStorage.removeItem("session");
}