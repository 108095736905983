import React, {useEffect, useRef, useState} from "react";
import {Form} from "react-bootstrap";

import form_alert from "img/m/form-alert.svg";
import header_back from "img/m/header-back.svg";
import header_close from "img/m/header-close.svg";
import file_expert from "img/m/file-expert.svg";
import file_photo from "img/m/file-photo.svg";
import dash from "img/m/dash.svg";
import "css/range-picker-custom.css";
import {initData, RegisterProServiceMemberRequest} from "types/pro-service-request";
import {BankCode} from "utils/bank-code";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {DatePicker, TimePicker} from "antd";
import WishExpert from "./wish-expert";
import setUser from "utils/setUser";

const {RangePicker} = DatePicker;

let memData: any = {
    IsBusiness: false,
    Name: '',
    ProfileImg: '',
    IdentificationNumber: '',
    BusinessOwner: '',
    Address1: '',
    Address2: '',
    ContactNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RepresentativeActivitiesField: [],
    DetailedField: [],
    YearOfService: 0,
    BusinessHoursStart: '',
    BusinessHoursEnd: '',
    ExceptForWeekEnds: false,
    Skills: [],
    Careers: [],
    Comment: '',
    DetailComment: ''
};

let IndividualRef: any = {};
let OrganizationsRef: any = {};
let Step2Ref: any = {};
let isAgree = true;
let isExceptWeekend = false;
let userGbn = "Individual";
let isCompany = false;

function Individual() {
    const [IndividualData, setIndividualData] = useState<RegisterProServiceMemberRequest>(initData);

    IndividualRef = {
        Name: useRef<HTMLInputElement>(null),
        IdentificationNumber: useRef<HTMLInputElement>(null),
        BankNumber: useRef<HTMLInputElement>(null),
        BankCode: useRef<HTMLSelectElement>(null),
        BankOwner: useRef<HTMLInputElement>(null)
    };

    const handleIndividualChange = (e: any) => {
        setIndividualData({
            ...IndividualData,
            [e.target.name]: e.target.value
        });

        memData[e.target.name] = e.target.value;
    };

    return (
        <>
            {/* 이름 */}
            <div className="layout line type-profile">
                <div className="profile_name">이름</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="실명을 입력해주세요" name="Name"
                           value={IndividualData.Name} onChange={handleIndividualChange}
                           ref={IndividualRef.Name}/>
                </div>
            </div>
            {/* 주민번호 */}
            {/*<div className="layout line type-profile">
                <div className="profile_name">주민번호</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="'-' 없이 주민번호를 입력해주세요"
                           name="IdentificationNumber" value={IndividualData.IdentificationNumber}
                           onChange={handleIndividualChange} ref={IndividualRef.IdentificationNumber}/>
                </div>
            </div>*/}
            {/* 출금계좌번호 */}
            <div className="layout line type-profile">
                <div className="profile_name">출금계좌번호</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="'-' 없이 계좌번호를 입력해주세요"
                           name="BankNumber" value={IndividualData.BankNumber} onChange={handleIndividualChange}
                           ref={IndividualRef.BankNumber}/>
                </div>
            </div>
            {/* 은행명 */}
            <div className="layout line type-profile">
                <div className="profile_name">은행명</div>
                <div className="input_section">
                    <select className="selectbox" name="BankCode" value={IndividualData.BankCode}
                            onChange={handleIndividualChange} ref={IndividualRef.BankCode}>
                        <option value="">은행 선택</option>
                        {BankCode.map((bank, key) => (
                            <option key={key} value={bank.code}>{bank.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 예금주 */}
            <div className="layout line type-profile">
                <div className="profile_name">예금주</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="예금주를 입력해주세요" name="BankOwner"
                           value={IndividualData.BankOwner} onChange={handleIndividualChange}
                           ref={IndividualRef.BankOwner}/>
                </div>
            </div>
            <div className="layout line type-profile">
                <div className="notice_area">
                    <div className="title">
                        <img src={form_alert} alt=""/>
                        <span>세금 관련 유의사항</span>
                    </div>
                    <div className="desc">
                        -픽퐁을 통해 서비스 판매하는 판매자는 별도의 사업자 등록이 없어도 서비스 등록과 판매가 가능합니다.<br/>
                        -지속적인 매출이 발생하는 경우 국세청에서는 사업자 등록을 권고하고 있으니 해당 내용을 참고하시어 사업자 등록 여부를 확인하시길 바랍니다.
                    </div>
                </div>
            </div>
        </>
    );
}

function Organizations() {
    const [OrganizationsData, setOrganizationsData] = useState<RegisterProServiceMemberRequest>(initData);

    OrganizationsRef = {
        Name: useRef<HTMLInputElement>(null),
        IdentificationNumber: useRef<HTMLInputElement>(null),
        BusinessOwner: useRef<HTMLInputElement>(null),
        Address1: useRef<HTMLInputElement>(null),
        Address2: useRef<HTMLInputElement>(null),
        ContactNumber: useRef<HTMLInputElement>(null),
        BankNumber: useRef<HTMLInputElement>(null),
        BankCode: useRef<HTMLSelectElement>(null),
        BankOwner: useRef<HTMLInputElement>(null)
    };

    const handleOrganizationsChange = (e: any) => {
        setOrganizationsData({
            ...OrganizationsData,
            [e.target.name]: e.target.value
        });

        memData[e.target.name] = e.target.value;

        if ("IdentificationNumber" == e.target.name) {
            isCompany = false;
        }
    };

    const validateCompany = () => {
        axios.get(`/api/ProService/validate_company/${memData.IdentificationNumber}`)
            .then(response => {
                isCompany = response.data;

                if (!isCompany) {
                    alert("인증에 실패했습니다.");
                } else {
                    alert("인증에 성공했습니다.");
                }
            });
    }

    return (
        <>
            {/* 기업명 */}
            <div className="layout line type-profile">
                <div className="profile_name">기업명</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="기업명을 입력해주세요" name="Name"
                           value={OrganizationsData.Name} onChange={handleOrganizationsChange}
                           ref={OrganizationsRef.Name}/>
                </div>
            </div>
            {/* 사업자 등록번호 */}
            <div className="layout line type-profile">
                <div className="profile_name">사업자 등록번호</div>
                <div className="input_section">
                    <div className="input button-right">
                        <input className="input basic" type="text" placeholder="- 제외, 숫자만 입력하세요"
                               name="IdentificationNumber" value={OrganizationsData.IdentificationNumber}
                               onChange={handleOrganizationsChange} ref={OrganizationsRef.IdentificationNumber}/>
                        <a onClick={() => validateCompany()}
                           className="input button d-flex align-items-center justify-content-center">인증</a>
                    </div>
                </div>
            </div>
            {/* 대표자명 */}
            <div className="layout line type-profile">
                <div className="profile_name">대표자명</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="대표자명을 입력하세요" name="BusinessOwner"
                           value={OrganizationsData.BusinessOwner} onChange={handleOrganizationsChange}
                           ref={OrganizationsRef.BusinessOwner}/>
                </div>
            </div>
            {/* 주소 */}
            <div className="layout line type-profile">
                <div className="profile_name">주소</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"
                           name="Address1" value={OrganizationsData.Address1} onChange={handleOrganizationsChange}
                           ref={OrganizationsRef.Address1}/>
                </div>
                <div className="input_section adress">
                    <input className="input basic" type="text" placeholder="상세주소를 입력하세요"
                           name="Address2" value={OrganizationsData.Address2} onChange={handleOrganizationsChange}
                           ref={OrganizationsRef.Address2}/>
                </div>
            </div>
            {/* 담당자 연락처 */}
            <div className="layout line type-profile">
                <div className="profile_name">담당자 연락처</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="- 제외, 숫자만 입력하세요"
                           name="ContactNumber" value={OrganizationsData.ContactNumber}
                           onChange={handleOrganizationsChange} ref={OrganizationsRef.ContactNumber}/>
                </div>
            </div>
            {/* 출금계좌번호 */}
            <div className="layout line type-profile">
                <div className="profile_name">출금계좌번호</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="'-' 없이 계좌번호를 입력해주세요"
                           name="BankNumber" value={OrganizationsData.BankNumber}
                           onChange={handleOrganizationsChange} ref={OrganizationsRef.BankNumber}/>
                </div>
            </div>
            {/* 은행명 */}
            <div className="layout line type-profile">
                <div className="profile_name">은행명</div>
                <div className="input_section">
                    <select className="selectbox" name="BankCode" value={OrganizationsData.BankCode}
                            onChange={handleOrganizationsChange} ref={OrganizationsRef.BankCode}>
                        <option value="">은행 선택</option>
                        {BankCode.map((bank, key) => (
                            <option key={key} value={bank.code}>{bank.name}</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* 예금주 */}
            <div className="layout line type-profile">
                <div className="profile_name">예금주</div>
                <div className="input_section">
                    <input className="input basic" type="text" placeholder="예금주를 입력해주세요" name="BankOwner"
                           value={OrganizationsData.BankOwner} onChange={handleOrganizationsChange}
                           ref={OrganizationsRef.BankOwner}/>
                </div>
            </div>
            <div className="layout line type-profile">
                <div className="notice_area">
                    <div className="title">
                        <img src={form_alert} alt=""/>
                        <span>세금 관련 유의사항</span>
                    </div>
                    <div className="desc">
                        -픽퐁을 통해 서비스 판매하는 판매자는 별도의 사업자 등록이 없어도 서비스 등록과 판매가 가능합니다.<br/>
                        -지속적인 매출이 발생하는 경우 국세청에서는 사업자 등록을 권고하고 있으니 해당 내용을 참고하시어 사업자 등록 여부를 확인하시길 바랍니다.
                    </div>
                </div>
            </div>
        </>
    );
}

function Stipulate() {
    const handleAgreeCheckChange = (e: any) => {
        isAgree = !isAgree;
    }

    return (
        <>
            {/* 약관동의 */}
            <div className="layout line type-profile">
                <div className="profile_name">약관동의</div>
                <div className="notice_area">
                    <div className="desc scroll_limit">
                        <div className="limit_seciton">
                            <div className="title">픽퐁 판매/홍보 대행 약관</div>
                            <div className="info">
                                제 1조 [목적]<br/>
                                본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                            </div>
                        </div>
                        <div className="limit_seciton">
                            <div className="title">픽퐁 판매/홍보 대행 약관</div>
                            <div className="info">
                                제 1조 [목적]<br/>
                                본 약관은 서비스를 판매,홍보하는 주식회사 돌샘(이하 "회사")와 픽퐁에 입점하여 서비스를 판매하는 판매자(이하 "판매자) 사이에 체겨된 입점
                                계약에 따른 권리, 의무 및 책임사항을 규정하여 신뢰와 협조로써 계약 내용을 준수하여 상호발전에 기여하는데 있습니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout line type-profile">
                <div className="checkbox">
                    <input type="checkbox" className="check_input" id="vehicle2" checked={isAgree}
                           onChange={handleAgreeCheckChange}/>
                    <label htmlFor="vehicle2">
                        <span className="circle checked"></span>
                        <span className="name agree_text type03">
                            본인은 위 약관의 내용을 모두 확인하였으며, 약관에 따라 성실히 활동할 것에 동의합니다.
                        </span>
                    </label>
                </div>
            </div>
            {/* 서명 */}
            {/*TODO : 차후 구현*/}
            {/*<div className="layout line type-profile">
                <div className="profile_name del">
                    <span>서명</span>
                    <a href="#" className="del_btn">지우기</a>
                </div>
                <div className="signature"></div>
            </div>*/}
        </>
    );
}

function DivStep1Form(props: { userType: string }) {
    if (props.userType == "Individual")
        return (<><Individual/><Stipulate/></>);
    return (<><Organizations/><Stipulate/></>);
}

type setPopupState = React.Dispatch<React.SetStateAction<boolean>>;
type setStrState = React.Dispatch<React.SetStateAction<string>>;
type setStrArrState = React.Dispatch<React.SetStateAction<string[]>>;

function Step1(props: { onNext: Function, userType: string, setUserType: setStrState }) {
    const [profileImage, setProfileImage] = useState<any>(null);

    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        setProfileImage(profileImageReader.result);
        memData.ProfileImg = profileImageReader.result || "";
    }

    const handleUserTypeChange = (e: any) => {
        props.setUserType(e.target.value);
        userGbn = e.target.value;
    };

    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    }

    return (
        <>
            <div className="member profile">
                <div className="file_upload center">
                    <div className="circle">
                        {profileImage === null ? (<img className="speaker" src={file_expert} alt=""/>)
                            : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                    </div>
                    <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                    <img className="photo_button" src={file_photo} alt=""/>
                </div>
                {/* 본인인증 타이틀 */}
                <div className="layout">
                    <div className="profile_title">본인인증</div>
                </div>
                <form action="">
                    {/* 판매자 구분 */}
                    <div className="layout line type-profile">
                        <div className="profile_name">판매자 구분</div>
                        <div className="input_section">
                            <Form.Check inline className="radio inline">
                                <Form.Check.Label>
                                    <input type="radio" id="individual" style={{accentColor: "#6f5bba"}}
                                                      className="circle"
                                                      value="Individual"
                                                      name="user_type"
                                                      checked={props.userType === "Individual"}
                                                      onChange={handleUserTypeChange}
                                    ></input>
                                    <Form.Check.Label className="name basic" htmlFor="individual">개인</Form.Check.Label>
                                </Form.Check.Label>
                            </Form.Check>
                            <Form.Check inline className="radio">
                                <Form.Check.Label>
                                    <input type="radio" id="organizations" style={{accentColor: "#6f5bba"}}
                                                      className="circle"
                                                      name="user_type"
                                                      value="Organizations"
                                                      checked={props.userType === "Organizations"}
                                                      onChange={handleUserTypeChange}
                                    ></input>
                                    <Form.Check.Label className="name basic"
                                                      htmlFor="organizations">기업</Form.Check.Label>
                                </Form.Check.Label>
                            </Form.Check>
                        </div>
                    </div>
                    <DivStep1Form userType={props.userType}/>
                </form>
            </div>
            <div className="bottom chap">
                <a className="button full purple" onClick={() => props.onNext()}>다음단계</a>
            </div>
        </>
    );
}

function Step2(props: {
    setCate: setPopupState, selectCate: string, selectDetail: string[], setSelectCate: setStrState,
    setSelectDetail: setStrArrState
}) {
    const [step2Data, setStep2Data] = useState<RegisterProServiceMemberRequest>(initData);
    const [careers, setCareers] = useState<any[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const navigate = useNavigate();

    Step2Ref = {
        BusinessHoursStart: useRef<HTMLInputElement>(null),
        BusinessHoursEnd: useRef<HTMLInputElement>(null),
        CareerTitle: useRef<HTMLInputElement[]>([]),
        CareerDt: useRef<HTMLInputElement[]>([]),
        Skill: useRef<HTMLInputElement[]>([]),
        Comment: useRef<HTMLInputElement>(null),
        DetailComment: useRef<HTMLTextAreaElement>(null)
    };

    const handleStep2Change = (e: any) => {
        setStep2Data({
            ...step2Data,
            [e.target.name]: e.target.value
        });

        memData[e.target.name] = e.target.value;
    };

    const handleChkChange = (e: any) => {
        isExceptWeekend = !isExceptWeekend;

        setStep2Data({
            ...step2Data,
            ExceptForWeekEnds: isExceptWeekend
        });

        memData.ExceptForWeekEnds = isExceptWeekend;
    };

    const setCareerTitle = (e: any, idx: number) => {
        setCareers(careers.map((ca, i) => (
            i === idx ? {...ca, Title: e.target.value} : ca
        )));
    };

    const setCareerDate = (dates: any, dateStrings: [string, string], idx: number) => {
        const [startDt, endDt] = dates;
        const [startDtStr, endDtStr] = dateStrings;
        setCareers(careers.map((ca, i) => (
            i === idx ? {...ca, StartDt: startDt, EndDt: endDt, StartDtStr: startDtStr, EndDtStr: endDtStr} : ca
        )));
    };

    const clearWishPro = () => {
        props.setSelectCate('');
        props.setSelectDetail([]);
        memData.RepresentativeActivitiesField = [];
        memData.DetailedField = [];
    };

    const deleteWishPro = (pro: string) => {
        let selDetail = props.selectDetail.filter(item => item !== pro);
        props.setSelectDetail(selDetail);
        memData.DetailedField = selDetail;
    };

    const addCareer = () => {
        let addItem = {Title: '', StartDt: null, EndDt: null, StartDtStr: '', EndDtStr: ''};
        setCareers([...careers, addItem]);

    };

    const addSkill = () => {
        setSkills([...skills, '']);
    }

    const RegistProProfile = () => {
        if (memData.RepresentativeActivitiesField.length < 1) {
            alert("관심 분야를 선택해주세요.");
            return;
        }

        if (memData.DetailedField.length < 1) {
            alert("상세 분야를 선택해주세요.");
            return;
        }

        if (!startTime) {
            alert("연락 가능 시간을 입력해주세요.");
            if (Step2Ref.BusinessHoursStart.current !== null) Step2Ref.BusinessHoursStart.current.focus();
            return;
        }

        if (!endTime) {
            alert("연락 가능 시간을 입력해주세요.");
            if (Step2Ref.BusinessHoursEnd.current !== null) Step2Ref.BusinessHoursEnd.current.focus();
            return;
        }

        let flag = true;

        careers.some((ca, idx) => {
            if ('' == ca.Title) {
                alert("경력을 입력해주세요.");
                if (Step2Ref.CareerTitle.current[idx] !== null) Step2Ref.CareerTitle.current[idx].focus();
                flag = false;
                return true;
            }

            if ('' == ca.StartDtStr) {
                alert("경력 기간을 입력해주세요.");
                if (Step2Ref.CareerDt.current[idx] !== null) Step2Ref.CareerDt.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if (!flag) {
            return;
        } else {
            flag = true;
        }

        skills.some((skill, idx) => {
            if ('' == skill) {
                alert("자격 및 기술을 입력해주세요.");
                if (Step2Ref.Skill.current[idx] !== null) Step2Ref.Skill.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if (!flag) {
            return;
        }

        if ('' == memData.Comment) {
            alert("자기 소개를 입력해주세요.");
            if (Step2Ref.Comment.current !== null) Step2Ref.Comment.current.focus();
            return;
        }

        if ('' == memData.DetailComment) {
            alert("자기 소개를 입력해주세요.");
            if (Step2Ref.DetailComment.current !== null) Step2Ref.DetailComment.current.focus();
            return;
        }

        memData.BusinessHoursStart = startTime.format('HH:mm');
        memData.BusinessHoursEnd = endTime.format('HH:mm');

        careers.forEach((ca) => {
            memData.Careers.push({
                Title: ca.Title,
                StartAt: new Date(ca.StartDtStr.substring(0, 4) + '-' + ca.StartDtStr.substring(4, 6)),
                EndAt: new Date(ca.EndDtStr.substring(0, 4) + '-' + ca.EndDtStr.substring(4, 6))
            });
        });

        skills.forEach((skill) => {
            memData.Skills.push(skill);
        });

        console.log(memData);

        axios.post('/api/ProService/member', memData).then(response => {
            alert("프로필 등록이 완료되었습니다.");
            setUser(response.data);

            navigate('/');
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");

            return;
        });
    }

    useEffect(() => {
        setCareers([{Title: '', StartDt: null, EndDt: null, StartDtStr: '', EndDtStr: ''}]);
        setSkills(['']);
    }, []);

    return (
        <>
            <div className="member profile">
                {/* 타이틀 - 추가정보 */}
                <div className="profile-title">
                    <div className="profile_title">추가정보</div>
                </div>
                <form action="">
                    {/* 대표 활동 분야 */}
                    <div className="layout line type-profile">
                        <div className="wish_local purple">
                            <div className="title">대표 활동 분야</div>
                            <a className="choice _wish_expert" onClick={() => props.setCate(true)}>선택하기</a>
                        </div>
                        <div className="input_section wish_local-info">
                            {props.selectCate.length < 1 ? '분야를 선택하세요.' :
                                <a className="button close_type purple" onClick={() => clearWishPro()}>
                                <span className="wrap">
                                    <span className="name">{props.selectCate}</span>
                                    <span className="exit"></span>
                                </span>
                                </a>
                            }
                        </div>
                    </div>
                    {/* 상세 분야 */}
                    <div className="layout line type-profile">
                        <div className="wish_local purple">
                            <div className="title">상세 분야</div>
                        </div>
                        <div className="input_section wish_local-info">
                            {props.selectDetail.length < 1 ? '분야를 선택하세요.' :
                                props.selectDetail.map((wish, idx) => (
                                    <a key={idx} className="button close_type purple"
                                       onClick={() => deleteWishPro(wish)}>
                                    <span className="wrap">
                                        <span className="name">{wish}</span>
                                        <span className="exit"></span>
                                    </span>
                                    </a>
                                ))}
                        </div>
                    </div>

                    {/* 연락 가능 시간 */}
                    <div className="layout line type-profile">
                        <div className="wish_local">
                            <div className="title">연락 가능시간</div>
                            { // @ts-ignore
                                <div className="choice checkbox">
                                    <span className="choice_name">주말 제외</span>
                                    <span className="choice_check">
                                    <input type="checkbox" checked={isExceptWeekend} onChange={handleChkChange}/>
                                </span>
                                </div>
                            }
                        </div>
                        <div className="input_section wish_local-info">
                            <div className="time_time">
                                <div className="time">
                                    <TimePicker className="input basic" format="HH:mm" placeholder="HH24:MI"
                                                value={startTime} ref={Step2Ref.BusinessHoursStart}
                                                onChange={(time) => setStartTime(time)}/>
                                </div>
                                <div className="dash"><img src={dash} alt=""/></div>
                                <div className="time">
                                    <TimePicker className="input basic" format="HH:mm" placeholder="HH24:MI"
                                                value={endTime} ref={Step2Ref.BusinessHoursEnd}
                                                onChange={(time) => setEndTime(time)}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 경력 */}
                    {/* 추가하기 클릭시 노출될 디자인 필요 */}
                    <div className="layout line type-profile">
                        <div className="wish_local purple">
                            <div className="title">경력</div>
                            <a className="choice _carrer" onClick={() => addCareer()}>추가하기</a>
                        </div>
                        {careers.map((career, idx) => (
                            <div key={idx} className="input_section">
                                <input className="input basic" type="text" placeholder="경력을 입력해주세요"
                                       value={career.Title} onChange={(event) => setCareerTitle(event, idx)}
                                       ref={ref => {
                                           Step2Ref.CareerTitle.current[idx] = ref
                                       }}/>
                                <RangePicker picker="month" format="YYYYMM" className="input basic"
                                             style={{marginTop: '5px', marginBottom: '10px'}}
                                             value={[career.StartDt, career.EndDt]} allowEmpty={[false, true]}
                                             onChange={(dates, dateStrings) => setCareerDate(dates, dateStrings, idx)}
                                             placeholder={['시작년월(YYYYMM)', '종료년월(YYYYMM)']}
                                             ref={ref => {
                                                 Step2Ref.CareerDt.current[idx] = ref
                                             }} separator={'~'}/>
                            </div>
                        ))}
                    </div>

                    {/* 자격 및 기술 */}
                    {/* 추가하기 클릭시 노출될 디자인 필요 */}
                    <div className="layout line type-profile">
                        <div className="wish_local purple">
                            <div className="title">자격 및 기술</div>
                            <a className="choice _skill" onClick={() => addSkill()}>추가하기</a>
                        </div>
                        <div className="input_section wish_local-info">
                            {skills.map((skill, idx) => (
                                <input className="input basic" type="text" value={skill} key={idx}
                                       ref={ref => {
                                           Step2Ref.Skill.current[idx] = ref
                                       }}
                                       placeholder="보유 자격 및 기술 사항을 입력해주세요" style={{marginBottom: '5px'}}
                                       onChange={(event) => setSkills(skills.map((sk, i) => (
                                           i === idx ? event.target.value : sk
                                       )))}/>
                            ))}
                        </div>
                    </div>

                    {/* 자기 소개 */}
                    <div className="profile-title">
                        <div className="profile_title">자기 소개</div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="input_section">
                            <input type="text" className="input basic" placeholder="" ref={Step2Ref.Comment}
                                   name="Comment" value={step2Data.Comment} onChange={handleStep2Change}/>
                        </div>
                    </div>

                    <div className="profile-title">
                        <div className="profile_title">상세 소개</div>
                    </div>
                    <div className="layout line type-profile">
                        <div className="input_section">
                            { // @ts-ignore
                                <textarea className="textarea basic" placeholder="" ref={Step2Ref.DetailComment}
                                          name="DetailComment" value={step2Data.DetailComment}
                                          onChange={handleStep2Change}>
                                </textarea>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className="bottom chap">
                <a onClick={() => RegistProProfile()} className="button full purple">등록완료</a>
            </div>
        </>
    );
}

function DivStep(props: {
    step: number, onNext: Function, setCate: setPopupState, selectCate: string, selectDetail: string[],
    setSelectCate: setStrState, setSelectDetail: setStrArrState, userType: string, setUserType: setStrState
}) {
    if (props.step == 0) {
        return (<Step1 onNext={props.onNext} userType={props.userType} setUserType={props.setUserType}/>);
    } else if (props.step == 1) {
        return (<Step2 setCate={props.setCate} selectCate={props.selectCate} selectDetail={props.selectDetail}
                       setSelectCate={props.setSelectCate} setSelectDetail={props.setSelectDetail}/>);
    }

    throw new Error(`[DivStep]: ${props.step} not implemented.`)
}

function DivComplete(props: { index: number, step: number }) {
    if (props.index <= props.step) {
        return (<li className="complete"></li>);
    }

    return (<li></li>);
}

function ProProfileAdd() {
    const [step, setStep] = useState(0);
    const [wishPro, setWishPro] = useState(false);
    const [wishCateSel, setWishCateSel] = useState<string>('');
    const [wishDetailSel, setWishDetailSel] = useState<string[]>([]);
    const [userType, setUserType] = useState<string>("Individual");
    const stepArr = [0, 1];

    const selectCate = (value: string) => {
        setWishCateSel(value);
        memData.RepresentativeActivitiesField = [value];
    };

    const selectDetail = (values: string[]) => {
        setWishDetailSel(values);
        memData.DetailedField = values;
    };

    const onBack = () => {
        if (step == 0) {
            history.back();
        }

        setStep(step - 1);
        setUserType(userGbn);
        
        memData = {
            IsBusiness: userGbn === "Organizations",
            Name: '',
            ProfileImg: '',
            IdentificationNumber: '',
            BusinessOwner: '',
            Address1: '',
            Address2: '',
            ContactNumber: '',
            BankNumber: '',
            BankCode: '',
            BankOwner: '',
            RepresentativeActivitiesField: [],
            DetailedField: [],
            YearOfService: 0,
            BusinessHoursStart: '',
            BusinessHoursEnd: '',
            ExceptForWeekEnds: false,
            Skills: [],
            Careers: [],
            Comment: '',
            DetailComment: ''
        };
    };

    const handleNext = () => {
        let regNum = /^[0-9]+$/;

        if (userGbn === "Individual") {
            memData.IsBusiness = false;

            if ('' == memData.Name) {
                alert("이름을 입력해주세요.");
                if (IndividualRef.Name.current !== null) IndividualRef.Name.current.focus();
                return;
            }

            /*if ('' == memData.IdentificationNumber) {
                alert("주민번호를 입력해주세요.");
                if (IndividualRef.IdentificationNumber.current !== null)
                    IndividualRef.IdentificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("주민번호를 숫자로만 입력해주세요.");
                if (IndividualRef.IdentificationNumber.current !== null)
                    IndividualRef.IdentificationNumber.current.focus();
                return;
            }*/

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                if (IndividualRef.BankNumber.current !== null) IndividualRef.BankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                if (IndividualRef.BankNumber.current !== null) IndividualRef.BankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                if (IndividualRef.BankCode.current !== null) IndividualRef.BankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                if (IndividualRef.BankOwner.current !== null) IndividualRef.BankOwner.current.focus();
                return;
            }
        } else {
            memData.isBusiness = true;

            if ('' == memData.Name) {
                alert("기업명을 입력해주세요.");
                if (OrganizationsRef.Name.current !== null) OrganizationsRef.Name.current.focus();
                return;
            }

            if ('' == memData.IdentificationNumber) {
                alert("사업자번호를 입력해주세요.");
                if (OrganizationsRef.IdentificationNumber.current !== null)
                    OrganizationsRef.IdentificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("숫자로만 입력해주세요.");
                if (OrganizationsRef.IdentificationNumber.current !== null)
                    OrganizationsRef.IdentificationNumber.current.focus();
                return;
            }

            if (!isCompany) {
                alert("사업자번호를 인증해주세요.");
                if (OrganizationsRef.IdentificationNumber.current !== null)
                    OrganizationsRef.IdentificationNumber.current.focus();
                return;
            }

            if ('' == memData.BusinessOwner) {
                alert("대표자명을 입력해주세요.");
                if (OrganizationsRef.BusinessOwner.current !== null) OrganizationsRef.BusinessOwner.current.focus();
                return;
            }

            if ('' == memData.Address1) {
                alert("주소를 입력해주세요.");
                if (OrganizationsRef.Address1.current !== null) OrganizationsRef.Address1.current.focus();
                return;
            }

            if ('' == memData.Address2) {
                alert("상세주소를 입력해주세요.");
                if (OrganizationsRef.Address2.current !== null) OrganizationsRef.Address2.current.focus();
                return;
            }

            if ('' == memData.ContactNumber) {
                alert("담당자 연락처를 입력해주세요.");
                if (OrganizationsRef.ContactNumber.current !== null) OrganizationsRef.ContactNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.ContactNumber)) {
                alert("담당자 연락처를 숫자로만 입력해주세요.");
                if (OrganizationsRef.ContactNumber.current !== null) OrganizationsRef.ContactNumber.current.focus();
                return;
            }

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                if (OrganizationsRef.BankNumber.current !== null) OrganizationsRef.BankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                if (OrganizationsRef.BankNumber.current !== null) OrganizationsRef.BankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                if (OrganizationsRef.BankCode.current !== null) OrganizationsRef.BankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                if (OrganizationsRef.BankOwner.current !== null) OrganizationsRef.BankOwner.current.focus();
                return;
            }
        }

        if (!isAgree) {
            alert("약관에 동의해야 합니다.");
            return;
        }

        setStep(step + 1);
    };

    if (wishPro) {
        return (<WishExpert setWishPro={setWishPro} setSelect={selectCate} setSelectDetail={selectDetail}/>);
    } else {
        return (
            <div>
                <div className="contents mobile">
                    <header className="header-member">
                        <div className="header_left">
                            <a onClick={() => onBack()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">전문가 프로필 등록</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                        </div>
                    </header>
                    <ul className="navigation progress purple">
                        {stepArr.map((_, index) => (
                            <DivComplete key={index} index={index} step={step}/>
                        ))}
                    </ul>

                    <DivStep step={step} onNext={handleNext} setCate={setWishPro} selectCate={wishCateSel}
                             selectDetail={wishDetailSel} setSelectCate={setWishCateSel}
                             setSelectDetail={setWishDetailSel} userType={userType} setUserType={setUserType}/>
                </div>
            </div>
        );
    }
}

export default ProProfileAdd;