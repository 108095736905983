import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";
import ReactQuill from "react-quill";

import header_close from "img/m/header-close.svg";
import file_basic from "img/m/file-basic.svg";
import WishLocation from "../member/wish-location";
import WishAdvertisement from "../member/wish-ad";
import img2 from "../../img/m/intro-2.png";

function AddKakao() {
    const handleOnClose = () => {
    };

    return (<AddKakaoImpl onClose={() => handleOnClose()}/>);
}

function DivRadio(props: { id: string, value: string, checkedValue: string, title: string, onChange: (value: string) => void }) {
    const checked = props.value === props.checkedValue;

    return (
        <Form.Check bsPrefix={"#"}>
            <Form.Check.Input type="radio" id={props.id}
                              className="d-none"
                              value={props.value}
                              checked={checked}
                              onChange={e => props.onChange(e.target.value)}
                              bsPrefix={"#"}
            ></Form.Check.Input>
            <Form.Check.Label htmlFor={props.id} className={`button ad_channel blue ${checked ? "on" : ""}`}>
                <span>{props.title}</span>
            </Form.Check.Label>
        </Form.Check>
    );
}

type richEditorSetState = React.Dispatch<React.SetStateAction<string | undefined>>;

function DivRichEditor(props: { value: string | undefined, setValue: richEditorSetState }) {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}],
            [{'size': ['small', false, 'large', 'huge']}],
            ['image'],
            [{'list': 'bullet'}, {'list': 'ordered'}],
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'color',
        'size',
        'image',
        'bullet', 'list',
    ];

    return (
        <ReactQuill theme="snow" value={props.value} onChange={props.setValue}
                    modules={modules} formats={formats}/>
    );
}

function AddKakaoImpl(props: { onClose: () => void }) {
    const [campaignCategory, setCampaignCategory] = useState("category1");
    const [loc, setLoc] = useState(false);
    const [adLoc, setAdLoc] = useState(false);
    const [locSel, setLocSel] = useState<string[]>([]);
    const [adLocSel, setAdLocSel] = useState<string[]>([]);
    const [job, setJob] = useState("전체");
    const [title, setTitle] = useState("");
    const [contents, setContents] = useState<string>();
    const [campaignUrl, setCampaignUrl] = useState("https://");
    const [profileImage, setProfileImage] = useState<any[]>([null, null, null]);
    const profileImageReader = [new FileReader(), new FileReader(), new FileReader()];

    profileImageReader.forEach((r, i) => {
        r.onloadend = (e) => {
            setProfileImage(profileImage.map((p, j) => (i == j ? r.result : p)));
        }
    });

    const handleProfileImageChange = (e: any, i: number) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader[i].readAsDataURL(img);
        }
    }
    
    const handleCampaignCategoryChange = (value: string) => {
        setCampaignCategory(value);
    }

    const [gender, setGender] = useState("man");
    const handleGenderChange = (value: string) => {
        setGender(value);
    }

    const [ages, setAges] = useState("ages1");
    const handleAgesChange = (value: string) => {
        setAges(value);
    }

    if(loc) {
        return (<WishLocation setWishLoc={setLoc} setSelect={setLocSel}/>);
    } else if(adLoc) {
        return (<WishLocation setWishLoc={setAdLoc} setSelect={setAdLocSel}/>);
    } else {
        return (
            <div>
                <div className="contents mobile">
                    <header className="header-member">
                        <div className="header_left">
                            <span className="location">카카오플러스 친구 광고 신청</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => props.onClose()}><img className="close" src={header_close} alt=""/></a>
                        </div>
                    </header>

                    <div className="write pro">
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="wish_local">
                                    <div className="title">거주 지역</div>
                                    <a className="choice _wish_local" onClick={() => setLoc(true)}>선택하기</a>
                                </div>
                                <div className="input_section wish_local-info">
                                    {locSel.length > 0 ? locSel.join(', ') : '지역을 선택하세요.'}
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="wish_local">
                                    <div className="title">광고 지역</div>
                                    <a className="choice _wish_local" onClick={() => setAdLoc(true)}>선택하기</a>
                                </div>
                                <div className="input_section wish_local-info">
                                    {adLocSel.length > 0 ? adLocSel.join(', ') : '지역을 선택하세요.'}
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="profile_name">광고 카테고리</div>
                                <div className="input_section">
                                    <div className="grid grid-3">
                                        <DivRadio id={"category1"} value={"category1"} checkedValue={campaignCategory}
                                                  title={"광고"} onChange={handleCampaignCategoryChange}/>
                                        <DivRadio id={"category2"} value={"category2"} checkedValue={campaignCategory}
                                                  title={"사람찾기"} onChange={handleCampaignCategoryChange}/>
                                        <DivRadio id={"category3"} value={"category3"} checkedValue={campaignCategory}
                                                  title={"애완동물찾기"} onChange={handleCampaignCategoryChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="profile_name">성별</div>
                                <div className="input_section">
                                    <div className="grid grid-2">
                                        <DivRadio id={"man"} value={"man"} checkedValue={gender} title={"남"}
                                                  onChange={handleGenderChange}/>
                                        <DivRadio id={"woman"} value={"woman"} checkedValue={gender} title={"여"}
                                                  onChange={handleGenderChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="profile_name">연령대</div>
                                <div className="input_section">
                                    <div className="grid grid-3">
                                        <DivRadio id={"ages1"} value={"ages1"} checkedValue={ages} title={"10대"}
                                                  onChange={handleAgesChange}/>
                                        <DivRadio id={"ages2"} value={"ages2"} checkedValue={ages} title={"20대"}
                                                  onChange={handleAgesChange}/>
                                        <DivRadio id={"ages3"} value={"ages3"} checkedValue={ages} title={"30대"}
                                                  onChange={handleAgesChange}/>
                                        <DivRadio id={"ages4"} value={"ages4"} checkedValue={ages} title={"40대"}
                                                  onChange={handleAgesChange}/>
                                        <DivRadio id={"ages5"} value={"ages5"} checkedValue={ages} title={"50대"}
                                                  onChange={handleAgesChange}/>
                                        <DivRadio id={"ages6"} value={"ages6"} checkedValue={ages} title={"60대 이상"}
                                                  onChange={handleAgesChange}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="profile_name">직업</div>
                                <div className="write-select">
                                    <Form.Control as="select" bsPrefix={"#"}
                                                  value={job}
                                                  onChange={e => setJob(e.target.value)}>
                                        <option value="전체">전체</option>
                                        <option value="사무직">사무직</option>
                                        <option value="서비스직">서비스직</option>
                                        <option value="전문직">전문직</option>
                                        <option value="공무원">공무원</option>
                                        <option value="기타">기타</option>
                                    </Form.Control>
                                </div>
                            </div>
                        </div>
                        <div className="section-write">
                            <div className="title">광고명</div>
                            <Form.Control type="text" className="write-input" placeholder="광고명을 입력해주세요."
                                          bsPrefix={"#"} value={title} onChange={e => setTitle(e.target.value)}/>
                        </div>
                        <div className="section-write">
                            <div className="title">광고 내용</div>
                            {/* editor 영역입니다. text area로 대체해둠.*/}
                            <div className="write-textarea">
                                <DivRichEditor value={contents} setValue={setContents}/>
                            </div>
                        </div>

                        <div className="section-write">
                            <div className="title">광고 썸네일</div>
                            <div className="grid grid-3">
                                <div>
                                    <div className="file_upload ad_thumbnail">
                                        <div className="squre">
                                            {profileImage[0] === null ? <img className="basic" src={file_basic} alt=""/>
                                                : <img className="photo" src={profileImage[0].toString()} alt="" style={{objectFit: 'contain'}}/>}
                                        </div>
                                        <input type="file" accept="image/*" onChange={(e) => handleProfileImageChange(e, 0)}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="file_upload ad_thumbnail">
                                        <div className="squre">
                                            {profileImage[1] === null ? <img className="basic" src={file_basic} alt=""/>
                                                : <img className="photo" src={profileImage[1].toString()} alt="" style={{objectFit: 'contain'}}/>}
                                        </div>
                                        <input type="file" accept="image/*" onChange={(e) => handleProfileImageChange(e, 1)}/>
                                    </div>
                                </div>
                                <div>
                                    <div className="file_upload ad_thumbnail">
                                        <div className="squre">
                                            {profileImage[2] === null ? <img className="basic" src={file_basic} alt=""/>
                                                : <img className="photo" src={profileImage[2].toString()} alt="" style={{objectFit: 'contain'}}/>}
                                        </div>
                                        <input type="file" accept="image/*" onChange={(e) => handleProfileImageChange(e, 2)}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-write">
                            <div className="layout line type-profile">
                                <div className="profile_name">광고 URL</div>
                                <div className="input_section">
                                    <div className="input button-right">
                                        <Form.Control bsPrefix={"#"} className="input basic" type="text"
                                                      value={campaignUrl}
                                                      onChange={e => setCampaignUrl(e.target.value)}/>
                                        <button className="input button">등록</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottom chap">
                        {/*TODO: 링크 연결 필요*/}
                        <a className="button full blue">광고신청</a>
                    </div>
                </div>
            </div>
        );
    }
}

export function AddKakaoModal() {
    const [show, setShow] = useState(false);

    return (
        <>
            <a className="ad_button" onClick={() => setShow(true)}>
                <span>카카오플러스 친구 광고 신청</span>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AddKakaoImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export function AddKakaoModal2() {
    const [show, setShow] = useState(false);

    return (
        <>
            <a onClick={() => setShow(true)} style={{width: '48%'}}>
                <div className="box-link service">
                    <div className="text" style={{width: '100%'}}>
                        <div className="title">카카오톡<br/>광고하기</div>
                        <div className="icon" style={{textAlign: 'right', width: '100%', marginTop: '-7px'}}>
                            <img src={img2} style={{height: '29px'}} alt="kakao ad"/>
                        </div>
                    </div>
                </div>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AddKakaoImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddKakao;