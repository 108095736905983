import React, {useEffect, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import request_influ from "img/m/request-influe.png";
import request_pro from "img/m/request-pro.png";
import request_check_blue from "img/m/request-check-blue.png";
import header_close from "img/m/header-close.svg";
import icon_calc from "img/m/icon-calc.svg";
import icon_calc_on from "img/m/icon-calc_on.svg";
import axios from "axios";
import {getLocalSession} from "../../modules/localSession";
import {UserType} from "../../types/user-type";
import {AdvertisingType} from "../../types/advertising-type";
import {PlatformType} from "../../types/platform";
import {DatePicker} from "antd";

const {RangePicker} = DatePicker;

let userName: string = "";

let estimateData: any = {
    RequestTargetUser: UserType.None,
    Title: '',
    Categories: [],
    PlatformType: -1,
    AdvertisingType: AdvertisingType.None,
    WorkingDay: 0,
    MinBudget: 0,
    MaxBudget: 0,
    StartAt: null,
    EndAt: null
};

function AdRequest() {
    const handleOnClose = () => {
    };

    return (<AdRequestImpl onClose={() => handleOnClose()}/>);
}

function DivCheckBox(props: {
    id: number, name: string, checked: boolean, onChange: (id: number, name: string, chk: boolean) => void
}) {
    return (
        <Form.Check type="checkbox" className={`button ad_channel ${props.checked ? "on" : ""}`}>
            <input type="checkbox" className="d-none" checked={props.checked} id={`category${props.id}`}
                   onChange={() => props.onChange(props.id, props.name, !props.checked)}/>
            <label htmlFor={`category${props.id}`}>
                <span>{props.name}</span>
            </label>
        </Form.Check>
    );
}

function DivRadio(props: { id: string, value: string, checkedValue: string, title: string, onChange: (value: string) => void }) {
    const checked = props.value === props.checkedValue;

    return (
        <Form.Check className={checked ? "item selected" : "item"}>
            <Form.Check.Label htmlFor={props.id} className={"w-100 text-start"}>
                <span className="name">{props.title}</span>
            </Form.Check.Label>
            <Form.Check.Input type="radio" id={props.id}
                              className="circle"
                              value={props.value}
                              checked={checked}
                              onChange={e => props.onChange(e.target.value)}
                              bsPrefix={"#"}
            ></Form.Check.Input>
        </Form.Check>
    );
}

function Step1(props: { onNext: Function }) {
    const [userType, setUserType] = useState<UserType>(UserType.None);
    const style = { width: "63rem", height: "56rem" };
    
    const handleUserType = (type: UserType) => {
        setUserType(type);
        estimateData.RequestTargetUser = type;
    };
    
    return (
        <>
            <div className="request step01">
                <div className="ment">
                    <p>{userName} 고객님,</p>
                    <p>견적을 요청할 사람을 선택해주세요.</p>
                </div>
                <div className="request-human">
                    <a className="item">
                        <img src={request_influ} alt=""/>
                        <p>인플루언서</p>
                    </a>
                    <a className="item">
                        <img src={request_pro} alt=""/>
                        <p>전문가</p>
                    </a>
                </div>
                <div className="d-flex justify-content-center position-relative" style={{top: "-54rem"}}>
                    <a style={userType === UserType.Influencer ? {...style, borderRadius: "10px", border: "1px solid #000"} : style}
                       onClick={() => handleUserType(UserType.Influencer)}></a>
                    <a style={userType === UserType.Pro ? {...style, borderRadius: "10px", border: "1px solid #000"} : style}
                       onClick={() => handleUserType(UserType.Pro)}></a>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step2(props: { onNext: Function }) {
    const [title, setTitle] = useState("");

    const changeTitle = (value: string) => {
        setTitle(value);
        estimateData.Title = value;
    };

    return (
        <>
            <div className="request step02">
                <div className="ment">
                    요청서 제목을 입력해주세요.
                </div>
                <div className="request-input">
                    <Form.Control type="text" placeholder="제목을 간략하게 입력해주세요." bsPrefix={"#"}
                                  value={title} onChange={e => changeTitle(e.target.value)}/>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step3(props: { onNext: Function }) {
    const [categories, setCategories] = useState<any[]>([]);

    const chkCategory = (id: number, name: string, chk: boolean) => {
        let cates = categories.map(cate => (
            id === cate.categoryId ? {...cate, checked: chk} : cate
        ));
        setCategories(cates);
        estimateData.Categories = cates.filter(c => c.checked).map(c => c.name);
    };

    useEffect(() => {
        axios.get(`api/Advertiser/mainCategory`)
            .then(response => {
                let datas: any[] = response.data;

                setCategories(datas.map(data => (
                    {...data, checked: false}
                )));
            });
    }, []);

    return (
        <>
            <div className="request step03">
                <div className="ment">
                    <div className="title">어떤 카테고리 견적을<br/>받고 싶으신가요?</div>
                </div>
                <div className="request-checkgroup">
                    <div className="grid grid-3">
                        {categories.map((category, idx) => (
                            <div key={idx}>
                                <DivCheckBox id={category.categoryId} name={category.name}
                                             onChange={chkCategory} checked={category.checked}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step4(props: { onNext: Function }) {
    const [channel, setChannel] = useState<string>("-1");
    const handleChannelChange = (value: string) => {
        setChannel(value);
        estimateData.PlatformType = Number(value);
    }

    return (
        <>
            <div className="request step04">
                <div className="ment">
                    <div className="title">광고채널을 선택해주세요.</div>
                </div>
                <div className="request-channel_select blue">
                    <DivRadio id={"channel1"} value={(PlatformType.Instagram).toLocaleString()} checkedValue={channel}
                              title={"인스타그램"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel2"} value={(PlatformType.Facebook).toLocaleString()} checkedValue={channel}
                              title={"페이스북"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel3"} value={(PlatformType.NaverBlog).toLocaleString()} checkedValue={channel}
                              title={"네이버 블로그"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel4"} value={(PlatformType.NaverCafe).toLocaleString()} checkedValue={channel}
                              title={"네이버 카페"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel5"} value={(PlatformType.KakaoFriend).toLocaleString()} checkedValue={channel}
                              title={"카카오톡"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel5"} value={(PlatformType.Youtube).toLocaleString()} checkedValue={channel}
                              title={"유튜브"} onChange={handleChannelChange}/>
                    <DivRadio id={"channel5"} value={(PlatformType.Etc).toLocaleString()} checkedValue={channel}
                              title={"기타"} onChange={handleChannelChange}/>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step5(props: { onNext: Function }) {
    const [campaignType, setCampaignType] = useState("0");
    const handleCampaignTypeChange = (value: string) => {
        setCampaignType(value);
        estimateData.AdvertisingType = Number(value);
    }

    return (
        <>
            <div className="request step05">
                <div className="ment">
                    <div className="title">광고방식을 선택해주세요.</div>
                </div>
                <div className="request-channel_select blue">
                    <DivRadio id={"campaignType1"} value={AdvertisingType.FeedUpload.toLocaleString()}
                              checkedValue={campaignType} title={"피드 업로드"} onChange={handleCampaignTypeChange}/>
                    <DivRadio id={"campaignType2"} value={AdvertisingType.Review.toLocaleString()}
                              checkedValue={campaignType} title={"후기작성"} onChange={handleCampaignTypeChange}/>
                    <DivRadio id={"campaignType3"} value={AdvertisingType.ExperienceGroup.toLocaleString()}
                              checkedValue={campaignType} title={"체험단"} onChange={handleCampaignTypeChange}/>
                    <DivRadio id={"campaignType4"} value={AdvertisingType.RequestKakaoFriend.toLocaleString()}
                              checkedValue={campaignType} title={"카카오톡 친구추천"} onChange={handleCampaignTypeChange}/>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step6(props: { onNext: Function }) {
    const [date, setDate] = useState<any>({StartDt: null, EndDt: null});

    const changeDate = (dates: any, dateStrs: [string, string]) => {
        const [sDt, eDt] = dates;
        const [startDtStr, endDtStr] = dateStrs;

        setDate({StartDt: sDt, EndDt: eDt});

        estimateData.StartAt = new Date(startDtStr);
        estimateData.EndAt = new Date(endDtStr);

        const diff = estimateData.EndAt.getTime() - estimateData.StartAt.getTime();
        estimateData.WorkingDay = diff / (1000*60*60*24) + 1;
    };

    return (
        <>
            <div className="request step06">
                <div className="ment">
                    <div className="title">광고기간을 선택해주세요.</div>
                </div>
                <div className="request-channel_select blue">
                    <RangePicker picker="date" format="YYYY-MM-DD" className={"w-100 text-start"}
                                 value={[date.StartDt, date.EndDt]} allowEmpty={[false, false]}
                                 onChange={(dates, dateStrings) => changeDate(dates, dateStrings)}
                                 placeholder={['YYYY-MM-DD', 'YYYY-MM-DD']} separator={'~'}/>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>다음</a>
            </div>
        </>
    );
}

function Step7(props: { onNext: Function }) {
    const [price, setPrice] = useState("100~500");

    const handlePriceChange = (value: string) => {
        setPrice(value);
        const arr = value.split('~');
        estimateData.MinBudget = Number(arr[0]);
        estimateData.MaxBudget = Number(arr[1]);
    }

    return (
        <>
            <div className="request step07">
                <div className="ment">
                    <div className="title">광고 예상 금액을 선택해주세요.</div>
                </div>
                <div className="request-channel_select blue">
                    <DivRadio id={"price1"} value={"100~500"} checkedValue={price} title={"100~500원"}
                              onChange={handlePriceChange}/>
                    <DivRadio id={"price2"} value={"500~1000"} checkedValue={price} title={"500~1,000원"}
                              onChange={handlePriceChange}/>
                    <DivRadio id={"price3"} value={"1000~1500"} checkedValue={price} title={"1,000~1,500원"}
                              onChange={handlePriceChange}/>
                    <DivRadio id={"price4"} value={"1500~2000"} checkedValue={price} title={"1,500~2,000원"}
                              onChange={handlePriceChange}/>
                    <DivRadio id={"price5"} value={"2000~2500"} checkedValue={price} title={"2,000~2,500원"}
                              onChange={handlePriceChange}/>
                    <DivRadio id={"price6"} value={"0~0"} checkedValue={price} title={"협의"}
                              onChange={handlePriceChange}/>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => props.onNext()}>견적 의뢰하기</a>
            </div>
        </>
    );
}

function Step8() {
    const goRequireList = () => {
        location.href = "/requirements-list/ad-requirements-list";
    };
    
    return (
        <>
            <div className="request step08">
                <div className="request-ending">
                    <img className="request-check" src={request_check_blue} alt=""/>
                    <div className="ment">
                        <p>{userName} 고객님,</p>
                        <p>견적서 의뢰가 완료되었습니다.</p>
                    </div>
                </div>
            </div>
            <div className="bottom chap">
                <a className="button full black" onClick={() => goRequireList()}>견적 요청서 확인하기</a>
            </div>
        </>
    );
}

function DivStep(props: { step: number, onNext: Function }) {
    if (props.step == 0) {
        return (<Step1 onNext={props.onNext}/>);
    } else if (props.step == 1) {
        return (<Step2 onNext={props.onNext}/>);
    } else if (props.step == 2) {
        return (<Step3 onNext={props.onNext}/>);
    } else if (props.step == 3) {
        return (<Step4 onNext={props.onNext}/>);
    } else if (props.step == 4) {
        return (<Step5 onNext={props.onNext}/>);
    } else if (props.step == 5) {
        return (<Step6 onNext={props.onNext}/>);
    } else if (props.step == 6) {
        return (<Step7 onNext={props.onNext}/>);
    } else if (props.step == 7) {
        return (<Step8/>);
    }

    throw new Error(`[DivStep]: ${props.step} not implemented.`)
}

function DivComplete(props: { index: number, step: number }) {
    if (props.index <= props.step) {
        return (<li className="complete"></li>);
    }

    return (<li></li>);
}

function AdRequestImpl(props: { onClose: () => void }) {
    const [step, setStep] = useState(0);
    const stepArr = [0, 1, 2, 3, 4, 5, 6, 7];

    const handleNext = () => {
        if(step === 0) {
            if(estimateData.RequestTargetUser === UserType.None) {
                alert("견적을 요청할 사람을 선택해주세요.");
                return;
            }
        } else if(step === 1) {
            if(estimateData.Title === '') {
                alert("요청서 제목을 입력해주세요.");
                return;
            }
        } else if(step === 2) {
            if(1 > estimateData.Categories.length) {
                alert("카테고리를 선택해주세요.");
                return;
            }
        } else if(step === 3) {
            if(0 > estimateData.PlatformType) {
                alert("광고 채널을 선택해주세요.");
                return;
            }
        } else if(step === 4) {
            if(AdvertisingType.None === estimateData.AdvertisingType) {
                alert("광고 방식을 선택해주세요.");
                return;
            }
        } else if(step === 5) {
            if(1 > estimateData.WorkingDay) {
                alert("광고 기간을 선택해주세요.");
                return;
            }
        } else if(step === 6) {
            axios.post('/api/Advertiser/estimate', estimateData).catch(error => {
                console.log(error);
                alert("시스템 오류가 발생했습니다.");
                return;
            });
        }

        console.log(estimateData);
        setStep(step + 1);
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">견적 요청하기</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => props.onClose()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <ul className="navigation progress blue">
                    {stepArr.map((_, index) => (
                        <DivComplete key={index} index={index} step={step}/>
                    ))}
                </ul>

                <DivStep step={step} onNext={handleNext}/>
            </div>
        </div>
    );
}

export function AdRequestModal(props: { curMenu: string }) {
    const [show, setShow] = useState(false);
    const session = getLocalSession();
    
    const showModal = () => {
        if(session) {
            axios.get(`api/Advertiser/profile/${session.id}`)
                .then(response => {
                    userName = response.data.name;
                    estimateData = {
                        RequestTargetUser: UserType.None,
                        Title: '',
                        Categories: [],
                        PlatformType: -1,
                        AdvertisingType: AdvertisingType.None,
                        WorkingDay: 0,
                        MinBudget: 0,
                        MaxBudget: 0,
                        StartAt: null,
                        EndAt: null
                    };
                    setShow(true);
                })
                .catch(error => {
                    if (403 == error.response.status) {
                        alert("광고주만 견적 요청을 할 수 있습니다.");
                    } else {
                        console.log(error);
                    }
                });
        } else {
            alert("로그인을 해주세요.");
            location.href = "/member/login";
        }
    }

    return (
        <>
            <a onClick={() => showModal()}>
                <img src={props.curMenu === "calc" ? icon_calc_on : icon_calc} alt=""/>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AdRequestImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdRequest;