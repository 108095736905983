import React from "react";

import header_back from "img/m/header-back.svg";
import {Link} from "react-router-dom";

function MySettingHome() {
    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">설정</span>
                    </div>
                </header>
                <div className="section">
                    <ul className="board">
                        {/*<li>
                            <Link to={"/my/my-setting"}><p className="menu">알림</p></Link>
                        </li>*/}
                        <li>
                            <Link to={"/my/my-terms"}><p className="menu">이용약관</p></Link>
                        </li>
                        {/*<li>
                            <Link to={"/my/my-notice-list"}><p className="menu">공지사항</p></Link>
                        </li>
                        <li>
                            <Link to={"/my/my-mtm"}><p className="menu">1:1문의</p></Link>
                        </li>
                        <li>
                            <Link to={"/my/my-faq"}><p className="menu">FAQ</p></Link>
                        </li>*/}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MySettingHome;