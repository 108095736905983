import React, {useEffect, useRef, useState} from "react";
import {Nav, Tab} from "react-bootstrap";

import header_back from "img/m/header-back.svg";
import file_photo from "img/m/file-photo.svg";
import dummy_detail02 from "img/m/dummy-detail02.png";
import file_speaker from "img/m/file-speaker.svg";
import {initData, RegisterInfluencerMemberRequest, UpdateInfluencerMemberRequest} from "../../types/influencer-request";
import axios from "axios";
import {getLocalSession, removeLocalSession} from "../../modules/localSession";
import {getPlatformName, PlatformType} from "../../types/platform";
import {BankCode} from "../../utils/bank-code";
import {TimePicker} from "antd";
import dash from "../../img/m/dash.svg";
import WishLocation from "../member/wish-location";
import WishInfluencer from "../member/wish-influ";
import moment from "moment";
import {useNavigate} from "react-router-dom";

let memData: any = {};

type setStrArrState =  React.Dispatch<React.SetStateAction<string[]>>;

function DivAreaBadge(props: { areaName: string, setAreaName: setStrArrState }) {
    const deleteArea = () => {
        props.setAreaName(prevState => prevState.filter(item => item !== props.areaName));
    };

    return (
        <a onClick={() => deleteArea()} className="button close_type green">
            <span className="wrap">
                <span className="name">{props.areaName}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function DivInterestBadge(props: { interest: string, setInterest: setStrArrState }) {
    const deleteInterest = () => {
        props.setInterest(prevState => prevState.filter(item => item !== props.interest));
    };

    return (
        <a onClick={() => deleteInterest()} className="button close_type green">
            <span className="wrap">
                <span className="name">{props.interest}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function InfluProfileModify() {
    const session = getLocalSession();
    const [pwd, setPwd] = useState<string>("");
    const [repeatPwd, setRepeatPwd] = useState<string>("");
    const [profileData, setProfileData] = useState<UpdateInfluencerMemberRequest>();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [platformSel, setPlatformSel] = useState<any[]>([]);
    const [wishLoc, setWishLoc] = useState(false);
    const [wishInflu, setWishInflu] = useState(false);
    const [wishLocSel, setWishLocSel] = useState<string[]>([]);
    const [wishInfluSel, setWishInfluSel] = useState<string[]>([]);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [tab, setTab] = useState<string>("defaultInfo");
    const navigate = useNavigate();

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];

    const influMemberRef = {
        Name: useRef<HTMLInputElement>(null),
        Job: useRef<HTMLSelectElement>(null),
        Address1: useRef<HTMLInputElement>(null),
        Address2: useRef<HTMLInputElement>(null),
        IdentificationNumber: useRef<HTMLInputElement>(null),
        BankNumber: useRef<HTMLInputElement>(null),
        BankCode: useRef<HTMLSelectElement>(null),
        BankOwner: useRef<HTMLInputElement>(null),
        BusinessHoursStart: useRef<HTMLInputElement>(null),
        BusinessHoursEnd: useRef<HTMLInputElement>(null),
        YearOfService: useRef<HTMLInputElement>(null),
        Url: useRef<HTMLInputElement[]>([]),
        Comment: useRef<HTMLInputElement>(null),
        DetailComment: useRef<HTMLTextAreaElement>(null),
        pwd: useRef<HTMLInputElement>(null),
        repeatPwd: useRef<HTMLInputElement>(null)
    };

    useEffect(() => {
        axios.get(`api/MyInfluencer/detail`)
            .then(response => {
                let data = response.data;
                let plats = [];

                setProfileData(data);
                setProfileImage(data.profileImg);
                setWishLocSel(data.regionOfInterest);
                setWishInfluSel(data.areaOfInterest);
                setStartTime(moment(new Date(moment().format("YYYY-MM-DD") + " " + data.businessHoursStart)));
                setEndTime(moment(new Date(moment().format("YYYY-MM-DD") + " " + data.businessHoursEnd)));

                if(data.instagramUrl) {
                    plats.push({
                        id: PlatformType.Instagram,
                        name: getPlatformName(PlatformType.Instagram),
                        url: data.instagramUrl
                    });
                }

                if(data.facebookUrl) {
                    plats.push({
                        id: PlatformType.Facebook,
                        name: getPlatformName(PlatformType.Facebook),
                        url: data.facebookUrl
                    });
                }

                if(data.twitterUrl) {
                    plats.push({
                        id: PlatformType.Twitter,
                        name: getPlatformName(PlatformType.Twitter),
                        url: data.twitterUrl
                    });
                }

                if(data.naverBlogUrl) {
                    plats.push({
                        id: PlatformType.NaverBlog,
                        name: getPlatformName(PlatformType.NaverBlog),
                        url: data.naverBlogUrl
                    });
                }

                if(data.naverCafeUrl) {
                    plats.push({
                        id: PlatformType.NaverCafe,
                        name: getPlatformName(PlatformType.NaverCafe),
                        url: data.naverCafeUrl
                    });
                }

                if(data.kakaoStoryUrl) {
                    plats.push({
                        id: PlatformType.KakaoFriend,
                        name: getPlatformName(PlatformType.KakaoFriend),
                        url: data.kakaoStoryUrl
                    });
                }

                if(data.youtubeUrl) {
                    plats.push({
                        id: PlatformType.Youtube,
                        name: getPlatformName(PlatformType.Youtube),
                        url: data.youtubeUrl
                    });
                }

                if(data.etcUrl) {
                    plats.push({
                        id: PlatformType.Etc,
                        name: getPlatformName(PlatformType.Etc),
                        url: data.etcUrl
                    });
                }

                setPlatformSel(plats);

                memData = {
                    Job: data.job,
                    Name: data.name,
                    ProfileImg: data.profileImg,
                    IdentificationNumber: data.identificationNumber,
                    Address1: data.address1,
                    Address2: data.address2,
                    BankNumber: data.bankNumber,
                    BankCode: data.bankCode,
                    BankOwner: data.bankOwner,
                    RegionOfInterest: data.regionOfInterest,
                    AreaOfInterest: data.areaOfInterest,
                    YearOfService: data.yearOfService,
                    BusinessHoursStart: data.businessHoursStart,
                    BusinessHoursEnd: data.businessHoursEnd,
                    ExceptForWeekEnds: data.exceptForWeekEnds,
                    InstagramUrl: data.instagramUrl,
                    FacebookUrl: data.facebookUrl,
                    TwitterUrl: data.twitterUrl,
                    NaverBlogUrl: data.naverBlogUrl,
                    NaverCafeUrl: data.naverCafeUrl,
                    YoutubeUrl: data.youtubeUrl,
                    KakaoStoryUrl: data.kakaoStoryUrl,
                    EtcUrl: data.etcUrl,
                    Comment: data.comment,
                    DetailComment: data.detailComment
                };
            });
    }, []);

    if (profileData == null) {
        return (<></>);
    }

    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        setProfileImage(profileImageReader.result);
        memData.ProfileImg = profileImageReader.result || "";
    }

    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    }

    const handleDataChange = (e: any) => {
        let value = e.target.value;
        let targetName = e.target.name.charAt(0).toLowerCase() + e.target.name.substring(1);

        if("ExceptForWeekEnds" == e.target.name) {
            value = !profileData.exceptForWeekEnds;
        }
        setProfileData({
            ...profileData,
            [targetName]: value
        });

        memData[e.target.name] = value;
    };

    const selectPlatform = (platformType: number, platformName: string) => {
        if(platformSel.includes(platformSel.find(p => p.id === platformType))) {
            setPlatformSel(platformSel.filter(item => item.id !== platformType));
        } else {
            setPlatformSel([...platformSel, {id: platformType, name: platformName, url: ''}]);
        }
    };

    const handleUrlChange = (e: any, id: number) => {
        setPlatformSel(platformSel.map(platform => (
            platform.id === id ? {...platform, url: e.target.value} : platform
        )));
    };

    const logout = () => {
        axios.post("api/Account/logout")
            .then(response => {
                removeLocalSession();
                location.href = "/";
            });
    }

    const updateProfile = () => {
        let regNum = /^[0-9]+$/;

        if (!("" == pwd && "" == repeatPwd)) {
            if (pwd !== repeatPwd) {
                alert("비밀번호가 일치하지 않습니다.");
                setTab("defaultInfo");
                if (influMemberRef.pwd.current !== null) influMemberRef.pwd.current.focus();
                return;
            }
        }

        if('' == memData.Job) {
            alert("직업을 선택해주세요.");
            setTab("profileInfo");
            if(influMemberRef.Job.current !== null) influMemberRef.Job.current.focus();
            return;
        }

        if('' == memData.Address1) {
            alert("주소를 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.Address1.current !== null) influMemberRef.Address1.current.focus();
            return;
        }

        if('' == memData.Address2) {
            alert("상세주소를 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.Address2.current !== null) influMemberRef.Address2.current.focus();
            return;
        }

        if('' == memData.Name) {
            alert("이름을 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.Name.current !== null) influMemberRef.Name.current.focus();
            return;
        }

        /*if('' == memData.IdentificationNumber) {
            alert("주민번호를 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.IdentificationNumber.current !== null)
                influMemberRef.IdentificationNumber.current.focus();
            return;
        }

        if(!regNum.test(memData.IdentificationNumber)) {
            alert("주민번호를 숫자로만 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.IdentificationNumber.current !== null)
                influMemberRef.IdentificationNumber.current.focus();
            return;
        }*/

        if('' == memData.BankNumber) {
            alert("계좌번호를 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.BankNumber.current !== null) influMemberRef.BankNumber.current.focus();
            return;
        }

        if(!regNum.test(memData.BankNumber)) {
            alert("계좌번호를 숫자로만 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.BankNumber.current !== null) influMemberRef.BankNumber.current.focus();
            return;
        }

        if('' == memData.BankCode) {
            alert("은행명을 선택해주세요.");
            setTab("profileInfo");
            if(influMemberRef.BankCode.current !== null) influMemberRef.BankCode.current.focus();
            return;
        }

        if('' == memData.BankOwner) {
            alert("예금주를 입력해주세요.");
            setTab("profileInfo");
            if(influMemberRef.BankOwner.current !== null) influMemberRef.BankOwner.current.focus();
            return;
        }

        if (wishLocSel.length < 1) {
            alert("관심 지역을 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if (wishInfluSel.length < 1) {
            alert("관심 분야를 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if(!startTime) {
            alert("연락 가능 시간을 입력해주세요.");
            setTab("extraInfo");
            if(influMemberRef.BusinessHoursStart.current !== null) influMemberRef.BusinessHoursStart.current.focus();
            return;
        }

        if(!endTime) {
            alert("연락 가능 시간을 입력해주세요.");
            setTab("extraInfo");
            if(influMemberRef.BusinessHoursEnd.current !== null) influMemberRef.BusinessHoursEnd.current.focus();
            return;
        }

        if('' == memData.YearOfService.toLocaleString()) {
            alert("경력 년수를 입력해주세요.");
            setTab("extraInfo");
            if(influMemberRef.YearOfService.current !== null) influMemberRef.YearOfService.current.focus();
            return;
        }

        if (platformSel.length < 1) {
            alert("광고 채널을 1개 이상 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        let flag = true;

        platformSel.some((platform, idx) => {
            if('' == platform.url) {
                alert("광고 채널 URL을 입력해주세요.");
                setTab("extraInfo");
                if(influMemberRef.Url.current[idx] !== null) influMemberRef.Url.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if(!flag) {
            return;
        }

        if('' == memData.Comment) {
            alert("자기 소개를 입력해주세요.");
            setTab("extraInfo");
            if(influMemberRef.Comment.current !== null) influMemberRef.Comment.current.focus();
            return;
        }

        if('' == memData.DetailComment) {
            alert("상세 소개를 입력해주세요.");
            setTab("extraInfo");
            if(influMemberRef.DetailComment.current !== null) influMemberRef.DetailComment.current.focus();
            return;
        }

        memData.AreaOfInterest = wishInfluSel;
        memData.RegionOfInterest = wishLocSel;
        memData.BusinessHoursStart = startTime.format('HH:mm');
        memData.BusinessHoursEnd = endTime.format('HH:mm');

        platformSel.forEach(platform => {
            if(Number(platform.id) == PlatformType.Instagram) {
                memData.InstagramUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.Facebook) {
                memData.FacebookUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.Twitter) {
                memData.TwitterUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.NaverBlog) {
                memData.NaverBlogUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.NaverCafe) {
                memData.NaverCafeUrl = platform.url;
            } else if(Number(platform.id) == PlatformType.KakaoFriend) {
                memData.KakaoStoryUrl = platform.url;
            } else if(Number(platform.id) == 6) {
                memData.YoutubeUrl = platform.url;
            } else if(Number(platform.id) == 99) {
                memData.EtcUrl = platform.url;
            }
        });

        axios.patch('/api/MyInfluencer', memData).then(response => {
            if("" !== pwd) {
                axios.patch('/api/Account/password', {Password: pwd}).then(response => {
                    alert("프로필 수정이 완료되었습니다.");
                    navigate('/my/my-influ-main');
                }).catch(error => {
                    console.log(error);
                    alert("시스템 오류가 발생했습니다.");
                    return;
                });
            } else {
                alert("프로필 수정이 완료되었습니다.");
                navigate('/my/my-influ-main');
            }
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");
            return;
        });
    };

    if(wishLoc) {
        return (<WishLocation setWishLoc={setWishLoc} setSelect={setWishLocSel}/>);
    } else if(wishInflu) {
        return (<WishInfluencer setWishInflu={setWishInflu} setSelect={setWishInfluSel}/>);
    } else {
        return (
            <div>
                <div className="contents mobile profile-modify-bg">
                    <header className="header-member">
                        <div className="header_left">
                            <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">개인정보 수정</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => logout()} className="logout">로그아웃</a>
                        </div>
                    </header>

                    {/* 프로필 사진 영역 */}
                    <div className="profile-modify">
                        <div className="profile-area">
                            <div className="my-main_profile">
                                <div className="file_upload center">
                                    <div className="circle">
                                        {!profileImage ? (<img className="speaker" src={file_speaker} alt=""/>)
                                            : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                                    </div>
                                    <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                                    <img className="photo_button" src={file_photo} alt=""/>
                                </div>
                            </div>
                        </div>

                        <Tab.Container defaultActiveKey="defaultInfo">
                            {/* 탭 영역 */}
                            <Nav className="navigation tab green tab-area" as={"ul"}>
                                <Nav.Link eventKey="defaultInfo" as={"li"} active={tab === "defaultInfo"}>
                                    <a onClick={() => setTab("defaultInfo")}>기본 정보</a>
                                </Nav.Link>
                                <Nav.Link eventKey="profileInfo" as={"li"} active={tab === "profileInfo"}>
                                    <a onClick={() => setTab("profileInfo")}>프로필정보</a>
                                </Nav.Link>
                                <Nav.Link eventKey="extraInfo" as={"li"} active={tab === "extraInfo"}>
                                    <a onClick={() => setTab("extraInfo")}>추가정보</a>
                                </Nav.Link>
                            </Nav>
                            {/* 콘텐츠 아레아 */}
                            <Tab.Content>
                                <Tab.Pane eventKey="defaultInfo" className="requirements-list-body"
                                          active={tab === "defaultInfo"}>
                                    <div className="detail_area">
                                        <div className="item">
                                            <span className="title">닉네임</span>
                                            <span className="info">{session?.displayName}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">이메일</span>
                                            <span className="info">{profileData.email}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={pwd}
                                                   onChange={(e) => setPwd(e.target.value)}
                                                   ref={influMemberRef.pwd}/>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호 재입력</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={repeatPwd}
                                                   onChange={(e) => setRepeatPwd(e.target.value)}
                                                   ref={influMemberRef.repeatPwd}/>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="profileInfo" className="requirements-list-body"
                                          active={tab === "profileInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">직업</div>
                                                    <div className="con">
                                                        <div className="write-select">
                                                            <select name="Job" value={profileData.job}
                                                                    onChange={handleDataChange}
                                                                    ref={influMemberRef.Job}>
                                                                <option value="학생">학생</option>
                                                                <option value="직장인">직장인</option>
                                                                <option value="사업가">사업가</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">주소</div>
                                                    <input type="text" className="input basic" name="Address1"
                                                           value={profileData.address1} onChange={handleDataChange}
                                                           ref={influMemberRef.Address1}
                                                           placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Address2"
                                                           value={profileData.address2} onChange={handleDataChange}
                                                           ref={influMemberRef.Address2}
                                                           placeholder="상세주소를 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="detail_area">
                                        <div className="item type02 border-0">
                                            <div className="item_title">
                                                <div className="title">신상정보</div>
                                            </div>
                                        </div>
                                        <div className="item type02 pt-0">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">이름</div>
                                                    <input type="text" className="input basic" name="Name"
                                                           value={profileData.name} onChange={handleDataChange}
                                                           ref={influMemberRef.Name} placeholder="실명을 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">주민번호</div>
                                                    <input type="text" className="input basic"
                                                           name="IdentificationNumber"
                                                           value={profileData.identificationNumber}
                                                           onChange={handleDataChange}
                                                           ref={influMemberRef.IdentificationNumber}
                                                           placeholder="'-' 없이 주민번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>*/}
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">계좌번호</div>
                                                    <input type="text" className="input basic" name="BankNumber"
                                                           value={profileData.bankNumber} onChange={handleDataChange}
                                                           ref={influMemberRef.BankNumber}
                                                           placeholder="'-' 없이 계좌번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">은행명</div>
                                                    <div className="con">
                                                        <div className="write-select">
                                                            <select name="BankCode" value={profileData.bankCode}
                                                                    onChange={handleDataChange}
                                                                    ref={influMemberRef.BankCode}>
                                                                {BankCode.map((bank, key) => (
                                                                    <option key={key}
                                                                            value={bank.code}>{bank.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">예금주</div>
                                                    <input type="text" className="input basic" name="BankOwner"
                                                           value={profileData.bankOwner} onChange={handleDataChange}
                                                           ref={influMemberRef.BankOwner}
                                                           placeholder="예금주를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="extraInfo" className="requirements-list-body"
                                          active={tab === "extraInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">거주 및 활동 지역</div>
                                                <a onClick={() => setWishLoc(true)}>선택하기</a>
                                            </div>
                                            <div className="item_body">
                                                {wishLocSel.map((loc, key) => (
                                                    <DivAreaBadge areaName={loc} key={key} setAreaName={setWishLocSel}/>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">나의 관심 분야</div>
                                                <a onClick={() => setWishInflu(true)}>선택하기</a>
                                            </div>
                                            <div className="item_body">
                                                {wishInfluSel.map((sel, key) => (
                                                    <DivInterestBadge interest={sel} key={key}
                                                                      setInterest={setWishInfluSel}/>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">연락 가능 시간</div>
                                                <div className="choice checkbox">
                                                    <span style={{fontSize: "4.4rem"}}>주말 제외</span>
                                                    <span className="choice_check">
                                                    <input type="checkbox"
                                                           checked={profileData.exceptForWeekEnds}
                                                           onChange={handleDataChange} name="ExceptForWeekEnds"/>
                                                </span>
                                                </div>
                                            </div>
                                            <div className="item_body">
                                                <div className="d-flex justify-content-evenly">
                                                    <div className="time">
                                                        <TimePicker className="input basic" format="HH:mm"
                                                                    placeholder="HH24:MI"
                                                                    value={startTime}
                                                                    ref={influMemberRef.BusinessHoursStart}
                                                                    onChange={(time) => setStartTime(time)}/>
                                                    </div>
                                                    <div className="dash"><img src={dash} style={{width: "10px"}}
                                                                               alt=""/></div>
                                                    <div className="time">
                                                        <TimePicker className="input basic" format="HH:mm"
                                                                    placeholder="HH24:MI"
                                                                    value={endTime}
                                                                    ref={influMemberRef.BusinessHoursEnd}
                                                                    onChange={(time) => setEndTime(time)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">경력</div>
                                            </div>
                                            <div className="item_body d-flex">
                                                <input style={{marginRight: '5px'}} className="input basic"
                                                       type="number"
                                                       name="YearOfService" value={profileData.yearOfService}
                                                       onChange={handleDataChange} ref={influMemberRef.YearOfService}/>
                                                <div className="input basic">년</div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">광고 채널</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="grid grid-3">
                                                    {platforms.map((p, idx) => (
                                                        <div key={idx}>
                                                            <a className={
                                                                `button ad_channel _onOff
                                                            ${platformSel.includes(
                                                                    platformSel.find(p2 => p2.id === p.type)
                                                                ) ? ' on' : ''}`
                                                            }
                                                               onClick={() => selectPlatform(p.type, p.name)}>{p.name}</a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail_area">
                                            {platformSel.map((p, idx) => (
                                                <div key={idx} className="item">
                                                    <span className="title">{p.name}</span>
                                                    <input type="text" className="info border-0 w-75" value={p.url}
                                                           style={{backgroundColor: "inherit"}}
                                                           placeholder="https://"
                                                           ref={ref => influMemberRef.Url.current[idx] = ref as HTMLInputElement}
                                                           onChange={(e) => handleUrlChange(e, p.id)}/>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">한줄 자기소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Comment"
                                                           value={profileData.comment} onChange={handleDataChange}
                                                           ref={influMemberRef.Comment} placeholder="자기소개를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">상세 소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                <textarea className="textarea basic" name="DetailComment"
                                                          value={profileData.detailComment} onChange={handleDataChange}
                                                          ref={influMemberRef.DetailComment}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                        <div className="navigation height">
                            <div className="foot-btn">
                                <div className="detail-foot_one_button">
                                    <div>
                                        <a onClick={() => updateProfile()} className="button full green">수정</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InfluProfileModify;