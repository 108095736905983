import React, {useEffect, useState} from "react";
import moment from "moment";

import MobileNavigationFooter from "components/m-navigation-footer";
import {TradeItem} from "types/trade";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";
import icon_list_left from "img/m/icon-list-left.png";
import axios from "axios";
import MobileTradeInfluItem from "../../components/m-trade-influencer-item";
import DetailProPopup from "../popup/detail-list-pro";
import DetailInfluPopup from "../popup/detail-list-influ";

type setBoolState = React.Dispatch<React.SetStateAction<boolean>>;
type setStrState = React.Dispatch<React.SetStateAction<string>>;

function DivTradeListDay(props: {
    date: string, items: TradeItem[], popupShow: boolean, setPopupShow: setBoolState, setTradeId: setStrState,
    service: string, setService: setStrState
}) {
    const detailTrade = (id: string) => {
        props.setPopupShow(true);
        props.setTradeId(id);
        props.setService(props.service);
    };

    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.date}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <MobileTradeInfluItem key={key} item={item} onClick={() => detailTrade(item.receiptId)}/>
                ))}
            </div>
        </div>
    );
}

function MyInfluTrade() {
    const [tab, setTab] = useState<string>("ad");
    const [campaign, setCampaign] = useState<any>();
    const [proService, setProService] = useState<any>();
    const [influPopupShow, setInfluPopupShow] = useState<boolean>(false);
    const [proPopupShow, setProPopupShow] = useState<boolean>(false);
    const [receiptId, setReceiptId] = useState<string>('');
    const [service, setService] = useState<string>('');

    useEffect(() => {
        axios.get(`api/MyInfluencer/receipts/campaign`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setCampaign({ ...data, items: list });
            });
        axios.get(`api/MyInfluencer/receipts/pro`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createdAt).format('YYYY.MM.DD')}
                ));
                setProService({ ...data, items: list });
            });
    }, []);

    if (campaign == null) return (<></>);
    if (proService == null) return (<></>);

    const camDts = campaign.items.map((item: any) => item.createDt);
    const dateListCampaign = camDts.filter((dt: any, i: number) => camDts.indexOf(dt) === i);

    const proDts = proService.items.map((item: any) => item.createDt);
    const dateListProService = proDts.filter((dt: any, i: number) => proDts.indexOf(dt) === i);
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">거래내역</span>
                    </div>
                    <div className="header_right">
                        {/*<a className="del"><img src={icon_del} alt="삭제"/></a>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab green">
                        <li id="tab-01" className={tab === "ad" ? "on" : ""}>
                            <a onClick={() => setTab("ad")}>캠페인</a>
                        </li>
                        <li id="tab-02" className={tab === "pro" ? "on" : ""}>
                            <a onClick={() => setTab("pro")}>전문가</a>
                        </li>
                    </ul>
                </div>
                <div className="requirements-list">
                    {/*  견적 요청중 탭 */}
                    <div className={`requirements-list-body${tab === "ad" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {campaign.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area">
                            {dateListCampaign.map((date: string, idx: number) => (
                                <DivTradeListDay key={idx} date={date} popupShow={influPopupShow} setPopupShow={setInfluPopupShow}
                                                 items={campaign.items.filter((c: any) => c.createDt === date)}
                                                 setTradeId={setReceiptId} service={"campaign"} setService={setService}/>
                            ))}
                        </div>
                    </div>

                    {/*  견적마감 */}
                    <div className={`requirements-list-body${tab === "pro" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {proService.count}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            {dateListProService.map((date: string, idx: number) => (
                                <DivTradeListDay key={idx} date={date} popupShow={proPopupShow} setPopupShow={setProPopupShow}
                                                 items={proService.items.filter((p: any) => p.createDt === date)}
                                                 setTradeId={setReceiptId} service={"pro"} setService={setService}/>
                            ))}
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={"my"}/>
            </div>

            <DetailInfluPopup show={influPopupShow} setShow={setInfluPopupShow} receiptId={receiptId} service={service}
                              myService="MyInfluencer"/>
            <DetailProPopup show={proPopupShow} setShow={setProPopupShow} receiptId={receiptId} service={service}
                            myService="MyInfluencer"/>
        </div>
    );
}

export default MyInfluTrade;