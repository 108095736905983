import React from "react";

import {Coupon} from "types/coupon";
import {DivDateRange} from "types/datetime-extensions";

function MobileCouponItem(props: { coupon: Coupon }) {
    return (
        <div className={props.coupon.isUsed ? "item on" : "item"}>
            <div className="used">
                <span>사용이 완료되었습니다.</span>
            </div>
            <div className="info">
                <div className="cate">{props.coupon.cate}</div>
                <div className="title" dangerouslySetInnerHTML={{__html: props.coupon.title}}></div>
                <div className="date">
                    <DivDateRange startDate={props.coupon.startDate} endDate={props.coupon.endDate}/>
                </div>
            </div>
        </div>
    );
}

export default MobileCouponItem;