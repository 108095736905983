import React, {useState} from "react";
import {useParams} from "react-router-dom";

import {ChatMessage, ChatRoom} from "types/chat";

import header_back from "img/m/header-back.svg";
import icon_chat_menu from "img/m/icon-chat-menu.svg";
import icon_chat_caution from "img/m/icon-chat-caution.svg";
import button_attached from "img/m/button-attached.svg";
import attached_photo from "img/m/attached-photo.svg";
import attached_album from "img/m/attached-album.svg";
import attached_file from "img/m/attached-file.svg";
import moment from "moment";

function DivChat(props: { isMine: boolean, chat: ChatMessage }) {
    let date = moment(props.chat.messageDate).format('a h:mm');
    if (moment().diff(props.chat.messageDate, 'days') != 0) {
        date = moment(props.chat.messageDate).format('YYYY.MM.DD');
    }

    if (props.isMine) {
        return (

            <div className="chat to">
                <div className="txt">{props.chat.message}<span className="time">{date}</span></div>
            </div>
        );
    }

    return (
        <div className="chat from">
            <img className="photo" src={props.chat.profileImg} alt=""/>
            <div className="txt">{props.chat.message}<span className="time">{date}</span></div>
        </div>
    );
}

function MyMessageWrite() {
    const params = useParams();

    const room: ChatRoom = {
        roomId: params.roomId ?? "",
        title: "전문가",
        profileImg: "https://placeimg.com/700/700/any"
    };

    const messages: ChatMessage[] = [
        {
            userIdx: 1,
            profileImg: "",
            name: "",
            message: "안녕하세요. 포스터디자인 의로하고싶습니다.",
            messageDate: new Date(2022, 6, 8),
        },
        {
            userIdx: 2,
            profileImg: "https://placeimg.com/700/700/any",
            name: "이름없음",
            message: "안녕하세요. 포스터디자인 의로하고싶습니다.",
            messageDate: new Date(2022, 6, 8),
        },
        {
            userIdx: 1,
            profileImg: "",
            name: "",
            message: "안녕하세요. 포스터디자인 의로하고싶습니다.",
            messageDate: moment().add(-1, 'hour').toDate(),
        },
        {
            userIdx: 2,
            profileImg: "https://placeimg.com/700/700/any",
            name: "이름없음",
            message: "안녕하세요. 포스터디자인 의로하고싶습니다.",
            messageDate: moment().add(-1, 'minute').toDate(),
        },
        {
            userIdx: 2,
            profileImg: "https://placeimg.com/700/700/any",
            name: "이름없음",
            message: "안녕하세요. 포스터디자인 의로하고싶습니다.",
            messageDate: moment().add(-1, 'second').toDate(),
        },
    ];

    // TODO : 교체 필요
    const myUserIdx = 1;

    const [menuShow, setMenuShow] = useState(false);
    const [alertShow, setAlertShow] = useState(true);

    setTimeout(() => {
        setAlertShow(false);
    }, 1500);

    return (
        <div>
            <div className="contents mobile page-my msg-write">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <img className="photo" src={room.profileImg} alt=""/>
                        <span className="location">{room.title}</span>
                    </div>
                    <div className="header_right">
                        {/*TODO : 메뉴 구성이 없음*/}
                        <a className="chat_menu"><img src={icon_chat_menu} alt="메뉴"/></a>
                    </div>
                </header>
                <div className="section pd0">
                    <div className={`caution ${alertShow ? "on" : ""}`}>
                        <img src={icon_chat_caution} alt=""/>
                        잠시만요!<br/>연락처 공유 시 피해위험이 있을 수 있으니 주의하세요.
                    </div>
                    {messages.map((chat, key) => (
                        <DivChat isMine={chat.userIdx == myUserIdx} chat={chat} key={key}/>
                    ))}
                </div>

                <div className="foot-btn">
                    <div className="typing">
                        <a className="attached-btn" onClick={() => setMenuShow(!menuShow)}>
                            <img src={button_attached} alt=""/>
                        </a>
                        <input type="text" placeholder="Write a message"/>
                        <ul className="attached-box">
                            {/*TODO : 메뉴 연결 필요*/}
                            <li className={menuShow ? "on" : ""}><a><img src={attached_photo} alt=""/></a></li>
                            <li className={menuShow ? "on" : ""}><a><img src={attached_album} alt=""/></a></li>
                            <li className={menuShow ? "on" : ""}><a><img src={attached_file} alt=""/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyMessageWrite;