import React, {useState} from "react";

import header_refresh from "img/m/header-refresh.svg";
import header_close from "img/m/header-close.svg";
import layer_check from "img/m/layer-check.svg";

function WishLocation(props: {
    setWishLoc: React.Dispatch<React.SetStateAction<boolean>>,
    setSelect: (values: string[]) => void
}) {
    const [sido, setSido] = useState<string>("서울");
    const [locDtl, setLocDtl] = useState<string[]>(['전체','강남/논현','건대/왕십리','관악/신림','교대/사당',
        '노원/강북','기타']);
    const [selLoc, setSelLoc] = useState<string[]>([]);
    const locations = [
        {sido: '서울', detail: ['전체','강남/논현','건대/왕십리','관악/신림','교대/사당','노원/강북','기타']},
        {sido: '경기', detail: ['전체','성남/과천','수원/의왕/안양','남양주/하남','안산/시흥','오산/화성/평택','양평/가평',
                '기타']},
        {sido: '인천', detail: ['전체','부평','송도','청라','월미도/영종도','강화/옹진','기타']},
        {sido: '강원', detail: ['전체','강릉/속초/양양','포천/철원','원주/횡성/홍천','동해/삼척/태백','평창/정선','기타']},
        {sido: '대전', detail: ['전체','서대전','유성','대덕','기타']},
        {sido: '세종', detail: ['전체','세종','기타']},
        {sido: '충청', detail: ['전체','천안/아산','공주/부여','진천/음성/충주','청주','기타']},
        {sido: '대구', detail: ['전체','수성','동대구','동성로','기타']},
        {sido: '부산', detail: ['전체','해운대','광안리','사상','영도','동래','기타']},
        {sido: '울산', detail: ['전체','울산','방어진','태화강','기타']},
        {sido: '경상', detail: ['전체','안동/의성','김천/구미','포항','경주','청도/밀양','창원/거제','진주/사천','기타']},
        {sido: '광주', detail: ['전체','상무','광산','남광주','무등산','기타']},
        {sido: '전라', detail: ['전체','전주','군산/익산','남원/순창','장성/담양','나주/화순','목포/해남','여수/광양','기타']},
        {sido: '제주', detail: ['전체','제주','서귀포','마라도','우도','기타']}
    ];
    
    const selectSido = (loc: any) => {
        setSido(loc.sido);
        setLocDtl(loc.detail);
    };
    
    const selectLocation = (locDetail: string) => {
        if(!selLoc.includes(locDetail)) {
            setSelLoc([...selLoc, locDetail]);
        }
    };
    
    const applyLocation = () => {
        if (selLoc.length > 0) {
            props.setSelect(selLoc);
            props.setWishLoc(false);
        }
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-layout under_line">
                    <div className="header_left">
                        <span className="location">지역 선택</span>
                    </div>
                    <div className="header_right">
                        <a><img className="refresh" src={header_refresh} alt=""/></a>
                        <a onClick={() => props.setWishLoc(false)}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="layer type01">
                    <div className="scroll_wrap">
                        <ul className="scroll type01">
                        {locations.map((loc, idx) => (
                            <li key={idx} className={sido === loc.sido ? 'on' : ''}>
                                <a onClick={() => selectSido(loc)}>{loc.sido}</a>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="depth2_area">
                        <ul className="depth02">
                        {locDtl.map((dtl, idx) => (
                            <li key={idx} className={selLoc.includes(['전체','기타'].includes(dtl) ? (sido+'/'+dtl) : dtl) ? 'checked' : ''}>
                                <a onClick={() => selectLocation(['전체','기타'].includes(dtl) ? (sido+'/'+dtl) : dtl)}>
                                    <span className="name">{dtl}</span>
                                    <span className="check_img"><img src={layer_check} alt=""/></span>
                                </a>
                            </li>
                        ))}
                        </ul>
                        <div className="selected_item">
                        {selLoc.map((lctn, idx) => (
                            <a key={idx} className="button close_type basic">
                                <span className="wrap">
                                    <span className="name">{lctn}</span>
                                    <span className="exit" onClick={() => setSelLoc(selLoc.filter(item => item !== lctn))}></span>
                                </span>
                            </a>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="bottom chap">
                    <a onClick={() => applyLocation()} className={`button full ${selLoc.length > 0 ? 'black' : 'gray'}`}>선택완료</a>
                </div>
            </div>
        </div>
    );
}

export default WishLocation;