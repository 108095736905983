import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {setCategories} from "../modules/slice/proCategorySlice";

function ProServiceCategoryGet() {
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get("api/ProService/category")
            .then(response => {
                const categories = response.data;
                dispatch(setCategories({categories}));
            })
    }, []);

    return null;
}

export default ProServiceCategoryGet;