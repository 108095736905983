import React, {useEffect, useState} from "react";

import header_back from "img/m/header-back.svg";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {UserSimpleProfile} from "../../types/profile";
import moment from "moment";
import {getPlatformName} from "../../types/platform";
import {AdvertisingType} from "../../types/advertising-type";
import {getLocalSession} from "../../modules/localSession";

function InfluRequireSend() {
    const params = useParams();
    const session = getLocalSession();
    const [estimates, setEstimates] = useState<any>();
    const [profile, setProfile] = useState<UserSimpleProfile>();
    const [isJoin, setIsJoin] = useState(false);

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                setEstimates(data);
            });
        axios.get(`api/Advertiser/estimate/${params.bid}/profile`)
            .then(response => {
                let data = response.data;
                setProfile(data);
            });
        axios.get(`api/MyAdvertiser/estimate/${params.bid}/joined`)
            .then(response => {
                let data2 = response.data;
                
                if(session) {
                    let join = data2.find((j: any) => j.userId == session.id);

                    if(join) {
                        setIsJoin(true);
                    }
                }
            });
    }, [params]);

    if (estimates == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    const adType = estimates.advertisingType === AdvertisingType.FeedUpload ? "피드업로드" :
        estimates.advertisingType === AdvertisingType.Review ? "후기작성" :
            estimates.advertisingType === AdvertisingType.ExperienceGroup ? "체험단" :
                estimates.advertisingType === AdvertisingType.RequestKakaoFriend ? "카카오톡 친구추천" : "";
    const price = estimates.minBudget === 0 && estimates.maxBudget === 0 ? "협의" :
        estimates.minBudget.toLocaleString() + "원 ~ " + estimates.maxBudget.toLocaleString() + "원";
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">견적요청서</span>
                    </div>
                    <div className="header_right">
                    </div>
                </header>
                <div className="c-line"></div>

                <div className="requirements">
                    <div className="requir-head">
                        <span className="requir-badge green">인플루언서</span>
                        <div className="title">{estimates.title}</div>
                        <div className="info_area">
                            <div className="nick">{profile.name}</div>
                            <div className="date">{moment(estimates.createAt).format("YYYY.MM.DD")}</div>
                        </div>
                        <div className="total">
                            <span className="text">참여 견적수</span>
                            <span className="ea"> {estimates.joinCount}건</span>
                        </div>
                    </div>
                    <div className="requir-body show">
                        <div className="requir-block">
                            <div className="title">견적서 요청할 사람</div>
                            <div className="desc">인플루언서</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 분야</div>
                            <div className="desc">
                                {estimates.categories.map((category: string, idx: number) => (
                                    <a key={idx} className="button round green big"
                                       style={{marginRight: "3px"}}>{category}</a>
                                ))}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고채널</div>
                            <div className="desc">{getPlatformName(estimates.platformType)}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고방식</div>
                            <div className="desc">{adType}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고기간</div>
                            <div className="desc">
                                {moment(estimates.startAt).format("YYYY.MM.DD")} ~ {moment(estimates.endAt).format("YYYY.MM.DD")}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 예상 금액</div>
                            <div className="desc">{price}</div>
                        </div>
                    </div>
                </div>
                <div className="bottom chap">
                    {isJoin ? <a className="button full gray">견적 보내기 완료</a> :
                    <Link to={`/requirements/common-require-send/${params.bid}`} className="button full black">
                        견적 보내기
                    </Link>
                    }
                </div>
            </div>
        </div>
    );
}

export default InfluRequireSend;