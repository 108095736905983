import React, {useEffect, useState} from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileRequirementItem from "components/m-requirement-item";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_del from "img/m/icon-del.png";
import icon_list_left from "img/m/icon-list-left.png";
import {UserType} from "../../types/user-type";
import axios from "axios";
import moment from "moment";
import {useNavigate} from "react-router-dom";

function AdRequirementsList() {
    const [tab, setTab] = useState<string>("ing");
    const [ingList, setIngList] = useState<any>();
    const [endList, setEndList] = useState<any>();
    const [rcvList, setRcvList] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate`)
            .then(response => {
                let data = response.data;
                setIngList(data);
            });
        axios.get(`api/MyAdvertiser/estimate/end`)
            .then(response => {
                let data = response.data;
                setEndList(data);
            });
        axios.get(`api/MyAdvertiser/estimate/received`)
            .then(response => {
                let data = response.data;
                let items = data.items;
                let newItems: any[] = [];
                let rcvItems = data.rcvItems;

                rcvItems.forEach((rcv: any) => {
                    let item = items.find((it: any) => it.bid == rcv.boardId);

                    if(item) {
                        item.paymentType = rcv.paymentProcessType;
                        newItems.push(item);
                    }
                });

                setRcvList({...data, items: newItems});
            });
    }, []);

    if (ingList == null) {
        return (<></>);
    }

    if (endList == null) {
        return (<></>);
    }

    if (rcvList == null) {
        return (<></>);
    }

    const back = () => {
        navigate(`/home/ad-main`);
    };
    
    return (
        <>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">견적요청내역</span>
                    </div>
                    <div className="header_right">
                        {/*<a href="#" className="del"><img src={icon_del} alt="삭제"/></a>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab blue">
                        <li id="tab-01" className={tab === "ing" ? "on" : ""}>
                            <a onClick={() => setTab("ing")}>견적요청중</a>
                        </li>
                        <li id="tab-02" className={tab === "end" ? "on" : ""}>
                            <a onClick={() => setTab("end")}>견적마감</a>
                        </li>
                        <li id="tab-03" className={tab === "req" ? "on" : ""}>
                            <a onClick={() => setTab("req")}>의뢰완료</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">

                    {/*  견적 요청중 탭 */}
                    <div className={`requirements-list-body${tab === "ing" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {ingList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="requirements-list-area">
                        {ingList.items.map((ing: any, idx: number) => (
                            <MobileRequirementItem key={idx} estimate={ing} userType={"ad"}/>
                        ))}
                        </div>
                    </div>

                    {/*  견적마감 */}
                    <div className={`requirements-list-body${tab === "end" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {endList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="requirements-list-area end">
                        {endList.items.map((end: any, idx: number) => (
                            <MobileRequirementItem key={idx} estimate={end} userType={"ad"}/>
                        ))}
                        </div>
                    </div>

                    <div className={`requirements-list-body${tab === "req" ? " show" : ""}`} id="contents-03">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {rcvList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="requirements-list-area req">
                            {rcvList.items.map((rcv: any, idx: number) => (
                                <MobileRequirementItem key={idx} estimate={rcv} userType={"ad"} isRcv={true}/>
                            ))}
                        </div>
                    </div>

                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Ad} curMenu={"list"}/>
            </div>
        </>
    );
}

export default AdRequirementsList;