import React from "react";
import moment from "moment";

import {TradeItem, TradeState} from "types/trade";

import dummy_01 from "img/m/dummy-01.png";

function DivTradeState(props: { state: TradeState }) {
    if (props.state == TradeState.Trading) {
        return (<span className="badge trade ing" style={{background: "#0A61D4"}}>결제대기</span>);
    } else if (props.state == TradeState.Complete) {
        return (<span className="badge trade end">결제완료</span>);
    } else if (props.state == TradeState.Fail) {
        return (<span className="badge trade end" style={{background: "#ff00ff"}}>결제실패</span>);
    } else {
        return (<span className="badge trade end" style={{background: "#aaaaaa"}}>결제취소</span>);
    }
}

function MobileTradeCampaignItem(props: { item: TradeItem, onClick: () => void }) {
    const endDate = moment(props.item.completedAt).format('YYYY.MM.DD');

    return (
        <div className="item" onClick={() => props.onClick()}>
            <div className="thumbnail width">
                <div className="image_area">
                    <img src={props.item.bannerImg || dummy_01} style={{objectFit: 'cover'}} alt=""/>
                    {/*<div className="button heart blue _onOff on"></div>*/}
                </div>
                <div className="text_area">
                    <a className="cate type-star">
                        <span>{props.item.category}</span>
                        <DivTradeState state={props.item.status}/>
                    </a>
                    <a className="title">{props.item.title}</a>
                    <div className="trade_area">
                        <div className="last_date">{props.item.completedAt ? "거래일 : " + endDate : ""}</div>
                        <div className="last_price">{props.item.money.toLocaleString()} 원</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MobileTradeCampaignItem;