import React, {useEffect, useRef, useState} from "react";
import {Form, Modal} from "react-bootstrap";
import ReactQuill from "react-quill";

import {PlatformType} from "types/platform";

import header_close from "img/m/header-close.svg";
import write_right from "img/m/write-right.png";
import write_circle_plus from "img/m/write-circle-plus.png";
import write_circle_minus from "img/m/write-circle-minus.png";
import write_circle_minus_red from "img/m/write-circle-minus-red.png";
import icon_main_wirte_green from "img/m/icon-main-wirte-green.png";
import {MainCategory, SubCategory} from "../../types/category";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import {CreateInfluencerMediaRequest, CreateInfluencerRequest} from "../../types/influencer-request";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function AdDetailWrite() {
    const handleOnClose = () => {
    };

    return (<AdDetailWriteImpl onClose={() => handleOnClose()}/>);
}

let influRef: any = {};

type mediasSetState = React.Dispatch<React.SetStateAction<CreateInfluencerMediaRequest[]>>;
type unitPriceSetState = React.Dispatch<React.SetStateAction<any[]>>;

function DivUnitPrice(props: { unit: number, price: number, setUnitPrice: unitPriceSetState,
    onRemoveMedia: (unit: number) => void }) {
    const setUnit = (value: string) => {
    };

    const setPrice = (value: string) => {
        props.setUnitPrice(prevState => prevState.map(up => (
            up.unit === props.unit ? {...up, price: Number(value)} : up
        )));
    };
    
    return (
        <div className="choice">
            <div className="type_ea">
                <div className="service_select">
                    <Form.Control as="select" bsPrefix={"#"} value={props.unit} onChange={e => setUnit(e.target.value)}>
                        <option value={props.unit}>{props.unit === 0 ? "클릭" : "피드"}</option>
                    </Form.Control>
                </div>
                <div className="service_won">
                    <span className="ea">1회당</span>
                    <Form.Control type="number" className="money" value={props.price}
                                  onChange={e => setPrice(e.target.value)}/>
                    <span className="won">원</span>
                </div>
                <a className="minus_button" onClick={() => props.onRemoveMedia(props.unit)}>
                    <img src={write_circle_minus_red} alt=""/>
                </a>
            </div>
        </div>
    );
}

function DivMedia(props: { index: number, setMedia: mediasSetState, onRemoveMedia: (index: number) => void }) {
    const [media, setMedia] = useState<string>("-1");
    const [link, setLink] = useState<string>("");
    const [sbsCount, setSbsCount] = useState<string>("0");
    const [unitPrices, setUnitPrices] = useState<any[]>([]);

    const platforms = [
        {type: PlatformType.Instagram, name: '인스타그램'},
        {type: PlatformType.Facebook, name: '페이스북'},
        {type: PlatformType.Twitter, name: '트위터'},
        {type: PlatformType.NaverBlog, name: '네이버 블로그'},
        {type: PlatformType.NaverCafe, name: '네이버 카페'},
        {type: PlatformType.KakaoFriend, name: '카카오스토리'},
        {type: 6, name: '유튜브'},
        {type: 99, name: '기타'},
    ];

    const handleAddMedia = () => {
        if(unitPrices.length < 2) {
            setUnitPrices(unitPrices.concat({
                type: Number(media),
                unit: unitPrices.find(up => up.unit === 0) ? 1 : 0,
                price: 0
            }));
        }
    }

    const handleRemoveMedia = (unit: number) => {
        setUnitPrices(unitPrices.filter((up) => up.unit !== unit));
    }

    useEffect(() => {
        props.setMedia(prevState => prevState.map((md, idx) => {
            if(idx === props.index) {
                md = {
                    ...md,
                    PlatformType: Number(media),
                    Link: link,
                    SubscribeCount: Number(sbsCount)
                };

                let ups = unitPrices.filter(up => up.type === md.PlatformType);

                if(ups.length > 0) {
                    md = {
                        ...md,
                        CostPerClick: ups.find(o => o.unit === 0) ? ups.find(o => o.unit === 0).price : 0,
                        CostPerFeed: ups.find(o => o.unit === 1) ? ups.find(o => o.unit === 1).price : 0
                    }
                }
            }
            
            return md;
        }));
    }, [media, link, sbsCount, unitPrices, props.index]);

    return (
        <div className="write-media_add">
            <div className="line">
                <div className="name type_add">
                    <span>미디어 선택</span>
                    <a className="add_button" onClick={() => props.onRemoveMedia(props.index)}>
                        <img src={write_circle_minus} alt=""/>
                        <span>삭제하기</span>
                    </a>
                </div>
                <div className="choice">
                    <select value={media} onChange={e => setMedia(e.target.value)}
                            ref={ref => influRef.MediaType.current[props.index] = ref}>
                        <option value="-1">미디어를 선택해주세요.</option>
                    {platforms.map((platform, idx) => (
                        <option key={idx} value={platform.type}>{platform.name}</option>
                    ))}
                    </select>
                </div>
            </div>
            <div className="line">
                <div className="name">링크</div>
                <div className="choice">
                    <input className="type_link" type="text" value={link} onChange={e => setLink(e.target.value)}
                           ref={ref => influRef.Link.current[props.index] = ref}/>
                </div>
            </div>
            <div className="line">
                <div className="name">친구수 (팔로워,구독자,이웃 등)</div>
                <div className="choice">
                    <input className="type_link" type="number" placeholder="ex) 21800 숫자로만 입력해주세요."
                           value={sbsCount} onChange={e => setSbsCount(e.target.value)}
                           ref={ref => influRef.SubscribeCount.current[props.index] = ref}/>
                </div>
            </div>
            <div className="line">
                <div className="name type_add">
                    <span>단가</span>
                    <a className="add_button" onClick={() => handleAddMedia()}>
                        <img src={write_circle_plus} alt=""/>
                        <span>추가하기</span>
                    </a>
                </div>
                {unitPrices.map((up, key) => (
                    <DivUnitPrice unit={up.unit} price={up.price} setUnitPrice={setUnitPrices}
                                  onRemoveMedia={() => handleRemoveMedia(up.unit)} key={key}/>
                ))}
            </div>
        </div>
    );
}

type richEditorSetRef = React.Dispatch<React.SetStateAction<ReactQuill | null>>;

function DivRichEditor(props: { value: string, setValue: (e: ReactQuill.UnprivilegedEditor) => void, setRef: richEditorSetRef }) {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'color': []}],
            [{'size': ['small', false, 'large', 'huge']}],
            ['image'],
            [{'list': 'bullet'}, {'list': 'ordered'}],
        ],
    };

    const formats = [
        'bold', 'italic', 'underline', 'strike',
        'color',
        'size',
        'image',
        'bullet', 'list',
    ];

    return (
        <ReactQuill theme="snow" value={props.value} modules={modules} formats={formats} ref={ref => props.setRef(ref)}
                    onChange={(content, delta, source, editor) => props.setValue(editor)}/>
    );
}

function AdDetailWriteImpl(props: { onClose: () => void }) {
    const [title, setTitle] = useState<string>('');
    const [contents, setContents] = useState<string>('');
    const [text, setText] = useState<string>('');
    const [medias, setMedias] = useState<CreateInfluencerMediaRequest[]>([]);
    const [categories, setCategories] = useState<MainCategory[]>([]);
    const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
    const [selSubCate, setSelSubCate] = useState(false);
    const [subCateName, setSubCateName] = useState('상세 카테고리 선택');
    const [selCate, setSelCate] = useState(false);
    const [cateName, setCateName] = useState('카테고리 선택');
    const [contentRef, setContentRef] = useState<ReactQuill | null>(null);

    const [influData, setInfluData] = useState<CreateInfluencerRequest>({
        CategoryId: 0,
        SubCategoryId: 0,
        Title: '',
        MediaRequests: [],
        Detail: ''
    });

    const influCategory = useSelector((state: RootState) => state.influCategory);

    const selectCate = (cateId: number, cateName: string) => {
        setSelCate(false);
        setCateName(cateName);
        setSelSubCate(true);
        setSubCategories(influCategory.repo.getSubCategories(cateId));
        setInfluData({ ...influData, CategoryId: cateId });
    };

    const selectSubCate = (cateId: number, cateName: string) => {
        setSubCateName(cateName);
        setSelSubCate(false);
        setInfluData({ ...influData, SubCategoryId: cateId });
    };

    const handleAddMedia = () => {
        setMedias(medias.concat({
            PlatformType: -1,
            Link: "",
            SubscribeCount: 0,
            CostPerClick: 0,
            CostPerFeed: 0
        }));
    };

    const handleRemoveMedia = (index: number) => {
        setMedias(medias.filter((_, i) => i !== index));
    };

    const changeContents = (editor: ReactQuill.UnprivilegedEditor) => {
        setContents(editor.getHTML());
        setText(editor.getText());
    }
    
    const registerInflu = () => {
        if(1 > influData.CategoryId) {
            alert("카테고리를 선택하세요.");
            return;
        }

        if(1 > influData.SubCategoryId) {
            alert("상세 카테고리를 선택하세요.");
            return;
        }
        
        if(1 > medias.length) {
            alert("미디어를 1개 이상 등록하세요.");
            return;
        }
        
        let flag = true;

        medias.some((media, idx) => {
            let regNum = /^[0-9]+$/;
            
            if(0 > media.PlatformType) {
                alert("미디어를 선택해주세요.");
                if(influRef.MediaType.current[idx] !== null) influRef.MediaType.current[idx].focus();
                flag = false;
                return true;
            }

            if('' == media.Link) {
                alert("링크를 입력해주세요.");
                if(influRef.Link.current[idx] !== null) influRef.Link.current[idx].focus();
                flag = false;
                return true;
            }
            
            if('' == String(media.SubscribeCount)) {
                alert("친구 수를 입력해주세요.");
                if(influRef.SubscribeCount.current[idx] !== null) influRef.SubscribeCount.current[idx].focus();
                flag = false;
                return true;
            }

            if(!regNum.test(String(media.SubscribeCount))) {
                alert("친구 수를 숫자로만 입력해주세요.");
                if(influRef.SubscribeCount.current[idx] !== null) influRef.SubscribeCount.current[idx].focus();
                flag = false;
                return true;
            }
            
            if(media.CostPerClick == 0 && media.CostPerFeed == 0) {
                alert("단가를 1개 이상, 1회당 1원 이상 입력해주세요.");
                flag = false;
                return true;
            }
        });
        
        if(!flag) return;

        if('' == title) {
            alert("소개명을 입력해주세요.");
            if(influRef.Title.current !== null) influRef.Title.current.focus();
            return;
        }

        if('' == text) {
            alert("내용을 입력하세요.");
            if(contentRef !== null) contentRef.focus();
            return;
        }
        
        influData.Title = title;
        influData.Detail = contents;
        influData.MediaRequests = medias;

        console.log(influData);

        axios.post('/api/Influencer/influ', influData).then(response => {
            alert("서비스 등록이 완료되었습니다.");
            location.href = '/my/my-influ-main';
        }).catch(error => {
            console.log(error);
            alert("서비스 등록에 실패했습니다. 관리자에 문의해주세요.");

            return;
        });
    };

    useEffect(() => {
        axios.get("api/Influencer/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
    }, []);
    
    influRef = {
        Title: useRef<HTMLInputElement>(null),
        MediaType: useRef<HTMLInputElement[]>([]),
        Link: useRef<HTMLInputElement[]>([]),
        SubscribeCount: useRef<HTMLInputElement[]>([])
    }

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">광고자 상세 등록</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => props.onClose()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="write pro">
                    <div className="section-write">
                        <div className="title">광고자 분야</div>
                        <a className="write-select_button" onClick={() => setSelCate(!selCate)}>
                            <span>{cateName}</span>
                            <span className="arrow"><img src={write_right} alt=""/></span>
                        </a>
                    {selCate ?
                        <div className="border" style={{borderRadius: '2rem'}}>
                            <ul style={{fontSize: '5.4rem'}}>
                                {categories.map((cate, idx) => (
                                    <li key={idx} onClick={() => selectCate(cate.categoryId, cate.name)}
                                        className="p-3">{cate.name}</li>
                                ))}
                            </ul>
                        </div>
                        : null}
                        <a className="write-select_button" onClick={() => setSelSubCate(!selSubCate)}>
                            <span>{subCateName}</span>
                            <span className="arrow"><img src={write_right} alt=""/></span>
                        </a>
                    {selSubCate ?
                        <div className="border" style={{borderRadius: '2rem'}}>
                            <ul style={{fontSize: '5.4rem'}}>
                                {subCategories.filter(sub => sub.subCategoryId != 0).map((cate, idx) => (
                                    <li key={idx} onClick={() => selectSubCate(cate.subCategoryId, cate.name)}
                                        className="p-3">{cate.name}</li>
                                ))}
                            </ul>
                        </div>
                        : null}
                    </div>
                    <div className="section-write type04">
                        <div className="title">
                            <span>미디어 등록</span>
                            <a onClick={() => handleAddMedia()}>
                                <img src={write_circle_plus} alt=""/>
                                <span>추가하기</span>
                            </a>
                        </div>
                        <div className="media_list">
                            {medias.map((media, key) => (
                                <DivMedia index={key} setMedia={setMedias} onRemoveMedia={handleRemoveMedia} key={key}/>
                            ))}
                        </div>
                    </div>
                    <div className="section-write">
                        <div className="title">소개명</div>
                        <input type="text" className="write-input" placeholder="소개명을 입력해주세요."
                               value={title} onChange={(e) => setTitle(e.target.value)}
                               ref={influRef.Title}/>
                    </div>
                    <div className="section-write">
                        <div className="title">내용</div>
                        <div className="write-textarea">
                            <DivRichEditor value={contents} setValue={(e) => changeContents(e)}
                                           setRef={setContentRef}/>
                        </div>
                    </div>
                </div>

                <div className="bottom chap">
                    <a onClick={() => registerInflu()} className="button full black">등록</a>
                </div>
            </div>
        </div>
    );
}

export function AdDetailWriteModal() {
    const [show, setShow] = useState(false);

    return (
        <>
            <a className="ad_button green" onClick={() => setShow(true)}>
                <img src={icon_main_wirte_green} alt=""/>
                <span>광고자 등록</span>
            </a>
            <Modal show={show} fullscreen={true} animation={false} scrollable={true}
                   onHide={() => setShow(false)} dialogClassName="modal-dialog-margin-unset">
                <Modal.Body className={"modal-body-padding-unset"}>
                    <AdDetailWriteImpl onClose={() => setShow(false)}/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdDetailWrite;