import React from "react";

import header_close from "img/m/header-close.svg";

function CommonRequire() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">견적요청서</span>
                    </div>
                    <div className="header_right">
                        <a href="#"><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>

                <div className="requirements">
                    <div className="requir-head">
                        <div className="title">
                            신규 브런치카페를 홍보해 주실 인기 인플루언서님 찾습니다.
                        </div>
                        <div className="date">2020.10.30</div>
                    </div>
                    <div className="requir-body show">
                        <div className="requir-block">
                            <div className="title">견적서 요청할 사람</div>
                            <div className="desc">인플루언서</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 분야</div>
                            <div className="desc">
                                <a href="#" className="button round gray big">베이커리</a>
                                <a href="#" className="button round gray big">브런치카페</a>
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고채널</div>
                            <div className="desc">인스타그램</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고방식</div>
                            <div className="desc">피드업로드</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고기간</div>
                            <div className="desc">2020.10.30~2020.11.03</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 예상 금액</div>
                            <div className="desc">10,000원</div>
                        </div>
                    </div>
                </div>
                <div className="bottom chap">

                </div>
            </div>
        </div>
    );
}

export default CommonRequire;