import React, {useEffect, useState} from "react";
import {CampaignItem, DivCampaignFlag} from "types/campaign";
import dummy_01 from "img/m/dummy-01.png";
import ico_trade_etc from "img/m/ico-trade_etc.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../modules";
import {getLocalSession} from "../modules/localSession";
import axios from "axios";
import {UserType} from "../types/user-type";

function MobileCampaignThumbnailWidth(props: { campaign: any, usePopup?: boolean, header?: JSX.Element }) {
    const banner = props.campaign.images.length != 0 ? props.campaign.images[0] : dummy_01;
    const adCategory = useSelector((state: RootState) => state.adCategory);
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);

    useEffect(() => {
        if(session) {
            setZzim(props.campaign.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.campaign.bid, ZzimType: UserType.Advertiser, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }

    return (
        <div className="thumbnail width">
            {props.header}
            <div className="image_area">
                <Link to={`/home/ad-detail/${props.campaign.bid}`}>
                    <img src={banner} style={{objectFit: 'cover'}} alt=""/>
                </Link>
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart blue${zzim ? " on" : ""}`}></div>
                : null }
            </div>
            <div className="text_area">
                <a className="cate type-star">
                    <span>{adCategory.repo.getSubCategoryName(props.campaign.categoryId, props.campaign.subCategoryId)}</span>
                    {props.usePopup ? (<div className="trade_etc">
                            <a className="etc _etc-popover" data-container="body"
                               data-toggle="popover" data-placement="bottom"
                               data-content="Vivamus."><img src={ico_trade_etc} alt=""/></a>
                        </div>
                    ) : (
                        <></>
                    )}
                </a>
                <Link to={`/home/ad-detail/${props.campaign.bid}`} className="title">
                    {props.campaign.title}
                </Link>
                <div className="price_area">
                    <a className="price">
                        <DivCampaignFlag flag={props.campaign.flag}/>
                        <span>{props.campaign.point}P</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

function MobileCampaignThumbnailHeight(props: { campaign: any }) {
    const banner = props.campaign.images.length != 0 ? props.campaign.images[0] : dummy_01;
    const adCategory = useSelector((state: RootState) => state.adCategory);
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);

    useEffect(() => {
        if(session) {
            setZzim(props.campaign.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.campaign.bid, ZzimType: UserType.Advertiser, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }

    return (
        <div className="thumbnail height">
            <div className="image_area">
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart blue${zzim ? " on" : ""}`}></div>
                : null }
                <Link to={`/home/ad-detail/${props.campaign.bid}`}>
                    <img src={banner} style={{objectFit: 'cover'}} alt=""/>
                </Link>
            </div>
            <div className="text_area">
                <Link to={"/home/ad-list"} className="cate">
                    {adCategory.repo.getSubCategoryName(props.campaign.categoryId, props.campaign.subCategoryId)}
                </Link>
                <Link to={`/home/ad-detail/${props.campaign.bid}`} className="title">
                    {props.campaign.title}
                </Link>
                <div className="price_area">
                    <a className="price">
                        <DivCampaignFlag flag={props.campaign.flag}/>
                        <span>{props.campaign.point}P</span>
                    </a>
                </div>
            </div>
        </div>
    );
}

function MobileCampaignThumbnail(props: { height?: boolean, campaign: any, usePopup?: boolean, header?: JSX.Element }) {
    const isHeight = props.height ?? false;
    return isHeight ?
        <MobileCampaignThumbnailHeight campaign={props.campaign}/> :
        <MobileCampaignThumbnailWidth campaign={props.campaign} usePopup={props.usePopup} header={props.header}/>;
}

export default MobileCampaignThumbnail;