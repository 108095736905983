import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileInfluThumbnail from "components/m-influ-thumbnail";
import {Influ} from "types/influ";
import {PageType} from "types/page-type";
import {AdSearchResultModal} from "../search/ad-search-result";

import header_back from "img/m/header-back.svg";
import header_filter_black from "img/m/header-filter_black.svg";
import icon_list_left from "img/m/icon-list-left.png";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import axios from "axios";
import {getLocalSession} from "../../modules/localSession";

function InfluList() {
    const session = getLocalSession();
    const {location = "1", category = "0"} = useParams();
    const [fetching, setFetching] = useState(false);
    const [influCount, setInfluCount] = useState<number>(0);
    const [lastBid, setLastBid] = useState<number>(0);
    
    const mainCategoryId: number = Number.parseInt(location);
    const subCategoryId: number = Number.parseInt(category);

    const influCategory = useSelector((state: RootState) => state.influCategory);
    const categories = influCategory.repo.getSubCategories(mainCategoryId);
    const mainCategoryName = influCategory.repo.getMainCategoryName(mainCategoryId);

    const [influencers, setInfluencers] = useState<Influ[]>([]);

    useEffect(() => {
        axios.get(`api/Influencer/influ/${location}/${category}`)
            .then(response => {
                const count: number = response.data.count;
                const items: any[] = response.data.items;
                let newItems: any[] = [];

                setInfluCount(count);

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.influItems;

                            items.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setInfluencers(newItems);

                            if (newItems.length != 0) {
                                setLastBid(newItems[newItems.length - 1].bid);
                            }
                        });
                } else {
                    newItems = items.map((c: any) => ({...c, isZzim: false}));
                    setInfluencers(newItems);

                    if (newItems.length != 0) {
                        setLastBid(newItems[newItems.length - 1].bid);
                    }
                }
            });
    }, [location, category])

    const fetchMoreFeeds = async () => {
        setFetching(true);
        
        let queryParam = lastBid !== 0 ? `?last=${lastBid}` : "";
        
        const response = await axios.get(`api/Influencer/influ/${location}/${category}${queryParam}`);
        const count: number = response.data.count;
        const items: any[] = response.data.items;
        let newItems: any[] = [];

        setInfluCount(count);

        if(session) {
            axios.get(`api/Zzim/item`)
                .then(response2 => {
                    let data2 = response2.data.influItems;

                    items.forEach((c: any) => {
                        let zzim = data2.find((z: any) => z.boardId == c.bid);

                        if(zzim) {
                            newItems.push({...c, isZzim: zzim.isZzim});
                        } else {
                            newItems.push({...c, isZzim: false});
                        }
                    });

                    setInfluencers(influencers.concat(newItems));

                    if (newItems.length != 0) {
                        setLastBid(newItems[newItems.length - 1].bid);
                    }

                    setFetching(false);
                });
        } else {
            newItems = items.map((c: any) => ({...c, isZzim: false}));
            setInfluencers(influencers.concat(newItems));

            if (newItems.length != 0) {
                setLastBid(newItems[newItems.length - 1].bid);
            }

            setFetching(false);
        }
    };

    const handleScroll = () => {
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollTop = document.documentElement.scrollTop;
        const clientHeight = document.documentElement.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight && !fetching) {
            fetchMoreFeeds().then();
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    })

    return (
        <div>
            {/* 리스트 상단 */}
            <header className="header-sub">
                <div className="header_left">
                    <Link to={"/home/influ-main"} className="back"><img src={header_back} alt="뒤로가기"/></Link>
                    <span className="location">{mainCategoryName}</span>
                </div>
                <div className="header_right">
                    {/*<AdSearchResultModal/>
                    <a className="search"><img src={header_filter_black} alt="필터"/></a>*/}
                </div>
            </header>
            {/* 리스트 메뉴 */}
            <div className="scroll_wrap">
                <ul className="scroll type02 green d-inline-flex scroll-width-unset">
                    {categories.map((item, key) => (
                        <li key={key}
                            className={item.subCategoryId == subCategoryId ? "on" : ""}>
                            <Link to={`/home/influ-list/${location}/${item.subCategoryId}`}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            {/* 리스트 전체 */}
            <div className="page-list">
                {/* 리스트 총 ${count}, sort */}
                <div className="list-sort">
                    <div className="total_count">
                        총 {influCount}건
                    </div>
                    <a className="sort">
                        <img src={icon_list_left} alt=""/>
                    </a>
                </div>
                {/* 리스트 세로 1열 목록 */}
                <div className="list-list">
                    <div className="thumbnail wrap">
                        <div className="line one">
                            {influencers.map((influ, key) => (
                                <MobileInfluThumbnail key={key} influ={influ}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {/* 리스트 네비게이션 */}
            <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={""}/>
        </div>
    );
}

export default InfluList;