import {AdvertisingType} from "./advertising-type";
import {PlatformType} from "./platform";
import {ProServicePriceOptionType} from "./pro-service";
import {UserType} from "./user-type";

export interface RegisterProServiceMemberRequest {
    IsBusiness: boolean,
    Name: string,
    ProfileImg: string,
    IdentificationNumber: string,
    BusinessOwner: string,
    Address1: string,
    Address2: string,
    ContactNumber: string,
    BankNumber: string,
    BankCode: string,
    BankOwner: string,
    RepresentativeActivitiesField: string[],
    DetailedField: string[],
    YearOfService: number,
    BusinessHoursStart: string,
    BusinessHoursEnd: string,
    ExceptForWeekEnds: boolean,
    Skills: string[],
    Careers: RegisterProServiceMemberCareerRequest[],
    Comment: string,
    DetailComment: string,
}

export interface UpdateProServiceMemberRequest {
    isBusiness: boolean,
    name: string,
    profileImg: string,
    identificationNumber: string,
    businessOwner: string,
    address1: string,
    address2: string,
    contactNumber: string,
    bankNumber: string,
    bankCode: string,
    bankOwner: string,
    representativeActivitiesField: string[],
    detailedField: string[],
    yearOfService: number,
    businessHoursStart: string,
    businessHoursEnd: string,
    exceptForWeekEnds: boolean,
    skills: string[],
    careers: RegisterProServiceMemberCareerRequest[],
    comment: string,
    detailComment: string,
    email: string
}

export const initData: RegisterProServiceMemberRequest = {
    IsBusiness: false,
    Name: '',
    ProfileImg: '',
    IdentificationNumber: '',
    BusinessOwner: '',
    Address1: '',
    Address2: '',
    ContactNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RepresentativeActivitiesField: [],
    DetailedField: [],
    YearOfService: 0,
    BusinessHoursStart: '',
    BusinessHoursEnd: '',
    ExceptForWeekEnds: false,
    Skills: [],
    Careers: [],
    Comment: '',
    DetailComment: '',
};

export interface RegisterProServiceMemberCareerRequest {
    Title: string,
    StartAt: Date,
    EndAt: Date | null
}

export interface CreateProServiceRequest {
    CategoryId: number,
    SubCategoryId: number,
    Title: string,
    Detail: string,
    Keywords: string,
    PriceOptionRequests: CreateProServicePriceOptionRequest[],
    CustomOptionRequests: CreateProServiceCustomOptionRequest[],
}

export interface CreateProServicePriceOptionRequest {
    PriceOptionType: ProServicePriceOptionType,
    Price: number,
    WorkingDay: number,
    ModifyCount: number,
    DraftCount: number,
    CustomOptions: string,
}

export interface CreateProServiceCustomOptionRequest {
    Title: string,
    Price: number,
}

export interface CreateProServiceEstimateRequest {
    RequestTargetUser: UserType,
    Title: string,
    Categories: string[],
    PlatformType: PlatformType,
    AdvertisingType: AdvertisingType,
    WorkingDay: number,
    MinBudget: number,
    MaxBudget: number,
    StartAt: Date,
    EndAt: Date,
    Comment: string
}

export interface ModifyProServiceEstimateRequest {
    BoardId: number,
    RequestTargetUser: UserType,
    Title: string,
    Categories: string[],
    PlatformType: PlatformType,
    AdvertisingType: AdvertisingType,
    WorkingDay: number,
    MinBudget: number,
    MaxBudget: number,
    StartAt: Date,
    EndAt: Date,
    Comment: string
}

export interface CreateProServiceJoinEstimateRequest {
    BoardId: number,
    Price: number,
    Comment: string,
}