import React from "react";

import default_img from "../img/m/file-speaker.svg"
import {useNavigate} from "react-router-dom";

function MobileJoinEstimate(props: { joinEstimate: any }) {
    const navigate = useNavigate();
    const requestEstimate = () => {
        navigate(`/requirements-list/common-requirements-list-detail/${props.joinEstimate.bid}`, {
            state: {
                userId: props.joinEstimate.userId,
                price: props.joinEstimate.price,
                comment: props.joinEstimate.comment
            }
        })
    };
    
    return (
        <div className="item" onClick={() => requestEstimate()}>
            <div className="product">
                <div className="thumbnail width">
                    <div className="image_area">
                        <img src={props.joinEstimate.profileImg || default_img} alt="" style={{objectFit: "contain"}}/>
                    </div>
                    <div className="text_area">
                        <a className="cate type-star">
                            <span>{props.joinEstimate.category.replaceAll(";", ", ")}</span>
                            <span className="grade"><span className="star">★</span>{props.joinEstimate.rating}</span>
                        </a>
                        <a className="title">{props.joinEstimate.name}</a>
                    </div>
                </div>
            </div>
            <div className="info">
                <div className="name">받은 견적 가격</div>
                <div className="result price">{props.joinEstimate.price.toLocaleString()}원</div>
            </div>
            <div className="info">
                <div className="name">기타 메세지</div>
                <div className="result">{props.joinEstimate.comment}</div>
            </div>
        </div>
    );
}

export default MobileJoinEstimate;