import React from "react";

function FilteringEmpty() {
    return (
        <div className="cont-list d-flex justify-content-center align-items-center" style={{height: "219.12rem"}}>
            <div className="thumbnail width">
                <div className="text_area">
                    <div className="price_area" style={{marginTop: "0"}}>
                        <div className="price">
                            <span style={{color: "#cccccc", fontWeight: 400, fontSize: "5.5rem"}}>
                                필터링 결과가 존재하지 않습니다.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilteringEmpty;