import {PlatformType} from "./platform"

export interface RegisterInfluencerMemberRequest {
    Name: string,
    ProfileImg: string,
    Job: string,
    Address1: string,
    Address2: string,
    IdentificationNumber: string,
    BankNumber: string,
    BankCode: string,
    BankOwner: string,
    RegionOfInterest: string[],
    AreaOfInterest: string[],
    InstagramUrl: string,
    FacebookUrl: string,
    TwitterUrl: string,
    NaverBlogUrl: string,
    NaverCafeUrl: string,
    YoutubeUrl: string,
    KakaoStoryUrl: string,
    EtcUrl: string,
    Comment: string,
    DetailComment: string,
    YearOfService: number
    BusinessHoursStart: string
    BusinessHoursEnd: string
    ExceptForWeekEnds: boolean
}

export interface UpdateInfluencerMemberRequest {
    name: string,
    profileImg: string,
    job: string,
    address1: string,
    address2: string,
    identificationNumber: string,
    bankNumber: string,
    bankCode: string,
    bankOwner: string,
    regionOfInterest: string[],
    areaOfInterest: string[],
    instagramUrl: string,
    facebookUrl: string,
    twitterUrl: string,
    naverBlogUrl: string,
    naverCafeUrl: string,
    youtubeUrl: string,
    kakaoStoryUrl: string,
    etcUrl: string,
    comment: string,
    detailComment: string,
    yearOfService: number
    businessHoursStart: string
    businessHoursEnd: string
    exceptForWeekEnds: boolean,
    email: string
}

export const initData: RegisterInfluencerMemberRequest = {
    Name: '',
    ProfileImg: '',
    Job: '',
    Address1: '',
    Address2: '',
    IdentificationNumber: '',
    BankNumber: '',
    BankCode: '',
    BankOwner: '',
    RegionOfInterest: [],
    AreaOfInterest: [],
    InstagramUrl: '',
    FacebookUrl: '',
    TwitterUrl: '',
    NaverBlogUrl: '',
    NaverCafeUrl: '',
    YoutubeUrl: '',
    KakaoStoryUrl: '',
    EtcUrl: '',
    Comment: '',
    DetailComment: '',
    YearOfService: 0,
    BusinessHoursStart: '',
    BusinessHoursEnd: '',
    ExceptForWeekEnds: false
}

export interface CreateInfluencerRequest {
    CategoryId: number,
    SubCategoryId: number,
    Title: string,
    Detail: string,
    MediaRequests: CreateInfluencerMediaRequest[],
}

export interface CreateInfluencerMediaRequest {
    PlatformType: PlatformType,
    Link: string,
    SubscribeCount: number,
    CostPerClick: number,
    CostPerFeed: number,
}
