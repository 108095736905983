import React from "react";
import {UserType} from "./user-type";

export function DivEstimateOrderType(props: { requestUserType: UserType }) {
    if (props.requestUserType == UserType.Influencer) {
        return (<a className="button round-badge green">인플루언서</a>);
    } else if (props.requestUserType == UserType.Pro) {
        return (<a className="button round-badge purple">전문가</a>);
    }

    throw new Error(`[DivInfluMinimumLevel]: ${props.requestUserType} not implemented.`)
}

export function DivEstimatePaymentType(props: { type: EstimatePaymentType }) {
    if (props.type == EstimatePaymentType.RequestPayment) {
        return (<a className="button round-badge black">결제대기</a>);
    } else if (props.type == EstimatePaymentType.CompletePayment) {
        return (<a className="button round-badge gray">결제완료</a>);
    } else if(props.type == EstimatePaymentType.FailPayment) {
        return (<a className="button round-badge" style={{background: "#ff00ff"}}>결제실패</a>);
    } else {
        return (<a className="button round-badge" style={{background: "#aaaaaa"}}>결제취소</a>);
    }
}

export interface Estimate {
    RequestTargetUser: UserType,
    Title: string,
    JoinedCount: number,
    Categories: string[],
    CreatedAt: Date
}

export type JoinEstimate = {
    UserId: string,
    ProfileImg: string,
    Name: string,
    Category: string,
    Rating: number,
    Price: number,
    Comment: string
}

export type ProEstimate = {
    category: string,
    rating: number,
    title: string,
    price: number,
    options: EstimateOption[]
}

export type EstimateOption = {
    name: string,
    price: number
}

export const enum EstimatePaymentType {
    None,
    RequestPayment,
    CompletePayment,
    FailPayment,
    CancelPayment
}