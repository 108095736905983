import React, {useEffect, useState} from "react";

import header_close from "img/m/header-close.svg";
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {UserType} from "../../types/user-type";
import {getPlatformName} from "../../types/platform";
import {AdvertisingType} from "../../types/advertising-type";
import MobileJoinEstimate from "../../components/m-join-estimate";
import {EstimatePaymentType} from "../../types/estimate";

function AdPaymentRequire() {
    const params = useParams();
    const [require, setRequire] = useState<any>();
    const [joinList, setJoinList] = useState<any[]>([]);
    const [completed, setCompleted] = useState(false);
    const [tab, setTab] = useState<string>("req");
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                setRequire(data);
            });
        axios.get(`api/MyAdvertiser/estimate/${params.bid}/joined`)
            .then(response => {
                let data = response.data;

                axios.get(`api/MyAdvertiser/estimate/${params.bid}/received`)
                    .then(response => {
                        let data2 = response.data;
                        let items: any[] = [];

                        data2.forEach((rcv: any) => {
                            let join = data.find((j: any) => j.userId == rcv.userId && j.bid == rcv.boardId);

                            if (join) {
                                items.push(join);
                            }
                        });

                        setJoinList(items);
                        
                        let paidRcv = data2.find((r: any) => r.paymentProcessType == EstimatePaymentType.CompletePayment);
                        
                        if(paidRcv) {
                            setCompleted(true);
                        }
                    });
            });
    }, [params]);

    if (require == null) {
        return (<></>);
    }

    const userType = require.requestTargetUser === UserType.Influencer ? "인플루언서" : "전문가";
    const adType = require.advertisingType === AdvertisingType.FeedUpload ? "피드업로드" :
        require.advertisingType === AdvertisingType.Review ? "후기작성" :
            require.advertisingType === AdvertisingType.ExperienceGroup ? "체험단" :
                require.advertisingType === AdvertisingType.RequestKakaoFriend ? "카카오톡 친구추천" : "";
    const price = require.minBudget === 0 && require.maxBudget === 0 ? "협의" :
        require.minBudget.toLocaleString() + "원 ~ " + require.maxBudget.toLocaleString() + "원";

    const endRequirement = () => {
        if(confirm("마감하시겠습니까?")) {
            axios.post(`/api/Advertiser/estimate/${params.bid}`).then(response => {
                alert("마감 처리 되었습니다.");
                location.href = "/requirements-list/ad-requirements-list";
            }).catch(error => {
                console.log(error);
                alert("마감에 실패했습니다.");
                return;
            });
        }
    };

    const back = () => {
        navigate(`/requirements-list/ad-requirements-list`);
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">견적요청서</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>

                <div className={`requirements${require.enabled ? " detail" : ""}`}>
                    <div className="requir-head">
                        <div className="title">{require.title}</div>
                        <div className="date">{moment(require.createAt).format("YYYY.MM.DD")}</div>
                    </div>
                    {require.enabled ?
                        <div className="requir-tab">
                            <ul className="navigation tab blue">
                                <li id="tab-01" className={tab === "req" ? "on" : ""}>
                                    <a onClick={() => setTab("req")}>광고정보</a>
                                </li>
                                <li id="tab-02" className={tab === "join" ? "on" : ""}>
                                    <a onClick={() => setTab("join")}>완료 견적서 {require.receivedCount}건</a>
                                </li>
                            </ul>
                        </div>
                        : null
                    }
                    <div className={`requir-body${tab === "req" ? " show" : ""}`} id="contents-01">
                        <div className="requir-block">
                            <div className="title">견적서 요청할 사람</div>
                            <div className="desc">{userType}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 분야</div>
                            <div className="desc">
                                {require.categories.map((category: string, idx: number) => (
                                    <a key={idx} className="button round blue big"
                                       style={{marginRight: "3px"}}>{category}</a>
                                ))}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고채널</div>
                            <div className="desc">{getPlatformName(require.platformType)}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고방식</div>
                            <div className="desc">{adType}</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고기간</div>
                            <div className="desc">
                                {moment(require.startAt).format("YYYY.MM.DD")} ~ {moment(require.endAt).format("YYYY.MM.DD")}
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 예상 금액</div>
                            <div className="desc">{price}</div>
                        </div>
                    </div>
                    <div className={`requir-body${tab === "join" ? " show" : ""}`} id="contents-02">
                        <div className="requir_participate">
                            {joinList.map((join, idx) => (
                                <MobileJoinEstimate key={idx} joinEstimate={join}/>
                            ))}
                        </div>
                    </div>
                </div>

                {tab === "req" ?
                <div className="bottom chap d-flex">
                    {completed ? <a className="button full gray">결제완료</a> :
                    <>
                    {require.receivedCount > 0 ?
                    <Link to={`/requirements/payments/${params.bid}`} className="button full black">결제하기</Link> : null}
                    {require.enabled ? <a onClick={() => endRequirement()} className="button full black">마감</a> : null}
                    </>
                    }
                </div>
                : null}
            </div>
        </div>
    );
}

export default AdPaymentRequire;