import React, {useEffect, useState} from "react";
import {Nav, Tab} from "react-bootstrap";

import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import MobileDetailComment from "components/m-detail-comment";
import {CampaignComment} from "types/campaign";
import {ProServiceItem} from "types/pro-service";
import dummy_profile from "img/m/request-pro.png";
import header_back from "img/m/header-back.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {getLocalSession} from "../../modules/localSession";
import {UserType} from "../../types/user-type";

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function ButtonOrder() {
    {/* TODO : 링크 연결 필요 */}
    return (<a className="button full black fixed">의뢰하기</a>);
}

function DivServiceDetail(props: { name: string, price: number }) {
    return (
        <div>
            <div className="subject">{props.name}</div>
            <div className="price">{props.price.toLocaleString()}원</div>
        </div>
    );
}

function ProProfile() {
    const params = useParams();
    const session = getLocalSession();
    const [profile, setProfile] = useState<any>();
    const [service, setService] = useState<any>();
    const [review, setReview] = useState<any>();
    const [zzim, setZzim] = useState(false);

    useEffect(() => {
        axios.get(`api/ProService/profile/${params.userId}`)
            .then(response => {
                setProfile(response.data);
            });
        axios.get(`api/ProService/profile/${params.userId}/service`)
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.proItems;

                            data.items.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setService({...data, items: newItems});
                        });
                } else {
                    newItems = data.items.map((c: any) => ({...c, isZzim: false}));
                    setService({...data, items: newItems});
                }
            });
        axios.get(`api/ProService/profile/${params.userId}/review`)
            .then(response => {
                setReview(response.data);
            });

        if(session) {
            axios.get(`api/Zzim/user/${params.userId}`)
                .then(response => {
                    let data = response.data;
                    setZzim(data.isZzim);
                });
        }
    }, [params]);

    if (profile == null) {
        return (<></>);
    }

    if (service == null) {
        return (<></>);
    }

    if (review == null) {
        return (<></>);
    }
    
    const serviceTags: string[] = profile.detailedField;
    const careerSet: any[] = profile.careers;
    const careers: string[] = careerSet.map(ca => (
        ca.title + " " + ca.startAt.split("T")[0] + "~" + ca.endAt.split("T")[0])
    );
    
    const certifications: string[] = profile.skills;
    const services: any[] = service.items;
    const reviews: CampaignComment[] = review.comments;

    const changeZzim = () => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: params.userId, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    };

    return (
        <div>
            <div className="contents mobile page-profile">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">프로필</span>
                    </div>
                    <div className="header_right">
                        {session && session.userType !== UserType.None ?
                        <div onClick={() => changeZzim()} className={`button heart purple border${zzim ? " on" : ""}`}></div>
                        : null }
                    </div>
                </header>
                <div className="user">
                    <div className="img"><img src={profile.profileImg || dummy_profile} alt=""/></div>
                    <div className="name">{profile.name}</div>
                    <div className="comm">{profile.comment}</div>
                </div>
                <div className="review">
                    <div className="count">
                        <div className="num"><span>{profile.workCount}</span>건</div>
                        <div className="num"><span>{profile.yearOfService}</span>년</div>
                        <div className="title">총 작업수/경력</div>
                    </div>
                    <div className="score">
                        <div className="num">{profile.rating}</div>
                        <div className="star">
                            <DivCommentStar num={1} star={profile.rating}/>
                            <DivCommentStar num={2} star={profile.rating}/>
                            <DivCommentStar num={3} star={profile.rating}/>
                            <DivCommentStar num={4} star={profile.rating}/>
                            <DivCommentStar num={5} star={profile.rating}/>
                        </div>
                        <div className="title">평균평점</div>
                    </div>
                    <div className="count">
                        <div className="num">{`${profile.businessHoursStart}~${profile.businessHoursEnd}`}</div>
                        <div>{profile.exceptForWeekEnds === true ? "(주말제외)" : ""}</div>
                        <div className="title">연락 가능 시간</div>
                    </div>
                </div>

                <Tab.Container defaultActiveKey="info">
                    <Nav className="navigation tab purple" as={"ul"}>
                        <Nav.Link eventKey="info" as={"li"}>
                            <a>기본정보</a>
                        </Nav.Link>
                        <Nav.Link eventKey="service" as={"li"}>
                            <a>서비스</a>
                        </Nav.Link>
                        {/*<Nav.Link eventKey="portfolio" as={"li"}>
                            <a>포트폴리오</a>
                        </Nav.Link>*/}
                        <Nav.Link eventKey="review" as={"li"}>
                            <a>후기</a>
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="info">
                            <div className="tab_cont">
                                <div className="exp"
                                     dangerouslySetInnerHTML={{
                                         __html: profile.detailComment.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                     }}
                                >
                                </div>
                                <div className="section">
                                    <div className="title">제공 서비스</div>
                                    {serviceTags.map((services, key) => (
                                        <React.Fragment key={key}>
                                            <a className="button round-deep purple">{services}</a>
                                            &nbsp;
                                        </React.Fragment>
                                    ))}
                                </div>
                                <div className="section">
                                    <div className="title">경력사항</div>
                                    <ul className="career">
                                        {careers.map((c, key) => (
                                            <li key={key}>{c}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="section">
                                    <div className="title">자격사항</div>
                                    <ul className="certification">
                                        {certifications.map((c, key) => (
                                            <li key={key}>{c}</li>
                                        ))}
                                    </ul>
                                </div>
                                {/*<ButtonOrder/>*/}
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="service">
                            <div className="tab_cont">
                                {/*TODO : 차후 api 추가*/}
                                {/*<div className="section">*/}
                                {/*    <div className="title">서비스 상세설명/단가</div>*/}
                                {/*    <div className="service">*/}
                                {/*        {serviceOptions.map((service, key) => (*/}
                                {/*            <DivServiceDetail key={key} name={service.name} price={service.price}/>*/}
                                {/*        ))}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="section">
                                    <div className="title">서비스 상세설명/단가</div>
                                    <div className="thumbnail_list">
                                        {services.map((service, key) => (
                                            <MobileProServiceThumbnail key={key} campaign={service}/>
                                        ))}
                                    </div>
                                </div>
                                {/*<ButtonOrder/>*/}
                            </div>
                        </Tab.Pane>
                        {/*<Tab.Pane eventKey="portfolio">
                            <div className="tab_cont">
                                <div className="section">
                                    <div className="portfolio">
                                        {portfolio}
                                    </div>
                                </div>
                                <ButtonOrder/>
                            </div>
                        </Tab.Pane>*/}
                        <Tab.Pane eventKey="review">
                            <div className="tab_cont">
                                <div className="section">
                                    <div className="c-review_list">
                                        {reviews.map((comment, key) => (
                                            <MobileDetailComment key={key} campaignComment={comment}/>
                                        ))}
                                    </div>
                                </div>
                                {/*<ButtonOrder/>*/}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default ProProfile;