import React, {useEffect, useState} from "react";
import {Accordion, Nav, Tab} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

import MobileInfluThumbnail from "components/m-influ-thumbnail";
import MobileDetailComment from "components/m-detail-comment";

import header_back from "img/m/header-back.svg";
import dummy_profile from "img/m/request-influe.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import icon_naverblog from "img/m/icon-naverblog.png";
import icon_twitter from "img/m/icon-twitter.png";
import icon_navercafe from "img/m/icon-naver.svg";
import icon_kakaostory from "img/m/icon-kakao.svg"
import {useParams} from "react-router-dom";
import axios from "axios";
import {getPlatformIcon, getPlatformName} from "../../types/platform";
import {getLocalSession} from "../../modules/localSession";
import {UserType} from "../../types/user-type";

function DivPlatformPrice(props: { platformName: string; platformIcon: string, likePrice: number, feedPrice: number }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>();

    return (
        <Accordion className="type-channel_price"
                   onSelect={setActiveKey}>
            <Accordion.Item eventKey="0" bsPrefix="#">
                <Accordion.Button as={"div"} className="head" bsPrefix="#">
                    <span className="name">
                        <img src={props.platformIcon} alt=""/>
                        <span>{props.platformName}</span>
                    </span>
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    <div className="ad_detail">
                        <div className="like">
                            <div className="title">좋아요</div>
                            <div className="ea">
                                1회당<span>{props.likePrice.toLocaleString()}</span>원
                            </div>
                        </div>
                        <div className="feed">
                            <div className="title">피드 (해시태그포함)</div>
                            <div className="ea">
                                1건당<span>{props.feedPrice.toLocaleString()}</span>원
                            </div>
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function InfluProfile() {
    const params = useParams();
    const session = getLocalSession();
    const [profile, setProfile] = useState<any>();
    const [service, setService] = useState<any>();
    const [review, setReview] = useState<any>();
    const [zzim, setZzim] = useState(false);

    useEffect(() => {
        axios.get(`api/Influencer/profile/${params.userId}`)
            .then(response => {
                setProfile(response.data);
            });
        axios.get(`api/Influencer/profile/${params.userId}/service`)
            .then(response => {
                let data = response.data;
                let newItems: any = {};

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.influItems;
                            let zzim = data2.find((z: any) => z.boardId == data.bid);

                            if(zzim) {
                                newItems = {...data, isZzim: zzim.isZzim};
                            } else {
                                newItems = {...data, isZzim: false};
                            }

                            setService(newItems);
                        });
                } else {
                    newItems = {...data, isZzim: false};
                    setService(newItems);
                }
            });
        axios.get(`api/Influencer/profile/${params.userId}/review`)
            .then(response => {
                setReview(response.data);
            });

        if(session) {
            axios.get(`api/Zzim/user/${params.userId}`)
                .then(response => {
                    let data = response.data;
                    setZzim(data.isZzim);
                });
        }
    }, [params]);

    if (profile == null) {
        return (<></>);
    }

    if (service == null) {
        return (<></>);
    }

    if (review == null) {
        return (<></>);
    }
    
    const influ: any = service;
    const reviews: any[] = review.comments;

    const changeZzim = () => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: params.userId, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    };

    return (
        <div>
            <div className="contents mobile page-profile">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">프로필</span>
                    </div>
                    <div className="header_right">
                        {session && session.userType !== UserType.None ?
                        <div onClick={() => changeZzim()} className={`button heart green border${zzim ? " on" : ""}`}></div>
                        : null }
                    </div>
                </header>
                <div className="user">
                    <div className="img"><img src={profile.profileImg || dummy_profile} alt=""/></div>
                    <div className="name">{profile.name}</div>
                    <div className="comm">{profile.comment}</div>
                </div>
                <div className="review">
                    <div className="count">
                        <div className="num"><span>{profile.workCount}</span>건</div>
                        <div className="num"><span>{profile.yearOfService}</span>년</div>
                        <div className="title">총 작업수/경력</div>
                    </div>
                    <div className="score">
                        <div className="num">{profile.rating}</div>
                        <div className="star">
                            <DivCommentStar num={1} star={profile.rating}/>
                            <DivCommentStar num={2} star={profile.rating}/>
                            <DivCommentStar num={3} star={profile.rating}/>
                            <DivCommentStar num={4} star={profile.rating}/>
                            <DivCommentStar num={5} star={profile.rating}/>
                        </div>
                        <div className="title">평균평점</div>
                    </div>
                    <div className="count">
                        <div className="num">{`${profile.businessHoursStart}~${profile.businessHoursEnd}`}</div>
                        <div>{profile.exceptForWeekEnds === true ? "(주말제외)" : ""}</div>
                        <div className="title">연락 가능 시간</div>
                    </div>
                </div>

                <Tab.Container defaultActiveKey="info">
                    <Nav className="navigation tab green" as={"ul"}>
                        <Nav.Link eventKey="info" as={"li"}>
                            <a>기본정보</a>
                        </Nav.Link>
                        <Nav.Link eventKey="campaign" as={"li"}>
                            <a>광고</a>
                        </Nav.Link>
                        <Nav.Link eventKey="review" as={"li"}>
                            <a>후기</a>
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="info">
                            <div id="tab01" className="tab_cont">
                                <div className="exp"
                                     dangerouslySetInnerHTML={{
                                         __html: profile.detailComment.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                     }}
                                >
                                </div>
                                <div className="section">
                                    <div className="title">진행 광고 채널</div>
                                    <div className="sns">
                                    {profile.instagramUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_instagram} alt=""/> Instagram</div>
                                            <a href={profile.instagramUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.facebookUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_facebook} alt=""/> Facebook</div>
                                            <a href={profile.facebookUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.twitterUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_twitter} alt=""/> Twitter</div>
                                            <a href={profile.twitterUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.naverBlogUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_naverblog} alt=""/> Naver Blog</div>
                                            <a href={profile.naverBlogUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.naverCafeUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_navercafe} alt=""/> Naver Cafe</div>
                                            <a href={profile.naverCafeUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.kakaoStoryUrl ? (
                                        <div className="inner">
                                            <div className="img"><img src={icon_kakaostory} alt=""/> Kakao Story</div>
                                            <a href={profile.kakaoStoryUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.youtubeUrl ? (
                                        <div className="inner">
                                            <div className="img"> Youtube</div>
                                            <a href={profile.youtubeUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    {profile.etcUrl ? (
                                        <div className="inner">
                                            <div className="img"> 기타</div>
                                            <a href={profile.etcUrl} target={"_blank"}
                                               className="button small round gray">채널보기</a>
                                        </div>
                                    ) : null}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="campaign">
                            <div className="tab_cont">
                                <div className="list-list">
                                    <div className="thumbnail wrap">
                                        <div className="line one">
                                            <MobileInfluThumbnail influ={influ}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="section">
                                    <div className="title">채널별 단가</div>
                                    <div className="ad_price">
                                        {influ.platforms.map((inf: any, idx: number) => (
                                            <DivPlatformPrice key={idx}
                                                platformName={getPlatformName(inf.type)}
                                                platformIcon={getPlatformIcon(inf.type)}
                                                likePrice={inf.costPerClick}
                                                feedPrice={inf.costPerFeed}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="review">
                            <div className="tab_cont">
                                <div className="section">
                                    <div className="c-review_list">
                                        {reviews.map((comment, key) => (
                                            <MobileDetailComment key={key} campaignComment={comment}/>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default InfluProfile;