import React, {useEffect, useRef, useState} from "react";
import {Nav, Tab} from "react-bootstrap";

import header_back from "img/m/header-back.svg";
import file_photo from "img/m/file-photo.svg";
import file_speaker from "img/m/file-speaker.svg";
import {
    UpdateProServiceMemberRequest
} from "../../types/pro-service-request";
import axios from "axios";
import {getLocalSession, removeLocalSession} from "../../modules/localSession";
import {BankCode} from "../../utils/bank-code";
import dash from "../../img/m/dash.svg";
import WishExpert from "../member/wish-expert";
const { RangePicker } = DatePicker;
import {DatePicker, TimePicker} from "antd";
import moment from "moment";
import {useNavigate} from "react-router-dom";

let memData: any = {};

type setStrState =  React.Dispatch<React.SetStateAction<string>>;
type setStrArrState =  React.Dispatch<React.SetStateAction<string[]>>;

function DivCateBadge(props: { category: string, setCategory: setStrState, setDetail: setStrArrState }) {
    const deleteCate = () => {
        props.setCategory('');
        props.setDetail([]);
        memData.RepresentativeActivitiesField = [];
        memData.DetailedField = [];
    };

    return (
        props.category.length < 1 ? <>분야를 선택하세요.</> :
        <a onClick={() => deleteCate()} className="button close_type purple">
            <span className="wrap">
                <span className="name">{props.category}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function DivDetailBadge(props: { detail: string, details: string[], setDetail: setStrArrState }) {
    const deleteDetail = () => {
        let selDetail = props.details.filter(item => item !== props.detail);
        props.setDetail(selDetail);
        memData.DetailedField = selDetail;
    };

    return (
        <a onClick={() => deleteDetail()} className="button close_type purple">
            <span className="wrap">
                <span className="name">{props.detail}</span>
                <span className="exit"></span>
            </span>
        </a>
    );
}

function ProProfileModify() {
    const session = getLocalSession();
    const [pwd, setPwd] = useState<string>("");
    const [repeatPwd, setRepeatPwd] = useState<string>("");
    const [profileData, setProfileData] = useState<UpdateProServiceMemberRequest>();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [careers, setCareers] = useState<any[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [wishPro, setWishPro] = useState(false);
    const [wishCateSel, setWishCateSel] = useState<string>('');
    const [wishDetailSel, setWishDetailSel] = useState<string[]>([]);
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [tab, setTab] = useState<string>("defaultInfo");
    const navigate = useNavigate();

    const proMemberRef = {
        Name: useRef<HTMLInputElement>(null),
        IdentificationNumber: useRef<HTMLInputElement>(null),
        BankNumber: useRef<HTMLInputElement>(null),
        BankCode: useRef<HTMLSelectElement>(null),
        BankOwner: useRef<HTMLInputElement>(null),
        BusinessOwner: useRef<HTMLInputElement>(null),
        Address1: useRef<HTMLInputElement>(null),
        Address2: useRef<HTMLInputElement>(null),
        ContactNumber: useRef<HTMLInputElement>(null),
        BusinessHoursStart: useRef<HTMLInputElement>(null),
        BusinessHoursEnd: useRef<HTMLInputElement>(null),
        CareerTitle: useRef<HTMLInputElement[]>([]),
        CareerDt: useRef<HTMLInputElement[]>([]),
        Skill: useRef<HTMLInputElement[]>([]),
        Comment: useRef<HTMLInputElement>(null),
        DetailComment: useRef<HTMLTextAreaElement>(null),
        pwd: useRef<HTMLInputElement>(null),
        repeatPwd: useRef<HTMLInputElement>(null)
    };

    useEffect(() => {
        axios.get(`api/MyProService/detail`)
            .then(response => {
                let data = response.data;

                setProfileData(data);
                setProfileImage(data.profileImg);
                setWishCateSel(data.representativeActivitiesField.length > 0 ? data.representativeActivitiesField[0] : '');
                setWishDetailSel(data.detailedField);
                setSkills(data.skills);
                setStartTime(moment(new Date(moment().format("YYYY-MM-DD") + " " + data.businessHoursStart)));
                setEndTime(moment(new Date(moment().format("YYYY-MM-DD") + " " + data.businessHoursEnd)));

                setCareers(data.careers.map((ca: any) => (
                    {
                        ...ca, StartDt: moment(new Date(ca.startAt)), EndDt: moment(new Date(ca.endAt)),
                        StartDtStr: moment(new Date(ca.startAt.split("T")[0])).format("YYYYMM"),
                        EndDtStr: moment(new Date(ca.endAt.split("T")[0])).format("YYYYMM")
                    }
                )));

                memData = {
                    IsBusiness: data.isBusiness,
                    Name: data.name,
                    ProfileImg: data.profileImg,
                    IdentificationNumber: data.identificationNumber,
                    BusinessOwner: data.businessOwner,
                    Address1: data.address1,
                    Address2: data.address2,
                    ContactNumber: data.contactNumber,
                    BankNumber: data.bankNumber,
                    BankCode: data.bankCode,
                    BankOwner: data.bankOwner,
                    RepresentativeActivitiesField: data.representativeActivitiesField,
                    DetailedField: data.detailedField,
                    YearOfService: data.yearOfService,
                    BusinessHoursStart: data.businessHoursStart,
                    BusinessHoursEnd: data.businessHoursEnd,
                    ExceptForWeekEnds: data.exceptForWeekEnds,
                    Skills: data.skills,
                    Careers: data.careers,
                    Comment: data.comment,
                    DetailComment: data.detailComment
                };
            });
    }, []);

    if (profileData == null) {
        return (<></>);
    }

    const profileImageReader = new FileReader();
    profileImageReader.onloadend = (e) => {
        setProfileImage(profileImageReader.result);
        memData.ProfileImg = profileImageReader.result || "";
    }

    const handleProfileImageChange = (e: any) => {
        const img = e.target.files[0];

        if (img) {
            profileImageReader.readAsDataURL(img);
        }
    };

    const selectCate = (value: string) => {
        setWishCateSel(value);
        memData.RepresentativeActivitiesField = [value];
    };

    const selectDetail = (values: string[]) => {
        setWishDetailSel(values);
        memData.DetailedField = values;
    };

    const addCareer = () => {
        let addItem = {title:'', StartDt:null, EndDt:null, StartDtStr:'', EndDtStr:''};
        setCareers([...careers, addItem]);

    };

    const addSkill = () => {
        setSkills([...skills, '']);
    }

    const handleDataChange = (e: any) => {
        let value = e.target.value;
        let targetName = e.target.name.charAt(0).toLowerCase() + e.target.name.substring(1);

        if("ExceptForWeekEnds" == e.target.name) {
            value = !profileData.exceptForWeekEnds;
        }
        setProfileData({
            ...profileData,
            [targetName]: value
        });

        memData[e.target.name] = value;
    };

    const setCareerTitle = (e: any, idx: number) => {
        setCareers(careers.map((ca, i) => (
            i === idx ? { ...ca, title: e.target.value } : ca
        )));
    };

    const setCareerDate = (dates: any, dateStrings: [string, string], idx: number) => {
        const [startDt, endDt] = dates;
        const [startDtStr, endDtStr] = dateStrings;
        setCareers(careers.map((ca, i) => (
            i === idx ? { ...ca, StartDt: startDt, EndDt: endDt, StartDtStr:startDtStr, EndDtStr:endDtStr } : ca
        )));
    };

    const logout = () => {
        axios.post("api/Account/logout")
            .then(response => {
                removeLocalSession();
                location.href = "/";
            });
    }

    const updateProfile = () => {
        let regNum = /^[0-9]+$/;

        if(!("" == pwd && "" == repeatPwd)) {
            if (pwd !== repeatPwd) {
                alert("비밀번호가 일치하지 않습니다.");
                setTab("defaultInfo");
                if (proMemberRef.pwd.current !== null) proMemberRef.pwd.current.focus();
                return;
            }
        }

        if (!memData.IsBusiness) {
            if ('' == memData.Name) {
                alert("이름을 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.Name.current !== null) proMemberRef.Name.current.focus();
                return;
            }

            /*if ('' == memData.IdentificationNumber) {
                alert("주민번호를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.IdentificationNumber.current !== null)
                    proMemberRef.IdentificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("주민번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.IdentificationNumber.current !== null)
                    proMemberRef.IdentificationNumber.current.focus();
                return;
            }*/

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankNumber.current !== null) proMemberRef.BankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankNumber.current !== null) proMemberRef.BankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankCode.current !== null) proMemberRef.BankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankOwner.current !== null) proMemberRef.BankOwner.current.focus();
                return;
            }
        } else {
            if ('' == memData.Name) {
                alert("기업명을 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.Name.current !== null) proMemberRef.Name.current.focus();
                return;
            }

            if ('' == memData.IdentificationNumber) {
                alert("사업자번호를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.IdentificationNumber.current !== null)
                    proMemberRef.IdentificationNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.IdentificationNumber)) {
                alert("숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.IdentificationNumber.current !== null)
                    proMemberRef.IdentificationNumber.current.focus();
                return;
            }

            if ('' == memData.BusinessOwner) {
                alert("대표자명을 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BusinessOwner.current !== null) proMemberRef.BusinessOwner.current.focus();
                return;
            }

            if ('' == memData.Address1) {
                alert("주소를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.Address1.current !== null) proMemberRef.Address1.current.focus();
                return;
            }

            if ('' == memData.Address2) {
                alert("상세주소를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.Address2.current !== null) proMemberRef.Address2.current.focus();
                return;
            }

            if ('' == memData.ContactNumber) {
                alert("담당자 연락처를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.ContactNumber.current !== null) proMemberRef.ContactNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.ContactNumber)) {
                alert("담당자 연락처를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.ContactNumber.current !== null) proMemberRef.ContactNumber.current.focus();
                return;
            }

            if ('' == memData.BankNumber) {
                alert("계좌번호를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankNumber.current !== null) proMemberRef.BankNumber.current.focus();
                return;
            }

            if (!regNum.test(memData.BankNumber)) {
                alert("계좌번호를 숫자로만 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankNumber.current !== null) proMemberRef.BankNumber.current.focus();
                return;
            }

            if ('' == memData.BankCode) {
                alert("은행명을 선택해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankCode.current !== null) proMemberRef.BankCode.current.focus();
                return;
            }

            if ('' == memData.BankOwner) {
                alert("예금주를 입력해주세요.");
                setTab("profileInfo");
                if (proMemberRef.BankOwner.current !== null) proMemberRef.BankOwner.current.focus();
                return;
            }
        }

        if (memData.RepresentativeActivitiesField.length < 1) {
            alert("관심 분야를 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if (memData.DetailedField.length < 1) {
            alert("상세 분야를 선택해주세요.");
            setTab("extraInfo");
            return;
        }

        if (!startTime) {
            alert("연락 가능 시간을 입력해주세요.");
            setTab("extraInfo");
            if (proMemberRef.BusinessHoursStart.current !== null) proMemberRef.BusinessHoursStart.current.focus();
            return;
        }

        if (!endTime) {
            alert("연락 가능 시간을 입력해주세요.");
            setTab("extraInfo");
            if (proMemberRef.BusinessHoursEnd.current !== null) proMemberRef.BusinessHoursEnd.current.focus();
            return;
        }

        let flag = true;

        careers.some((ca, idx) => {
            if ('' == ca.Title) {
                alert("경력을 입력해주세요.");
                setTab("extraInfo");
                if (proMemberRef.CareerTitle.current[idx] !== null) proMemberRef.CareerTitle.current[idx].focus();
                flag = false;
                return true;
            }

            if ('' == ca.StartDtStr) {
                alert("경력 기간을 입력해주세요.");
                setTab("extraInfo");
                if (proMemberRef.CareerDt.current[idx] !== null) proMemberRef.CareerDt.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if (!flag) {
            return;
        } else {
            flag = true;
        }

        skills.some((skill, idx) => {
            if ('' == skill) {
                alert("자격 및 기술을 입력해주세요.");
                setTab("extraInfo");
                if (proMemberRef.Skill.current[idx] !== null) proMemberRef.Skill.current[idx].focus();
                flag = false;
                return true;
            }
        });

        if (!flag) {
            return;
        }

        if ('' == memData.Comment) {
            alert("자기 소개를 입력해주세요.");
            setTab("extraInfo");
            if (proMemberRef.Comment.current !== null) proMemberRef.Comment.current.focus();
            return;
        }

        if ('' == memData.DetailComment) {
            alert("자기 소개를 입력해주세요.");
            setTab("extraInfo");
            if (proMemberRef.DetailComment.current !== null) proMemberRef.DetailComment.current.focus();
            return;
        }

        memData.BusinessHoursStart = startTime.format('HH:mm');
        memData.BusinessHoursEnd = endTime.format('HH:mm');
        memData.Careers = [];
        memData.Skills = [];
        
        careers.forEach((ca) => {
            memData.Careers.push({
                Title: ca.title,
                StartAt: new Date(ca.StartDtStr.substring(0, 4) + '-' + ca.StartDtStr.substring(4, 6)),
                EndAt: new Date(ca.EndDtStr.substring(0, 4) + '-' + ca.EndDtStr.substring(4, 6))
            });
        });

        skills.forEach((skill) => {
            memData.Skills.push(skill);
        });
        
        console.log(memData);

        axios.patch('/api/MyProService', memData).then(response => {
            if("" !== pwd) {
                axios.patch('/api/Account/password', {Password: pwd}).then(response => {
                    alert("프로필 수정이 완료되었습니다.");
                    navigate('/my/my-pro-main');
                }).catch(error => {
                    console.log(error);
                    alert("시스템 오류가 발생했습니다.");
                    return;
                });
            } else {
                alert("프로필 수정이 완료되었습니다.");
                navigate('/my/my-pro-main');
            }
        }).catch(error => {
            console.log(error);
            alert("시스템 오류가 발생했습니다.");
            return;
        });
    };

    if(wishPro) {
        return (<WishExpert setWishPro={setWishPro} setSelect={selectCate} setSelectDetail={selectDetail}/>);
    } else {
        return (
            <div>
                <div className="contents mobile profile-modify-bg">
                    <header className="header-member">
                        <div className="header_left">
                            <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">개인정보 수정</span>
                        </div>
                        <div className="header_right">
                            <a onClick={() => logout()} className="logout">로그아웃</a>
                        </div>
                    </header>

                    {/* 프로필 사진 영역 */}
                    <div className="profile-modify">
                        <div className="profile-area">
                            <div className="my-main_profile">
                                <div className="file_upload center">
                                    <div className="circle">
                                        {!profileImage ? (<img className="speaker" src={file_speaker} alt=""/>)
                                            : (<img className="photo" src={profileImage.toString()} alt=""/>)}
                                    </div>
                                    <input type="file" accept="image/*" onChange={handleProfileImageChange}/>
                                    <img className="photo_button" src={file_photo} alt=""/>
                                </div>
                            </div>
                        </div>

                        <Tab.Container defaultActiveKey="defaultInfo">
                            {/* 탭 영역 */}
                            <Nav className="navigation tab purple tab-area" as={"ul"}>
                                <Nav.Link eventKey="defaultInfo" as={"li"} active={tab === "defaultInfo"}>
                                    <a onClick={() => setTab("defaultInfo")}>기본 정보</a>
                                </Nav.Link>
                                <Nav.Link eventKey="profileInfo" as={"li"} active={tab === "profileInfo"}>
                                    <a onClick={() => setTab("profileInfo")}>
                                        {profileData.isBusiness ? "기업정보" : "프로필정보"}
                                    </a>
                                </Nav.Link>
                                <Nav.Link eventKey="extraInfo" as={"li"} active={tab === "extraInfo"}>
                                    <a onClick={() => setTab("extraInfo")}>추가정보</a>
                                </Nav.Link>
                            </Nav>
                            {/* 콘텐츠 아레아 */}
                            <Tab.Content>
                                <Tab.Pane eventKey="defaultInfo" className="requirements-list-body"
                                          active={tab === "defaultInfo"}>
                                    <div className="detail_area">
                                        <div className="item">
                                            <span className="title">닉네임</span>
                                            <span className="info">{session?.displayName}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">이메일</span>
                                            <span className="info">{profileData.email}</span>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={pwd}
                                                   onChange={(e) => setPwd(e.target.value)}
                                                   ref={proMemberRef.pwd}/>
                                        </div>
                                        <div className="item">
                                            <span className="title">비밀번호 재입력</span>
                                            <input type="password" className="info border-0"
                                                   style={{backgroundColor: "inherit"}} value={repeatPwd}
                                                   onChange={(e) => setRepeatPwd(e.target.value)}
                                                   ref={proMemberRef.repeatPwd}/>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="profileInfo" className="requirements-list-body"
                                          active={tab === "profileInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02 border-0">
                                            <div className="item_title">
                                                <div className="title">신상정보</div>
                                            </div>
                                        </div>
                                    {profileData.isBusiness ?
                                        <>
                                        <div className="item type02 pt-0">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">기업명</div>
                                                    <input type="text" className="input basic" name="Name"
                                                           value={profileData.name} onChange={handleDataChange}
                                                           ref={proMemberRef.Name} placeholder="기업명을 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">사업자 등록번호</div>
                                                    <input type="text" className="input basic"
                                                           name="IdentificationNumber"
                                                           value={profileData.identificationNumber}
                                                           onChange={handleDataChange}
                                                           ref={proMemberRef.IdentificationNumber}
                                                           placeholder="- 제외, 숫자만 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">대표자명</div>
                                                    <input type="text" className="input basic"
                                                           name="BusinessOwner"
                                                           value={profileData.businessOwner}
                                                           onChange={handleDataChange}
                                                           ref={proMemberRef.BusinessOwner}
                                                           placeholder="대표자명을 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">주소</div>
                                                    <input type="text" className="input basic" name="Address1"
                                                           value={profileData.address1} onChange={handleDataChange}
                                                           ref={proMemberRef.Address1}
                                                           placeholder="예) 강남구 역삼동 123-45 (혹은 건물명)"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Address2"
                                                           value={profileData.address2} onChange={handleDataChange}
                                                           ref={proMemberRef.Address2}
                                                           placeholder="상세주소를 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">담당자 연락처</div>
                                                    <input type="text" className="input basic" name="ContactNumber"
                                                           value={profileData.contactNumber}
                                                           onChange={handleDataChange}
                                                           ref={proMemberRef.ContactNumber}
                                                           placeholder="- 제외, 숫자만 입력하세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">출금계좌번호</div>
                                                    <input type="text" className="input basic" name="BankNumber"
                                                           value={profileData.bankNumber} onChange={handleDataChange}
                                                           ref={proMemberRef.BankNumber}
                                                           placeholder="'-' 없이 계좌번호를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">은행명</div>
                                                    <div className="con">
                                                        <div className="write-select">
                                                            <select name="BankCode" value={profileData.bankCode}
                                                                    onChange={handleDataChange}
                                                                    ref={proMemberRef.BankCode}>
                                                            {BankCode.map((bank, key) => (
                                                                <option key={key} value={bank.code}>{bank.name}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <div className="title">예금주</div>
                                                    <input type="text" className="input basic" name="BankOwner"
                                                           value={profileData.bankOwner} onChange={handleDataChange}
                                                           ref={proMemberRef.BankOwner}
                                                           placeholder="예금주를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <>
                                            <div className="item type02 pt-0">
                                                <div className="item_body">
                                                    <div className="sub_item">
                                                        <div className="title">이름</div>
                                                        <input type="text" className="input basic" name="Name"
                                                               value={profileData.name} onChange={handleDataChange}
                                                               ref={proMemberRef.Name} placeholder="실명을 입력해주세요."/>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<div className="item type02">
                                                <div className="item_body">
                                                    <div className="sub_item">
                                                        <div className="title">주민번호</div>
                                                        <input type="text" className="input basic"
                                                               name="IdentificationNumber"
                                                               value={profileData.identificationNumber}
                                                               onChange={handleDataChange}
                                                               ref={proMemberRef.IdentificationNumber}
                                                               placeholder="'-' 없이 주민번호를 입력해주세요."/>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            <div className="item type02">
                                                <div className="item_body">
                                                    <div className="sub_item">
                                                        <div className="title">계좌번호</div>
                                                        <input type="text" className="input basic" name="BankNumber"
                                                               value={profileData.bankNumber} onChange={handleDataChange}
                                                               ref={proMemberRef.BankNumber}
                                                               placeholder="'-' 없이 계좌번호를 입력해주세요."/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item type02">
                                                <div className="item_body">
                                                    <div className="sub_item">
                                                        <div className="title">은행명</div>
                                                        <div className="con">
                                                            <div className="write-select">
                                                                <select name="BankCode" value={profileData.bankCode}
                                                                        onChange={handleDataChange}
                                                                        ref={proMemberRef.BankCode}>
                                                                    {BankCode.map((bank, key) => (
                                                                        <option key={key}
                                                                                value={bank.code}>{bank.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="item type02">
                                                <div className="item_body">
                                                    <div className="sub_item">
                                                        <div className="title">예금주</div>
                                                        <input type="text" className="input basic" name="BankOwner"
                                                               value={profileData.bankOwner} onChange={handleDataChange}
                                                               ref={proMemberRef.BankOwner}
                                                               placeholder="예금주를 입력해주세요."/>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="extraInfo" className="requirements-list-body"
                                          active={tab === "extraInfo"}>
                                    <div className="detail_area">
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">대표 활동 분야</div>
                                                <a onClick={() => setWishPro(true)}>선택하기</a>
                                            </div>
                                            <div className="item_body">
                                                <DivCateBadge category={wishCateSel} setCategory={setWishCateSel}
                                                              setDetail={setWishDetailSel}/>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">상세 분야</div>
                                            </div>
                                            <div className="item_body">
                                            {wishDetailSel.map((sel, idx) => (
                                                <DivDetailBadge key={idx} detail={sel} details={wishDetailSel}
                                                                setDetail={setWishDetailSel}/>
                                            ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">연락 가능 시간</div>
                                                <div className="choice checkbox">
                                                    <span style={{fontSize: "4.4rem"}}>주말 제외</span>
                                                    <span className="choice_check">
                                                    <input type="checkbox"
                                                           checked={profileData.exceptForWeekEnds}
                                                           onChange={handleDataChange} name="ExceptForWeekEnds"/>
                                                </span>
                                                </div>
                                            </div>
                                            <div className="item_body">
                                                <div className="d-flex justify-content-evenly">
                                                    <div className="time">
                                                        <TimePicker className="input basic" format="HH:mm"
                                                                    placeholder="HH24:MI"
                                                                    value={startTime}
                                                                    ref={proMemberRef.BusinessHoursStart}
                                                                    onChange={(time) => setStartTime(time)}/>
                                                    </div>
                                                    <div className="dash"><img src={dash} style={{width: "10px"}}
                                                                               alt=""/></div>
                                                    <div className="time">
                                                        <TimePicker className="input basic" format="HH:mm"
                                                                    placeholder="HH24:MI"
                                                                    value={endTime}
                                                                    ref={proMemberRef.BusinessHoursEnd}
                                                                    onChange={(time) => setEndTime(time)}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">경력</div>
                                                <a onClick={() => addCareer()}>추가하기</a>
                                            </div>
                                            <div className="item_body">
                                            {careers.map((career, idx) => (
                                                <div key={idx}>
                                                    <input className="input basic" type="text" placeholder="경력을 입력해주세요"
                                                           value={career.title} onChange={(event) => setCareerTitle(event, idx)}
                                                           ref={ref => {proMemberRef.CareerTitle.current[idx] = ref as HTMLInputElement}}/>
                                                    <RangePicker picker="month" format="YYYYMM" className="input basic"
                                                                 style={{marginTop: '5px', marginBottom: '10px'}}
                                                                 value={[career.StartDt, career.EndDt]} allowEmpty={[false, true]}
                                                                 onChange={(dates, dateStrings) => setCareerDate(dates, dateStrings, idx)}
                                                                 placeholder={['시작년월(YYYYMM)', '종료년월(YYYYMM)']}
                                                                 ref={ref => {
                                                                     proMemberRef.CareerDt.current[idx] = ref as any
                                                                 }} separator={'~'}/>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">자격 및 기술</div>
                                                <a onClick={() => addSkill()}>추가하기</a>
                                            </div>
                                            <div className="item_body">
                                            {skills.map((skill, idx) => (
                                                <input className="input basic" type="text" value={skill} key={idx}
                                                       ref={ref => {proMemberRef.Skill.current[idx] = ref as HTMLInputElement}}
                                                       placeholder="보유 자격 및 기술 사항을 입력해주세요." style={{marginBottom: '5px'}}
                                                       onChange={(event) => setSkills(skills.map((sk, i) => (
                                                           i === idx ? event.target.value : sk
                                                       )))}/>
                                            ))}
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">한줄 자기소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                    <input type="text" className="input basic" name="Comment"
                                                           value={profileData.comment} onChange={handleDataChange}
                                                           ref={proMemberRef.Comment} placeholder="자기소개를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item type02">
                                            <div className="item_title">
                                                <div className="title">상세 소개</div>
                                            </div>
                                            <div className="item_body">
                                                <div className="sub_item">
                                                <textarea className="textarea basic" name="DetailComment"
                                                          value={profileData.detailComment} onChange={handleDataChange}
                                                          ref={proMemberRef.DetailComment}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>

                        <div className="navigation height">
                            <div className="foot-btn">
                                <div className="detail-foot_one_button">
                                    <div>
                                        <a onClick={() => updateProfile()} className="button full purple">수정</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProProfileModify;