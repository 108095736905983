import React from "react";

import header_close from "img/m/header-close.svg";
import icon_account_search from "img/m/icon-account-serach.png";

function MyStatic() {
    return (
        <div>
            <div className="contents mobile my-static">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">나의 실적 통계</span>
                    </div>
                    <div className="header_right">
                        <a href="#"><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="my-static-cont">
                    <div className="static-search">
                        {/* 검색 input */}
                        <div className="my-account-serach">
                            <div className="serach_box">
                                <input type="text" id="datePicker" className="form-control" value="2019-06-27"/>
                            </div>
                            <div className="bettwen">~</div>
                            <div className="serach_box">
                                <input type="text" id="datePicker2" className="form-control" value="2019-06-27"/>
                            </div>
                            <a className="serach_cofirm" href="">
                                <img src={icon_account_search} alt=""/></a>
                            {/*<script>*/}
                            {/*    $('#datePicker').datepicker({*/}
                            {/*    format: "yyyy-mm-dd",	//데이터 포맷 형식(yyyy : 년 mm : 월 dd : 일 )*/}
                            {/*    startDate: '-10d',	//달력에서 선택 할 수 있는 가장 빠른 날짜. 이전으로는 선택 불가능 ( d : 일 m : 달 y : 년 w : 주)*/}
                            {/*    endDate: '+10d',	//달력에서 선택 할 수 있는 가장 느린 날짜. 이후로 선택 불가 ( d : 일 m : 달 y : 년 w : 주)*/}
                            {/*    autoclose : true,	//사용자가 날짜를 클릭하면 자동 캘린더가 닫히는 옵션*/}
                            {/*    calendarWeeks : false, //캘린더 옆에 몇 주차인지 보여주는 옵션 기본값 false 보여주려면 true*/}
                            {/*    clearBtn : false, //날짜 선택한 값 초기화 해주는 버튼 보여주는 옵션 기본값 false 보여주려면 true*/}
                            {/*    datesDisabled : ['2019-06-24','2019-06-26'],//선택 불가능한 일 설정 하는 배열 위에 있는 format 과 형식이 같아야함.*/}
                            {/*    daysOfWeekDisabled : [0,6],	//선택 불가능한 요일 설정 0 : 일요일 ~ 6 : 토요일*/}
                            {/*    disableTouchKeyboard : false,	//모바일에서 플러그인 작동 여부 기본값 false 가 작동 true가 작동 안함.*/}
                            {/*    immediateUpdates: false,	//사용자가 보는 화면으로 바로바로 날짜를 변경할지 여부 기본값 :false */}
                            {/*    multidate : false, //여러 날짜 선택할 수 있게 하는 옵션 기본값 :false */}
                            {/*    multidateSeparator :",", //여러 날짜를 선택했을 때 사이에 나타나는 글짜 2019-05-01,2019-06-01*/}
                            {/*    templates : {*/}
                            {/*    leftArrow: '&laquo;',*/}
                            {/*    rightArrow: '&raquo;'*/}
                            {/*}, //다음달 이전달로 넘어가는 화살표 모양 커스텀 마이징 */}
                            {/*    showWeekDays : true ,// 위에 요일 보여주는 옵션 기본값 : true*/}
                            {/*    todayHighlight : true ,	//오늘 날짜에 하이라이팅 기능 기본값 :false */}
                            {/*    toggleActive : true,	//이미 선택된 날짜 선택하면 기본값 : false인경우 그대로 유지 true인 경우 날짜 삭제*/}
                            {/*    weekStart : 0 ,//달력 시작 요일 선택하는 것 기본값은 0인 일요일 */}
                            {/*    language : "ko",	//달력의 언어 선택, 그에 맞는 js로 교체해줘야한다.*/}
                            {/*    icons: {*/}
                            {/*    time: "fa fa-clock-o",*/}
                            {/*    date: "fa fa-calendar",*/}
                            {/*    up: "fa fa-arrow-up",*/}
                            {/*    down: "fa fa-arrow-down"*/}
                            {/*}*/}

                            {/*});//datepicker end*/}
                            {/*    $('#datePicker2').datepicker({*/}
                            {/*    format: "yyyy-mm-dd",	//데이터 포맷 형식(yyyy : 년 mm : 월 dd : 일 )*/}
                            {/*    startDate: '-10d',	//달력에서 선택 할 수 있는 가장 빠른 날짜. 이전으로는 선택 불가능 ( d : 일 m : 달 y : 년 w : 주)*/}
                            {/*    endDate: '+10d',	//달력에서 선택 할 수 있는 가장 느린 날짜. 이후로 선택 불가 ( d : 일 m : 달 y : 년 w : 주)*/}
                            {/*    autoclose : true,	//사용자가 날짜를 클릭하면 자동 캘린더가 닫히는 옵션*/}
                            {/*    calendarWeeks : false, //캘린더 옆에 몇 주차인지 보여주는 옵션 기본값 false 보여주려면 true*/}
                            {/*    clearBtn : false, //날짜 선택한 값 초기화 해주는 버튼 보여주는 옵션 기본값 false 보여주려면 true*/}
                            {/*    datesDisabled : ['2019-06-24','2019-06-26'],//선택 불가능한 일 설정 하는 배열 위에 있는 format 과 형식이 같아야함.*/}
                            {/*    daysOfWeekDisabled : [0,6],	//선택 불가능한 요일 설정 0 : 일요일 ~ 6 : 토요일*/}
                            {/*    disableTouchKeyboard : false,	//모바일에서 플러그인 작동 여부 기본값 false 가 작동 true가 작동 안함.*/}
                            {/*    immediateUpdates: false,	//사용자가 보는 화면으로 바로바로 날짜를 변경할지 여부 기본값 :false */}
                            {/*    multidate : false, //여러 날짜 선택할 수 있게 하는 옵션 기본값 :false */}
                            {/*    multidateSeparator :",", //여러 날짜를 선택했을 때 사이에 나타나는 글짜 2019-05-01,2019-06-01*/}
                            {/*    templates : {*/}
                            {/*    leftArrow: '&laquo;',*/}
                            {/*    rightArrow: '&raquo;'*/}
                            {/*}, //다음달 이전달로 넘어가는 화살표 모양 커스텀 마이징 */}
                            {/*    showWeekDays : true ,// 위에 요일 보여주는 옵션 기본값 : true*/}
                            {/*    todayHighlight : true ,	//오늘 날짜에 하이라이팅 기능 기본값 :false */}
                            {/*    toggleActive : true,	//이미 선택된 날짜 선택하면 기본값 : false인경우 그대로 유지 true인 경우 날짜 삭제*/}
                            {/*    weekStart : 0 ,//달력 시작 요일 선택하는 것 기본값은 0인 일요일 */}
                            {/*    language : "ko",	//달력의 언어 선택, 그에 맞는 js로 교체해줘야한다.*/}
                            {/*    icons: {*/}
                            {/*    time: "fa fa-clock-o",*/}
                            {/*    date: "fa fa-calendar",*/}
                            {/*    up: "fa fa-arrow-up",*/}
                            {/*    down: "fa fa-arrow-down"*/}
                            {/*}*/}

                            {/*});//datepicker end*/}
                            {/*</script>*/}
                        </div>
                        {/* 검색 버튼 */}
                        <div className="my-account-tab black">
                            <a>전체</a>
                            <a>1개월</a>
                            <a>3개월</a>
                            <a className="active">6개월</a>
                        </div>
                    </div>
                    <div className="static-list">
                        <div className="title">통계결과내역</div>
                        <div className="sub-title">2020.01.01~2020.12.31</div>
                        {/* bar 스타일 */}
                        <div className="static-area">
                            <div className="title">수입</div>
                            <div className="sub-title">(단위 : 개)</div>
                            {/* chart.js 참고 https://www.chartjs.org/docs/latest/getting-started/usage.html */}
                            <canvas id="myChart" width="400" height="400"></canvas>
                            {/*<script>*/}
                            {/*    var ctx = document.getElementById('myChart');*/}
                            {/*    var myChart = new Chart(ctx, {*/}
                            {/*    type: 'bar',*/}
                            {/*    data: {*/}
                            {/*    labels: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],*/}
                            {/*    datasets: [*/}
                            {/*{*/}
                            {/*    label: '클릭',*/}
                            {/*    data: [590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000],*/}
                            {/*    backgroundColor: [*/}
                            {/*    'rgba(10, 97, 212, 1)'*/}
                            {/*    ]},*/}
                            {/*{*/}
                            {/*    label: '인증',*/}
                            {/*    data: [590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000, 590000],*/}
                            {/*    backgroundColor: [*/}
                            {/*    'rgba(59, 171, 128, 1)'*/}
                            {/*    ]}*/}
                            {/*    ]*/}
                            {/*},*/}
                            {/*    options: {*/}
                            {/*    responsive: true,*/}
                            {/*    scales: {*/}
                            {/*    y: {*/}
                            {/*    beginAtZero: true*/}
                            {/*}*/}
                            {/*}*/}
                            {/*}*/}
                            {/*});*/}
                            {/*</script>*/}
                        </div>
                        {/* round 스타일 */}
                        <div className="static-area">
                            <div className="title">채널별 광고 참여율</div>
                            <canvas id="myChart2" width="400" height="400"></canvas>
                            {/*<script>*/}
                            {/*    var ctx = document.getElementById('myChart2');*/}
                            {/*    var myChart = new Chart(ctx, {*/}
                            {/*    type: 'doughnut',*/}
                            {/*    data: {*/}
                            {/*    labels: ['인스타그램','페이스북','네이버 블로그','네이버 카페','트위터'],*/}
                            {/*    datasets: [{*/}
                            {/*    label: '# of Votes',*/}
                            {/*    data: [82.9, 21.2, 11.8, 8.5, 1.2],*/}
                            {/*    backgroundColor: [*/}
                            {/*    'rgba(249, 144, 74, 1)',*/}
                            {/*    'rgba(72, 149, 249, 1)',*/}
                            {/*    'rgba(156, 222, 40, 1)',*/}
                            {/*    'rgba(75, 177, 149, 1)',*/}
                            {/*    'rgba(125, 122, 226, 1)'*/}
                            {/*    ]*/}
                            {/*}]*/}
                            {/*},*/}
                            {/*    options: {*/}
                            {/*    legend: {*/}
                            {/*    display:false,*/}
                            {/*    position: 'left',*/}
                            {/*},*/}
                            {/*    align : 'left',*/}

                            {/*}*/}
                            {/*});*/}
                            {/*</script>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyStatic;