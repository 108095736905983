import React from "react";
import {AdvertisingType} from "./advertising-type";
import {PlatformType} from "./platform";
import {UserType} from "./user-type";

export const enum CampaignFlag {
    Feed,
    Click
}

export interface CampaignItem {
    bid: number,
    categoryId: number,
    subCategoryId: number,
    images: string[],
    title: string,
    point: number,
    flag: CampaignFlag
}

export interface CampaignItemDetail extends CampaignItem {
    campaignChannel: PlatformType[],
    keywords: string[],
    detail: string,
    reviewCount: number,
    joinUserCount: number,
    completeJoinUserCount: number,
    startDate?: Date,
    endDate?: Date,
    isComplete: boolean
}

export function CampaignFlagParse(val: string) {
    if (val === '0') {
        return CampaignFlag.Feed;
    } else if (val === '1') {
        return CampaignFlag.Click;
    }

    return undefined;
}

export function DivCampaignFlag(props: { flag: CampaignFlag }) {
    if (props.flag == CampaignFlag.Feed) {
        return (<div className="flag orange">피드</div>);
    } else if (props.flag == CampaignFlag.Click) {
        return (<div className="flag blue">클릭</div>);
    }

    throw new Error(`[DivCampaignFlag]: ${props.flag} not implemented.`)
}

export interface CampaignComment {
    userId: string,
    profileImg: string,
    name: string,
    rating: number,
    desc: string,
    date: Date,
    rewardComplete: boolean
}

export interface CampaignComments {
    totalCount: number,
    comments: CampaignComment[]
}

export interface CampaignEstimate {
    Bid: number,
    UserId: string,
    RequestTargetUser: UserType,
    Title: string,
    Categories: string[],
    PlatformType: PlatformType,
    AdvertisingType: AdvertisingType,
    WorkingDay: number,
    MinBudget: number,
    MaxBudget: number,
    StartAt: Date,
    EndAt: Date,
    CreateAt: Date,
    JoinCount: number,
}

export interface CampaignEstimates {
    TotalCount: number,
    Items: CampaignEstimate[],
}

export interface CampaignClickReward {
    Point: number
}

export interface CampaignMember {
    IsBusiness: boolean,
    Name: string,
    Email: string,
    ProfileImg: string,
    IdentificationNumber: string,
    BusinessOwner: string,
    Address1: string,
    Address2: string,
    ContactNumber: string,
    BankNumber: string,
    BankCode: string,
    BankOwner: string,
    RegionOfInterest: string[],
    AreaOfInterest: string[],
    Platforms: string[],
    Homepage: string,
    Comment: string,
    DetailComment: string
}