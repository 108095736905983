export const enum UserType {
    /// <summary>
    /// 등록 정보 없음
    /// </summary>
    None,

    /// <summary>
    /// 캠페인 광고자
    /// </summary>
    Advertiser,

    /// <summary>
    /// 인플루언서
    /// </summary>
    Influencer,

    /// <summary>
    /// 전문가
    /// </summary>
    Pro,
}