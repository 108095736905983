import React, {useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import icon_reward from "../../img/m/icon-reward.png";
import axios from "axios";
import {removeLocalSession} from "../../modules/localSession";

function MemberWithdraw(props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>}) {
    const [text, setText] = useState("");
    const ref = useRef<HTMLInputElement>(null);
    
    const handleClose = () => {
        props.setShow(false);
    };
    
    const withdraw = () => {
        if("탈퇴" !== text) {
            alert('"탈퇴"를 입력해주세요.');
            if(ref.current != null) ref.current.focus();
            return;
        }
        
        if(confirm("픽퐁 회원을 탈퇴하시겠습니까?")) {
            axios.delete("api/Account/withdraw").then(response => {
                alert("탈퇴가 완료되었습니다.");
                axios.post("/api/Account/logout").then();
                removeLocalSession();
                window.location.href = "/";
            }).catch(error => {
                console.log(error);
                alert("회원 탈퇴에 실패했습니다.");
                return;
            })
        }
    };

    return (
        <Modal className="type-center" centered show={props.show} onHide={handleClose}>
            <Modal.Body>
                <div className="reward">
                    <div style={{fontSize: "4.4rem", marginBottom: "2rem"}}>
                        탈퇴를 원하시면 아래 입력란에 <span style={{fontSize: "5.4rem", color: "red"}}>"탈퇴"</span>를 입력해주세요.
                    </div>
                    <input type="text" value={text} style={{width: "100%", fontSize: "8rem"}} ref={ref}
                           onChange={(e) => setText(e.target.value)} placeholder="탈퇴"/>
                </div>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: "red", padding: "4rem 0"}}>
                <a className="text-white" style={{fontSize: "6rem"}} data-dismiss="modal" aria-label="Close" onClick={withdraw}>탈퇴하기</a>
            </Modal.Footer>
        </Modal>
    );
}

export default MemberWithdraw;