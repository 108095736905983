import React from "react";
import moment from "moment";
import {DivEstimateOrderType, Estimate} from "types/estimate";
import {UserType} from "../types/user-type";
import {useNavigate} from "react-router-dom";

function MobileEstimateRequest(props: { estimate: any }) {
    const navigate = useNavigate();
    const date = moment(props.estimate.createdAt).format('YYYY.MM.DD');

    const detailRequirement = () => {
        let type = props.estimate.requestTargetUser == UserType.Influencer ? "influ" : "pro";
        navigate(`/requirements/${type}-require-send/${props.estimate.bid}`);
    };

    return (
        <div className="thumbnail width-write" style={{marginBottom: "8rem"}}>
            <div className="item" onClick={() => detailRequirement()}>
                <div className="cate">
                    <DivEstimateOrderType requestUserType={props.estimate.requestTargetUser}/>
                </div>
                <div className="title">{props.estimate.title}</div>
                <div className="order">
                    <span className="name">참여 견적수</span>
                    <span className={`count${props.estimate.requestTargetUser == UserType.Influencer ? " green" : " purple"}`}>
                        {props.estimate.joinedCount}건
                    </span>
                </div>
                <div className="info">
                    <div className="left">
                        {props.estimate.categories.map((tag: string, index: number) => (
                            <React.Fragment key={`MobileEstimateRequest_tag_${index}`}>
                                <a className="button round gray small">{tag}</a>
                                &nbsp;
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="right">{date}</div>
                </div>
            </div>
        </div>
    );
}

export default MobileEstimateRequest;