import React from "react";

import MobileJoinEstimate from "components/m-join-estimate";

import header_close from "img/m/header-close.svg";
import dummy_01 from "img/m/dummy-01.png";

function InfluRequireDetail() {
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">견적요청서</span>
                    </div>
                    <div className="header_right">
                        <a href="#"><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>

                <div className="requirements detail">
                    <div className="requir-head">
                        <span className="requir-badge green">인플루언서</span>
                        <div className="title">
                            신규 브런치카페를 홍보해 주실 인기 인플루언서님 찾습니다.
                        </div>
                        <div className="date">2020.10.30</div>
                    </div>
                    <div className="requir-tab">
                        <ul className="navigation tab green">
                            <li id="tab-01" className="on">
                                <a href="#none">광고정보</a>
                            </li>
                            <li id="tab-02">
                                <a href="#none">참여 견적서 3건</a>
                            </li>
                        </ul>
                    </div>
                    {/* 견적요청정보 */}
                    <div className="requir-body show" id="contents-01">
                        <div className="requir-block">
                            <div className="title">견적서 요청할 사람</div>
                            <div className="desc">인플루언서</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 분야</div>
                            <div className="desc">
                                <a href="#" className="button round gray big">베이커리</a>
                                <a href="#" className="button round gray big">브런치카페</a>
                            </div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고채널</div>
                            <div className="desc">인스타그램</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고방식</div>
                            <div className="desc">피드업로드</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고기간</div>
                            <div className="desc">2020.10.30~2020.11.03</div>
                        </div>
                        <div className="requir-block">
                            <div className="title">광고 예상 금액</div>
                            <div className="desc">10,000원</div>
                        </div>
                    </div>

                    {/* 참여 견적서 3건 */}
                    <div className="requir-body" id="contents-02">
                        <div className="requir_participate">
                            <MobileJoinEstimate joinEstimate={{
                                Name: "자넨네일 올슨",
                                Rating: 4.8,
                                Category: "뷰티 · 메이크업",
                                Comment: "추가적으로 사진업로드..",
                                Price: 50000,
                                ProfileImg: dummy_01,
                                UserId: ""
                            }}/>
                            <MobileJoinEstimate joinEstimate={{
                                Name: "자넨네일 올슨",
                                Rating: 4.8,
                                Category: "뷰티 · 메이크업",
                                Comment: "추가적으로 사진업로드..",
                                Price: 50000,
                                ProfileImg: dummy_01,
                                UserId: ""
                            }}/>
                            <MobileJoinEstimate joinEstimate={{
                                Name: "자넨네일 올슨",
                                Rating: 4.8,
                                Category: "뷰티 · 메이크업",
                                Comment: "추가적으로 사진업로드..",
                                Price: 50000,
                                ProfileImg: dummy_01,
                                UserId: ""
                            }}/>
                        </div>
                    </div>
                </div>
                <div className="foot-btn">
                    <div className="detail-foot_button type_half all_black">
                        <div><a href="#" className="button full black">삭제</a></div>
                        <div><a href="#" className="button full black">수정</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InfluRequireDetail;