import React, {useState} from "react";
import {Modal} from "react-bootstrap";

import icon_reward from "img/m/icon-reward.png";

function RewardPopup(props: {show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, point: number}) {
    const handleClose = () => {
        props.setShow(false);
        window.location.reload();
    };

    return (
        <Modal className="type-center" centered show={props.show} onHide={handleClose}>
            <Modal.Body>
                <div className="reward">
                    <img src={icon_reward} alt=""/>
                    <div className="point">{props.point.toLocaleString()}P</div>
                    <div className="ment">리워드가 지급되었습니다.</div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>확인</a>
            </Modal.Footer>
        </Modal>
    );
}

export default RewardPopup;