import React from "react";
import {PointReceipt} from "types/point-receipt";
import moment from "moment";

function MobilePointReport(props: { pointReport: PointReceipt }) {
    const date = moment(props.pointReport.createdAt).format('YYYY.MM.DD');
    const price = Math.abs(props.pointReport.price);

    return (
        <div className="item">
            <div className="info">
                <div className="name">{props.pointReport.title}</div>
                <div className="date">{date}</div>
            </div>
            <div className="price">
                <span className={props.pointReport.price > 0 ? "plus" : ""}>
                    {props.pointReport.price > 0 ? "+" : "-"} {price.toLocaleString()} 원
                </span>
            </div>
        </div>
    );
}

export default MobilePointReport;