import React, {useState} from "react";
import {Modal} from "react-bootstrap";

import request_check_blue from "img/m/request-check-blue.png";

function RewardGradePopup() {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);

    return (
        <Modal className="type-center" centered show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="reward_grade">
                    <img src={request_check_blue} alt=""/>
                    <p>10 포인트가 지급되었습니다.</p>
                    <p>평점을 선택해주세요!</p>
                    <div className="chocie_star">
                        <div className="grade">
                            <span className="on">★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                            <span>★</span>
                        </div>
                        <div className="ment">선택해주세요.</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <a className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>다음에 할게요.</a>
            </Modal.Footer>
        </Modal>
    );
}

export default RewardGradePopup;