import {useNavigate, useParams} from "react-router-dom";
import default_img from "../../img/m/file-speaker.svg";
import React, {useEffect, useState} from "react";
import axios from "axios";
import header_close from "../../img/m/header-close.svg";

function PaymentRequirements() {
    const navigate = useNavigate();
    const params = useParams();
    const [joinList, setJoinList] = useState<any[]>([]);

    useEffect(() => {
        axios.get(`api/MyAdvertiser/estimate/${params.bid}/joined`)
            .then(response => {
                let data = response.data;

                axios.get(`api/MyAdvertiser/estimate/${params.bid}/received`)
                    .then(response => {
                        let data2 = response.data;
                        let items: any[] = [];

                        data2.forEach((rcv: any) => {
                            let join = data.find((j: any) => j.userId == rcv.userId && j.bid == rcv.boardId);

                            if (join) {
                                items.push({...join, status: rcv.status});
                            }
                        });

                        setJoinList(items);
                    });
            });
    }, [params]);
    const requestEstimate = (estimate: any) => {
        if(estimate.status !== false) {
            navigate(`/home/ad-payment/${params.bid}`, {
                state: {
                    userId: estimate.userId,
                    price: estimate.price,
                    comment: estimate.comment
                }
            });
        } else {
            alert("탈퇴한 회원입니다.");
        }
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">결제할 견적서를 선택하세요.</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>
                <div className="c-line"></div>
                <div className="requirements detail">
                    <div className="requir-body show">
                        <div className="requir_participate">
                        {joinList.map((join, idx) => (
                            <div key={idx} className="item" onClick={() => requestEstimate(join)}>
                                <div className="product">
                                    <div className="thumbnail width">
                                        <div className="image_area">
                                            <img src={join.profileImg || default_img} alt="" style={{objectFit: "contain"}}/>
                                        </div>
                                        <div className="text_area">
                                            <a className="cate type-star">
                                                <span>{join.category.replaceAll(";", ", ")}</span>
                                                <span className="grade"><span className="star">★</span>{join.rating}</span>
                                            </a>
                                            <a className="title">{join.name}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="name">받은 견적 가격</div>
                                    <div className="result price">{join.price.toLocaleString()}원</div>
                                </div>
                                <div className="info">
                                    <div className="name">기타 메세지</div>
                                    <div className="result">{join.comment}</div>
                                </div>
                                {join.status === false ?
                                <div className="info">
                                    <div className="name" style={{color: "red"}}>탈퇴한 회원입니다.</div>
                                </div>
                                : null}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentRequirements;