import React, {useEffect, useState} from "react";
import {Accordion} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import {useNavigate, useParams} from "react-router-dom";

import header_back from "img/m/header-back.svg";
import option_minus from "img/m/option-minus.png";
import option_plus from "img/m/option-plus.png";
import icon_instagram from "img/m/icon-instagram.png";
import icon_facebook from "img/m/icon-facebook.png";
import icon_naverblog from "img/m/icon-naverblog.png";
import {InfluDetailItem} from "../../types/influ";
import axios from "axios";
import {PlatformType} from "../../types/platform";
import icon_twitter from "../../img/m/icon-twitter.svg";
import icon_naver from "../../img/m/icon-naver.svg";
import icon_kakaofriend from "../../img/m/icon-kakao.png.png";
import icon_default from "../../img/m/file-basic.svg";
import {UserSimpleProfile} from "../../types/profile";

type CountSetState = React.Dispatch<React.SetStateAction<any[]>>;

function DivPlatformPrice(props: {
    platformType: PlatformType,
    likePrice: number,
    likeCount: number,
    feedPrice: number,
    feedCount: number,
    setCount: CountSetState
}) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>("0");

    let platformName = "";
    let platformIcon = "";

    if (props.platformType == PlatformType.Instagram) {
        platformName = "Instagram";
        platformIcon = icon_instagram;
    } else if (props.platformType == PlatformType.Facebook) {
        platformName = "Facebook";
        platformIcon = icon_facebook;
    } else if (props.platformType == PlatformType.Twitter) {
        platformName = "Twitter";
        platformIcon = icon_twitter;
    } else if (props.platformType == PlatformType.NaverCafe) {
        platformName = "Naver Cafe";
        platformIcon = icon_naver;
    } else if (props.platformType == PlatformType.NaverBlog) {
        platformName = "Naver Blog";
        platformIcon = icon_naverblog;
    } else if (props.platformType == PlatformType.KakaoFriend) {
        platformName = "Kakao Friend";
        platformIcon = icon_kakaofriend;
    } else if (props.platformType == PlatformType.Youtube) {
        platformName = "Youtube";
        platformIcon = icon_default;
    } else if (props.platformType == PlatformType.Etc) {
        platformName = "기타";
        platformIcon = icon_default;
    }
    
    const minusLike = () => {
        if (props.likeCount > 0) {
            props.setCount(prevState => prevState.map(c => (
                c.type === props.platformType ? {...c, likeCount: props.likeCount - 1} : c
            )));
        }
    };

    const plusLike = () => {
        props.setCount(prevState => prevState.map(c => (
            c.type === props.platformType ? {...c, likeCount: props.likeCount + 1} : c
        )));
    };

    const minusFeed = () => {
        if (props.feedCount > 0) {
            props.setCount(prevState => prevState.map(c => (
                c.type === props.platformType ? {...c, feedCount: props.feedCount - 1} : c
            )));
        }
    };

    const plusFeed = () => {
        props.setCount(prevState => prevState.map(c => (
            c.type === props.platformType ? {...c, feedCount: props.feedCount + 1} : c
        )));
    }

    return (
        <Accordion className="type-channel_price" onSelect={setActiveKey}>
            <Accordion.Item eventKey={"0"} bsPrefix={"#"}>
                <Accordion.Button as={"div"} className="head" bsPrefix="#">
                    <span className="name">
                        <img src={platformIcon} alt=""/>
                        <span>{platformName}</span>
                    </span>
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    {props.likePrice > 0 ?
                    <div className="ad_detail option_select">
                        <div className="left">
                            <div className="title">좋아요</div>
                            <div className="ea">
                                1회당<span>{props.likePrice.toLocaleString()}</span>원
                            </div>
                        </div>
                        <div className="right">
                                <span className="minus" onClick={() => minusLike()}>
                                    <img src={option_minus} alt=""/>
                                </span>
                            <span className="num">{props.likeCount.toLocaleString()}</span>
                            <span className="plus" onClick={() => plusLike()}>
                                    <img src={option_plus} alt=""/>
                                </span>
                        </div>
                    </div>
                    : null}
                    {props.feedPrice > 0 ?
                    <div className="ad_detail option_select">
                        <div className="left">
                            <div className="title">피드<span className="small">(해시태그포함)</span></div>
                            <div className="ea">
                                1건당<span>{props.feedPrice.toLocaleString()}</span>원
                            </div>
                        </div>
                        <div className="right">
                                <span className="minus" onClick={() => minusFeed()}>
                                    <img src={option_minus} alt=""/>
                                </span>
                            <span className="num">{props.feedCount.toLocaleString()}</span>
                            <span className="plus" onClick={() => plusFeed()}>
                                    <img src={option_plus} alt=""/>
                                </span>
                        </div>
                    </div>
                    : null}
                    <div className="option_select-total">
                        <div className="left">
                            합계 {(props.likeCount + props.feedCount).toLocaleString()}건
                        </div>
                        <div className="right">
                            {(props.likeCount * props.likePrice + props.feedCount * props.feedPrice).toLocaleString()} 원
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function InfluOption() {
    const params = useParams();
    const [influ, setInflu] = useState<InfluDetailItem>();
    const [count, setCount] = useState<any[]>([]);
    const [profile, setProfile] = useState<UserSimpleProfile>();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/Influencer/influ/${params.bid}`)
            .then(response => {
                let data = response.data;
                let countList: any[] = [];
                
                setInflu(data);
                
                data.platforms.forEach((p: any) => {
                    countList.push({type: p.type, likeCount: 0, feedCount: 0});
                });

                setCount(countList);
            });
        axios.get(`api/Influencer/influ/${params.bid}/profile`)
            .then(response => {
                setProfile(response.data);
            });
    }, [params]);

    if (influ == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    let totalPrice = 0;
    influ.platforms.forEach(inf => {
        let c = count.find(cnt => cnt.type === inf.type);
        totalPrice += inf.costPerClick * (c ? c.likeCount : 0);
        totalPrice += inf.costPerFeed * (c ? c.feedCount : 0);
    });

    const goInfluPayment = () => {
        navigate(`/home/influ-payment/${params.bid}`, {
            state: {
                influCount: count,
                profileId: profile.userId
            }
        })
    };

    return (
        <div className="layout-btn">

            {/* header */}
            <header className="header-sub">
                <div className="header_left">
                    <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                    <span className="location">서비스 옵션 선택</span>
                </div>
                <div className="header_right">
                </div>
            </header>

            {/* 인플루언서 광고 선택 */}
            <div className="page-option influe">
                <div className="price_area" id="accordion_parent_example01" role="tablist" aria-multiselectable="true">
                    {influ.platforms.map((inf, idx) => (
                        <DivPlatformPrice key={idx}
                            platformType={inf.type}
                            likePrice={inf.costPerClick}
                            likeCount={count.find(c => c.type === inf.type) ? count.find(c => c.type === inf.type).likeCount : 0}
                            feedPrice={inf.costPerFeed}
                            feedCount={count.find(c => c.type === inf.type) ? count.find(c => c.type === inf.type).feedCount : 0}
                            setCount={setCount}
                        />
                    ))}
                </div>
            </div>

            {/* 결제하기 버튼 */}
            <div className="foot-btn">
                <div className="detail-foot_button type02">
                    <div>
                        <a className="button full green">
                            <span>총 결제금액</span>
                            <span className="won">{totalPrice.toLocaleString()} 원</span>
                        </a>
                    </div>
                    <div><a onClick={() => goInfluPayment()} className="button full black">결제하기</a></div>
                </div>
            </div>
        </div>
    );
}

export default InfluOption;