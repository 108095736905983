import React from "react";
import {DivEstimateOrderType, DivEstimatePaymentType} from "types/estimate";
import moment from "moment";
import {useNavigate} from "react-router-dom";

function MobileRequirementItem(props: { estimate: any, userType: string, isRcv?: boolean }) {
    const navigate = useNavigate();
    const date = moment(props.estimate.createAt).format('YYYY.MM.DD');
    
    const detailRequirement = () => {
        if(props.isRcv) {
            navigate(`/requirements/ad-payment-require/${props.estimate.bid}`);
        } else {
            navigate(`/requirements/${props.userType}-require/${props.estimate.bid}`);
        }
    };
    
    return (
        <div className="item" onClick={() => detailRequirement()}>
            <div className="cate">
                {props.estimate.paymentType ?
                <DivEstimatePaymentType type={props.estimate.paymentType}/>
                :
                <DivEstimateOrderType requestUserType={props.estimate.requestTargetUser}/>
                }
            </div>
            <div className="title">{props.estimate.title}</div>
            <div className="info">
                <span className="name">참여 견적수</span>
                <span className="count purple">{props.estimate.joinCount}건</span>
            </div>
            <div className="date_group">
                <span className="hash_group">
                    {props.estimate.categories.map((tag: string, key: number) => (
                        <React.Fragment key={key}>
                            <a className="button round gray small">{tag}</a>
                            {'\n'}
                        </React.Fragment>
                    ))}
                </span>
                <div className="date">{date}</div>
            </div>
        </div>
    );
}

export default MobileRequirementItem;