import React from "react";

export const enum RankChangeEnum {
    Equal,
    Up,
    Down
}

export type RankProp = {
    rank: number,
    change: RankChangeEnum
};

export function DivRank(props: { rank: RankProp }) {
    return (
        <div className="rank">
            {props.rank.change == RankChangeEnum.Up ? (<p className="up"/>) : (<></>)}
            <p>{props.rank.rank}</p>
            {props.rank.change == RankChangeEnum.Down ? (<p className="down"/>) : (<></>)}
        </div>
    );
}