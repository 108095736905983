import React from "react";
import {Link} from "react-router-dom";

import {AdRequestModal} from "pages/request/ad-request";
import {PageType} from "types/page-type";

import icon_foot_home from "img/m/icon-foot_home.svg";
import icon_foot_list from "img/m/icon-foot_list.svg";
import icon_foot_chat from "img/m/icon-foot_chat.svg";
import icon_foot_my from "img/m/icon-foot_my.svg";
import icon_foot_home_on from "img/m/icon-foot_home_on.svg";
import icon_foot_list_on from "img/m/icon-foot_list_on.svg";
import icon_foot_chat_on from "img/m/icon-foot_chat_on.svg";
import icon_foot_my_on from "img/m/icon-foot_my_on.svg";
import {getLocalSession} from "../modules/localSession";
import {UserType} from "../types/user-type";

function DivCalc(props: { curMenu: string }) {
    return (<AdRequestModal curMenu={props.curMenu}/>);
}

function MobileNavigationFooter(props: { hasHeight?: boolean, pageType: PageType, curMenu: string }) {
    const session = getLocalSession();
    const myPageLink = props.pageType == PageType.Ad ? "/my/my-ad-main"
        : props.pageType == PageType.Influ ? "/my/my-influ-main" : "/my/my-pro-main";
    const estimateLink = props.pageType == PageType.Ad ? "/requirements-list/ad-requirements-list"
        : props.pageType == PageType.Influ ? "/requirements-list/influ-requirements-list"
            : "/requirements-list/pro-requirements-list";
    const serviceName = props.pageType == PageType.Ad ? "캠페인"
        : props.pageType == PageType.Influ ? "인플루언서" : "전문가";
    const serviceLink = props.pageType == PageType.Ad ? "campaign"
        : props.pageType == PageType.Influ ? "influ" : "pro";
    const {innerWidth} = window;
    
    const widthStyle = innerWidth > 360 ? {} : {height: "17.6rem"};
    
    const goMyPage = () => {
        if(session) {
            let type: number = session.userType;

            if(type == UserType.None) {
                if(props.pageType === PageType.Common) {
                    if(confirm(`등록된 서비스가 없습니다.\n프로필 등록을 하시겠습니까?`)) {
                        location.href = `/member/welcome`;
                    }
                } else {
                    if(confirm(`고객님은 ${serviceName}이(가) 아닙니다.\n${serviceName} 프로필 등록을 하시겠습니까?`)) {
                        location.href = `/member/${serviceLink}-profile-add`;
                    }
                }
            } else {
                if(type == props.pageType) {
                    location.href = myPageLink;
                } else {
                    location.href = type == UserType.Advertiser ? "/my/my-ad-main"
                        : type == UserType.Influencer ? "/my/my-influ-main" : "/my/my-pro-main";
                }
            }
        } else {
            alert("로그인을 해주세요.");
            location.href = "/member/login";
        }
    };
    
    const goEstimate = () => {
        if(session) {
            let type: number = session.userType;

            if(type == UserType.None) {
                if(props.pageType === PageType.Common) {
                    if(confirm(`등록된 서비스가 없습니다.\n프로필 등록을 하시겠습니까?`)) {
                        location.href = `/member/welcome`;
                    }
                } else {
                    if(confirm(`고객님은 ${serviceName}이(가) 아닙니다.\n${serviceName} 프로필 등록을 하시겠습니까?`)) {
                        location.href = `/member/${serviceLink}-profile-add`;
                    }
                }
            } else {
                if(type == props.pageType) {
                    location.href = estimateLink;
                } else {
                    location.href = type == UserType.Advertiser ? "/requirements-list/ad-requirements-list"
                        : type == UserType.Influencer ? "/requirements-list/influ-requirements-list"
                            : "/requirements-list/pro-requirements-list";
                }
            }
        } else {
            alert("로그인을 해주세요.");
            location.href = "/member/login";
        }
    };

    return (
        <React.Fragment>
            {props.hasHeight && <div className="navigation height"></div>}
            <ul className="navigation foot">
                <li className={props.curMenu === "home" ? "on" : ""} style={props.curMenu === "home" ? widthStyle : {}}>
                    <Link to={"/"}>
                        <img src={props.curMenu === "home" ? icon_foot_home_on : icon_foot_home} alt=""/>
                    </Link>
                </li>
                <li className={props.curMenu === "list" ? "on" : ""} style={props.curMenu === "list" ? widthStyle : {}}>
                    <a onClick={goEstimate}>
                        <img src={props.curMenu === "list" ? icon_foot_list_on : icon_foot_list} alt=""/>
                    </a>
                </li>
                <li className={props.curMenu === "chat" ? "on" : ""} style={props.curMenu === "chat" ? widthStyle : {}}>
                    <a onClick={() => alert("준비중입니다.")}>
                        <img src={props.curMenu === "chat" ? icon_foot_chat_on : icon_foot_chat} alt=""/>
                    </a>
                </li>
                <li className={props.curMenu === "my" ? "on" : ""} style={props.curMenu === "my" ? widthStyle : {}}>
                    <a onClick={goMyPage}>
                        <img src={props.curMenu === "my" ? icon_foot_my_on : icon_foot_my} alt=""/>
                    </a>
                </li>
                <li className={props.curMenu === "calc" ? "on" : ""} style={props.curMenu === "calc" ? widthStyle : {}}>
                    <DivCalc curMenu={props.curMenu}/>
                </li>
            </ul>
        </React.Fragment>
    );
}

export default MobileNavigationFooter;