import React from "react";
import moment from "moment";

import {CampaignComment} from "types/campaign";

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function MobileDetailComment(props: { campaignComment: CampaignComment }) {
    const date = moment(props.campaignComment.date).format('YYYY.MM.DD');

    return (
        <div className="item">
            <div className="profile">
                <div className="review_thumbnail">
                    <div>
                        <img className="comment-profile_img" src={props.campaignComment.profileImg} alt=""/>
                    </div>
                </div>
                <div className="info">
                    <div className="name">{props.campaignComment.name}</div>
                    <div className="star">
                        <DivCommentStar num={1} star={props.campaignComment.rating}/>
                        <DivCommentStar num={2} star={props.campaignComment.rating}/>
                        <DivCommentStar num={3} star={props.campaignComment.rating}/>
                        <DivCommentStar num={4} star={props.campaignComment.rating}/>
                        <DivCommentStar num={5} star={props.campaignComment.rating}/>
                    </div>
                </div>
            </div>
            <div className="desc" dangerouslySetInnerHTML={{__html: props.campaignComment.desc}}>
                {/*/!* html에서의 말줄임은 1줄 또는 2줄 과 같이 단일 속성으로만 지정할 수 있습니다. 한문장의 끝에 1번이 최대입니다. *!/*/}
                {/*https://search.naver.com/search.naver?where... /!* ... 이후 다시 글 나오는거 불가능 *!/*/}
                {/*<br/>*/}
                {/*블로그에 글올렸습니다.*/}
                {/*<br/>*/}
                {/*/!* 이미지 *!/*/}
                {/*<img src={dummy_detail01} alt=""/>*/}
            </div>
            <div className="date">{date}</div>
        </div>
    );
}

export default MobileDetailComment;