import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {ProServiceItem} from "types/pro-service";

import dummy_01 from "img/m/dummy-01.png";
import ico_trade_etc from "img/m/ico-trade_etc.png";
import {useSelector} from "react-redux";
import {RootState} from "../modules";
import {getLocalSession} from "../modules/localSession";
import axios from "axios";
import {UserType} from "../types/user-type";

function DivPrice(props: { minPrice?: number, maxPrice?: number }) {
    if (props.minPrice != null && props.maxPrice != null) {
        return (
            <a className="price">{props.minPrice.toLocaleString()}원 ~ {props.maxPrice.toLocaleString()}원</a>);
    } else if (props.minPrice != null) {
        return (<a className="price">{props.minPrice.toLocaleString()}원 ~</a>);
    } else if (props.maxPrice != null) {
        return (<a className="price">~ {props.maxPrice.toLocaleString()}원</a>);
    }

    return (<a className="price"></a>);
}

function MobileProServiceThumbnailWidth(props: { campaign: any, usePopup?: boolean, header?: JSX.Element }) {
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);
    const proCategory = useSelector((state: RootState) => state.proCategory);

    useEffect(() => {
        if(session) {
            setZzim(props.campaign.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.campaign.bid, ZzimType: UserType.Pro, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }
    
    return (
        <div className="thumbnail width">
            {props.header}
            <div className="image_area">
                <Link to={`/home/pro-detail/${props.campaign.bid}`}>
                    <img src={props.campaign.images.length > 0 ? props.campaign.images[0] : dummy_01} style={{objectFit: 'cover'}} alt=""/>
                </Link>
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart purple${zzim ? " on" : ""}`}></div>
                : null }
            </div>
            <div className="text_area">
                <Link to={"/home/pro-list"} className="cate type-star">
                    <span>{proCategory.repo.getMainCategoryName(props.campaign.categoryId)}</span>
                    <div className="trade_etc">
                        <span className="grade"><span className="star">★</span>{props.campaign.rating}</span>
                        {props.usePopup ?
                            (<a className="etc _etc-popover" data-container="body"
                                data-toggle="popover" data-placement="bottom"
                                data-content="Vivamus." data-original-title="" title="">
                                <img src={ico_trade_etc} alt=""/>
                            </a>) : (<></>)}
                    </div>
                </Link>
                <Link to={`/home/pro-detail/${props.campaign.bid}`} className="title">{props.campaign.title}</Link>
                <div className="price_area">
                    <DivPrice minPrice={props.campaign.minPrice} maxPrice={props.campaign.maxPrice}/>
                </div>
            </div>
        </div>
    );
}

function MobileProServiceThumbnailHeight(props: { campaign: any }) {
    const session = getLocalSession();
    const [zzim, setZzim] = useState(false);
    const proCategory = useSelector((state: RootState) => state.proCategory);

    useEffect(() => {
        if(session) {
            setZzim(props.campaign.isZzim);
        }
    });

    const changeZzim = () => {
        axios.post(`api/Zzim/item`, {
            ZzimBoardId: props.campaign.bid, ZzimType: UserType.Pro, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    }
    
    return (
        <div className="thumbnail height">
            <div className="image_area">
                {session && session.userType !== UserType.None ?
                <div onClick={() => changeZzim()} className={`button heart purple${zzim ? " on" : ""}`}></div>
                : null }
                <Link to={`/home/pro-detail/${props.campaign.bid}`}>
                    <img src={props.campaign.images.length > 0 ? props.campaign.images[0] : dummy_01} style={{objectFit: 'cover'}} alt=""/>
                </Link>
            </div>
            <div className="text_area">
                <Link to={`/home/pro-list/${props.campaign.categoryId}`} className="cate">
                    {proCategory.repo.getMainCategoryName(props.campaign.categoryId)}
                </Link>
                <Link to={`/home/pro-detail/${props.campaign.bid}`} className="title">{props.campaign.title}</Link>
                <div className="price_area">
                    <DivPrice minPrice={props.campaign.minPrice} maxPrice={props.campaign.maxPrice}/>
                    <a className="grade"><span className="star">★</span>{props.campaign.rating}</a>
                </div>
            </div>
        </div>
    );
}

function MobileProServiceThumbnail(props: { height?: boolean, campaign: any, usePopup?: boolean, header?: JSX.Element }) {
    const isHeight = props.height ?? false;

    return isHeight ?
        <MobileProServiceThumbnailHeight campaign={props.campaign}/> :
        <MobileProServiceThumbnailWidth campaign={props.campaign} usePopup={props.usePopup} header={props.header}/>
}

export default MobileProServiceThumbnail;