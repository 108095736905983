import React, {useState} from "react";

import header_back from "img/m/header-back.svg";
import {Link} from "react-router-dom";
import RewardPopup from "../popup/reward";
import MemberWithdraw from "../popup/member-withdraw";

function MyTerms() {
    const [popupShow, setPopupShow] = useState<boolean>(false);
    
    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">이용약관</span>
                    </div>
                </header>
                <div className="section">
                    <div className="title">이용약관</div>
                    <ul className="board">
                        <li>
                            <Link to={'/home/terms'} target="_blank" style={{color:"#191919"}}>
                                <p className="subject">서비스 이용약관</p>
                                <span className="arrow"></span>
                            </Link>
                        </li>
                        <li>
                            <Link to={'/home/privacy'} target="_blank" style={{color:"#191919"}}>
                                <p className="subject">개인정보 처리방침</p>
                                <span className="arrow"></span>
                            </Link>
                        </li>
                        <li>
                            {/*<a>
                                <p className="subject">알림 받을 분야</p>
                                <span className="arrow"></span>
                            </a>*/}
                        </li>
                    </ul>

                    <div className="title">기타</div>
                    <ul className="board">
                        <li>
                            <a onClick={() => setPopupShow(true)}>
                                <p className="subject">탈퇴하기</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <MemberWithdraw show={popupShow} setShow={setPopupShow}/>
        </div>
    );
}

export default MyTerms;