import React, {useRef, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import setUser from "utils/setUser";

import header_back from "img/m/header-back.svg";
import icon_naver from "img/m/icon-naver.svg";
import icon_kakao from "img/m/icon-kakao.svg";

function Login() {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const [idSaveChecked, setIdSaveChecked] = useState(false);
    const handleIdSaveCheckChange = (e: any) => setIdSaveChecked(!idSaveChecked);
    
    const handleLoginSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        if('' == email) {
            alert("이메일을 입력하세요.");
            if(emailRef.current !== null) emailRef.current.focus();
            return;
        }

        if('' == password) {
            alert("비밀번호를 입력하세요.");
            if(passwordRef.current !== null) passwordRef.current.focus();
            return;
        }

        axios.post('/api/Account/login', {email: email, password: password}).then(response => {
            setUser(response.data);

            history.back();
        }).catch(error => {
            console.log(error);
            
            if(401 == error.response.status) {
                alert("이메일 또는 비밀번호가 맞지 않습니다.");
            } else {
                alert("시스템 오류가 발생했습니다.");
            }
            
            return;
        });
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">로그인</span>
                    </div>
                    <div className="header_right">
                    </div>
                </header>

                <div className="member login">
                    <Form onSubmit={handleLoginSubmit}>
                        <div className="layout line">
                            <Form.Control type="email" placeholder="이메일" className="input basic" bsPrefix="#"
                                          value={email} onChange={e => setEmail(e.target.value)}
                                          ref={emailRef}/>
                        </div>
                        <div className="layout line">
                            <Form.Control type="password" placeholder="비밀번호" className="input basic" bsPrefix="#"
                                          value={password} onChange={e => setPassword(e.target.value)}
                                          ref={passwordRef}/>
                        </div>
                        <div className="layout line02">
                            <div className="checkbox">
                                <Form.Check.Label>
                                    <Form.Check.Input type="checkbox" id="idSave"
                                                      className="check_input"
                                                      checked={idSaveChecked}
                                                      onChange={handleIdSaveCheckChange}
                                    ></Form.Check.Input>
                                    <Form.Check.Label htmlFor="idSave">
                                        <span className={`circle ${idSaveChecked ? "checked" : ""}`}></span>
                                        <span className="name agree_text type02">아이디 저장</span>
                                    </Form.Check.Label>
                                </Form.Check.Label>
                            </div>
                        </div>

                        <div className="layout line">
                            {/*TODO : 로그인 링크 연결 필요*/}
                            <Button type="submit" className="button full black radius">로그인</Button>
                        </div>

                        <div className="layout line space_between">
                            <div className="left"><a className="link type01">아이디·비밀번호 찾기</a></div>
                            <div className="right"><Link to={"/member/join"} className="link type01">회원가입</Link></div>
                        </div>
                    </Form>
                </div>
                <div className="bottom member login">
                    <div className="layout divider_title">
                        <div className="divider middle_line">
                            <span className="title">간편 로그인으로 시작하기</span>
                        </div>
                    </div>
                    <div className="layout center">
                        <div className="icon_group">
                            {/*TODO : 소셜 링크 연결 필요*/}
                            {/*<a href="#"><img src={icon_apple} alt=""/></a>*/}
                            {/*<a href="#"><img src={icon_google} alt=""/></a>*/}
                            <a href="/api/Account/naver-login"><img src={icon_naver} alt=""/></a>
                            <a href="/api/Account/kakao-login"><img src={icon_kakao} alt=""/></a>
                            {/*<a href="#"><img src={icon_twitter} alt=""/></a>*/}
                        </div>
                    </div>
                </div>
                <div className="bottom d-flex justify-content-between">
                    <div className="left">
                        <Link to={"/home/terms"} target="_blank" className="link type01" style={{color: "#666666"}}>
                            이용약관
                        </Link>
                    </div>
                    <div className="right">
                        <Link to={"/home/privacy"} target="_blank" className="link type01" style={{color: "#666666"}}>
                            개인정보처리방침
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;