import React, {useEffect, useState} from "react";

import header_refresh from "img/m/header-refresh.svg";
import header_close from "img/m/header-close.svg";
import layer_check from "img/m/layer-check.svg";
import {MainCategory, SubCategory} from "../../types/category";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import axios from "axios";

function WishExpert(props: {
    setWishPro: React.Dispatch<React.SetStateAction<boolean>>,
    setSelect: (value: string) => void,
    setSelectDetail: (values: string[]) => void
}) {
    const [categories, setCategories] = useState<MainCategory[]>([]);
    const [subCates, setSubCates] = useState<SubCategory[]>([]);
    const [mainCateId, setMainCateId] = useState<number>(1);
    const [selPro, setSelPro] = useState<SubCategory[]>([]);
    const [selCateName, setSelCateName] = useState<string[]>([]);
    const [selMainCateName, setSelMainCateName] = useState<string>('');

    const proCategory = useSelector((state: RootState) => state.proCategory);

    useEffect(() => {
        axios.get("api/ProService/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
        
        let cateName = proCategory.repo.getMainCategoryName(1);
        setSelMainCateName(cateName === undefined ? '' : cateName);
        setSubCates(proCategory.repo.getSubCategories(1));
    }, []);

    const selectCate = (cateId: number, cateName: string) => {
        setMainCateId(cateId);
        setSelMainCateName(cateName);
        setSubCates(proCategory.repo.getSubCategories(cateId));
    };

    const selectSub = (sub: SubCategory) => {
        if(!selPro.includes(sub)) {
            setSelPro([...selPro, sub]);
            setSelCateName([...selCateName, sub.name])
        }
    };

    const applyPro = () => {
        if (selPro.length > 0) {
            props.setSelectDetail(selCateName);
            props.setSelect(selMainCateName);
            props.setWishPro(false);
        }
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-layout under_line">
                    <div className="header_left">
                        <span className="location">전문가 활동 분야 선택</span>
                    </div>
                    <div className="header_right">
                        <a><img className="refresh" src={header_refresh} alt=""/></a>
                        <a onClick={() => props.setWishPro(false)}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="layer type01">
                    <div className="scroll_wrap">
                        <ul className="scroll type01">
                        {categories.map((cate, idx) => (
                            <li key={idx} className={mainCateId == cate.categoryId ? 'on' : ''}>
                                <a onClick={() => selectCate(cate.categoryId, cate.name)}>{cate.name}</a>
                            </li>
                        ))}
                        </ul>
                    </div>
                    <div className="depth2_area">
                        <ul className="depth02">
                        {subCates.filter(sub => sub.subCategoryId != 0).map((sub, idx) => (
                            <li key={idx} className={selPro.includes(sub) ? 'checked' : ''}>
                                <a onClick={() => selectSub(sub)}>
                                    <span className="name">{sub.name}</span>
                                    <span className="check_img"><img src={layer_check} alt=""/></span>
                                </a>
                            </li>
                        ))}
                        </ul>
                        <div className="selected_item">
                        {selPro.map((pro, idx) => (
                            <a key={idx} className="button close_type basic">
                                <span className="wrap">
                                    <span className="name">{pro.name}</span>
                                    <span className="exit"
                                          onClick={() => setSelPro(
                                              selPro.filter(item => item.subCategoryId !== pro.subCategoryId
                                          ))}>
                                    </span>
                                </span>
                            </a>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="bottom chap">
                    <a onClick={() => applyPro()}
                       className={`button full ${selPro.length > 0 ? 'purple' : 'gray'}`}>선택완료</a>
                </div>
            </div>
        </div>
    );
}

export default WishExpert;