import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";
import {Accordion, Card} from "react-bootstrap";

import header_back from "img/m/header-back.svg";
import radio_checked_purple from "img/m/radio-checked-purple.png";
import icon_price_select_list from "img/m/icon-price_select-list.png";
import icon_minus from "img/m/icon-minus.png";
import icon_plus from "img/m/icon-plus.png";
import axios from "axios";
import {GetProServicePriceOptionType, ProServiceItemDetail, ProServicePriceOption} from "../../types/pro-service";

type CountSetState = React.Dispatch<React.SetStateAction<number>>;

function DivPriceOption(props: {
    checked: boolean, eventKey: string, price: number, grade: string, options: ProServicePriceOption,
    priceOptionCount: number, setPriceOptionCount: CountSetState
}) {
    const minusCount = () => {
        if (props.priceOptionCount > 1) {
            props.setPriceOptionCount(props.priceOptionCount - 1);
        }
    };

    const plusCount = () => {
        props.setPriceOptionCount(props.priceOptionCount + 1);
    };

    let items: string[] = [];

    if (props.options.workingDay != 0)
        items = items.concat(`작업일 ${props.options.workingDay}일`);
    if (props.options.modifyCount != 0)
        items = items.concat(`수정 ${props.options.modifyCount}회`);
    if (props.options.draftCount != 0)
        items = items.concat(`시안 ${props.options.draftCount}개`);
    items = items.concat(props.options.customOptions);

    return (
        <Accordion.Item eventKey={props.eventKey} bsPrefix="#" className={props.checked ? "item checked" : "item"}>
            <Accordion.Button as={"div"} className="head" bsPrefix="#">
                <div className="price_area">
                    <div className="price">{props.price.toLocaleString()}원</div>
                    <div className="grade">{props.grade}</div>
                </div>
                <div className="checked_area">
                    <img src={radio_checked_purple} alt=""/>
                </div>
            </Accordion.Button>
            <Accordion.Body>
                <React.Fragment>
                    <Card.Body className="body">
                        <ul>
                            {items.map((option, key) => (
                                <li key={key}>
                                    <span className="dot">
                                        <img src={icon_price_select_list} alt=""/>
                                    </span>
                                    <span className="name">{option}</span>
                                </li>
                            ))}
                        </ul>
                    </Card.Body>
                    <Card.Footer className="foot" bsPrefix="#">
                        <div className="price_area">{(props.priceOptionCount * props.price).toLocaleString()} 원</div>
                        <div className="quantity">
                            <a onClick={() => minusCount()}><img src={icon_minus} alt=""/></a>
                            <span className="num">{props.priceOptionCount.toLocaleString()}</span>
                            <a onClick={() => plusCount()}><img src={icon_plus} alt=""/></a>
                        </div>
                    </Card.Footer>
                </React.Fragment>
            </Accordion.Body>
        </Accordion.Item>
    );
}

function ProOption() {
    const params = useParams();
    const [activePriceOption, setActivePriceOption] = useState<AccordionEventKey>("0");
    const [priceOption, setPriceOption] = useState<AccordionEventKey>("0");
    const [priceOptionCount, setPriceOptionCount] = useState(1);
    const [pro, setPro] = useState<ProServiceItemDetail>();
    const [addOptionChecks, setAddOptionChecks] = useState<boolean[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/ProService/pro/${params.bid}`)
            .then(response => {
                let data = response.data;
                setPro(data);
                setAddOptionChecks(data.customOptions.map((_: any) => false));
            });
    }, [params]);

    if (pro == null) {
        return (<></>);
    }

    const price: number = pro.priceOptions[Number(priceOption)].price;
    const addOptions = pro.customOptions;

    const onPriceOptionSelect = (eventKey: AccordionEventKey) => {
        const activeKey = eventKey || "0";
        setActivePriceOption(activeKey);
        setPriceOption(activeKey);
        setPriceOptionCount(1);
    };

    const onAddOptionChangeValue = (index: number) => {
        addOptionChecks[index] = !addOptionChecks[index];
        setAddOptionChecks({...addOptionChecks});
    };

    const totalPrice = price * priceOptionCount +
        addOptions.reduce((prev, cur, idx) => (prev + (addOptionChecks[idx] ? cur.price : 0)), 0);
    
    const goProPayment = () => {
        navigate(`/home/pro-payment/${params.bid}`, {
            state: {
                serviceOption: pro.priceOptions[Number(priceOption)],
                serviceOptionCount: priceOptionCount,
                addOptions: addOptions.filter((_, idx) => addOptionChecks[idx])
            }
        })
    };
        
    return (
        <div className="layout-btn">

            {/* header */}
            <header className="header-sub">
                <div className="header_left">
                    <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                    <span className="location">서비스 옵션 선택</span>
                </div>
                <div className="header_right">
                </div>
            </header>

            {/* 전문가 서비스 옵션 선택 STANDARD */}
            <div className="page-option pro">
                <div className="price_select">
                    <Accordion activeKey={activePriceOption || "0"}
                               defaultActiveKey={priceOption || "0"}
                               onSelect={onPriceOptionSelect}>
                        {pro.priceOptions.map((opt, key) => (
                        <DivPriceOption key={key}
                            checked={priceOption == key.toLocaleString()}
                            eventKey={key.toLocaleString()}
                            price={opt.price}
                            grade={GetProServicePriceOptionType(opt.type)}
                            options={opt}
                            priceOptionCount={priceOptionCount}
                            setPriceOptionCount={setPriceOptionCount}
                        />
                        ))}
                    </Accordion>
                </div>

                {/* 추가옵션 */}
                <div className="page-add_option">
                    <div className="title">추가 옵션</div>
                    <div className="price_select">
                        {addOptions.map((addOption, index) => (
                            <div key={index} className={addOptionChecks[index] ? "item checked" : "item"}>
                                <div className="head" onClick={() => onAddOptionChangeValue(index)}>
                                    <div className="price_area type-add_option">
                                        <div className="price">{addOption.price.toLocaleString()}원</div>
                                        <div className="option">{addOption.title}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* 결제하기 버튼 */}
            <div className="foot-btn">
                <div className="detail-foot_button type02">
                    <div><a className="button full purple">
                        <span>총 결제금액</span>
                        <span className="won">{totalPrice.toLocaleString()}원</span>
                    </a></div>
                    <div><a onClick={() => goProPayment()} className="button full black">결제하기</a></div>
                </div>
            </div>
        </div>
    );
}

export default ProOption;