import React from "react";
import {createSlice} from "@reduxjs/toolkit";

export const userSlice = createSlice({
    name: "user",
    initialState: {
        name: "",
        isLoading: false,
        isLogin: null,
    },
    reducers: {
        loginUser: (state, action) => {
            state.name = action.payload.username;

            return state;
        },
        clearUser: (state) => {
            state.name = "";

            return state;
        }
    }
});

export const {loginUser, clearUser} = userSlice.actions;
export default userSlice.reducer;