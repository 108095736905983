import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import axios from "axios";
import {setCategories} from "../modules/slice/influCategorySlice";

function InfluCategoryGet() {
    const dispatch = useDispatch();

    useEffect(() => {
        axios.get("api/Influencer/category")
            .then(response => {
                const categories = response.data;
                dispatch(setCategories({categories}));
            });
    }, []);

    return null;
}

export default InfluCategoryGet;