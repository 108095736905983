import React, {useEffect, useState} from "react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import header_refresh from "img/m/header-refresh.svg";
import icon_close from "img/m/icon-close.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";

function ProFilteringForm() {
    const [price, setPrice] = useState([0, 10000000]);
    const [career, setCareer] = useState([0, 30]);
    const [rating, setRating] = useState([0, 5]);
    const [cateSel, setCateSel] = useState<number[]>([]);
    const navigate = useNavigate();
    const proCategory = useSelector((state: RootState) => state.proCategory);
    const location = useLocation();
    const categories = proCategory.repo.getMainCategories();

    useEffect(() => {
        if(location.state) {
            const state: any = location.state;

            setCateSel(state.Categories);
            setPrice([state.MinPrice, state.MaxPrice]);
            setCareer([state.MinCareerCount, state.MaxCareerCount]);
            setRating([state.MinRate, state.MaxRate]);
        }
    }, []);

    const selectCategory = (categoryId: number) => {
        if (cateSel.includes(categoryId)) {
            setCateSel(cateSel.filter(item => item !== categoryId));
        } else {
            setCateSel([...cateSel, categoryId]);
        }
    };

    const resultFiltering = () => {
        let filter = {
            Categories: cateSel, // MainCategoryId
            MinPrice: price[0],
            MaxPrice: price[1],
            MinCareerCount: career[0],
            MaxCareerCount: career[1],
            MinRate: rating[0],
            MaxRate: rating[1]
        };

        console.log(filter);

        navigate('/filtering/pro-filtering-result', {state: filter});
    };

    const reloadFiltering = () => {
        window.history.replaceState({}, '', '/filtering/pro-filtering-form');
        window.location.reload();
    };
    
    return (
        <div className="contents mobile filtering campaign">
            <header className="header-sub shadow-none border-none">
                <div className="header_left">
                    <span className="location">전문가 필터링</span>
                </div>
                <div className="header_right">
                    <a onClick={() => reloadFiltering()} className="refresh">
                        <img src={header_refresh} alt="새로고침"/>
                    </a>
                    <a onClick={() => navigate('/home/pro-main')} className="close">
                        <img src={icon_close} alt="닫기"/>
                    </a>
                </div>
            </header>
            <div className="section">
                <div className="form-wrap">
                    {/*<div className="form">
                        <span className="form-title">성별</span>
                        <div className="grid grid-3">
                            <div className="ipt-button purple">
                                <input type="radio" name="gender" id="all" title="전체" checked/>
                                <label htmlFor="all">전체</label>
                            </div>
                            <div className="ipt-button purple">
                                <input type="radio" name="gender" id="male" title="남"/>
                                <label htmlFor="male">남</label>
                            </div>
                            <div className="ipt-button purple">
                                <input type="radio" name="gender" id="female" title="여"/>
                                <label htmlFor="female">여</label>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className="form">
                        <span className="form-title">검색어</span>
                        <div className="request-input">
                            <input type="text"/>
                        </div>
                    </div>*/}
                    <div className="form">
                        <span className="form-title">카테고리</span>
                        <div className="grid grid-3">
                            {categories.map((c, idx) => (
                                <div key={idx} className="ipt-button purple">
                                    <input type="checkbox" id={`ad-ctgry${c.categoryId}`}
                                           checked={cateSel.includes(c.categoryId)}
                                           onChange={() => selectCategory(c.categoryId)}/>
                                    <label htmlFor={`ad-ctgry${c.categoryId}`}>{c.name}</label>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="form" style={{margin: "15rem 0"}}>
                        <span className="form-title">가격</span>
                        <form>
                            <Slider range={true} min={0} max={10000000} step={10000} value={price}
                                    onChange={(values: any) => setPrice(values)} railStyle={{height: '10px'}}
                                    trackStyle={{backgroundColor: "purple", height: '10px'}}
                                    handleStyle={{width: '20px', height: '20px'}}
                            />
                            <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>
                                {price[0].toLocaleString()}
                            </span>
                            <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>
                                {price[1].toLocaleString()}
                            </span>
                        </form>
                    </div>

                    <div className="form" style={{margin: "15rem 0"}}>
                        <span className="form-title">경력</span>
                        <form>
                            <Slider range={true} min={0} max={30} value={career}
                                    onChange={(values: any) => setCareer(values)} railStyle={{height: '10px'}}
                                    trackStyle={{backgroundColor: "purple", height: '10px'}}
                                    handleStyle={{width: '20px', height: '20px'}}
                            />
                            <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>{career[0]}</span>
                            <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>{career[1]}</span>
                        </form>
                    </div>

                    <div className="form" style={{margin: "15rem 0"}}>
                        <span className="form-title">평점</span>
                        <form>
                            <Slider range={true} min={0} max={5} value={rating}
                                    onChange={(values: any) => setRating(values)} railStyle={{height: '10px'}}
                                    trackStyle={{backgroundColor: "purple", height: '10px'}}
                                    handleStyle={{width: '20px', height: '20px'}}
                            />
                            <span className="position-absolute mt-2" style={{fontSize: "6rem"}}>{rating[0]}</span>
                            <span className="position-absolute mt-2" style={{right: "1em", fontSize: "6rem"}}>{rating[1]}</span>
                        </form>
                    </div>
                </div>

                <div className="foot-btn">
                    <div className="detail-foot_one_button">
                        <div><a onClick={() => resultFiltering()} className="button full black">결과 보기</a></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProFilteringForm;