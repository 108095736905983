import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Accordion, Button, OverlayTrigger, Popover} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

import MobileNavigationFooter from "components/m-navigation-footer";
import {PageType} from "types/page-type";

import icon_my_transfer_arrow_down from "img/m/icon-my-transfer-arrow-down.png";
import icon_header_setting from "img/m/icon-header-setting.png";
import icon_my_modify from "img/m/icon-my-modify.png";
import icon_main_wirte_purple from "img/m/icon-main-wirte-purple.png";
import file_speaker from "img/m/file-speaker.svg";
import axios from "axios";
import {UserType} from "../../types/user-type";
import setUser from "../../utils/setUser";

function DivProfileImg(props: { img?: string }) {
    if (props.img) {
        return (<img className="photo2" src={props.img} alt=""/>);
    }

    return (<img className="speaker" src={file_speaker} alt=""/>);
}

function DivAccordionCard(props: { header: JSX.Element, body: JSX.Element }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>("0");

    return (
        <Accordion defaultActiveKey="0" className="type-detail type-my-profile"
                   onSelect={setActiveKey}>
            <Accordion.Item eventKey={"0"} bsPrefix="#">
                <Accordion.Button as="div" className="head" bsPrefix="#">
                    {props.header}
                    <span className={activeKey == "0" ? "arrow down" : "arrow"}></span>
                </Accordion.Button>
                <Accordion.Body className="body">
                    {props.body}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function MyProMain() {
    const [profile, setProfile] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        axios.get(`api/MyProService`)
            .then(response => {
                setProfile(response.data);
            });
    }, []);

    if (profile == null) {
        return (<></>);
    }

    const changeAd = () => {
        if(confirm("광고주로 전환하시겠습니까?")) {
            axios.post("api/MyProService/change", {Type: UserType.Advertiser}).then(response => {
                setUser(response.data);
                navigate("/my/my-ad-main");
            }).catch(error => {
                console.log(error);
                alert("전환에 실패했습니다.");
                return;
            });
        }
    };

    const changeInflu = () => {
        if(confirm("인플루언서로 전환하시겠습니까?")) {
            axios.post("api/MyProService/change", {Type: UserType.Influencer}).then(response => {
                setUser(response.data);
                navigate("/my/my-influ-main");
            }).catch(error => {
                console.log(error);
                alert("전환에 실패했습니다.");
                return;
            });
        }
    };

    return (
        <div>
            <div className="contents mobile">

                {/* 마이페이지 헤더 */}
                <header className="header-my my-pro-main">
                    <div className="header_left">
                        <span className="location">마이페이지</span>
                        <div className="my-transfer_type">
                            <span>광고주로 전환</span>
                            <OverlayTrigger
                                trigger="click"
                                placement="bottom"
                                overlay={
                                    <Popover id={`popover-positioned-bottom`} className="top-menu-popover">
                                        <Popover.Body>
                                            <div className="popover-nav" style={{padding: "4rem"}}>
                                                <div onClick={() => changeAd()}>
                                                    <div className="text" style={{marginBottom: "3rem"}}>
                                                        <div className="title">광고주로 전환</div>
                                                    </div>
                                                </div>
                                                <div onClick={() => changeInflu()}>
                                                    <div className="text">
                                                        <div className="title">인플루언서로 전환</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Popover.Body>
                                    </Popover>
                                }>
                                <Button variant="link">
                                    <img src={icon_my_transfer_arrow_down} alt=""/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="header_right">
                        <Link to={"/my/my-setting-home"} className="setting">
                            <img src={icon_header_setting} alt="설정"/>
                        </Link>
                    </div>
                </header>

                {/* 마이페이지 메인영역*/}
                <div className="my-main purple">
                    <div className="my-main_profile">
                        <div className="file_upload center">
                            <div className="circle">
                                <DivProfileImg img={profile.profileImg}/>
                            </div>
                            <Link to={"/my/pro-profile-modify"}>
                                <img className="photo_button" src={icon_my_modify} alt=""/>
                            </Link>
                        </div>
                        <div className="name">{profile.name} 님</div>
                        <div className="email">{profile.mail}</div>
                    </div>

                    {/* MY 포인트*/}
                    <div className="my-main_point-wrap">
                        <div className="my-main_point">
                            <span className="title">MY 포인트</span>
                            <span className="desc">
                                <span className="money">{profile.point.toLocaleString()}</span>
                                <span className="won">원</span>
                            </span>
                        </div>
                    </div>


                    {/* MY 광고 현황 */}
                    <div className="my-main_ad_dash-wrap">
                        <div className="my-main_ad_dash">
                            <div className="title">MY 서비스 현황</div>
                            <div className="item_wrap">
                                <div className="item">
                                    <Link to={"/my/my-pro-ing/ing"} className="num">
                                        {profile.proServiceCount.toLocaleString()}
                                    </Link>
                                    <div className="sub">진행서비스</div>
                                </div>
                                <div className="item">
                                    <Link to={"/my/my-pro-ing/end"} className="num">
                                        {profile.completeProServiceCount.toLocaleString()}
                                    </Link>
                                    <div className="sub">완료서비스</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 광고등록 & 카카오플러스 친구 광고 신청*/}
                <div className="my-main-ad-write">
                    <Link to={"/write/pro-write"} className="ad_button purple">
                        <img src={icon_main_wirte_purple} alt=""/>
                        <span>서비스등록</span>
                    </Link>
                </div>

                {/* 목록 */}
                <div className="my-main-list">
                   {/* <div className="item">
                        <div className="accordion type-detail type-my-profile">
                            <div className="head">
                                TODO: 무슨 데이터가 들어가는 것인가?
                                <span className="name">나의 실적 통계</span>
                            </div>
                        </div>
                    </div>*/}
                    <div className="item">
                        <DivAccordionCard
                            header={<span className="name">내 정보</span>}
                            body={
                                <>
                                    {/*TODO: 링크 연결 필요*/}
                                    <Link to={"/my/my-zzim"} className="my-main-list-link">찜내역</Link>
                                    <Link to={"/my/my-pro-trade"} className="my-main-list-link">거래내역</Link>
                                    <Link to={"/requirements-list/common-requirements-list/pro"}
                                          className="my-main-list-link">견적서</Link>
                                    <a className="my-main-list-link">쿠폰내역</a>
                                </>
                            }/>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Pro} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyProMain;