import React, {useEffect, useState} from "react";
import {Nav, Tab} from "react-bootstrap";

import MobileCampaignThumbnail from "components/m-campaign-thumbnail";

import header_back from "img/m/header-back.svg";
import dummy_profile from "img/m/icon-intro_service01.svg";
import {useParams} from "react-router-dom";
import axios from "axios";
import {UserType} from "../../types/user-type";
import {getLocalSession} from "../../modules/localSession";

function DivCommentStar(props: { num: number, star: number }) {
    if (props.num <= props.star) {
        return (<span>★</span>);
    }

    return (<span className="empty">★</span>);
}

function AdProfile() {
    const params = useParams();
    const session = getLocalSession();
    const [profile, setProfile] = useState<any>();
    const [campaign, setCampaign] = useState<any>();
    const [zzim, setZzim] = useState(false);

    useEffect(() => {
        axios.get(`api/Advertiser/profile/${params.userId}`)
            .then(response => {
                setProfile(response.data);
            });
        axios.get(`api/Advertiser/profile/${params.userId}/campaign`)
            .then(response => {
                let data = response.data;
                let newItems: any[] = [];

                if(session) {
                    axios.get(`api/Zzim/item`)
                        .then(response2 => {
                            let data2 = response2.data.adItems;

                            data.items.forEach((c: any) => {
                                let zzim = data2.find((z: any) => z.boardId == c.bid);

                                if(zzim) {
                                    newItems.push({...c, isZzim: zzim.isZzim});
                                } else {
                                    newItems.push({...c, isZzim: false});
                                }
                            });

                            setCampaign({...data, items: newItems});
                        });
                } else {
                    newItems = data.items.map((c: any) => ({...c, isZzim: false}));
                    setCampaign({...data, items: newItems});
                }
            });
        
        if(session) {
            axios.get(`api/Zzim/user/${params.userId}`)
                .then(response => {
                    let data = response.data;
                    setZzim(data.isZzim);
                });
        }
    }, [params]);

    if (profile == null) {
        return (<></>);
    }

    if (campaign == null) {
        return (<></>);
    }

    const processingCampaigns: any[] = campaign.items;
    const campaigns: any[] = campaign.items;

    const changeZzim = () => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: params.userId, IsZzim: !zzim
        }).then(response => {
            window.location.reload();
        });
    };

    return (
        <div>
            <div className="contents mobile page-profile">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">프로필</span>
                    </div>
                    <div className="header_right">
                        {session && session.userType !== UserType.None ?
                        <div onClick={() => changeZzim()} className={`button heart border${zzim ? " on" : ""}`}></div>
                        : null }
                    </div>
                </header>
                <div className="user">
                    <div className="img"><img src={profile.profileImg || dummy_profile} alt=""/></div>
                    <div className="name">{profile.name}</div>
                    <div className="comm">{profile.comment}</div>
                </div>
                <div className="review">
                    <div className="count">
                        <div className="num"><span>{profile.campaignCount}</span>건</div>
                        <div className="title">총 광고진행 수</div>
                    </div>
                    {/*TODO : 중간 기획으로 평점이 삭제됨*/}
                    {/*<div className="score">*/}
                    {/*    <div className="num">{profile.rating}</div>*/}
                    {/*    <div className="star">*/}
                    {/*        <DivCommentStar num={1} star={profile.rating}/>*/}
                    {/*        <DivCommentStar num={2} star={profile.rating}/>*/}
                    {/*        <DivCommentStar num={3} star={profile.rating}/>*/}
                    {/*        <DivCommentStar num={4} star={profile.rating}/>*/}
                    {/*        <DivCommentStar num={5} star={profile.rating}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="title">평균평점</div>*/}
                    {/*</div>*/}
                </div>

                <Tab.Container defaultActiveKey="info">
                    <Nav className="navigation tab blue" as={"ul"}>
                        <Nav.Link eventKey="info" as={"li"}>
                            <a>기본정보</a>
                        </Nav.Link>
                        <Nav.Link eventKey="campaign" as={"li"}>
                            <a>광고</a>
                        </Nav.Link>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="info">
                            <div className="tab_cont">
                                <div className="exp"
                                     dangerouslySetInnerHTML={{
                                         __html: profile.detailComment.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                     }}
                                >
                                </div>
                                <div className="thumbnail_list">
                                    <div className="title">진행중인 광고</div>
                                    {processingCampaigns.map((campaign, key) => (
                                        <MobileCampaignThumbnail key={key} campaign={campaign}/>
                                    ))}
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="campaign">
                            <div className="tab_cont">
                                <div className="thumbnail_list">
                                    {campaigns.map((campaign, key) => (
                                        <MobileCampaignThumbnail key={key} campaign={campaign}/>
                                    ))}
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
}

export default AdProfile;