import React, {useState} from "react";
import moment from "moment";
import {DatePicker} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";

import MobilePointReport from "components/m-point-report";
import {PointReceipt} from "types/point-receipt";

import header_back_white from "img/m/header-back-white.png";
import icon_my_account from "img/m/icon-my-account.png";
import icon_header_refresh from "img/m/icon-header-refresh.png";
import my_account_thnder from "img/m/my-account-thnder.png";
import icon_account_search from "img/m/icon-account-serach.png";

function MyInfluPoint() {
    const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
    const [endDate, setEndDate] = useState<moment.Moment | null>(moment());

    const dateFormat = 'YYYY-MM-DD';

    const myAccount = {
        accountNumber: "12345-00-123454",
        point: 123000
    }

    const pointReports: PointReceipt[] = [
        {
            title: "메이크업 인스타그램 피드광고",
            price: 1000,
            createdAt: new Date(2022, 5, 20)
        },
        {
            title: "메이크업 인스타그램 피드광고",
            price: -1000,
            createdAt: new Date(2022, 5, 20)
        },
        {
            title: "메이크업 인스타그램 피드광고",
            price: 1000,
            createdAt: new Date(2022, 5, 20)
        },
        {
            title: "메이크업 인스타그램 피드광고",
            price: -1000,
            createdAt: new Date(2022, 5, 20)
        },
    ];

    return (
        <div>
            <div className="contents mobile">

                {/* 마이페이지 헤더 */}
                <header className="header-my my-influ-main">
                    <div className="header_left">
                        <a className="back"><img src={header_back_white} alt="뒤로가기"/></a>
                        <span className="location">MY 포인트</span>
                    </div>
                    <div className="header_right">
                        <div className="my-account_type">
                            {/*TODO : 기능 구현 필요*/}
                            <img src={icon_my_account} alt=""/>
                            <span>광고주로 전환</span>
                        </div>
                        {/*TODO : 기능 구현 필요*/}
                        <a className="refresh">
                            <img src={icon_header_refresh} alt="새로고침"/>
                        </a>
                    </div>
                </header>

                {/* 마이페이지 메인영역*/}
                <div className="my-main green">
                    {/* 내 계좌 */}
                    <div className="my-account-line01">
                        <span className="title">내 계좌</span>
                        <span className="desc">{myAccount.accountNumber}</span>
                    </div>
                    {/* 보유포인트 */}
                    <div className="my-account-line02">
                        <span className="title">
                            <span>보유 포인트</span>
                            {/*TODO : 기능 구현 필요*/}
                            <a>
                                <img src={my_account_thnder} alt=""/>
                                <span>충전</span>
                            </a>
                        </span>
                        <span className="desc">
                            <span className="money">{myAccount.point.toLocaleString()}</span>
                            <span>원</span>
                        </span>
                    </div>
                </div>

                <div className="my-account-tab green">
                    {/*TODO : 기능 구현 필요*/}
                    <a>전체</a>
                    <a>1개월</a>
                    <a>3개월</a>
                    <a className="active">상세조회</a>
                </div>

                {/* 검색 영역 */}
                <div className="my-account-serach">
                    <div className="serach_box">
                        <DatePicker className="form-control" allowClear={false} locale={locale}
                                    value={startDate} onChange={(date, _) => setStartDate(date)}
                                    format={dateFormat}/>
                    </div>
                    <div className="bettwen">~</div>
                    <div className="serach_box">
                        <DatePicker className="form-control" allowClear={false} locale={locale}
                                    value={endDate} onChange={(date, _) => setEndDate(date)}
                                    defaultValue={moment()} format={dateFormat}/>
                    </div>
                    {/*TODO : 기능 구현 필요*/}
                    <a className="serach_cofirm">
                        <img src={icon_account_search} alt=""/>
                    </a>
                </div>
                {/*TODO : 기능 구현 필요*/}
                <div className="my-account-serach-confirm">
                    <a className="button full black">검색</a>
                </div>

                {/* 사용내역 조회 */}
                <div className="my-account-list green">
                    <div className="title">사용 내역 조회</div>
                    <div className="contents">
                        <div className="list">
                            {pointReports.map((report, key) => (
                                <MobilePointReport key={key} pointReport={report}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MyInfluPoint;