import React, {useEffect, useState} from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_list_left from "img/m/icon-list-left.png";
import {useParams} from "react-router-dom";
import axios from "axios";
import moment from "moment";

function MyProIng() {
    const params = useParams();
    const [tab, setTab] = useState<string>("ing");
    const [ingList, setIngList] = useState<any>();
    const [endList, setEndList] = useState<any>();
    const [isModify, setIsModify] = useState<any>(false);

    useEffect(() => {
        setTab(params.tab || "ing");
        
        axios.get(`api/MyProService/services`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createDateTime).format('YYYY.MM.DD')}
                ));
                setIngList({ ...data, items: list });
            });
        axios.get(`api/MyProService/services/end`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createDateTime).format('YYYY.MM.DD')}
                ));
                setEndList({ ...data, items: list });
            });
    }, [params]);

    if (ingList == null) {
        return (<></>);
    }

    if (endList == null) {
        return (<></>);
    }

    const ingDts = ingList.items.map((item: any) => item.createDt);
    const dateListIng = ingDts.filter((dt: any, i: number) => ingDts.indexOf(dt) === i);

    const endDts = endList.items.map((item: any) => item.createDt);
    const dateListEnd = endDts.filter((dt: any, i: number) => endDts.indexOf(dt) === i);

    if(isModify) {
        return (
            <div>
                <div className="contents mobile">
                    <header className="header-member">
                        <div className="header_left">
                            <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">MY 서비스 현황</span>
                        </div>
                        {/*<div className="header_right">
                            <a onClick={() => setIsModify(false)} className="del">
                                <img src={header_close} alt="취소"/>
                            </a>
                        </div>*/}
                    </header>

                    {/* 탭 영역 */}
                    <div className="requir-list-tab">
                        <ul className="navigation tab purple">
                            <li id="tab-01" className={tab === "ing" ? "on" : ""}>
                                <a onClick={() => setTab("ing")}>진행서비스</a>
                            </li>
                            <li id="tab-02" className={tab === "end" ? "on" : ""}>
                                <a onClick={() => setTab("end")}>완료서비스</a>
                            </li>
                        </ul>
                    </div>

                    <div className="requirements-list">
                        {/*  진행광고 */}
                        <div className={`requirements-list-body${tab === "ing" ? " show" : ""}`} id="contents-01">
                            {/* total 영역 */}
                            <div className="total_area">
                                <span className="all"><a className="button round small gray">전체선택</a></span>
                                <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                            </div>
                            {/*  list 영역 */}
                            <div className="pro-trade-list-area influ">
                                {dateListIng.map((date: string, idx: number) => (
                                    <div key={idx} className="trade-list-day">
                                        <div className="list_head">
                                            <span>{date}</span>
                                        </div>
                                        <div className="list_body">
                                            {ingList.items.filter((ing: any) => ing.createDt === date)
                                                .map((ing: any, idx2: number) => (
                                                    <div key={idx2} className="item">
                                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                                        <div className="trade_wrap">
                                                            <MobileProServiceThumbnail usePopup campaign={ing}/>
                                                            {/*<a className="review_btn">거래중</a>*/}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/*  완료광고 */}
                        <div className={`requirements-list-body${tab === "end" ? " show" : ""}`} id="contents-02">
                            {/* total 영역 */}
                            <div className="total_area">
                                <span className="all"><a className="button round small gray">전체선택</a></span>
                                <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                            </div>
                            {/*  list 영역 */}
                            <div className="pro-trade-list-area influ">
                                {dateListEnd.map((date: string, idx: number) => (
                                    <div key={idx} className="trade-list-day sucess">
                                        <div className="list_head">
                                            <span>{date}</span>
                                        </div>
                                        <div className="list_body">
                                            {endList.items.filter((end: any) => end.createDt === date)
                                                .map((end: any, idx2: number) => (
                                                    <div key={idx2} className="item">
                                                        <div className="modify-checkbox"><input type="checkbox"/></div>
                                                        <div className="trade_wrap">
                                                            <MobileProServiceThumbnail usePopup campaign={end}/>
                                                            {/*<a className="review_btn">거래중</a>*/}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                    {/* 하단영역 */}
                    <MobileNavigationFooter hasHeight pageType={PageType.Pro} curMenu={"my"}/>
                </div>
                {/* //container*/}
            </div>
        );
    } else {
        return (
            <div>
                <div className="contents mobile">
                    <header className="header-member">
                        <div className="header_left">
                            <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                            <span className="location">MY 서비스 현황</span>
                        </div>
                        {/*<div className="header_right">
                            <a onClick={() => setIsModify(true)} className="del">
                                <img src={icon_del} alt="삭제"/>
                            </a>
                        </div>*/}
                    </header>

                    {/* 탭 영역 */}
                    <div className="requir-list-tab">
                        <ul className="navigation tab purple">
                            <li id="tab-01" className={tab === "ing" ? "on" : ""}>
                                <a onClick={() => setTab("ing")}>진행서비스</a>
                            </li>
                            <li id="tab-02" className={tab === "end" ? "on" : ""}>
                                <a onClick={() => setTab("end")}>완료서비스</a>
                            </li>
                        </ul>
                    </div>

                    <div className="requirements-list">
                        {/*  진행광고 */}
                        <div className={`requirements-list-body${tab === "ing" ? " show" : ""}`} id="contents-01">
                            {/* total 영역 */}
                            <div className="total_area">
                                <span className="all">총 {ingList.count}건</span>
                                <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                            </div>
                            {/*  list 영역 */}
                            <div className="pro-trade-list-area influ">
                                {dateListIng.map((date: string, idx: number) => (
                                    <div key={idx} className="trade-list-day">
                                        <div className="list_head">
                                            <span>{date}</span>
                                        </div>
                                        <div className="list_body">
                                            {ingList.items.filter((ing: any) => ing.createDt === date)
                                                .map((ing: any, idx2: number) => (
                                                    <div key={idx2} className="item">
                                                        <div className="trade_wrap">
                                                            <MobileProServiceThumbnail usePopup campaign={ing}/>
                                                            {/*<a className="review_btn">거래중</a>*/}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/*  완료광고 */}
                        <div className={`requirements-list-body${tab === "end" ? " show" : ""}`} id="contents-02">
                            {/* total 영역 */}
                            <div className="total_area">
                                <span className="all">총 {endList.count}건</span>
                                <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                            </div>
                            {/*  list 영역 */}
                            <div className="pro-trade-list-area influ">
                                {dateListEnd.map((date: string, idx: number) => (
                                    <div key={idx} className="trade-list-day sucess">
                                        <div className="list_head">
                                            <span>{date}</span>
                                        </div>
                                        <div className="list_body">
                                            {endList.items.filter((end: any) => end.createDt === date)
                                                .map((end: any, idx2: number) => (
                                                    <div key={idx2} className="item">
                                                        <div className="trade_wrap">
                                                            <MobileProServiceThumbnail usePopup campaign={end}/>
                                                            {/*<a className="review_btn">거래중</a>*/}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>

                    {/* 하단영역 */}
                    <MobileNavigationFooter hasHeight pageType={PageType.Pro} curMenu={"my"}/>
                </div>
                {/* //container*/}
            </div>
        );
    }
}

export default MyProIng;