import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

import modal_close from "img/m/modal-close.png";
import axios from "axios";
import {getLocalSession} from "../../modules/localSession";

type setDataState =  React.Dispatch<React.SetStateAction<any[]>>;
type setBoolState = React.Dispatch<React.SetStateAction<boolean>>;
function GivePointPopup(props: {
    show: boolean, setShow: setBoolState, setData: setDataState, setResult: setBoolState, param: any
}) {
    const session = getLocalSession();
    const [popularity, setPopularity] = useState(0);
    const [price, setPrice] = useState(0);

    const handleClose = () => {
        props.setShow(false);
    };
    
    const handleResult = () => {
        axios.post(`api/Filtering/CampaignFilteringByOptimize`, {
            FilterRequest: props.param, Popularity: popularity, Price: price
        }).then(response => {
            let data = response.data;
            let newItems: any[] = [];

            if(session) {
                axios.get(`api/Zzim/item`)
                    .then(response2 => {
                        let data2 = response2.data.adItems;

                        data.forEach((c: any) => {
                            let zzim = data2.find((z: any) => z.boardId == c.bid);

                            if(zzim) {
                                newItems.push({...c, isZzim: zzim.isZzim});
                            } else {
                                newItems.push({...c, isZzim: false});
                            }
                        });

                        props.setData(newItems);
                    });
            } else {
                newItems = data.map((c: any) => ({...c, isZzim: false}));
                props.setData(newItems);
            }
            
            props.setResult(true);
            props.setShow(false);
        });
    };

    return (
        <Modal className="type-center" centered show={props.show} onHide={handleClose}>
            <div className="modal-close"><img src={modal_close} alt="" onClick={handleClose}/></div>
            <Modal.Body>
                <div className="give_point">
                    <div className="title">가점주기</div>
                    <div className="desc">중요시하는 카테고리에 가점 부여하여 더 정확한<br/>필터링 결과를 확인해보세요.</div>
                    <div className="slide_dot_list">
                        <div className="item">
                            <div className="title">
                                <div className="name">인기순</div>
                                <div className="point"><span className="amount">{popularity}</span></div>
                            </div>
                            <div className="slide_dot">
                                <RangeSlider
                                    min={-10}
                                    max={10}
                                    value={popularity}
                                    onChange={e => setPopularity(Number.parseInt(e.target.value))}/>
                            </div>
                        </div>
                        <div className="item">
                            <div className="title">
                                <div className="name">가격순</div>
                                <div className="point"><span className="amount">{price}</span></div>
                            </div>
                            <div className="slide_dot">
                                <RangeSlider
                                    min={-10}
                                    max={10}
                                    value={price}
                                    onChange={e => setPrice(Number.parseInt(e.target.value))}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="type-button" onClick={handleResult}>
                결과보기
            </Modal.Footer>
        </Modal>
    );
}

export default GivePointPopup;