import React, {useEffect, useState} from "react";

import header_back from "img/m/header-back.svg";
import default_img from "../../img/m/file-speaker.svg";
import payment_arrow_right from "img/m/payment_arrow_right.png";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {CPID, IMPCode, PaymentType, PayMethod, PG, PG_PHONE} from "../../types/payment";
import axios from "axios";
import {EstimatePaymentType} from "../../types/estimate";
import {UserType} from "../../types/user-type";
import {getLocalSession} from "../../modules/localSession";

function AdPayment() {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const session = getLocalSession();
    const [require, setRequire] = useState<any>();
    const [profile, setProfile] = useState<any>();
    const [profile2, setProfile2] = useState<any>();
    const [price, setPrice] = useState<number>(0);
    const [comment, setComment] = useState<string>("");
    const [addPoint, setAddPoint] = useState(0);
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CreditCard);
    const [isAgree, setIsAgree] = useState(true);
    const [point, setPoint] = useState(0);

    useEffect(() => {
        // 결제모듈 CDN
        const jquerySrc = "https://code.jquery.com/jquery-1.12.4.min.js";
        const iamportSrc = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";

        let jqueryScript = document.querySelector(`script[src="${jquerySrc}"]`);
        let iamportScript = document.querySelector(`script[src="${iamportSrc}"]`);

        if (!jqueryScript) {
            const jquery = document.createElement("script");
            jquery.src = jquerySrc;
            jquery.async = true;
            document.body.appendChild(jquery);
        }

        if (!iamportScript) {
            const iamport = document.createElement("script");
            iamport.src = iamportSrc;
            iamport.async = true;
            document.body.appendChild(iamport);
        }
        
        let state: any = location.state;
        setPrice(state.price);
        setComment(state.comment);
        
        axios.get(`api/MyAdvertiser/estimate/${params.bid}`)
            .then(response => {
                let data = response.data;
                let control = data.requestTargetUser == UserType.Influencer ? "Influencer" : "ProService";

                setRequire(data);
                
                axios.get(`api/${control}/profile/${state.userId}`)
                    .then(response => {
                        setProfile(response.data);
                    });
            });
        axios.get(`api/Account/point_receipt`)
            .then(response => {
                let list = response.data;
                let p = 0;

                list.forEach((row: any) => {
                    p = p + row.point;
                });

                setPoint(p);
            });

        if(session) {
            axios.get(`api/Account/user`)
                .then(response => {
                    setProfile2(response.data);
                });
        }
    }, [params]);

    if (require == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    if (profile2 == null) {
        return (<></>);
    }

    const procPayment = () => {
        if(!isAgree) {
            alert("결제내역 확인 및 진행에 동의해 주세요.");
            return;
        }

        if(paymentType === PaymentType.Bank && 1000 > price - addPoint) {
            alert("계좌이체 시 최소 결제금액은 1000원입니다.");
            return;
        }

        // @ts-ignore
        const { IMP } = window;
        IMP.init(IMPCode);
        
        const pgId = paymentType === PaymentType.CellPhone ? PG_PHONE : `${PG}.${CPID}`;
        const payData = {
            pg: pgId,                                           // PG사
            pay_method: PayMethod[paymentType],                 // 결제수단
            merchant_uid: `mid_ad_${new Date().getTime()}`,     // 주문번호
            amount: price-addPoint,                             // 결제금액
            name: '광고 견적 상품',                               // 주문명
            buyer_name: profile2.displayName,                   // 구매자 이름
            buyer_tel: profile2.phoneNumber,                    // 구매자 전화번호
            buyer_email: profile2.userName,                     // 구매자 이메일
        };

        IMP.request_pay(payData, (resp: any) => {
            console.log(resp);
            if(resp.success) {
                let control = require.requestTargetUser == UserType.Influencer ? "influencer" : "pro";

                axios.post(`/api/Shop/${control}/estimate/creditCard`, {
                    Bid: params.bid,
                    UserId: profile.userId,
                    Point: addPoint,
                    ImpUid: resp.imp_uid,
                    MerchantUid: resp.merchant_uid,
                    PayMethod: resp.pay_method,
                    Amount: resp.paid_amount,
                    Status: resp.status,
                    PayType: paymentType
                }).then(response => {
                    alert("결제가 완료되었습니다.");
                    navigate(`/requirements/ad-payment-require/${params.bid}`);
                }).catch(error => {
                    console.log(error);
                    alert("결제에 실패했습니다.");

                    return;
                });
            } else {
                alert(resp.error_msg);
            }
        });
    };
    
    return (
        <div className="layout-btn">
            {/* header */}
            <header className="header-sub">
                <div className="header_left">
                    <Link to={"/requirements-list/ad-requirements-list"} className="back"><img src={header_back} alt="뒤로가기"/></Link>
                    <span className="location">결제하기</span>
                </div>
                <div className="header_right">
                </div>
            </header>

            {/* 상품 정보 */}
            <div className="product_info blue">
                <div className="product_item">
                    <div className="img">
                        {profile.profileImg ? 
                        <img src={profile.profileImg} alt="" style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                        : <img src={default_img} style={{backgroundColor: "#A8A8A8", padding: "7rem"}} alt=""/>
                        }
                    </div>
                    <div className="desc">
                        <div className="content">{require.title}</div>
                        <div className="name">{profile.name}</div>
                    </div>
                </div>
                <div className="product_name">
                    <div className="title">견적 금액</div>
                    <div className="price">{price.toLocaleString()}원</div>
                </div>
                <div className="product_detail">{comment}</div>
            </div>

            {/* 결제 금액 */}
            <div className="payment_price">
                <div className="section_title">결제 금액</div>
                <a className="coupon_button">
                    <div className="name">쿠폰 적용</div>
                    <div className="arrow"><img src={payment_arrow_right} alt=""/></div>
                </a>
                <div className="payment_total">
                    <div className="price_area">
                        <div className="title">보유포인트</div>
                        <div className="won">{point.toLocaleString()}원</div>
                    </div>
                </div>
                <div className="coupon_button_02">
                    <input className="input basic" type="number" placeholder="포인트입력" value={addPoint}
                           style={{width: "calc(100% - 32rem - 4rem)"}} max={point} min={0}
                           onChange={(e) => setAddPoint(point < Number(e.target.value) ? point : Number(e.target.value))}/>
                    <a onClick={() => setAddPoint(point)} className="point_button">전액사용</a>
                </div>
                <div className="payment_total">
                    <div className="price_area">
                        <div className="title">총 서비스 금액</div>
                        <div className="won">{price.toLocaleString()}</div>
                    </div>
                    <div className="price_area">
                        <div className="title">쿠폰사용</div>
                        <div className="won">0</div>
                    </div>
                    <div className="price_area">
                        <div className="title">포인트 사용</div>
                        <div className="won">{addPoint}</div>
                    </div>
                    <div className="last_price blue">
                        <div className="title">최종 결제금액</div>
                        <div className="won">{(price-addPoint).toLocaleString()}원</div>
                    </div>
                </div>
            </div>

            {/* 결제 수단 */}
            <div className="payment_price">
                <div className="section_title">결제 수단</div>
                <div className="form">
                    <div className="grid grid-3">
                        <div className="ipt-button blue">
                            <input type="radio" id="card" checked={paymentType === PaymentType.CreditCard}
                                   onChange={() => setPaymentType(PaymentType.CreditCard)}/>
                            <label htmlFor="card">신용카드</label>
                        </div>
                        <div className="ipt-button blue">
                            <input type="radio" id="hp" checked={paymentType === PaymentType.CellPhone}
                                   onChange={() => setPaymentType(PaymentType.CellPhone)}/>
                            <label htmlFor="hp">휴대폰</label>
                        </div>
                        <div className="ipt-button blue">
                            <input type="radio" id="bank" checked={paymentType === PaymentType.Bank}
                                   onChange={() => setPaymentType(PaymentType.Bank)}/>
                            <label htmlFor="bank">계좌이체</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* 동의 영역*/}
            <div className="payment_price">
                <div className="check_notice">
                    <div className="accordion type-detail type-check">
                        <div className="head">
                            <span className="name">
                                <input type="checkbox" checked={isAgree} onChange={() => setIsAgree(!isAgree)}/>
                                { // @ts-ignore
                                    <span data-toggle="collapse" href="#collapseExample2" aria-expanded="false"
                                          aria-controls="collapseExample2">결제내역 확인 및 진행 동의</span>
                                }
                            </span>
                            { // @ts-ignore
                                <span data-toggle="collapse" href="#collapseExample2" aria-expanded="false"
                                      aria-controls="collapseExample2" className="arrow down"></span>
                            }
                        </div>
                        <div className="body collapse" id="collapseExample2">
                            1. 전문가와 의뢰인 간의 상호 협의 후 청약철회가 가능합니다. <br/>
                            2. 전문가의 귀책 사유로 디자인 작업을 시작하지...
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "#fff", color: "#666666", padding: "5px 8rem"}}>
                <ul>
                    <li>상호명 : (주)돌샘</li>
                    <li>대표자 : 노명식</li>
                    <li>사업자번호 : 608-86-11737</li>
                    <li>TEL : 02.586.2589</li>
                    <li>주소: 경기도 화성시 송산면 송산포도로 184-8, 2호</li>
                </ul>
            </div>

            {/* 결제하기 버튼 */}
            <div className="foot-btn">
                <div className="detail-foot_one_button">
                    <div><a onClick={() => procPayment()} className="button full black">결제하기</a></div>
                </div>
            </div>
        </div>
    );
}

export default AdPayment;