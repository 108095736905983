import React, {useEffect, useState} from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import MobileCampaignThumbnail from "components/m-campaign-thumbnail";
import MobileProServiceThumbnail from "components/m-pro-service-thumbnail";
import MobileInfluThumbnail from "components/m-influ-thumbnail";
import {PageType} from "types/page-type";
import default_profile from "img/m/file-speaker.svg";
import header_back from "img/m/header-back.svg";
import icon_list_left from "img/m/icon-list-left.png";
import {getLocalSession} from "../../modules/localSession";
import axios from "axios";
import {UserType} from "../../types/user-type";
import {Link} from "react-router-dom";

function DivCampaignListDay(props: { title: string, items: any[] }) {
    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <div className="trade_wrap">
                            <MobileCampaignThumbnail campaign={item}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function DivCampaignProfile(props: { title: string, items: any[] }) {
    const session = getLocalSession();

    const changeZzim = (id: string, isZzim: boolean) => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: id, IsZzim: isZzim
        }).then(response => {
            window.location.reload();
        });
    }
    
    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <div className="trade_wrap">
                            <div className="thumbnail width">
                                <div className="image_area">
                                    {session && session.userType !== UserType.None ?
                                    <div onClick={() => changeZzim(item.id, !item.isZzim)}
                                         className={`button heart blue${item.isZzim ? " on" : ""}`}></div>
                                    : null }
                                    <Link to={`/profile/ad-profile/${item.id}`}>
                                        <img src={item.profileImg || default_profile} alt=""/>
                                    </Link>
                                </div>
                                <div className="text_area">
                                    <div className="cate">
                                        {item.category.split(";").join(", ")}
                                    </div>
                                    <div className="price_area">
                                        <Link to={`/profile/ad-profile/${item.id}`} className="price">
                                            <span>{item.name}</span>
                                        </Link>
                                    </div>
                                    <div style={{marginTop: "5rem", color: "#a8a8a8"}}>{item.comment}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function DivInfluListDay(props: { title: string, items: any[] }) {
    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <div className="trade_wrap">
                            <MobileInfluThumbnail influ={item}/>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function DivInfluProfile(props: { title: string, items: any[] }) {
    const session = getLocalSession();

    const changeZzim = (id: string, isZzim: boolean) => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: id, IsZzim: isZzim
        }).then(response => {
            window.location.reload();
        });
    }

    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <div className="trade_wrap">
                            <div className="thumbnail width">
                                <div className="image_area">
                                    {session && session.userType !== UserType.None ?
                                        <div onClick={() => changeZzim(item.id, !item.isZzim)}
                                             className={`button heart green${item.isZzim ? " on" : ""}`}></div>
                                        : null }
                                    <Link to={`/profile/influ-profile/${item.id}`}>
                                        <img src={item.profileImg || default_profile} alt=""/>
                                    </Link>
                                </div>
                                <div className="text_area">
                                    <div className="cate">
                                        {item.category.split(";").join(", ")}
                                    </div>
                                    <div className="price_area">
                                        <Link to={`/profile/influ-profile/${item.id}`} className="price">
                                            <span>{item.name}</span>
                                        </Link>
                                    </div>
                                    <div style={{marginTop: "5rem", color: "#a8a8a8"}}>{item.comment}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function DivProListDay(props: { title: string, items: any[] }) {
    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <MobileProServiceThumbnail campaign={item}/>
                    </div>
                ))}
            </div>
        </div>
    );
}

function DivProProfile(props: { title: string, items: any[] }) {
    const session = getLocalSession();

    const changeZzim = (id: string, isZzim: boolean) => {
        axios.post(`api/Zzim/user`, {
            ZzimUserId: id, IsZzim: isZzim
        }).then(response => {
            window.location.reload();
        });
    }

    return (
        <div className="trade-list-day">
            <div className="list_head">
                <span>{props.title}</span>
            </div>
            <div className="list_body">
                {props.items.map((item, key) => (
                    <div className="item" key={key}>
                        <div className="trade_wrap">
                            <div className="thumbnail width">
                                <div className="image_area">
                                    {session && session.userType !== UserType.None ?
                                        <div onClick={() => changeZzim(item.id, !item.isZzim)}
                                             className={`button heart purple${item.isZzim ? " on" : ""}`}></div>
                                        : null }
                                    <Link to={`/profile/pro-profile/${item.id}`}>
                                        <img src={item.profileImg || default_profile} alt=""/>
                                    </Link>
                                </div>
                                <div className="text_area">
                                    <div className="cate">
                                        {item.category.split(";").join(", ")}
                                    </div>
                                    <div className="price_area">
                                        <Link to={`/profile/pro-profile/${item.id}`} className="price">
                                            <span>{item.name}</span>
                                        </Link>
                                    </div>
                                    <div style={{marginTop: "5rem", color: "#a8a8a8"}}>{item.comment}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function MyZzim() {
    const session = getLocalSession();
    const [tab, setTab] = useState<string>("ad");
    const [zzimUser, setZzimUser] = useState<any>();
    const [zzimItem, setZzimItem] = useState<any>();

    useEffect(() => {
        axios.get(`api/Zzim/user`)
            .then(response => {
                setZzimUser(response.data);
            });
        axios.get(`api/Zzim/item`)
            .then(response => {
                setZzimItem(response.data);
            });
    }, []);

    if (zzimUser == null) {
        return (<></>);
    }

    if (zzimItem == null) {
        return (<></>);
    }
    
    const color = session && session.userType === UserType.Advertiser ? "blue"
        : session && session.userType === UserType.Influencer ? "green"
            : session && session.userType === UserType.Pro ? "purple" : ""
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">찜내역</span>
                    </div>
                    <div className="header_right">
                        {/*<a className="del"><img src={icon_del} alt="삭제"/></a>*/}
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className={`navigation tab ${color}`}>
                        <li id="tab-01" className={tab === "ad" ? "on" : ""}>
                            <a onClick={() => setTab("ad")}>광고주</a>
                        </li>
                        <li id="tab-02" className={tab === "influ" ? "on" : ""}>
                            <a onClick={() => setTab("influ")}>인플루언서</a>
                        </li>
                        <li id="tab-03" className={tab === "pro" ? "on" : ""}>
                            <a onClick={() => setTab("pro")}>전문가</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">
                    {/*  광고 */}
                    <div className={`requirements-list-body${tab === "ad" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {zzimItem.adCount + zzimUser.adCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <DivCampaignListDay title={"광고"} items={zzimItem.adItems.filter((z: any) => z.isZzim)}/>
                            <DivCampaignProfile title={"프로필"} items={zzimUser.adItems.filter((z: any) => z.isZzim)}/>
                        </div>
                    </div>

                    {/*  인플루 */}
                    <div className={`requirements-list-body${tab === "influ" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {zzimItem.influCount + zzimUser.influCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <DivInfluListDay title={"광고"} items={zzimItem.influItems.filter((z: any) => z.isZzim)}/>
                            <DivInfluProfile title={"프로필"} items={zzimUser.influItems.filter((z: any) => z.isZzim)}/>
                        </div>
                    </div>

                    {/*  전문가 */}
                    <div className={`requirements-list-body${tab === "pro" ? " show" : ""}`} id="contents-03">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {zzimItem.proCount + zzimUser.proCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            <DivProListDay title={"광고"} items={zzimItem.proItems.filter((z: any) => z.isZzim)}/>
                            <DivProProfile title={"프로필"} items={zzimUser.proItems.filter((z: any) => z.isZzim)}/>
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Common} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyZzim;