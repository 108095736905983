import React from "react";

export const enum InquiryType {
    Wait,
    Complete
}

export type InquiryItem = {
    title: string,
    date: Date,
    inquiryType: InquiryType
}