import React, {useRef, useState} from "react";

import header_close from "img/m/header-close.svg";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";

function AdWriteAuth() {
    const location = useLocation();
    const [rating, setRating] = useState(1);
    const [comment, setComment] = useState("");
    const ref = useRef<HTMLTextAreaElement>(null);
    const navigate = useNavigate();
    
    const auth = () => {
        if("" == comment) {
            alert("내용을 입력하세요.");
            if(ref.current) ref.current.focus();
            return;
        }

        let state: any = location.state;

        axios.post(`/api/Advertiser/feed_write/${state.bid}`, {
            Body: comment
        }).then(response => {
            alert("광고 인증이 등록되었습니다.");
            navigate(`/home/ad-detail/${state.bid}`);
        }).catch(error => {
            console.log(error);
            alert("광고 인증에 실패했습니다.");
            return;
        });
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <span className="location">광고 인증하기</span>
                    </div>
                    <div className="header_right">
                        <a onClick={() => history.back()}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="write pro">
                    {/*<div className="section-write">
                         별점 선택 
                        <div className="chocie_star">
                            <div className="grade">
                                <span className={rating >= 1 ? "on" : ""} onClick={() => setRating(1)}>★</span>
                                <span className={rating >= 2 ? "on" : ""} onClick={() => setRating(2)}>★</span>
                                <span className={rating >= 3 ? "on" : ""} onClick={() => setRating(3)}>★</span>
                                <span className={rating >= 4 ? "on" : ""} onClick={() => setRating(4)}>★</span>
                                <span className={rating >= 5 ? "on" : ""} onClick={() => setRating(5)}>★</span>
                            </div>
                            <div className="ment">선택해주세요.</div>
                        </div>
                    </div>*/}
                    <div className="section-write">
                        {/* editor 영역입니다. text area로 대체해둠. */}
                        <div className="write-textarea">
                            { // @ts-ignore
                                <textarea value={comment} onChange={(e) => setComment(e.target.value)} ref={ref}
                                          cols={30} rows={10} placeholder=""></textarea>
                            }
                        </div>
                    </div>
                </div>

                <div className="bottom chap">
                    <a onClick={() => auth()} className="button full black">등록</a>
                </div>
            </div>
            {/* //container */}
        </div>
    );
}

export default AdWriteAuth;