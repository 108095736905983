export const enum AdvertisingType {
    None,

    /// <summary>
    /// 피드 업로드
    /// </summary>
    FeedUpload,

    /// <summary>
    /// 후기 작성
    /// </summary>
    Review,

    /// <summary>
    /// 체험단
    /// </summary>
    ExperienceGroup,

    /// <summary>
    /// 카카오톡 친구 추천
    /// </summary>
    RequestKakaoFriend,
}

export const enum AdvertisingJoinType {
    None,
    Click,
    FeedJoin,
    FeedUpdate,
    FeedComplete
}