import React, {useEffect, useState} from "react";

import MobileNavigationFooter from "components/m-navigation-footer";
import {PageType} from "types/page-type";

import header_back from "img/m/header-back.svg";
import icon_list_left from "img/m/icon-list-left.png";
import ico_trade_etc from "img/m/ico-trade_etc.png";
import {useParams} from "react-router-dom";
import axios from "axios";
import moment from "moment";
import MobileInfluEstimate from "../../components/m-influ-estimate";

function MyAdLive() {
    const params = useParams();
    const [tab, setTab] = useState<string>("join");
    const [joinList, setJoinList] = useState<any>();
    const [ingList, setIngList] = useState<any>();
    const [endList, setEndList] = useState<any>();
    const [isModify, setIsModify] = useState<any>(false);

    useEffect(() => {
        setTab(params.tab || "join");
        
        axios.get(`api/MyInfluencer/estimate/ad/join`)
            .then(response => {
                let data = response.data;
                let items = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createAt).format('YYYY.MM.DD')}
                ));
                let newItems: any[] = [];
                let rcvItems = data.rcvItems;

                items.forEach((it: any) => {
                    let item = rcvItems.find((rcv: any) => it.bid == rcv.boardId);

                    if(item) {
                        it.paymentType = item.paymentProcessType;
                    }

                    newItems.push(it);
                });

                setJoinList({...data, items: newItems});
            });
        axios.get(`api/MyInfluencer/estimate/ad/ing`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createAt).format('YYYY.MM.DD')}
                ));
                setIngList({ ...data, items: list });
            });
        axios.get(`api/MyInfluencer/estimate/ad/end`)
            .then(response => {
                let data = response.data;
                let list = data.items.map((row: any) => (
                    {...row, createDt: moment(row.createAt).format('YYYY.MM.DD')}
                ));
                setEndList({ ...data, items: list });
            });
    }, [params]);

    if (joinList == null) {
        return (<></>);
    }

    if (ingList == null) {
        return (<></>);
    }

    if (endList == null) {
        return (<></>);
    }

    const joinDts = joinList.items.map((item: any) => item.createDt);
    const dateListJoin = joinDts.filter((dt: any, i: number) => joinDts.indexOf(dt) === i);

    const ingDts = ingList.items.map((item: any) => item.createDt);
    const dateListIng = ingDts.filter((dt: any, i: number) => ingDts.indexOf(dt) === i);

    const endDts = endList.items.map((item: any) => item.createDt);
    const dateListEnd = endDts.filter((dt: any, i: number) => endDts.indexOf(dt) === i);

    const getDate = (date: any) => {
        const dt = new Date(date);
        const year = dt.getFullYear();
        const month = dt.getMonth() + 1;
        const day = dt.getDate();

        return (`${year}.${month >= 10 ? month : '0' + month}.${day >= 10 ? day : '0' + day}`);
    };
    
    return (
        <div>
            <div className="contents mobile">
                <header className="header-member">
                    <div className="header_left">
                        <a onClick={() => history.back()} className="back">
                            <img src={header_back} alt="뒤로가기"/>
                        </a>
                        <span className="location">MY 광고 현황</span>
                    </div>
                    <div className="header_right">
                        <a className="etc _etc-header-popover" data-container="body" data-toggle="popover"
                           data-placement="bottom" data-content="Vivamus."><img src={ico_trade_etc} alt=""/></a>
                    </div>
                </header>

                {/* 탭 영역 */}
                <div className="requir-list-tab">
                    <ul className="navigation tab green">
                        <li id="tab-01" className={tab === "join" ? "on" : ""}>
                            <a onClick={() => setTab("join")}>지원한 광고</a>
                        </li>
                        <li id="tab-02" className={tab === "ing" ? "on" : ""}>
                            <a onClick={() => setTab("ing")}>진행광고</a>
                        </li>
                        <li id="tab-03" className={tab === "end" ? "on" : ""}>
                            <a onClick={() => setTab("end")}>완료광고</a>
                        </li>
                    </ul>
                </div>

                <div className="requirements-list">

                    {/*  지원한 광고 */}
                    <div className={`requirements-list-body${tab === "join" ? " show" : ""}`} id="contents-01">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {joinList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            {dateListJoin.map((date: string, idx: number) => (
                                <div key={idx} className="trade-list-day">
                                    <div className="list_head">
                                        <span>{date}</span>
                                    </div>
                                    <div className="list_body" style={{padding: "0"}}>
                                        {joinList.items.filter((join: any) => join.createDt === date)
                                            .map((join: any, idx2: number) => (
                                                <div key={idx2} className="item"
                                                     style={{borderBottom: "1px solid #a8a8a8", paddingBottom: "6rem"}}>
                                                    <div className="trade_wrap">
                                                        <MobileInfluEstimate estimate={join} userType={"influ"}/>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*  진행광고 */}
                    <div className={`requirements-list-body${tab === "ing" ? " show" : ""}`} id="contents-02">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {ingList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            {dateListIng.map((date: string, idx: number) => (
                                <div key={idx} className="trade-list-day">
                                    <div className="list_head">
                                        <span>{date}</span>
                                    </div>
                                    <div className="list_body" style={{padding: "0"}}>
                                        {ingList.items.filter((ing: any) => ing.createDt === date)
                                            .map((ing: any, idx2: number) => (
                                                <div key={idx2} className="item"
                                                     style={{borderBottom: "1px solid #a8a8a8", paddingBottom: "6rem"}}>
                                                    <div className="trade_wrap">
                                                        <MobileInfluEstimate estimate={ing} userType={"influ"}/>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*  완료광고 */}
                    <div className={`requirements-list-body${tab === "end" ? " show" : ""}`} id="contents-03">
                        {/* total 영역 */}
                        <div className="total_area">
                            <span className="all">총 {endList.totalCount}건</span>
                            <span className="sort"><img src={icon_list_left} alt=""/><span>최신순</span></span>
                        </div>
                        {/*  list 영역 */}
                        <div className="pro-trade-list-area influ">
                            {dateListEnd.map((date: string, idx: number) => (
                                <div key={idx} className="trade-list-day">
                                    <div className="list_head">
                                        <span>{date}</span>
                                    </div>
                                    <div className="list_body" style={{padding: "0"}}>
                                        {endList.items.filter((end: any) => end.createDt === date)
                                            .map((end: any, idx2: number) => (
                                                <div key={idx2} className="item"
                                                     style={{borderBottom: "1px solid #a8a8a8", paddingBottom: "6rem"}}>
                                                    <div className="trade_wrap">
                                                        <MobileInfluEstimate estimate={end} userType={"influ"}/>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* 하단영역 */}
                <MobileNavigationFooter hasHeight pageType={PageType.Influ} curMenu={"my"}/>
            </div>
        </div>
    );
}

export default MyAdLive;