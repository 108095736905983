import React, {useEffect, useState} from "react";

import header_back from "img/m/header-back.svg";
import dummy_pament01 from "img/m/dummy_pament01.png";
import icon_instagram from "img/m/icon-instagram.png";
import payment_arrow_right from "img/m/payment_arrow_right.png";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {InfluDetailItem} from "../../types/influ";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";
import {getPlatformName, PlatformType} from "../../types/platform";
import icon_facebook from "../../img/m/icon-facebook.png";
import icon_twitter from "../../img/m/icon-twitter.svg";
import icon_naver from "../../img/m/icon-naver.svg";
import icon_naverblog from "../../img/m/icon-naverblog.png";
import icon_kakaofriend from "../../img/m/icon-kakao.png.png";
import {CPID, IMPCode, PaymentType, PayMethod, PG, PG_PHONE} from "../../types/payment";
import {getLocalSession} from "../../modules/localSession";

function DivPlatformIcon(props: { type: PlatformType }) {
    if (props.type == PlatformType.Instagram) {
        return (<img src={icon_instagram} alt=""/>);
    } else if (props.type == PlatformType.Facebook) {
        return (<img src={icon_facebook} alt=""/>);
    } else if (props.type == PlatformType.Twitter) {
        return (<img src={icon_twitter} alt=""/>);
    } else if (props.type == PlatformType.NaverCafe) {
        return (<img src={icon_naver} alt=""/>);
    } else if (props.type == PlatformType.NaverBlog) {
        return (<img src={icon_naverblog} alt=""/>);
    } else if (props.type == PlatformType.KakaoFriend) {
        return (<img src={icon_kakaofriend} alt=""/>);
    } else {
        return (<></>);
    }
}

function InfluPayment() {
    const params = useParams();
    const location = useLocation();
    const [influ, setInflu] = useState<InfluDetailItem>();
    const [count, setCount] = useState<any[]>([{type: -1, likeCount: 0, feedCount: 0}]);
    const [addPoint, setAddPoint] = useState(0);
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.CreditCard);
    const [isAgree, setIsAgree] = useState(true);
    const [point, setPoint] = useState(0);
    const [profile, setProfile] = useState<any>();
    const influCategory = useSelector((state: RootState) => state.influCategory);
    const navigate = useNavigate();
    const session = getLocalSession();

    useEffect(() => {
        // 결제모듈 CDN
        const jquerySrc = "https://code.jquery.com/jquery-1.12.4.min.js";
        const iamportSrc = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
        
        let jqueryScript = document.querySelector(`script[src="${jquerySrc}"]`);
        let iamportScript = document.querySelector(`script[src="${iamportSrc}"]`);
        
        if (!jqueryScript) {
            const jquery = document.createElement("script");
            jquery.src = jquerySrc;
            jquery.async = true;
            document.body.appendChild(jquery);
        }

        if (!iamportScript) {
            const iamport = document.createElement("script");
            iamport.src = iamportSrc;
            iamport.async = true;
            document.body.appendChild(iamport);
        }
        
        let state: any = location.state;
        setCount(state.influCount);
        
        axios.get(`api/Influencer/influ/${params.bid}`)
            .then(response => {
                let data = response.data;
                setInflu(data);
            });
        axios.get(`api/Account/point_receipt`)
            .then(response => {
                let list = response.data;
                let p = 0;

                list.forEach((row: any) => {
                    p = p + row.point;
                });

                setPoint(p);
            });
        
        if(session) {
            axios.get(`api/Account/user`)
                .then(response => {
                    setProfile(response.data);
                });
        }
    }, [params]);

    if (influ == null) {
        return (<></>);
    }

    if (profile == null) {
        return (<></>);
    }

    const costPerClicks = Array.from(influ.platforms, (item) => item.costPerClick);
    const costPerFeeds = Array.from(influ.platforms, (item) => item.costPerFeed);

    const minCostPerClick = Math.min(...costPerClicks);
    const minCostPerFeed = Math.min(...costPerFeeds);

    let totalPrice = 0;
    influ.platforms.forEach(inf => {
        let c = count.find(cnt => cnt.type === inf.type);
        totalPrice += inf.costPerClick * (c ? c.likeCount : 0);
        totalPrice += inf.costPerFeed * (c ? c.feedCount : 0);
    });

    const procPayment = () => {
        if(!isAgree) {
            alert("결제내역 확인 및 진행에 동의해 주세요.");
            return;
        }
        
        if(paymentType === PaymentType.Bank && 1000 > totalPrice - addPoint) {
            alert("계좌이체 시 최소 결제금액은 1000원입니다.");
            return;
        }

        // @ts-ignore
        const { IMP } = window;
        IMP.init(IMPCode);

        const pgId = paymentType === PaymentType.CellPhone ? PG_PHONE : `${PG}.${CPID}`;
        const payData = {
            pg: pgId,                                            // PG사
            pay_method: PayMethod[paymentType],                  // 결제수단
            merchant_uid: `mid_influ_${new Date().getTime()}`,   // 주문번호
            amount: totalPrice-addPoint,                         // 결제금액
            name: '인플루언서 광고 상품',                          // 주문명
            buyer_name: profile.displayName,                    // 구매자 이름
            buyer_tel: profile.phoneNumber,                     // 구매자 전화번호
            buyer_email: profile.userName,                      // 구매자 이메일
        };

        IMP.request_pay(payData, (resp: any) => {
            console.log(resp);
            if(resp.success) {
                axios.post(`/api/Shop/influencer/${params.bid}/creditCard`, {
                    Options: count.map(c => (
                        {PlatformType: c.type, ClickCount: c.likeCount, FeedCount: c.feedCount}
                    )),
                    Point: addPoint,
                    ImpUid: resp.imp_uid,
                    MerchantUid: resp.merchant_uid,
                    PayMethod: resp.pay_method,
                    Amount: resp.paid_amount,
                    Status: resp.status,
                    PayType: paymentType
                }).then(response => {
                    alert("결제가 완료되었습니다.");
                    navigate(`/home/influ-detail/${params.bid}`);
                }).catch(error => {
                    console.log(error);
                    alert("결제에 실패했습니다.");

                    return;
                });
            } else {
                alert(resp.error_msg);
            }
        });
    };
    
    return (
        <div className="layout-btn">

            {/* header */}
            <header className="header-sub">
                <div className="header_left">
                    <a onClick={() => history.back()} className="back"><img src={header_back} alt="뒤로가기"/></a>
                    <span className="location">결제하기</span>
                </div>
                <div className="header_right">
                </div>
            </header>

            {/* 상품 정보 */}
            <div className="product_info green influe">
                <div className="product_item">
                    <div className="img">
                        {/*<div className="heart">
                            <img src={payment_heart} alt=""/>
                            <span>0</span>
                        </div>*/}
                        <img src={influ.images[0] || dummy_pament01} alt=""/>
                    </div>
                    <div className="desc">
                        <div className="content">
                            <div className="cate">{influCategory.repo.getSubCategoryName(influ.categoryId, influ.subCategoryId)}</div>
                            <div className="title">{influ.title}</div>
                            <div className="option">
                                <a className="button round gray small">클릭 {minCostPerClick}원</a>
                                <a className="button round gray small">피드 {minCostPerFeed}원</a>
                            </div>
                        </div>
                        {/* 인플루언서는 인스타와 star 가 들어감 */}
                        <div className="name">
                            <div className="name_icon">
                                {influ.platforms.map((plat, idx) => (
                                    <DivPlatformIcon key={idx} type={plat.type}/>
                                ))}
                            </div>
                            <div className="name_star">
                                <span className="grade"><span className="star">★</span>{influ.rating}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {influ.platforms.map((plat, idx) => (
                <div key={idx}>
                    <div className="product_name">
                        <div className="title">
                            <span className="icon"><DivPlatformIcon type={plat.type}/></span> {getPlatformName(plat.type)}
                        </div>
                        <div className="price">{(
                            plat.costPerClick * count.find(c => c.type === plat.type).likeCount +
                            plat.costPerFeed * count.find(c => c.type === plat.type).feedCount
                        ).toLocaleString()}원</div>
                    </div>
                    <div className="product_detail">
                        좋아요 {count.find(c => c.type === plat.type).likeCount}회 / 
                        피드(해시태그포함) {count.find(c => c.type === plat.type).feedCount}회
                    </div>
                </div>
                ))}
            </div>

            {/* 결제 금액 */}
            <div className="payment_price">
                <div className="section_title">결제 금액</div>
                <a className="coupon_button">
                    <div className="name">쿠폰 적용</div>
                    <div className="arrow"><img src={payment_arrow_right} alt=""/></div>
                </a>
                <div className="payment_total">
                    <div className="price_area">
                        <div className="title">보유포인트</div>
                        <div className="won">{point.toLocaleString()}원</div>
                    </div>
                </div>
                <div className="coupon_button_02">
                    <input className="input basic" type="number" placeholder="포인트입력" value={addPoint}
                           style={{width: "calc(100% - 32rem - 4rem)"}} max={point} min={0}
                           onChange={(e) => setAddPoint(point < Number(e.target.value) ? point : Number(e.target.value))}/>
                    <a onClick={() => setAddPoint(point)} className="point_button">전액사용</a>
                </div>
                <div className="payment_total">
                    <div className="price_area">
                        <div className="title">총 서비스 금액</div>
                        <div className="won">{totalPrice.toLocaleString()}</div>
                    </div>
                    <div className="price_area">
                        <div className="title">쿠폰사용</div>
                        <div className="won">0</div>
                    </div>
                    <div className="price_area">
                        <div className="title">포인트 사용</div>
                        <div className="won">{addPoint}</div>
                    </div>
                    <div className="last_price green">
                        <div className="title">최종 결제금액</div>
                        <div className="won">{(totalPrice-addPoint).toLocaleString()}원</div>
                    </div>
                </div>
            </div>

            {/* 결제 수단 */}
            <div className="payment_price">
                <div className="section_title">결제 수단</div>
                <div className="form">
                    <div className="grid grid-3">
                        <div className="ipt-button green">
                            <input type="radio" id="card" checked={paymentType === PaymentType.CreditCard}
                                   onChange={() => setPaymentType(PaymentType.CreditCard)}/>
                            <label htmlFor="card">신용카드</label>
                        </div>
                        <div className="ipt-button green">
                            <input type="radio" id="hp" checked={paymentType === PaymentType.CellPhone}
                                   onChange={() => setPaymentType(PaymentType.CellPhone)}/>
                            <label htmlFor="hp">휴대폰</label>
                        </div>
                        <div className="ipt-button green">
                            <input type="radio" id="bank" checked={paymentType === PaymentType.Bank}
                                   onChange={() => setPaymentType(PaymentType.Bank)}/>
                            <label htmlFor="bank">계좌이체</label>
                        </div>
                    </div>
                </div>
            </div>

            {/* 동의 영역*/}
            <div className="payment_price">
                <div className="check_notice">
                    <div className="accordion type-detail type-check">
                        <div className="head">
                            <span className="name">
                                <input type="checkbox" checked={isAgree} onChange={() => setIsAgree(!isAgree)}/>
                                { // @ts-ignore
                                <span data-toggle="collapse" href="#collapseExample2" aria-expanded="false"
                                      aria-controls="collapseExample2">결제내역 확인 및 진행 동의</span>
                                }
                            </span>
                            { // @ts-ignore
                            <span data-toggle="collapse" href="#collapseExample2" aria-expanded="false"
                                  aria-controls="collapseExample2" className="arrow down"></span>
                            }
                        </div>
                        <div className="body collapse" id="collapseExample2">
                            1. 전문가와 의뢰인 간의 상호 협의 후 청약철회가 가능합니다. <br/>
                            2. 전문가의 귀책 사유로 디자인 작업을 시작하지...
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "#fff", color: "#666666", padding: "5px 8rem"}}>
                <ul>
                    <li>상호명 : (주)돌샘</li>
                    <li>대표자 : 노명식</li>
                    <li>사업자번호 : 608-86-11737</li>
                    <li>TEL : 02.586.2589</li>
                    <li>주소: 경기도 화성시 송산면 송산포도로 184-8, 2호</li>
                </ul>
            </div>

            {/* 결제하기 버튼 */}
            <div className="foot-btn">
                <div className="detail-foot_one_button">
                    <div><a onClick={() => procPayment()} className="button full black">결제하기</a></div>
                </div>
            </div>

        </div>
    );
}

export default InfluPayment;