import React from "react";
import axios from "axios";
import {setLocalSession} from "modules/localSession";

function setUser(user: any) {
    const {token} = user;

    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    setLocalSession(user);
}

export default setUser;