import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";

import modal_close from "img/m/modal-close.png";
import axios from "axios";
import dummy_01 from "../../img/m/dummy-01.png";
import {GetProServicePriceOptionType} from "../../types/pro-service";
import moment from "moment/moment";
import {PaymentType} from "../../types/payment";
import {TradeState} from "../../types/trade";

function DetailProPopup(props: {
    show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, receiptId: string, service: string,
    myService: string
}) {
    const [items, setItems] = useState<any>();
    const handleClose = () => props.setShow(false);

    const cancelPayment = () => {
        if(confirm("결제를 취소하시겠습니까?")) {
            let query = items.isEstimate === true ? "?type=estimate" : "?type=service";
            axios.delete(`api/Shop/pro/${props.receiptId}${query}`).then(response => {
                alert("결제 취소가 완료되었습니다.");
                window.location.reload();
            }).catch(error => {
                console.log(error);
                alert("결제 취소에 실패했습니다.");

                return;
            });
        }
    };

    useEffect(() => {
        if(props.receiptId && props.show) {
            axios.get(`api/${props.myService}/receipts/${props.service}/${props.receiptId}`)
                .then(response => {
                    let data = response.data;

                    if(!data.customOptions) {
                        data = {...data, customOptions: []};
                    }
                    setItems(data);
                });
        }
    }, [props]);

    if(items == null) {
        return (<></>);
    }

    const payment = items.paymentType == PaymentType.CreditCard ? "신용카드" :
        items.paymentType == PaymentType.CellPhone ? "휴대폰" :
            items.paymentType == PaymentType.Bank ? "계좌이체" : "";

    let totalOptionPrice = 0;
    items.customOptions.forEach((option: any) => {
        totalOptionPrice += option.price;
    });

    return (
        <Modal className="type-center" centered show={props.show} onHide={handleClose}>
            <div className="modal-close"><img src={modal_close} alt="" onClick={handleClose}/></div>
            <Modal.Body>
                <div className="trade_detail">
                    <div className="title">거래 상세내역</div>
                    <div className="product_info">
                        <div className="thumbnail width">
                            <div className="image_area">
                                <img src={items.bannerImg || dummy_01} style={{objectFit: 'cover'}} alt=""/>
                                {/*<div className="button heart purple _onOff"></div>*/}
                            </div>
                            <div className="text_area">
                                <div className="cate type-star">
                                    <span>{items.category}</span>
                                    <div className="trade_etc">
                                        <span className="grade"><span className="star">★</span>{items.rating}</span>
                                    </div>
                                </div>
                                <div className="title">{items.title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="trade_service">
                        <div className="service_item">
                            <div className="title">거래 서비스</div>
                            {items.servicePrice > 0 ?
                            <div className="price_group">
                                <div className="name">
                                    {GetProServicePriceOptionType(items.priceOptionType)}
                                </div>
                                <div className="price">
                                    {items.servicePrice.toLocaleString()}원 X {items.serviceOptionCount}
                                </div>
                            </div>
                            : null}
                        </div>
                        <div className="service_item">
                            <div className="title">옵션</div>
                            {items.customOptions.map((option: any, idx: number) => (
                                <div className="price_group">
                                    <div className="name">- {option.title}</div>
                                    <div className="price">{option.price.toLocaleString()}원</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="byer_info">
                        <div className="name">구매자 정보</div>
                        <div className="price">{items.buyerName}</div>
                    </div>
                    <div className="trade_service">
                        <div className="service_item">
                            <div className="title">결제정보</div>
                            <div className="price_group">
                                <div className="name">결제일자</div>
                                <div className="price">
                                    <span className="date">
                                        {moment(items.createAt).format('YYYY.MM.DD HH:mm:ss')}
                                    </span>
                                </div>
                            </div>
                            <div className="price_group">
                                <div className="name">결제수단</div>
                                <div className="price">{payment}</div>
                            </div>
                        </div>
                    </div>
                    <div className="trade_total">
                        <div className="name">총 결제금액</div>
                        <div className="price">
                            {(
                                items.servicePrice * items.serviceOptionCount + totalOptionPrice <= 0 ?
                                    items.money : items.servicePrice * items.serviceOptionCount + totalOptionPrice
                            ).toLocaleString()}원
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {(props.myService === "MyAdvertiser" || props.myService === "MyInfluencer") && items.payStatus === TradeState.Complete ?
            <Modal.Footer className="type-button">
                <a onClick={() => cancelPayment()}>결제취소</a>
            </Modal.Footer>
            : null}
        </Modal>
    );
}

export default DetailProPopup;