import React from "react";
import {Link} from "react-router-dom";
import {SwipeableList, SwipeableListItem, SwipeAction, TrailingActions, Type} from "react-swipeable-list";
import moment from "moment";

import 'react-swipeable-list/dist/styles.css';

import MobileNavigationFooter from "components/m-navigation-footer";
import {ChatMessageIntro} from "types/chat";
import {PageType} from "types/page-type";

import header_search_black from "img/m/header-search_black.svg";
import header_filter_black from "img/m/header-filter_black.svg";
import icon_close from "img/m/icon-close.svg";
import icon_msg_del from "img/m/icon_msg_del.png";

function DivMessage(props: { intro: ChatMessageIntro }) {
    let date = moment(props.intro.lastMessageDate).format('a h:mm');
    if (moment().diff(props.intro.lastMessageDate, 'days') != 0) {
        date = moment(props.intro.lastMessageDate).format('YYYY.MM.DD');
    }

    return (
        <div className="msg-wrap">
            <div className="img"><img className="photo" src={props.intro.profileImg} alt=""/>
            </div>
            <div className="txt">
                <Link to={`/my/my-message-write/${props.intro.roomId}`}>
                    <div className="from">{props.intro.name}</div>
                    <div className="msg">{props.intro.lastMessage}</div>
                </Link>
            </div>
            <div className="time">{date}</div>
            {props.intro.messageCount ? <div className="new">{props.intro.messageCount}</div> : <></>}
        </div>
    );
}

function MyMessage() {
    const intros: ChatMessageIntro[] = [
        {
            roomId: "91582f38-1a79-4cb5-bc57-7bcebd59b53d",
            profileImg: "https://placeimg.com/700/700/any",
            name: "이시영",
            lastMessage: "안녕하세요",
            lastMessageDate: new Date(2022, 6, 8),
            messageCount: 5,
        },
        {
            roomId: "a876b16e-3906-4ec8-8f73-859e5ac13092",
            profileImg: "https://placeimg.com/700/700/any",
            name: "이시영",
            lastMessage: "안녕하세요",
            lastMessageDate: moment().toDate(),
            messageCount: 5,
        },
        {
            roomId: "148268a6-e9eb-4acc-880c-4537d30e49e5",
            profileImg: "https://placeimg.com/700/700/any",
            name: "이시영",
            lastMessage: "안녕하세요",
            lastMessageDate: new Date(2022, 6, 8),
            messageCount: 0,
        },
    ];

    const trailingActions = () => (
        <TrailingActions>
            <SwipeAction destructive={true} onClick={() => console.info('swipe action triggered')}>
                <div className="msg-wrap-del">
                    <div className="del">
                        <img src={icon_msg_del} alt=""/><br/>
                        Delete
                    </div>
                </div>
            </SwipeAction>
        </TrailingActions>
    );

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <span className="location">메시지</span>
                    </div>
                    <div className="header_right">
                        <a href="#" className="search"><img src={header_search_black} alt="검색"/></a>
                        <a href="#" className="filter"><img src={header_filter_black} alt="필터"/></a>
                        <div className="search-box">
                            <input type="text" placeholder="검색어를 입력하세요." className="header-search"/>
                            <input type="button" title="검색"/>
                            <a href="#" className="close"><img src={icon_close} alt="닫기"/></a>
                        </div>
                    </div>
                </header>
                <SwipeableList type={Type.IOS}>
                    {intros.map((intro, key) => (
                        <SwipeableListItem trailingActions={trailingActions()} key={key}>
                            <DivMessage intro={intro}/>
                        </SwipeableListItem>
                    ))}
                </SwipeableList>

                <MobileNavigationFooter pageType={PageType.Common} curMenu={"chat"}/>
            </div>
        </div>
    );
}

export default MyMessage;