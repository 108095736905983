import React, {useEffect, useState} from "react";

import header_refresh from "img/m/header-refresh.svg";
import header_close from "img/m/header-close.svg";
import layer_check from "img/m/layer-check.svg";
import {MainCategory, SubCategory} from "../../types/category";
import axios from "axios";
import {useSelector} from "react-redux";
import {RootState} from "../../modules";

function WishAdvertisement(props: {
    setWishAd: React.Dispatch<React.SetStateAction<boolean>>,
    setSelect: React.Dispatch<React.SetStateAction<string[]>>
}) {
    const [categories, setCategories] = useState<MainCategory[]>([]);
    const [subCates, setSubCates] = useState<SubCategory[]>([]);
    const [mainCateId, setMainCateId] = useState<number>(1);
    const [selAd, setSelAd] = useState<SubCategory[]>([]);
    const [selCateName, setSelCateName] = useState<string[]>([]);

    const adCategory = useSelector((state: RootState) => state.adCategory);
    
    useEffect(() => {
        axios.get("api/Advertiser/mainCategory")
            .then(response => {
                setCategories(response.data);
            });
        
        setSubCates(adCategory.repo.getSubCategories(1));
    }, []);

    const selectCate = (cateId: number) => {
        setMainCateId(cateId);
        setSubCates(adCategory.repo.getSubCategories(cateId));
    };

    const selectSub = (sub: SubCategory) => {
        if(!selAd.includes(sub)) {
            setSelAd([...selAd, sub]);
            setSelCateName([...selCateName, sub.name])
        }
    };

    const applyAdvertisement = () => {
        if (selAd.length > 0) {
            props.setSelect(selCateName);
            props.setWishAd(false);
        }
    };

    return (
        <div>
            <div className="contents mobile">
                <header className="header-layout under_line">
                    <div className="header_left">
                        <span className="location">광고 활동 분야 선택</span>
                    </div>
                    <div className="header_right">
                        <a><img className="refresh" src={header_refresh} alt=""/></a>
                        <a onClick={() => props.setWishAd(false)}><img className="close" src={header_close} alt=""/></a>
                    </div>
                </header>

                <div className="layer type01">
                    <div className="scroll_wrap">
                        <ul className="scroll type01">
                            {categories.map((cate, idx) => (
                                <li key={idx} className={mainCateId == cate.categoryId ? 'on' : ''}>
                                    <a onClick={() => selectCate(cate.categoryId)}>{cate.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="depth2_area">
                        <ul className="depth02">
                        {subCates.filter(sub => sub.subCategoryId != 0).map((sub, idx) => (
                            <li key={idx} className={selAd.includes(sub) ? 'checked' : ''}>
                                <a onClick={() => selectSub(sub)}>
                                    <span className="name">{sub.name}</span>
                                    <span className="check_img"><img src={layer_check} alt=""/></span>
                                </a>
                            </li>
                        ))}
                        </ul>
                        <div className="selected_item">
                        {selAd.map((ad, idx) => (
                            <a key={idx} className="button close_type basic">
                            <span className="wrap">
                                <span className="name">{ad.name}</span>
                                <span className="exit" onClick={() => setSelAd(selAd.filter(item => item.subCategoryId !== ad.subCategoryId))}></span>
                            </span>
                            </a>
                        ))}
                        </div>
                    </div>
                </div>
                <div className="bottom chap">
                    <a onClick={() => applyAdvertisement()} className={`button full ${selAd.length > 0 ? 'blue' : 'gray'}`}>선택완료</a>
                </div>
            </div>
        </div>
    );
}

export default WishAdvertisement;