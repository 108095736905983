import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";

import modal_close from "img/m/modal-close.png";
import axios from "axios";
import dummy_01 from "../../img/m/dummy-01.png";
import moment from "moment/moment";
import {PaymentType} from "../../types/payment";
import {getPlatformName} from "../../types/platform";
import {TradeState} from "../../types/trade";

function DetailInfluPopup(props: {
    show: boolean, setShow: React.Dispatch<React.SetStateAction<boolean>>, receiptId: string, service: string,
    myService: string
}) {
    const [items, setItems] = useState<any>();
    const handleClose = () => props.setShow(false);
    
    const cancelPayment = () => {
        if(confirm("결제를 취소하시겠습니까?")) {
            let query = items.isEstimate === true ? "?type=estimate" : "?type=service";
            axios.delete(`api/Shop/influencer/${props.receiptId}${query}`).then(response => {
                alert("결제 취소가 완료되었습니다.");
                window.location.reload();
            }).catch(error => {
                console.log(error);
                alert("결제 취소에 실패했습니다.");

                return;
            });
        }
    };

    useEffect(() => {
        if(props.receiptId && props.show) {
            axios.get(`api/${props.myService}/receipts/${props.service}/${props.receiptId}`)
                .then(response => {
                    let data = response.data;
                    
                    if(!data.platforms) {
                        data = {...data, platforms: []};
                    }
                    setItems(data);
                });
        }
    }, [props]);

    if(items == null) {
        return (<></>);
    }

    const payment = items.paymentType == PaymentType.CreditCard ? "신용카드" :
        items.paymentType == PaymentType.CellPhone ? "휴대폰" :
            items.paymentType == PaymentType.Bank ? "계좌이체" : "";

    let totalPrice = 0;
    items.platforms.forEach((plat: any) => {
        let option = items.options.find((op: any) => op.platformType == plat.type);
        totalPrice += plat.costPerClick * option.clickCount;
        totalPrice += plat.costPerFeed * option.feedCount;
    });

    return (
        <Modal className="type-center" show={props.show} onHide={handleClose}>
            <div className="modal-close"><img src={modal_close} alt="" onClick={handleClose}/></div>
            <Modal.Body>
                <div className="trade_detail">
                    <div className="title">거래 상세내역</div>
                    <div className="product_info">
                        <div className="thumbnail width">
                            <div className="image_area">
                                <img src={items.bannerImg || dummy_01} style={{objectFit: 'cover'}} alt=""/>
                                {/*<div className="button heart purple _onOff"></div>*/}
                            </div>
                            <div className="text_area">
                                <div className="cate type-star">
                                    <span>{items.category}</span>
                                    <div className="trade_etc">
                                        <span className="grade"><span className="star">★</span>{items.rating}</span>
                                    </div>
                                </div>
                                <div className="title">{items.title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="trade_service">
                        <div className="service_item">
                            <div className="title">거래 서비스</div>
                        </div>
                        {items.platforms.map((plat: any, idx: number) => (
                        <div key={idx} className="service_item">
                            <div className="title" style={{fontSize: "4rem"}}>- {getPlatformName(plat.type)}</div>
                            <div className="price_group" style={{fontSize: "4rem"}}>
                                <div className="name col-sm-4">좋아요</div>
                                <div className="price col-sm-4">1회당 {plat.costPerClick.toLocaleString()}원</div>
                                <div className="price col-sm-4">
                                    X {items.options.find(
                                        (op: any) => op.platformType == plat.type)
                                    .clickCount}
                                </div>
                            </div>
                            <div className="price_group" style={{fontSize: "4rem"}}>
                                <div className="name col-sm-4">피드</div>
                                <div className="price col-sm-4">1회당 {plat.costPerFeed.toLocaleString()}원</div>
                                <div className="price col-sm-4">
                                    X {items.options.find(
                                    (op: any) => op.platformType == plat.type)
                                    .feedCount}
                                </div>
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="byer_info">
                        <div className="name">구매자 정보</div>
                        <div className="price">{items.buyerName}</div>
                    </div>
                    <div className="trade_service">
                        <div className="service_item">
                            <div className="title">결제정보</div>
                            <div className="price_group">
                                <div className="name">결제일자</div>
                                <div className="price">
                                    <span className="date">
                                        {moment(items.createAt).format('YYYY.MM.DD HH:mm:ss')}
                                    </span>
                                </div>
                            </div>
                            <div className="price_group">
                                <div className="name">결제수단</div>
                                <div className="price">{payment}</div>
                            </div>
                        </div>
                    </div>
                    <div className="trade_total">
                        <div className="name">총 결제금액</div>
                        <div className="price">{(totalPrice <= 0 ? items.money : totalPrice).toLocaleString()}원</div>
                    </div>
                </div>
            </Modal.Body>
            {props.myService === "MyAdvertiser" && items.payStatus === TradeState.Complete ?
            <Modal.Footer className="type-button">
                <a onClick={() => cancelPayment()}>결제취소</a>
            </Modal.Footer>
            : null}
        </Modal>
    );
}

export default DetailInfluPopup;