import React from "react";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "css/common.css";
import "css/antd.css";
import "css/quill.css";
import "antd/es/date-picker/style/index.css";
import "react-quill/dist/quill.snow.css";

import moment from "moment";
import "moment/locale/ko";

import {BrowserRouter, Route, Routes} from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import Logo from "./pages/intro/logo";
import Intro from "./pages/intro/intro";
import Service from "./pages/intro/service";
import KakaoFriend from "./pages/intro/kakaofriend";
import AdMain from "./pages/home/ad-main";
import AdList from "./pages/home/ad-list";
import AdDetail from "./pages/home/ad-detail";
import AdPayment from "./pages/home/ad-payment";
import InfluMain from "./pages/home/influ-main";
import InfluList from "./pages/home/influ-list";
import InfluDetail from "./pages/home/influ-detail";
import InfluOption from "./pages/home/influ-option";
import InfluPayment from "./pages/home/influ-payment";
import ProMain from "./pages/home/pro-main";
import ProList from "./pages/home/pro-list";
import ProDetail from "./pages/home/pro-detail";
import ProOption from "./pages/home/pro-option";
import ProPayment from "./pages/home/pro-payment";
import RewardGradePopup from "./pages/popup/reward-grade";
/*import RewardPopup from "./pages/popup/reward";*/
/*import GivePointPopup from "./pages/popup/give-point";*/
/*import DetailListPopup from "./pages/popup/detail-list";*/
/*import DetailListProPopup from "./pages/popup/detail-list-pro";*/
import ProcessAdPopup from "./pages/popup/process-ad";
import ProWrite from "./pages/write/pro-write";
import AdDetailWrite from "./pages/write/ad-detail-write";
import AdWriteAuth from "./pages/write/ad-write-auth";
import AdWrite from "./pages/write/ad-write";
import UserReview from "./pages/write/user-review";
import AddKakao from "./pages/write/add-kakao";
import Login from "./pages/member/login";
import Join from "./pages/member/join";
import Welcome from "./pages/member/welcome";
import CampaignProfileAdd from "./pages/member/campaign-profile-add";
import InfluProfileAdd from "./pages/member/influ-profile-add";
import ProProfileAdd from "./pages/member/pro-profile-add";
/*import WishLocation from "./pages/member/wish-location";
import WishExpert from "./pages/member/wish-expert";*/
import AdProfile from "./pages/profile/ad-profile";
import ProProfile from "./pages/profile/pro-profile";
import InfluProfile from "./pages/profile/influ-profile";
import AdRequest from "./pages/request/ad-request";
import InfluRequire from "./pages/requirements/influ-require";
import InfluRequireDetail from "./pages/requirements/influ-require-detail";
import InfluRequireSend from "./pages/requirements/influ-require-send";
import ProRequireDetail from "./pages/requirements/pro-require-detail";
import ProRequireSend from "./pages/requirements/pro-require-send";
import AdRequire from "./pages/requirements/ad-require";
import CommonRequire from "./pages/requirements/common-require";
import CommonRequireSend from "./pages/requirements/common-require-send";
import AdRequirementsList from "./pages/requirements-list/ad-requirements-list";
import InfluRequirementsListSend from "./pages/requirements-list/influ-requirements-list-send";
import InfluMyRequirementsListSend from "./pages/requirements-list/influ-my-requirements-list-send";
import ProRequirementsList from "./pages/requirements-list/pro-requirements-list";
import CommonRequirementsList from "./pages/requirements-list/common-requirements-list";
import CommonRequirementsListDetail from "./pages/requirements-list/common-requirements-list-detail";
import MyAdMain from "./pages/my/my-ad-main";
import MyInfluMain from "./pages/my/my-influ-main";
import MyProMain from "./pages/my/my-pro-main";
import MyAdPoint from "./pages/my/my-ad-point";
import MyInfluPoint from "./pages/my/my-influ-point";
import InfluProfileModify from "./pages/my/influ-profile-modify";
import AdProfileModify from "./pages/my/ad-profile-modify";
import MyCouponInflu from "./pages/my/my-coupon-influ";
import MyStatic from "./pages/my/my-static";
import MySetting from "./pages/my/my-setting";
import MyNoticeList from "./pages/my/my-notice-list";
import MyMtm from "./pages/my/my-mtm";
import MyFaq from "./pages/my/my-faq";
import MyTerms from "./pages/my/my-terms";
import MyMessage from "./pages/my/my-message";
import MyMessageWrite from "./pages/my/my-message-write";
import MyProTrade from "./pages/my/my-pro-trade";
import MyInfluTrade from "./pages/my/my-influ-trade";
import MyAdTrade from "./pages/my/my-ad-trade";
import MyZzim from "./pages/my/my-zzim";
import MyAdLive from "./pages/my/my-ad-live";
import MyAdLiveModify from "./pages/my/my-ad-live-modify";
import MyIng from "./pages/my/my-ing";
import MyIngModify from "./pages/my/my-ing-modify";
import MyProIng from "./pages/my/my-pro-ing";
import MyAdIng from "./pages/my/my-ad-ing";
import MyAdIngModify from "./pages/my/my-ad-ing-modify";
import ProFilteringForm from "./pages/filtering/pro-filtering-form";
import ProFilteringResult from "./pages/filtering/pro-filtering-result";
import InfluFilteringForm from "./pages/filtering/influ-filtering-form";
import InfluFilteringResult from "./pages/filtering/influ-filtering-result";
import CampaignFilteringForm from "./pages/filtering/campaign-filtering-form";
import CampaignFilteringResult from "./pages/filtering/campaign-filtering-result";
import AdFilteringResult from "./pages/filtering/ad-filtering-result";
import AnalysisResult from "./pages/analysis/analysis-result";
import ProAnalysisResult from "./pages/analysis/pro-analysis-result";
import SearchResult from "./pages/search/search-result";
import AdSearchResult from "./pages/search/ad-search-result";
import MyNoticeDetail from "./pages/my/my-notice-detail";
import MySettingHome from "./pages/my/my-setting-home";
import AdCategoryGet from "./components/ad-category-get";
import ProServiceCategoryGet from "./components/pro-service-category-get";
import CategoryListPopup from "./pages/popup/category-list";
import InfluCategoryGet from "./components/influ-category-get";
import ExternalLogin from "./pages/member/external-login";
import ProProfileModify from "./pages/my/pro-profile-modify";
import MyInfluIng from "./pages/my/my-influ-ing";
import ProRequire from "./pages/requirements/pro-require";
import AdPaymentRequire from "./pages/requirements/ad-payment-require";
import PaymentRequirements from "./pages/requirements/payments";
import Privacy from "./pages/home/privacy";
import Terms from "./pages/home/terms";

moment.locale("ko");

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <AdCategoryGet/>
            <InfluCategoryGet/>
            <ProServiceCategoryGet/>
            {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
            <Routes>
                <Route path="/" element={<Intro/>}/>
                <Route path="/logo" element={<Logo/>}/>
                <Route path="/intro" element={<Intro/>}/>
                <Route path="/service" element={<Service/>}/>
                <Route path="/kakaofriend" element={<KakaoFriend/>}/>
                <Route path="/home/ad-main" element={<AdMain/>}/>
                <Route path="/home/ad-list" element={<AdList/>}/>
                <Route path="/home/ad-list/:mid" element={<AdList/>}/>
                <Route path="/home/ad-list/:mid/:sid" element={<AdList/>}/>
                <Route path="/home/ad-detail/:bid" element={<AdDetail/>}/>
                <Route path="/home/ad-payment/:bid" element={<AdPayment/>}/>
                <Route path="/home/influ-main" element={<InfluMain/>}/>
                <Route path="/home/influ-list" element={<InfluList/>}/>
                <Route path="/home/influ-list/:location" element={<InfluList/>}/>
                <Route path="/home/influ-list/:location/:category" element={<InfluList/>}/>
                <Route path="/home/influ-detail/:bid" element={<InfluDetail/>}/>
                <Route path="/home/influ-option/:bid" element={<InfluOption/>}/>
                <Route path="/home/influ-payment/:bid" element={<InfluPayment/>}/>
                <Route path="/home/pro-main" element={<ProMain/>}/>
                <Route path="/home/pro-list" element={<ProList/>}/>
                <Route path="/home/pro-list/:location" element={<ProList/>}/>
                <Route path="/home/pro-list/:location/:category" element={<ProList/>}/>
                <Route path="/home/pro-detail/:bid" element={<ProDetail/>}/>
                <Route path="/home/pro-option/:bid" element={<ProOption/>}/>
                <Route path="/home/pro-payment/:bid" element={<ProPayment/>}/>
                <Route path="/popup/reward-grade" element={<RewardGradePopup/>}/>
                {/*<Route path="/popup/reward" element={<RewardPopup/>}/>*/}
                {/*<Route path="/popup/give-point" element={<GivePointPopup/>}/>*/}
                {/*<Route path="/popup/detail-list" element={<DetailListPopup/>}/>*/}
                {/*<Route path="/popup/detail-list-pro" element={<DetailListProPopup/>}/>*/}
                <Route path="/popup/process-ad" element={<ProcessAdPopup/>}/>
                <Route path="/popup/category-list" element={<CategoryListPopup/>}/>
                <Route path="/write/pro-write" element={<ProWrite/>}/>
                <Route path="/write/ad-detail-write" element={<AdDetailWrite/>}/>
                <Route path="/write/ad-write-auth" element={<AdWriteAuth/>}/>
                <Route path="/write/ad-write" element={<AdWrite/>}/>
                <Route path="/write/user-review" element={<UserReview/>}/>
                <Route path="/write/add-kakao" element={<AddKakao/>}/>
                <Route path="/member/login" element={<Login/>}/>
                <Route path="/member/external-login" element={<ExternalLogin/>}/>
                <Route path="/member/join" element={<Join/>}/>
                <Route path="/member/welcome" element={<Welcome/>}/>
                <Route path="/member/campaign-profile-add" element={<CampaignProfileAdd/>}/>
                <Route path="/member/influ-profile-add" element={<InfluProfileAdd/>}/>
                <Route path="/member/pro-profile-add" element={<ProProfileAdd/>}/>
                {/*<Route path="/member/wish-location" element={<WishLocation/>}/>
                <Route path="/member/wish-expert" element={<WishExpert/>}/>*/}
                <Route path="/profile/ad-profile/:userId" element={<AdProfile/>}/>
                <Route path="/profile/pro-profile/:userId" element={<ProProfile/>}/>
                <Route path="/profile/influ-profile/:userId" element={<InfluProfile/>}/>
                <Route path="/request/ad-request" element={<AdRequest/>}/>
                <Route path="/requirements/influ-require/:bid" element={<InfluRequire/>}/>
                <Route path="/requirements/influ-require-detail" element={<InfluRequireDetail/>}/>
                <Route path="/requirements/influ-require-send/:bid" element={<InfluRequireSend/>}/>
                <Route path="/requirements/pro-require-detail" element={<ProRequireDetail/>}/>
                <Route path="/requirements/pro-require-send/:bid" element={<ProRequireSend/>}/>
                <Route path="/requirements/pro-require/:bid" element={<ProRequire/>}/>
                <Route path="/requirements/ad-require/:bid" element={<AdRequire/>}/>
                <Route path="/requirements/ad-payment-require/:bid" element={<AdPaymentRequire/>}/>
                <Route path="/requirements/payments/:bid" element={<PaymentRequirements/>}/>
                <Route path="/requirements/common-require/:bid" element={<CommonRequire/>}/>
                <Route path="/requirements/common-require-send/:bid" element={<CommonRequireSend/>}/>
                <Route path="/requirements-list/ad-requirements-list" element={<AdRequirementsList/>}/>
                <Route path="/requirements-list/influ-requirements-list" element={<InfluRequirementsListSend/>}/>
                <Route path="/requirements-list/influ-my-requirements-list-send"
                       element={<InfluMyRequirementsListSend/>}/>
                <Route path="/requirements-list/pro-requirements-list" element={<ProRequirementsList/>}/>
                <Route path="/requirements-list/common-requirements-list/:type" element={<CommonRequirementsList/>}/>
                <Route path="/requirements-list/common-requirements-list-detail/:bid"
                       element={<CommonRequirementsListDetail/>}/>
                <Route path="/my/my-ad-main" element={<MyAdMain/>}/>
                <Route path="/my/my-influ-main" element={<MyInfluMain/>}/>
                <Route path="/my/my-pro-main" element={<MyProMain/>}/>
                <Route path="/my/my-ad-point" element={<MyAdPoint/>}/>
                <Route path="/my/my-influ-point" element={<MyInfluPoint/>}/>
                <Route path="/my/influ-profile-modify" element={<InfluProfileModify/>}/>
                <Route path="/my/ad-profile-modify" element={<AdProfileModify/>}/>
                <Route path="/my/pro-profile-modify" element={<ProProfileModify/>}/>
                <Route path="/my/my-coupon-influ" element={<MyCouponInflu/>}/>
                <Route path="/my/my-static" element={<MyStatic/>}/>
                <Route path="/my/my-setting-home" element={<MySettingHome/>}/>
                <Route path="/my/my-setting" element={<MySetting/>}/>
                <Route path="/my/my-notice-list" element={<MyNoticeList/>}/>
                <Route path="/my/my-notice-detail/:bid" element={<MyNoticeDetail/>}/>
                <Route path="/my/my-mtm" element={<MyMtm/>}/>
                <Route path="/my/my-faq" element={<MyFaq/>}/>
                <Route path="/my/my-terms" element={<MyTerms/>}/>
                <Route path="/my/my-message" element={<MyMessage/>}/>
                <Route path="/my/my-message-write/:roomId" element={<MyMessageWrite/>}/>
                <Route path="/my/my-pro-trade" element={<MyProTrade/>}/>
                <Route path="/my/my-influ-trade" element={<MyInfluTrade/>}/>
                <Route path="/my/my-ad-trade" element={<MyAdTrade/>}/>
                <Route path="/my/my-zzim" element={<MyZzim/>}/>
                <Route path="/my/my-ad-live/:tab" element={<MyAdLive/>}/>
                <Route path="/my/my-ad-live-modify" element={<MyAdLiveModify/>}/>
                <Route path="/my/my-ing/:tab" element={<MyIng/>}/>
                <Route path="/my/my-ing-modify/:tab" element={<MyIngModify/>}/>
                <Route path="/my/my-pro-ing/:tab" element={<MyProIng/>}/>
                <Route path="/my/my-influ-ing" element={<MyInfluIng/>}/>
                <Route path="/my/my-ad-ing" element={<MyAdIng/>}/>
                <Route path="/my/my-ad-ing-modify" element={<MyAdIngModify/>}/>
                <Route path="/filtering/pro-filtering-form" element={<ProFilteringForm/>}/>
                <Route path="/filtering/pro-filtering-result" element={<ProFilteringResult/>}/>
                <Route path="/filtering/influ-filtering-form" element={<InfluFilteringForm/>}/>
                <Route path="/filtering/influ-filtering-result" element={<InfluFilteringResult/>}/>
                <Route path="/filtering/campaign-filtering-form" element={<CampaignFilteringForm/>}/>
                <Route path="/filtering/campaign-filtering-result" element={<CampaignFilteringResult/>}/>
                <Route path="/filtering/ad-filtering-result" element={<AdFilteringResult/>}/>
                <Route path="/analysis/analysis-result" element={<AnalysisResult/>}/>
                <Route path="/analysis/pro-analysis-result" element={<ProAnalysisResult/>}/>
                <Route path="/search/search-result" element={<SearchResult/>}/>
                <Route path="/search/ad-search-result" element={<AdSearchResult/>}/>
                <Route path="/home/terms" element={<Terms/>}/>
                <Route path="/home/privacy" element={<Privacy/>}/>
            </Routes>
        </BrowserRouter>
        // <div className="App">
        //     <header className="App-header">
        //         <img src={logo} className="App-logo" alt="logo"/>
        //         <p>
        //             Edit <code>src/App.tsx</code> and save to reload.
        //         </p>
        //         <a
        //             className="App-link"
        //             href="https://reactjs.org"
        //             target="_blank"
        //             rel="noopener noreferrer"
        //         >
        //             Learn React
        //         </a>
        //     </header>
        // </div>
    );
}

export default App;
