import React, {useState} from "react";
import {Accordion} from "react-bootstrap";
import {AccordionEventKey} from "react-bootstrap/AccordionContext";

import {FaqItem} from "types/faq";

import header_back from "img/m/header-back.svg";

function DivFaqItem(props: { item: FaqItem, on?: boolean }) {
    const [activeKey, setActiveKey] = useState<AccordionEventKey>();

    return (
        <Accordion onSelect={setActiveKey} as={"dl"}
                   className={activeKey == "0" ? "accordian faq on" : "accordian faq"}>
            <Accordion.Item eventKey="0" bsPrefix="#">
                <Accordion.Button as={"dt"} bsPrefix="#">
                    {props.item.title}
                </Accordion.Button>
                <Accordion.Body>
                    <dd>
                        {props.item.contents}
                    </dd>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

function MyFaq() {
    const faqs: FaqItem[] = [
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
        {
            title: "광고등록 방법 알려주세요.",
            contents: "마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다. 마이페이지 > 광고등록 버튼 클릭 후, 광고하실 정보를 등록하시면 됩니다."
        },
    ];

    return (
        <div>
            <div className="contents mobile page-my">
                <header className="header-sub shadow-none">
                    <div className="header_left">
                        <a className="back" onClick={() => history.back()}><img src={header_back} alt="뒤로가기"/></a>
                        <span className="location">FAQ</span>
                    </div>
                </header>
                <div className="section pd0">
                    <div className="faq-input">
                        {/*TODO : 기능 구현 필요*/}
                        <input type="text" placeholder="무엇을 도와드릴까요?"/>
                    </div>
                    {faqs.map((faq, key) => (
                        <DivFaqItem item={faq} key={key}/>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default MyFaq;