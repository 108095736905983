import React from "react";

import dummy_01 from "img/m/dummy-01.png";
import {AdUser} from "types/adUser";

function MobileAdThumbnailSmall(props: { user: AdUser, header?: JSX.Element }) {
    return (
        <div className="thumbnail width small">
            {props.header}
            <div className="image_area">
                <div className="button heart purple _onOff"></div>
                <img src={dummy_01}/>
            </div>
            <div className="text_area space-between">
                <div className="text_top">
                    <a href="#" className="cate type-star">
                        <span className="name">{props.user.name}</span>
                        <span className="grade"><span className="star">★</span>{props.user.rating}</span>
                    </a>
                </div>
                <div className="spec">
                    <div className="ad">광고수 <span>{props.user.adCount.toLocaleString()}</span></div>
                    <div className="follow">팔로워 <span>{props.user.follow.toLocaleString()}</span></div>
                </div>
            </div>
        </div>
    );
}

export default MobileAdThumbnailSmall;